const Library_mobile_ja = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] プロセッサー搭載"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] プロセッサー搭載"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "頭脳と同じように、プロセッサーは PC が実行することを制御します。あなたが見るビデオ、あなたがプレイするゲーム、あなたが訪れるウェブサイト、すべてプロセッサーから始まります。",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "プロセッサーは PC の頭脳です"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "パフォーマンスと価値",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "インテル® Pentium® Silver プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_subtitle": "ウェブ経由でファイルへアクセスするパフォーマンスによりデジタル世界の最新情報を把握します。プログラムを簡単に切り替えられるため、マルチタスクが容易になり、より多くを短時間でこなすことができます。",
                    "tr_gen_core_title": "インテル® Pentium® Silver プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_subtitle": "強化された生産性 - 最も負荷の高い日を乗り切るための高いパフォーマンス 効率的なウェブ・ブラウジング - メディアリッチなウェブページを迅速に読み込み、少ない待ち時間で、より多くを閲覧",
                    "tr_gen_core_title": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "インテル® Celeron® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_subtitle": "仕事とエンターテインメントの効率性を高めるパフォーマンス 映画や Web ビデオを細部まできれいに表示可能 作業に費やす時間を増やし、待ち時間を減らす。",
                    "tr_gen_core_title": "インテル® Celeron® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "インテル® Pentium® プロセッサー",
                    "tr_subtitle": "素晴らしいエンターテインメント、ビデオ・ストリーミング、生産性を実現する設計。映画や Web ビデオを細部まできれいに表示可能。作業に費やす時間を増やし、待ち時間を減らす。",
                    "tr_gen_core_title": "インテル® Pentium® プロセッサー"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "必要なパフォーマンスを、 手頃な価格で。",
                "tr_title": "インテル® プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "独自のカテゴリーとその価値",
                    "tr_subtitle": "ひとつのインテル® プロセッサーが、手の届く価格で、望むタスクのほぼすべてを賄います。ソフトウェア間の移動もスムーズに。安定したパフォーマンスで高品質のグラフィックスを楽しめます。",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "つながりをより強く",
                    "tr_subtitle": "より鮮やかなビデオ品質、豊かで明瞭なオーディオ、そしてほぼ 3 倍高速なインターネット接続により、場所を問わずに堂々とコラボレーションできます。<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "その違いをずっと感じる",
                    "tr_subtitle": "朝の映画マラソンからぎりぎりのタッチアップまで、インテル® プロセッサーがデバイスのバッテリー持続時間を拡張し、没入できて途切れない体験を提供します。",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "すべての環境が、学習環境",
                    "tr_subtitle": "自宅のあらゆる場所で、複数のオンライン学習ツールを簡単に実行します。"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "好きな場所で学ぶ",
                    "tr_subtitle": "さまざまな e 学習ツールを同時に実行しながら、好みの場所でレッスンを完了できます。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "予算やライフスタイルに合わせて、エンターテイメントや生産性の向上を実現できるパワーを提供します。",
                "tr_title": "予算に応じたイ&#65279;ン&#65279;テ&#65279;ル&#65279;のパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス。",
                "tr_gen_core_title": "インテル® Celeron® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "processorCompare": {
                  "tr_title": "適切なレベルのパフォーマンスと価値を見つける",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "インテル® Celeron® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "高速でメディアリッチなウェブ・ブラウジング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "プログラムのスムーズな切り替え",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "カジュアルゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K ビデオのストリーミング",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "簡単な写真やビデオの編集",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "インテリジェントなパフォーマンスで生産性を向上",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "第 7 世代インテル® Core™ [cpu_fam] プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "第 6 世代インテル® Core™ [cpu_fam] プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "第 5 世代インテル® Core™ [cpu_fam] プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "第 4 世代インテル® Core™ [cpu_fam] プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "第 3 世代インテル® Core™ [cpu_fam] プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "第 2 世代インテル® Core™ [cpu_fam] プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "第 1 世代インテル® Core™ [cpu_fam] プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "お勧めの用途:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "第 8 世代インテル® Core™ i3 プロセッサー搭載",
                "tr_gen_core_title": "第 8 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 世代インテル® Core™ プロセッ&#xfeff;サ&#xfeff;ー&#xfeff;は、最&#xfeff;新&#xfeff;の&#xfeff;デ&#xfeff;ジタル世界についていける&#xfeff;よ&#xfeff;う&#xfeff;に開&#xfeff;発&#xfeff;さ&#xfeff;れました。高レベルの速度とパ&#xfeff;フ&#xfeff;ォ&#xfeff;ーマ&#xfeff;ン&#xfeff;ス&#xfeff;の組み合わせは、PC でなけれ&#xfeff;ば&#xfeff;で&#xfeff;きな&#xfeff;か&#xfeff;っ&#xfeff;たことを、フラストレーショ&#xfeff;ン&#xfeff;な&#xfeff;しに&#xfeff;い&#xfeff;くら&#xfeff;でも実行できるようになります。",
                "tr_title": "第 9 世代インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "どこでも活躍する余裕のバッテリー持続時間に見合う万能のパフォーマンス。",
                "tr_title": "1 日中使えるバ&#xfeff;ッ&#xfeff;テ&#xfeff;リ&#xfeff;ー",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorCompare": {
                  "tr_title": "適切なレベルの第 10 世代パフォーマンスを見つけてください",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "4K の映画をストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高速で信頼性の高い接続性",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "写真のマスキングとビデオのフィルタリング",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "1080p のスムーズなフレームレートでのゲ&#65279;ー&#65279;ム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "大きなファイルやクリエイティブなプログラムに最適",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "映画のストリーミングから、プレゼンテーションの作成まで、十分なパフォーマンスを持続できます。",
                "tr_title": "1 日中使えるバ&#xfeff;ッ&#xfeff;テ&#xfeff;リ&#xfeff;ー",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "このノートブック PC は、移動が多いお客様のために作られました。",
                "tr_title": "新次元のノートブック PC をご覧ください",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "ノートブック PC 向けに設計された第 11 世代インテル® Core™ i3 プロセッサーを使用すると、場所に影響されることなくより多くのことができるようになります。",
                "tr_title": "新しいノートブック PC を最大限に活用",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i3 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "家族の皆が 1 つのデスクトップ PC であらゆる作業をこなすためには、第 11 世代インテル® Core™ i3 プロセッサーのパワーと汎用性が必要です。",
                "tr_title": "境界を超えるパフォーマンスであらゆることを可能に。",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i3 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "第 8 世代インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載",
                "tr_gen_core_title": "第 8 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 世代インテル® Core™ プロセッ&#xfeff;サ&#xfeff;ー&#xfeff;は、最&#xfeff;新&#xfeff;の&#xfeff;デ&#xfeff;ジタル世界についていける&#xfeff;よ&#xfeff;う&#xfeff;に開&#xfeff;発&#xfeff;さ&#xfeff;れました。高レベルの速度とパ&#xfeff;フ&#xfeff;ォ&#xfeff;ーマ&#xfeff;ン&#xfeff;ス&#xfeff;の組み合わせは、PC でなけれ&#xfeff;ば&#xfeff;で&#xfeff;きな&#xfeff;か&#xfeff;っ&#xfeff;たことを、フラストレーショ&#xfeff;ン&#xfeff;な&#xfeff;しに&#xfeff;い&#xfeff;くら&#xfeff;でも実行できるようになります。",
                "tr_title": "第 9 世代インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "最も要求の厳しいゲームでスムーズなゲームプレイと VR を",
                "tr_title": "真剣勝負は、ここから始まる",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合うインテル® Core™ プロセッサーを探す。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ゲーム、ストリーミング、録画のすべてを妥協なく",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高のゲーム向け第 9 世代インテル® Core™ プロセッサー",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.50GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 4 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "複数のプログラムを簡単に処理できるため、あらゆるタスクに、あらゆる場所で対応します。",
                "tr_title": "外出先でマ&#65279;ル&#65279;チ&#65279;タ&#65279;ス&#65279;ク処理",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorCompare": {
                  "tr_title": "適切なレベルの第 10 世代パフォーマンスを見つけてください",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "4K の映画をストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高速で信頼性の高い接続性",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "写真のマスキングとビデオのフィルタリング",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "1080p のスムーズなフレームレートでのゲ&#65279;ー&#65279;ム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "大きなファイルやクリエイティブなプログラムに最適",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "エンターテインメントから生産性、コンテンツ作成に至るまで、あ&#65279;ら&#65279;ゆ&#65279;る&#65279;作&#65279;業&#65279;を向&#65279;上させます。",
                "tr_title": "よりパワフルな PC にア&#65279;ッ&#65279;プ&#65279;グ&#65279;レ&#65279;ー&#65279;ドしましょう",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "このノートブック PC は、移動が多いお客様のために作られました。",
                "tr_title": "新次元のノートブック PC をご覧ください",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "シームレスなストリーミングと AAA ゲーム向けの構築",
                "tr_title": "好みの環境でプレイ",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.50GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "シームレスなストリーミングと AAA ゲーム向けの構築",
                "tr_title": "好みの環境でプレイ",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.80GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 10 <br/>スレッド数: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "複数のプログラムを簡単に処理できるため、あらゆるタスクに、あらゆる場所で対応します。",
                "tr_title": "外出先でマ&#65279;ル&#65279;チ&#65279;タ&#65279;ス&#65279;ク処理",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 10 世代インテル® Core™&nbsp;i5 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 世代インテル® Core™ i5 プロセッサーは、薄型軽量 PC で鮮やかなビ&#65279;ジ&#65279;ュ&#65279;ア&#65279;ル体験と広範囲にわたる生産性を実現します。",
                "tr_title": "驚異的なノートブ&#xfeff;ッ&#xfeff;ク PC のパワー",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i5 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "家族の皆が 1 つのデスクトップ PC であらゆる作業をこなすためには、第 11 世代インテル® Core™ i5 プロセッサーのパワーと汎用性が必要です。",
                "tr_title": "境界を超えるパフォーマンスであらゆることを可能に。",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i5 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 世代インテル® Core™ i5 プロセッサーは、薄型軽量ノートブック PC に鮮やかなビジュアル体験と広範な生産性をもたらします。",
                "tr_title": "驚異的なノートブ&#xfeff;ッ&#xfeff;ク PC のパワー",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i5 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "第 8 世代インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載 ",
                "tr_gen_core_title": "第 8 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "第 9 世代インテル® Core™ プロセッ&#xfeff;サ&#xfeff;ー&#xfeff;は、最&#xfeff;新&#xfeff;の&#xfeff;デ&#xfeff;ジタル世界についていける&#xfeff;よ&#xfeff;う&#xfeff;に開&#xfeff;発&#xfeff;さ&#xfeff;れました。高レベルの速度とパ&#xfeff;フ&#xfeff;ォ&#xfeff;ーマ&#xfeff;ン&#xfeff;ス&#xfeff;の組み合わせは、PC でなけれ&#xfeff;ば&#xfeff;で&#xfeff;きな&#xfeff;か&#xfeff;っ&#xfeff;たことを、フラストレーショ&#xfeff;ン&#xfeff;な&#xfeff;しに&#xfeff;い&#xfeff;くら&#xfeff;でも実行できるようになります。",
                "tr_title": "第 9 世代インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS が最大 32% 向上ゲ&#xfeff;ー&#xfeff;ム&#xfeff;、ストリーミング、録画の&#xfeff;同&#xfeff;時&#xfeff;実&#xfeff;行時(3 年前の PC&nbsp;と&#xfeff;比&#xfeff;較&#xfeff;)<sup>3</sup>",
                "tr_title": "最高のゲームプレイの瞬間を共有するパワー",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合うインテル® Core™ プロセッサーを探す。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ゲーム、ストリーミング、録画のすべてを妥協なく",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高のゲーム向け第 9 世代インテル® Core™ プロセッサー",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.50GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 4 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "このスタイリッシュなデバイスは、効率を重視して開発されており、作成、接続、ストリーミングの方法を大きく変えます。",
                "tr_title": "どこでも先進的なパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorCompare": {
                  "tr_title": "適切なレベルの第 10 世代パフォーマンスを見つけてください",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "4K の映画をストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高速で信頼性の高い接続性",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "写真のマスキングとビデオのフィルタリング",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "1080p のスムーズなフレームレートでのゲ&#65279;ー&#65279;ム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "大きなファイルやクリエイティブなプログラムに最適",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "あらゆる作業を次のレベルへと押し上げる先進的パフォーマンス",
                "tr_title": "ゆとりある処理能力",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "このノートブック PC は、移動が多いお客様のために作られました。",
                "tr_title": "新次元のノートブック PC をご覧ください",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "優れた競争力でゲーム + ストリーム + 録画",
                "tr_title": "ゲームの世界をパ&#65279;ワ&#65279;ー&#65279;ア&#65279;ッ&#65279;プ",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.50GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "優れた競争力でゲーム + ストリーム + 録画",
                "tr_title": "ゲームの世界をパ&#65279;ワ&#65279;ー&#65279;ア&#65279;ッ&#65279;プ",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.80GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 10 <br/>スレッド数: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "このスタイリッシュなデバイスは、効率を重視して設計され、作成、接続、ストリーミングの方法を大きく覆します。",
                "tr_title": "どこでも優れたパ&#xfeff;フ&#xfeff;ォ&#xfeff;ー&#xfeff;マンス",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 10 世代インテル® Core™&nbsp;i7 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 世代インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー&#65279;は、超軽量ノートブック PC&nbsp;に&#65279;お&#65279;い&#65279;て高度なコンテンツ作成、ス&#65279;ム&#65279;ー&#65279;ズ&#65279;な&#65279;ゲ&#65279;ームプレイ、次&#65279;世&#65279;代&#65279;のエンターテインメントを可能にします。",
                "tr_title": "限界を超えるノ&#65279;ー&#65279;ト&#65279;ブ&#65279;ッ&#65279;ク PC のパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "第 11 世代インテル® Core™ i7 プロセッサーの境界を超えるパフォーマンスにより、デスクトップ PC を家庭で必要なあらゆる作業をこなす拠点にします。",
                "tr_title": "仕事でも、学びでも、遊びでも、限&#65279;界&#65279;な&#65279;し&#65279;。",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 世代インテル® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー&#xfeff;は&#xfeff;、薄型軽量&#xfeff;ノ&#xfeff;ート&#xfeff;ブ&#xfeff;ッ&#xfeff;ク PC&nbsp;に&#xfeff;お&#xfeff;け&#xfeff;る高度&#xfeff;な&#xfeff;コ&#xfeff;ンテ&#xfeff;ン&#xfeff;ツ&#xfeff;作成、ス&#xfeff;ム&#xfeff;ー&#xfeff;ズ&#xfeff;な&#xfeff;ゲ&#xfeff;ー&#xfeff;ムプ&#xfeff;レ&#xfeff;イ&#xfeff;、ワンランク上&#xfeff;の&#xfeff;エ&#xfeff;ンタ&#xfeff;ー&#xfeff;テ&#xfeff;インメントを可能にし&#xfeff;ま&#xfeff;す&#xfeff;。",
                "tr_title": "限界を超えるノ&#65279;ー&#65279;ト&#65279;ブ&#65279;ッ&#65279;ク PC のパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 世代インテル® Core™ i7 プロセッサーの境界を超えるパフォーマンスにより、デスクトップ PC を家庭で必要なあらゆる作業をこなす拠点にします。",
                "tr_title": "境界のないコンテンツ作成",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "高度なコンテンツ作成、スムーズなゲームプレイ、そしてワンランク上のエンターテインメント、第 11 世代インテル® Core™ i7 プロセッサーは強化されました。",
                "tr_title": "限界を超えるノ&#65279;ー&#65279;ト&#65279;ブ&#65279;ッ&#65279;ク PC のパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "第 8 世代インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載",
                "tr_gen_core_title": "第 8 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 世代インテル® Core™ プロセッ&#xfeff;サ&#xfeff;ー&#xfeff;は、最&#xfeff;新&#xfeff;の&#xfeff;デ&#xfeff;ジタル世界についていける&#xfeff;よ&#xfeff;う&#xfeff;に開&#xfeff;発&#xfeff;さ&#xfeff;れました。高レベルの速度とパ&#xfeff;フ&#xfeff;ォ&#xfeff;ーマ&#xfeff;ン&#xfeff;ス&#xfeff;の組み合わせは、PC でなけれ&#xfeff;ば&#xfeff;で&#xfeff;きな&#xfeff;か&#xfeff;っ&#xfeff;たことを、フラストレーショ&#xfeff;ン&#xfeff;な&#xfeff;しに&#xfeff;い&#xfeff;くら&#xfeff;でも実行できるようになります。",
                "tr_title": "第 9 世代インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS が最大 41% 向上ゲ&#xfeff;ー&#xfeff;ム&#xfeff;、&#xfeff;ス&#xfeff;トリーミング、録画の&#xfeff;同&#xfeff;時&#xfeff;実&#xfeff;行&#xfeff;時(3 年前の PC&nbsp;と&#xfeff;比&#xfeff;較)<sup>4</sup>",
                "tr_title": "ゲーマーのニーズをかな&#xfeff;え&#xfeff;るイ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合うインテル® Core™ プロセッサーを探す。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ゲーム、ストリーミング、録画のすべてを妥協なく",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高のゲーム向け第 9 世代インテル® Core™ プロセッサー",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.50GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 4 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "究極のゲーム体験で自分の可能性を広げよう",
                "tr_title": "最高レベルでの競争",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.50GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "究極のゲーム体験で自分の可能性を広げよう",
                "tr_title": "最高レベルでの競争",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.80GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 10 <br/>スレッド数: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "究極のクリエーター体験で自分の可能性を広げましょう",
                "tr_title": "最高レベルでコンテンツを作成",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 10 世代インテル® Core™&nbsp;i9 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 世代インテル® Core™ i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ーとインテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス&#xfeff;を&#xfeff;搭載したインテル® Evo™ ノートブック PC は、薄&#xfeff;型&#xfeff;軽&#xfeff;量デ&#xfeff;ザ&#xfeff;イ&#xfeff;ンで驚異的な速度とパフォーマンスを実&#xfeff;現&#xfeff;し&#xfeff;ま&#xfeff;す。",
                "tr_title": "進化したノ&#xfeff;ー&#xfeff;ト&#xfeff;ブ&#xfeff;ッ&#xfeff;ク&nbsp;PC&nbsp;の増&#xfeff;強&#xfeff;さ&#xfeff;れ&#xfeff;た&#xfeff;パ&#xfeff;ワ&#xfeff;ー",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i5 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 世代インテル® Core™ i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー&#xfeff;と&#xfeff;イ&#xfeff;ンテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス&#xfeff;を&#xfeff;搭載したインテル® Evo™ ノートブック PC は、最&#xfeff;高&#xfeff;のモ&#xfeff;バ&#xfeff;イ&#xfeff;ル&#xfeff;体験を実現する圧倒的&#xfeff;なス&#xfeff;ピ&#xfeff;ー&#xfeff;ドとパフォーマンスを提&#xfeff;供&#xfeff;し&#xfeff;ま&#xfeff;す。",
                "tr_title": "進化したノ&#xfeff;ー&#xfeff;ト&#xfeff;ブ&#xfeff;ッ&#xfeff;ク PC の先&#xfeff;進&#xfeff;的&#xfeff;な&#xfeff;パ&#xfeff;ワ&#xfeff;ー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "パフォーマンス、メディア体験や接続性を驚きの価格でお楽しみく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                "tr_title": "驚きの価格で実現&#xfeff;す&#xfeff;るパ&#xfeff;フ&#xfeff;ォ&#xfeff;ー&#xfeff;マ&#xfeff;ン&#xfeff;ス&#xfeff;と接続性。",
                "tr_gen_core_title": "インテル® Pentium® Silver プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "processorCompare": {
                  "tr_title": "適切なレベルのパフォーマンスと価値を見つける",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "インテル® Pentium® Silver プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "高速でメディアリッチなウェブ・ブラウジング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "プログラムのスムーズな切り替え",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "カジュアルゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K ビデオのストリーミング",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "簡単な写真やビデオの編集",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "インテリジェントなパフォーマンスで生産性を向上",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "シームレスなマルチタスク、シンプルな写真やビデオの編集、4K ビ&#65279;デ&#65279;オ&#65279;や鮮やかなグラフィックスを、驚きの価格でお楽しみく&#65279;だ&#65279;さ&#65279;い。",
                "tr_title": "仕事も遊びもこなせるパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス&#65279;。",
                "tr_gen_core_title": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "processorCompare": {
                  "tr_title": "適切なレベルのパフォーマンスと価値を見つける",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "インテル® Pentium® Silver プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "高速でメディアリッチなウェブ・ブラウジング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "プログラムのスムーズな切り替え",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "カジュアルゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K ビデオのストリーミング",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "簡単な写真やビデオの編集",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "インテリジェントなパフォーマンスで生産性を向上",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA ランクのゲームを長いバッテリー持続時間で楽しめる超軽量ノートブック PC。妥協をせずに楽しみたいから。",
                "tr_title": "妥協はしなくてい&#65279;い&#65279;。",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "あなたに合うゲーム向けの超軽量インテル® Core™ プロセッサーを見つける。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i5&#8209;11300H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i7&#8209;11370H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i7&#8209;11375H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー・ス&#65279;ペ&#65279;シ&#65279;ャ&#65279;ル・エ&#65279;デ&#65279;ィ&#65279;シ&#65279;ョ&#65279;ン"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "最大 <span class='style_badge'>4.40GHz</span> の動作周波数",
                      "tr_td2_title": "最大 <span class='style_badge'>4.80GHz の</span>動作周波数",
                      "tr_td3_title": "最大 <span class='style_badge'>5GHz</span> の動作周波数"
                    },
                    {
                      "tr_td1_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド",
                      "tr_td2_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド",
                      "tr_td3_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド"
                    },
                    {
                      "tr_td1_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                      "tr_td2_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                      "tr_td3_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・マ&#65279;ッ&#65279;ク&#65279;ス&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー 3.0"
                    },
                    {
                      "tr_td1_title": "8MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ",
                      "tr_td2_title": "12MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ",
                      "tr_td3_title": "12MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ"
                    },
                    {
                      "tr_td1_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                      "tr_td2_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                      "tr_td3_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "バッテリー使用時の優れたパフォーマンスと、1080p 高設定の FPS で AAA ランクのゲームプレイを楽しめる超軽量ノ&#65279;ー&#65279;ト&#65279;ブ&#65279;ッ&#65279;ク PC。",
                "tr_title": "臨場感はここにある",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "あなたに合うゲーム向けの超軽量インテル® Core™ プロセッサーを見つける。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i5&#8209;11300H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i7&#8209;11370H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i7&#8209;11375H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー・ス&#65279;ペ&#65279;シ&#65279;ャ&#65279;ル・エ&#65279;デ&#65279;ィ&#65279;シ&#65279;ョ&#65279;ン"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "最大 <span class='style_badge'>4.40GHz</span> の動作周波数",
                      "tr_td2_title": "最大 <span class='style_badge'>4.80GHz の</span>動作周波数",
                      "tr_td3_title": "最大 <span class='style_badge'>5GHz</span> の動作周波数"
                    },
                    {
                      "tr_td1_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド",
                      "tr_td2_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド",
                      "tr_td3_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド"
                    },
                    {
                      "tr_td1_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                      "tr_td2_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                      "tr_td3_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・マ&#65279;ッ&#65279;ク&#65279;ス&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー 3.0"
                    },
                    {
                      "tr_td1_title": "8MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ",
                      "tr_td2_title": "12MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ",
                      "tr_td3_title": "12MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ"
                    },
                    {
                      "tr_td1_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                      "tr_td2_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                      "tr_td3_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "最大 5GHz の動作周波数がゲームの楽しみを広げ、どこへでも持ち運べる超軽量ノートブック PC。",
                "tr_title": "軽量ボディーに、優&#65279;れ&#65279;た&#65279;パ&#65279;ワ&#65279;ー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー&#xfeff;・ス&#xfeff;ペ&#xfeff;シ&#xfeff;ャ&#xfeff;ル&#xfeff;・エ&#xfeff;デ&#xfeff;ィ&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プロセッサー・スペシャル・エディション",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "あなたに合うゲーム向けの超軽量インテル® Core™ プロセッサーを見つける。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i5&#8209;11300H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i7&#8209;11370H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i7&#8209;11375H プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー・ス&#65279;ペ&#65279;シ&#65279;ャ&#65279;ル・エ&#65279;デ&#65279;ィ&#65279;シ&#65279;ョ&#65279;ン"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "最大 <span class='style_badge'>4.40GHz</span> の動作周波数",
                      "tr_td2_title": "最大 <span class='style_badge'>4.80GHz の</span>動作周波数",
                      "tr_td3_title": "最大 <span class='style_badge'>5GHz</span> の動作周波数"
                    },
                    {
                      "tr_td1_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド",
                      "tr_td2_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド",
                      "tr_td3_title": "4 コア / 8&nbsp;ス&#65279;レ&#65279;ッ&#65279;ド"
                    },
                    {
                      "tr_td1_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                      "tr_td2_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                      "tr_td3_title": "インテル® タ&#65279;ー&#65279;ボ&#65279;・ブ&#65279;ー&#65279;ス&#65279;ト&#65279;・マ&#65279;ッ&#65279;ク&#65279;ス&#65279;・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー 3.0"
                    },
                    {
                      "tr_td1_title": "8MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ",
                      "tr_td2_title": "12MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ",
                      "tr_td3_title": "12MB イ&#65279;ン&#65279;テ&#65279;ル&#65279;® ス&#65279;マ&#65279;ー&#65279;ト&#65279;・キ&#65279;ャ&#65279;ッ&#65279;シ&#65279;ュ"
                    },
                    {
                      "tr_td1_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                      "tr_td2_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                      "tr_td3_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 世代インテル® Core™ i7 プロセッサー・スペシャル・エディションは、高度なコンテンツ作成、スムーズなゲームプレイ、次世代のエンターテインメントを超軽量ノートブック PC にもたらします。",
                "tr_title": "限界を超えるノ&#65279;ー&#65279;ト&#65279;ブ&#65279;ッ&#65279;ク PC のパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー&#xfeff;・ス&#xfeff;ペ&#xfeff;シ&#xfeff;ャ&#xfeff;ル&#xfeff;・エ&#xfeff;デ&#xfeff;ィ&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー・ス&#65279;ペ&#65279;シ&#65279;ャ&#65279;ル・エディション"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 世代インテル® Core™ i7 プロセッサー・スペシャル・エディションは、高度なコンテンツ作成、スムーズなゲームプレイ、次世代のエンターテインメントを超軽量ノートブック PC にもたらします。",
                "tr_title": "限界を超えるノ&#65279;ー&#65279;ト&#65279;ブ&#65279;ッ&#65279;ク PC のパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー&#xfeff;・ス&#xfeff;ペ&#xfeff;シ&#xfeff;ャ&#xfeff;ル&#xfeff;・エ&#xfeff;デ&#xfeff;ィ&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー・ス&#65279;ペ&#65279;シ&#65279;ャ&#65279;ル・エディション",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プロセッサー・スペシャル・エディション",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "高い動作周波数と高いコア数がシームレスに連携し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "充実のパワー、理&#xfeff;想&#xfeff;的&#xfeff;なバランス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "動作周波数とコア性能を最適にバランス。高 FPS での対戦型ゲームでも臨場感あふれる AAA ゲームでも、思う存分ゲームを楽しめます。",
                "tr_title": "やり遂げるパワー",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高 FPS で負荷の高いゲームのプレイ、 録画、そして ストリーミングを同時実行。マルチタスクによる高ワークロードへの切り替えも素早く。",
                "tr_title": "限界を感じさせないパ&#xfeff;ワ&#xfeff;ー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高い動作周波数と高いコア数がシームレスに連携し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "充実のパワー、理&#xfeff;想&#xfeff;的&#xfeff;なバランス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "映画のストリーミングから、プレゼンテーションの作成まで、十分なパフォーマンスを持続できます。",
                "tr_title": "1 日中使えるバ&#xfeff;ッ&#xfeff;テ&#xfeff;リ&#xfeff;ー",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_subTitle2": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "どこでも活躍する余裕のバッテリー持続時間に見合う万能のパフォーマンス。",
                "tr_title": "1 日中使えるバ&#xfeff;ッ&#xfeff;テ&#xfeff;リ&#xfeff;ー",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_subTitle2": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "複数のプログラムを簡単に処理できるため、あらゆるタスクに、あらゆる場所で対応します。",
                "tr_title": "外出先でマ&#65279;ル&#65279;チ&#65279;タ&#65279;ス&#65279;ク処理",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_subTitle2": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "エンターテインメントから生産性、コンテンツ作成に至るまで、あ&#65279;ら&#65279;ゆ&#65279;る&#65279;作&#65279;業&#65279;を向&#65279;上させます。",
                "tr_title": "よりパワフルな PC にア&#65279;ッ&#65279;プ&#65279;グ&#65279;レ&#65279;ー&#65279;ドしましょう",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_subTitle2": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高 FPS での対戦型ゲームでも、臨場感あふれる AAA ゲームでも、デスクトップ PC に匹敵するゲーム・パフォーマンスをノートブック PC で実現します。",
                "tr_title": "やり遂げるパワー",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高 FPS での対戦型ゲームでも、臨場感あふれる AAA ゲームでも、デスクトップ PC に匹敵するゲーム・パフォーマンスをノートブック PC で実現します。",
                "tr_title": "やり遂げるパワー",
                "tr_gen_core_title": "第 11 世代インテル® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i5 プロセッサー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高 FPS で、ゲーム、録画、ストリーミングを同時実行し、すばやくマルチタスクの重いワークロードに切り替えのできるノートブック PC です。",
                "tr_title": "限界を感じさせないパ&#xfeff;ワ&#xfeff;ー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高 FPS で、ゲーム、録画、ストリーミングを同時実行し、すばやくマルチタスクの重いワークロードに切り替えのできるノートブック PC です。",
                "tr_title": "限界を感じさせないパ&#xfeff;ワ&#xfeff;ー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i7 プロセッサー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "PC とともに行く先々で高い動作周波数と高コア数がシームレスに連動し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "充実のパワー、理&#xfeff;想&#xfeff;的&#xfeff;なバランス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "PC とともに行く先々で高い動作周波数と高コア数がシームレスに連動し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "充実のパワー、理&#xfeff;想&#xfeff;的&#xfeff;なバランス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "PC とともに行く先々で高い動作周波数と高コア数、そしてアンロック対応のパフォーマンスががシームレスに連動し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "完璧なバランス、 解き放たれるパワー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "PC とともに行く先々で高い動作周波数と高コア数、そしてアンロック対応のパフォーマンスががシームレスに連動し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "完璧なバランス、 解き放たれるパワー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高い動作周波数と高いコア数がシームレスに連携し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "充実のパワー、理&#xfeff;想&#xfeff;的&#xfeff;なバランス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高動作周波数、高コア数がシームレスに連携。場所を問わず、複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "充実のパワー、理&#xfeff;想&#xfeff;的&#xfeff;なバランス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高動作周波数と高コア数がシームレスに連携。複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "充実のパワー、理&#xfeff;想&#xfeff;的&#xfeff;なバランス",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "究極のゲーム体験で自分の可能性を広げよう",
                "tr_title": "最高レベルでの競争",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.50GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "究極のゲーム体験で自分の可能性を広げよう",
                "tr_title": "最高レベルでの競争",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合う第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ プロセッサーを見つけてく&#65279;だ&#65279;さ&#65279;い&#65279;。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "シームレスな AAA ランクのゲームプレイ",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミング",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ストリーミングと録画",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高度なコンテンツ作成",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高レベルのゲームパフォーマンス",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "アンロックおよびオーバークロック対応 <sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.80GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.10GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 10 <br/>スレッド数: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "このノートブック PC は、移動が多いお客様のために作られました。",
                "tr_title": "新次元のノートブック PC をご覧ください",
                "tr_gen_core_title": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高い動作周波数と高いコア数がシームレスに連携し、対戦型ゲームや複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "完璧なバランス、 解き放たれるパワー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高動作周波数、高コア数、オーバークロック対応のパフォーマンスがシームレスに連携。場所を問わず、複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "完璧なバランス、 解き放たれるパワー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高動作周波数と高コア数がシームレスに連携。複雑なコンテンツ作成で威力を発揮します。",
                "tr_title": "完璧なバランス、 解き放たれるパワー",
                "tr_gen_core_title": "第 11 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "第 11 世代インテル® Core™ i9 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>インテル® ス&#xfeff;マ&#xfeff;ー&#xfeff;ト&#xfeff;・キ&#xfeff;ャ&#xfeff;ッ&#xfeff;シ&#xfeff;ュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "最も要求の厳しいゲームでスムーズなゲームプレイと VR を",
                "tr_title": "真剣勝負は、ここから始まる",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合うインテル® Core™ プロセッサーを探す。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ゲーム、ストリーミング、録画のすべてを妥協なく",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高のゲーム向け第 9 世代インテル® Core™ プロセッサー",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 4 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.60GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.00GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.60GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.90GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.00GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS が最大 32% 向上<br/><span class='tr_subtitle_font'>ゲーム、ストリーミング、録画の&#xfeff;同&#xfeff;時&#xfeff;実&#xfeff;行&#xfeff;時(3 年前の PC&nbsp;と&#xfeff;比&#xfeff;較&#xfeff;)<sup>3</sup></span>",
                "tr_title": "最高のゲームプレイの瞬間を共有するパワー",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合うインテル® Core™ プロセッサーを探す。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ゲーム、ストリーミング、録画のすべてを妥協なく",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高のゲーム向け第 9 世代インテル® Core™ プロセッサー",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 4 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.60GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.00GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.60GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.90GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.00GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS が最大 41% 向上<span class='tr_subtitle_font'>ゲ&#xfeff;ー&#xfeff;ム&#xfeff;、&#xfeff;ス&#xfeff;トリーミング、録画の&#xfeff;同&#xfeff;時&#xfeff;実&#xfeff;行&#xfeff;時(3 年前の PC&nbsp;と&#xfeff;比&#xfeff;較)<sup>4</sup></span>",
                "tr_title": "ゲーマーのニーズをかな&#xfeff;え&#xfeff;るイ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title": "第 9 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>スレッド</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>コア</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>最大<br/> </span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB<br/></span> <span class='subtitle_font'>キャッシュ</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "オーバーク&#xfeff;ロ&#xfeff;ッ&#xfeff;ク&#xfeff;対&#xfeff;応",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "自分のゲームの楽しみ方に合うインテル® Core™ プロセッサーを探す。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "対戦型ゲーム",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ゲーム、ストリーミング、録画のすべてを妥協なく",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最高のゲーム向け第 9 世代インテル® Core™ プロセッサー",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.30GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 4 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.60GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.00GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.60GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 6 <br/>スレッド数: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 4.90GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>動作周波数 <sup>2</sup> : 最&#65279;大 5.00GHz</span>",
                      "tr_subTitle": "<span class='small_font'>コア数: 8 <br/>スレッド数: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "次世代のゲーミング PC のために",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i5 プロセッサー"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i7 プロセッサー"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™ i9 プロセッサー"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>コア</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>スレッド</span></span>",
                    "tr_badgePreTitle": "Performance-cores: [p_thread]",
                    "tr_badgeSubTitle": "Efficient-cores: [e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "ゲームにも仕事にも有利な要素がここにある",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i7 プロセッサー"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™ i9 プロセッサー"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>コア</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>スレッド</span></span>",
                    "tr_badgePreTitle": "Performance-cores: [p_thread]",
                    "tr_badgeSubTitle": "Efficient-cores: [e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "最も必要な場面で信頼のパフォーマンスを発揮",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™ i3 プロセッサー",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "最も必要な場面で優れたパフォーマンスを発揮",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i5 プロセッサー",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "最も必要な場面で優れたパフォーマンスを発揮",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i5 プロセッサー",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "最も必要な場面で卓越したパフォーマンスを発揮",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i7 プロセッサー",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "最も必要な場面で卓越したパフォーマンスを発揮",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i7 プロセッサー",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "インテル® Evo™ ノートブック PC なら、パフォーマンス、堂々のビデオチャットを実現する内蔵イテリジェンス、高速インターネット、長時間持続バッテリーなどが手に入ります。",
                "tr_title": "最も必要な場面で優れたパフォーマンスを発揮",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i5 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "インテル® Evo™ ノートブック PC なら、パフォーマンス、堂々のビデオチャットを実現する内蔵イテリジェンス、高速インターネット、長時間持続バッテリーなどが手に入ります。",
                "tr_title": "最も必要な場面で卓越したパフォーマンスを発揮",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i7 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "インテル® Evo™ ノートブック PC なら、パフォーマンス、堂々のビデオチャットを実現する内蔵イテリジェンス、高速インターネット、長時間持続バッテリーなどが手に入ります。",
                "tr_title": "プレミアムなノートブ&#xfeff;ック PC で究極の性&#xfeff;能&#xfeff;を&#xfeff;発&#xfeff;揮",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "インテル® ターボ・ブースト・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー 2.0 最大 [max_turbo_frequency]GHz",
                "tr_title": "ビジネスニーズに応える",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i5 プロセッサー",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "ノイズ抑制と背景ぼかし",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "最も重要な作業を優先する、パフォーマンスの自動調整",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "スムーズで高品質なウェブ電話体験",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "すぐに利用できるハードウェアベースのセキュリティー",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "リモートデバイス管理機能の実現",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wi-Fi パフォーマンスを自動的に最適化<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "データ主導型アプリによる性能の強化",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "パフォーマンスの強化による生産性の最大化",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "インテル® ターボ・ブースト・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー 2.0 最大 [max_turbo_frequency]GHz",
                "tr_title": "頼れるから、業務に集中できる",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™&nbsp;i7 プロセッサー",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "ノイズ抑制と背景ぼかし",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "最も重要な作業を優先する、パフォーマンスの自動調整",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "スムーズで高品質なウェブ電話体験",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "すぐに利用できるハードウェアベースのセキュリティー",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "リモートデバイス管理機能の実現",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wi-Fi パフォーマンスを自動的に最適化<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "データ主導型アプリによる性能の強化",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "パフォーマンスの強化による生産性の最大化",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "インテル® ターボ・ブースト・テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー 2.0 最大 [max_turbo_frequency]GHz",
                "tr_title": "ビジネスを推進",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 12 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "ノイズ抑制と背景ぼかし",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "最も重要な作業を優先する、パフォーマンスの自動調整",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "スムーズで高品質なウェブ電話体験",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "すぐに利用できるハードウェアベースのセキュリティー",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "リモートデバイス管理機能の実現",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wi-Fi パフォーマンスを自動的に最適化<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "データ主導型アプリによる性能の強化",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "パフォーマンスの強化による生産性の最大化",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "より多くをこなす<br/>信頼のパフォーマンス",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "リアルなパフォーマンスが<br/>より多くをこなす",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i5 プロセッサー",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "リアルなパフォーマンスが<br/>より多くをこなす",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i5 プロセッサー",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "より多くの作業がこなせる、強力なパフォーマンス",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i7 プロセッサー",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "より多くの作業がこなせる、強力なパフォーマンス",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i7 プロセッサー",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "パフォーマンスを超えて",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™ i9 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>コア</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>スレッド</span></span>",
                    "tr_badgePreTitle": "Performance-cores: [p_thread]",
                    "tr_badgeSubTitle": "Efficient-cores: [e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "所有のスマートフォンとシームレスに連携し、<sup>24</sup>最善な Wi-Fi をインテリジェントに選択、そして長時間持続バッテリーを高速充電。",
                "tr_title": "パフォーマンスを超えて<br/>あらゆる場所でより多くをこなす",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i5 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "所有のスマートフォンとシームレスに連携し、<sup>24</sup>最善な Wi-Fi をインテリジェントに選択、そして長時間持続バッテリーを高速充電。",
                "tr_title": "パフォーマンスを超えて<br/>一度により多くをこなす PC",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i7 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "所有のスマートフォンとシームレスに連携し、<sup>24</sup>最善な Wi-Fi をインテリジェントに選択、そして長時間持続バッテリーを高速充電。",
                "tr_title": "パフォーマンスを超えて<br/>重いタスクも高速に",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "パフォーマンスを超えて",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i5 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i7 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™ i9 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>コア</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>スレッド</span></span>",
                    "tr_badgePreTitle": "Performance-cores: [p_thread]",
                    "tr_badgeSubTitle": "Efficient-cores: [e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "パフォーマンスを超えて",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "インテル® Core™ i5 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "インテル® Core™ i7 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "インテル® Core™ i9 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>コア</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>スレッド</span></span>",
                    "tr_badgePreTitle": "Performance-cores: [p_thread]",
                    "tr_badgeSubTitle": "Efficient-cores: [e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "より多くをこなす<br/>信頼のパフォーマンス",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "リアルなパフォーマンスが<br/>より多くをこなす",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "インテル® Core™ i5 プロセッサー",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "より多くの作業がこなせる、強力なパフォーマンス",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "インテル® Core™ i7 プロセッサー",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "パフォーマンスを超えて",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "インテル® Core™ i5 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "インテル® Core™ i7 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                    "tr_gen_core_title_uppercase": "インテル® Core™ i9 プロセッサー",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>コア</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>スレッド</span></span>",
                    "tr_badgePreTitle": "Performance-cores: [p_thread]",
                    "tr_badgeSubTitle": "Efficient-cores: [e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "より多くをこなす<br/>信頼のパフォーマンス",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "リアルなパフォーマンスが<br/>より多くをこなす",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "インテル® Core™&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "より多くの作業がこなせる、強力なパフォーマンス",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_gen_core_title_uppercase": "インテル® Core™&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "インテル® Core™&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "tr_title": "ゲームを妥協なく存分に楽しむ",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™ Ultra&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                    "tr_gen_core_title_uppercase": "インテル® CORE™ ULTRA 5 プロセッサー",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™ Ultra&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                    "tr_gen_core_title_uppercase": "インテル® CORE™ ULTRA 7 プロセッサー",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™ Ultra&nbsp;9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                    "tr_gen_core_title_uppercase": "インテル® CORE™ ULTRA 9 プロセッサー",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>コア</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>スレッド</span></span>",
                    "tr_badgePreTitle": "Performance-cores: [p_thread]",
                    "tr_badgeSubTitle": "Efficient-cores: [e_thread]",
                    "tr_badgeSubTitle1": "LPE-cores: [l_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ Ultra 5 プロセッサー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ Ultra 7 プロセッサー",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "インテル® Core™ Ultra 9 プロセッサー",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "ゲームを妥協なく存分に楽しむ",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "ゲームを妥協なく存分に楽しむ",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "ゲームを妥協なく存分に楽しむ",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "ゲームを妥協なく存分に楽しむ",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "ゲームを妥協なく存分に楽しむ",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "ゲームを妥協なく存分に楽しむ",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小規模ビジネスにふさわしいビ&#65279;ジ&#65279;ネ&#65279;ス&#65279;・ソリューションがあ&#65279;る",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i5 プロセッサー",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "ノイズ抑制と背景ぼかし",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "最も重要な作業を優先する、パフォーマンスの自動調整",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "スムーズで高品質なウェブ電話体験",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "既製のハードウェア・ベースのセキュリティー保護",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "物理的な改ざん攻撃からのデバイスおよびデータの保護",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小規模ビジネスにふさわしいビ&#65279;ジ&#65279;ネ&#65279;ス&#65279;・ソリューションがあ&#65279;る",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™&nbsp;i7 プロセッサー",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "ノイズ抑制と背景ぼかし",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "最も重要な作業を優先する、パフォーマンスの自動調整",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "スムーズで高品質なウェブ電話体験",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "既製のハードウェア・ベースのセキュリティー保護",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "物理的な改ざん攻撃からのデバイスおよびデータの保護",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小規模ビジネスにふさわしいビ&#65279;ジ&#65279;ネ&#65279;ス&#65279;・ソリューションがあ&#65279;る",
                "tr_gen_core_title": "第 13 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "tr_gen_core_title_uppercase": "第 13 世代インテル® Core™ i9 プロセッサー",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "このプロセッサーの性能比較",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "ノイズ抑制と背景ぼかし",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "最も重要な作業を優先する、パフォーマンスの自動調整",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "スムーズで高品質なウェブ電話体験",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "既製のハードウェア・ベースのセキュリティー保護",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "物理的な改ざん攻撃からのデバイスおよびデータの保護",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "新たな AI 体験をビ&#xfeff;ジ&#xfeff;ネ&#xfeff;ス&#xfeff;に",
                "tr_title": "インテル® Core™ Ultra&nbsp;[XX] プロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー搭&#xfeff;載 Intel&nbsp;vPro® プ&#xfeff;ラ&#xfeff;ッ&#xfeff;ト&#xfeff;フ&#xfeff;ォー&#xfeff;ムで&#xfeff;次&#xfeff;に&#xfeff;備&#xfeff;え&#xfeff;る",
                "tr_gen_core_title": "インテル® Core™ Ultra [XX] プロセッサー",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "インテルの AI PC で、ビジネスに活力を",
                    "tr_badgeSubTitle": "充実した体験を生むパワフルな PC は、ビジネスの生産性の中核を担います Intel vPro® プラットフォームは、ワークロードとアプリケーション全体で従業員の効率とパフォーマンスを飛躍的に向上させます。これは、適切なタスクを適切なタイミングで適切なエンジンに送信することで、各種の複雑なビジネス・ワークロードに対し、卓越した電力効率とパフォーマンスを提供する新しいアーキテクチャーによるものです。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "すぐに使用できるマ&#xfeff;ル&#xfeff;チ&#xfeff;レ&#xfeff;イ&#xfeff;ヤ&#xfeff;ー&#xfeff;のセ&#xfeff;キ&#xfeff;ュ&#xfeff;リ&#xfeff;テ&#xfeff;ィ&#xfeff;ー&#xfeff;機&#xfeff;能",
                    "tr_badgeSubTitle": "新興の脅威に対してはセキュリティーの包括的なアプローチが必要です。Intel vPro® プラットフォームは、ハ&#xfeff;ー&#xfeff;ド&#xfeff;ウ&#xfeff;ェ&#xfeff;ア&#xfeff;・レベルおよびスタック全体での保護を提供し、ビジネスの保護を強化します。AI 活用による脅威検出など、こ&#xfeff;れらの独自の既成セキュリティー機能により、ユーザー、デ&#xfeff;ー&#xfeff;タ、ビジネスを保護します。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "実質どこからでも内蔵ツールによりユーザーの生産性を維持",
                    "tr_badgeSubTitle": "どこでもオフィスになる、つまり PC も散在します。Intel&nbsp;vPro® プラットフォームは、最新のハードウェアベースの管理機能を提供し、企業の PC 資産管理の負担を軽減して、サポートを簡素化し、エンドユーザー体験を向上させるとともに、持続可能なコンピューティングの実践を可能にします。",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "すごいだけじゃない インテルの AI PC",
                "tr_subtitle1": "インテル® Core™ Ultra プロセッサー (シリーズ 2) 搭載",
                "tr_subtitle2": "インテルの AI PC で広げる可能性",
                "tr_subtitle": "インテルの最も効率的で安全かつ高性能なモバイル・プロセッサー<sup>39</sup>",
                "tr_gen_core_title": "インテル®&nbsp;Core™ Ultra&nbsp;5 プロセッサー (シリーズ&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "制作を高速化する AI ツールで<span class='spec-heading'>さらにクリエイティブ</span>に"
                  },
                  {
                    "tr_title": "時間を節約する AI アシスタントで<span class='spec-heading'>生産性を向上</span>"
                  },
                  {
                    "tr_title": "内蔵のデータ保護機能で<span class='spec-heading'>さらに安全</span>に"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "すごいだけじゃない インテルの AI PC",
                "tr_subtitle1": "インテル® Core™ Ultra プロセッサー (シリーズ 2) 搭載",
                "tr_subtitle2": "インテルの AI PC で広げる可能性",
                "tr_subtitle": "インテルの最も効率的で安全かつ高性能なモバイル・プロセッサー<sup>39</sup>",
                "tr_gen_core_title": "インテル®&nbsp;Core™ Ultra&nbsp;7 プロセッサー (シリーズ&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "制作を高速化する AI ツールで<span class='spec-heading'>さらにクリエイティブ</span>に"
                  },
                  {
                    "tr_title": "時間を節約する AI アシスタントで<span class='spec-heading'>生産性を向上</span>"
                  },
                  {
                    "tr_title": "内蔵のデータ保護機能で<span class='spec-heading'>さらに安全</span>に"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "すごいだけじゃない インテルの AI PC",
                "tr_subtitle1": "インテル® Core™ Ultra プロセッサー (シリーズ 2) 搭載",
                "tr_subtitle2": "インテルの AI PC で広げる可能性",
                "tr_subtitle": "インテルの最も効率的で安全かつ高性能なモバイル・プロセッサー<sup>39</sup>",
                "tr_gen_core_title": "インテル®&nbsp;Core™ Ultra&nbsp;5 プロセッサー (シリーズ&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "すごいだけじゃない イ&#xfeff;ン&#xfeff;テ&#xfeff;ルの AI PC",
                "tr_subtitle1": "インテル® Core™ Ultra プロセッサー (シリーズ 2) 搭載",
                "tr_subtitle2": "インテルの AI PC で広げる可能性",
                "tr_subtitle": "インテルの最も効率的で安全かつ高性能なモバイル・プロセッサー<sup>39</sup>",
                "tr_gen_core_title": "インテル®&nbsp;Core™ Ultra&nbsp;7 プロセッサー (シリーズ&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_tileTitle1": "専用グラフィックス",
                "tr_title": "グラフィックス・プロセシング・ユニットとは？",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "グラフィックス・プロセシング・ユニット (GPU) は、画面に映し出される画像を創り出します。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "統合型 GPU ",
                    "tr_badgeSubTitle": "一体型 GPU は、コンピューター内の他の部分と同じ RAM を使用するため、電力効率が向上します。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "ディスクリート GPU",
                    "tr_badgeSubTitle": "このタイプは最も強力なクラスの GPU です。専用の RAM と、優れたグラフィックスの表示に特化したほかのリソースも装備しています。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "ハイブリッド GPU",
                    "tr_badgeSubTitle": "このタイプは最も強力なクラスの GPU です。専用の RAM と、優れたグラフィックスの表示に特化したほかのリソースも装備しています。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "写真やビデオをスムーズに編集",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "4K ビデオコンテンツを一気に楽しむ",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "驚異的な鮮明度で人気のゲームをプレイする",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "デュアル GPU",
                    "tr_badgeSubTitle": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフィックスを搭載したシステムなど、一部のシステムでは、プロセッサー内蔵グラフィックスとディスクリート GPU の両方を使用して、グラフィックスのワークロードを分散することができます。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "統合型 GPU ",
                    "tr_badgeSubTitle": "統合型 GPU は、メイン・プロセッサーつまり CPU と同じチップに組み込まれています。このタイプの GPU は、PC 内のほかのコンポーネントと同じ RAM とリソースを使用して、電力効率に優れた薄型軽量のデバイスを構成します。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "専用 GPU",
                    "tr_badgeSubTitle": "このタイプは最も強力なクラスの GPU です。専用の RAM と、優れたグラフィックスの表示に特化したほかのリソースも装備しています。",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "標準",
                    "tr_badgeSubTitle": "最大 4GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "競争に優位",
                    "tr_badgeSubTitle": "最大 8GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "究極レベル",
                    "tr_badgeSubTitle": "8GB 超の VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "高度なグラフィックス処&#65279;理&#65279;能&#65279;力",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像とス&#65279;ム&#65279;ー&#65279;ズ&#65279;なアニメーションのみに注力できる専用のグ&#65279;ラ&#65279;フ&#65279;ィ&#65279;ッ&#65279;ク&#65279;ス&#65279;・カードが必要です。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "色彩豊かで鮮やかなグ&#65279;ラ&#65279;フ&#65279;ィ&#65279;ッ&#65279;ク&#65279;ス機能を内&#65279;蔵",
                "tr_pretitle": "インテル® Iris® Plus グラフィックス",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "インテル® Iris® Plus グラフィックスなら、4K HDR ビデオとフル HD のゲームに没頭できます。グラフィックス・カードを増設する必要はありません。",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "見事なビジュアル、快適なパフォーマンス",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "インテル® グラフィックスは、クリエーターのスムーズなデザインと豊かなゲーム体験をサポートする、変革的な内蔵グラフィックス・パフォーマンスを提供します。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "インテル® Iris® Pro グラフィックス ",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "X<sup class='small_text_sup_xe'>e</sup> アーキテクチャー搭載インテル® UHD グラフィックス",
                "tr_pretitle": "臨場感あふれるビ&#xfeff;ジ&#xfeff;ュ&#xfeff;ア&#xfeff;ル&#xfeff;体&#xfeff;験",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "写真やビデオをスムーズに編集したり、4K ビデオコンテンツを一気に楽しんだり、もしくは驚異的な鮮明度で人気のゲームをプレイしてください。",
                "tr_photo_text": "写真やビデオをスムーズに編集",
                "tr_video_text": "4K ビデオコンテンツを一気に楽しむ",
                "tr_game_text": "驚異的な鮮明度で人気のゲームをプレイする",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "きれいをはるかに超える画質",
                "tr_subtitle": "グラフィックス・カード (GPU) がプロセッサー (CPU)と緊密に連携することで、ゲームの動作と画質が決まります。グラフィックスに特化したビデオ RAM (VRAM) により、GPU のステータスを素早く知ることができます。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span> の <span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "高度なゲーム・グラフィックスに対応",
                "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像やスムーズなアニメーションの作成のみに注力できる専用のグラフィックス・カードが必要です。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span> の <span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "高度なゲーム・グラフィックスに対応",
                "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像やスムーズなアニメーションの作成のみに注力できる専用のグラフィックス・カードが必要です。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span> の <span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "きれいをはるかに超える画質",
                "tr_subtitle": "グラフィックス・カード (GPU) がプロセッサー (CPU)と緊密に連携することで、ゲームの動作と画質が決まります。グラフィックスに特化したビデオ RAM (VRAM) により、GPU のステータスを素早く知ることができます。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span> の <span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "高度なグラフィックス処&#65279;理&#65279;能&#65279;力",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像とス&#65279;ム&#65279;ー&#65279;ズ&#65279;なアニメーションのみに注力できる専用のグ&#65279;ラ&#65279;フ&#65279;ィ&#65279;ッ&#65279;ク&#65279;ス&#65279;・カードが必要です。",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "高度なゲーム・グラフィックスに対応",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像とス&#65279;ム&#65279;ー&#65279;ズ&#65279;なアニメーションのみに注力できる専用のグ&#65279;ラ&#65279;フ&#65279;ィ&#65279;ッ&#65279;ク&#65279;ス&#65279;・カードが必要です。",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span> の <span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像やスムーズなアニメーションの作成のみに注力できる専用のグラフィックス・カードが必要です。",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "ゲームで最低限必要とされるグラフィックス性能を十分に満たします。",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "競技会レベルのゲームを求めるハイエンドのプレーヤー向けです",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "高負荷のコンテンツ作成にも PC を使用する上級のゲーマー向けです",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "鮮明なビデオと臨場感あ&#65279;ふ&#65279;れ&#65279;るゲームプレイ",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "インテル® HD グラフィックス搭載の高解像度画面を最大限に活用して、豊&#65279;か&#65279;な&#65279;マ&#65279;ルチメディア体験と内蔵グラフィックス処理を楽しむことができます。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "鮮明なビデオと画像を実現",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "ビデオのストリーミングや画像でより精緻で鮮やかな色彩の描写が可能になります。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "驚異的なコンテンツ作成とゲーム",
                "tr_pretitle": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "臨場感のあるエンターテインメントや高&#xfeff;度&#xfeff;なコンテンツ作成からメインストリ&#xfeff;ー&#xfeff;ム&#xfeff;・ゲームまで、インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックスは、薄型軽量 PC に&#xfeff;革&#xfeff;新&#xfeff;的なグラフィックス・パフォーマンスをも&#xfeff;た&#xfeff;ら&#xfeff;し&#xfeff;ま&#xfeff;す&#xfeff;。",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_pretitle": "高度なゲーム・グラフィックスに対応",
                "tr_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像やスムーズなアニメーションの作成のみに注力できる専用のグラフィックス・カードが必要です。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]GB<br/></span> の <span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "創造性を解き放て",
                "tr_pretitle": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX グラフィックス",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "プロセッサー内蔵グラフィックスとインテル初のディスクリート・グラフィックス・エンジンのパワーを組み合わせて、クリエイティブなプロジェクトを加速し、さまざまなゲームに没入しましょう。",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "tr_title": "インテル® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX グラフィックス",
                "tr_pretitle": "高度なゲーム・グラフィックスに対応",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "極めて強力なグラフィックス処理には、鮮明でシャープな画像やスムーズなアニメーションの作成のみに注力できる専用のグラフィックス・カードが必要です。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]GB<br/></span> の <span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "グラフィックス",
                "tr_title": "優れたグ&#65279;ラ&#65279;フ&#65279;ィ&#65279;ッ&#65279;ク&#65279;ス機&#65279;能&#65279;を内&#65279;蔵",
                "tr_subtitle": "ゲームや映画、Web ビデオを驚くほど細部まで鮮&#65279;明に楽しむことができます。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "グラフィックス",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "グラフィックス",
                    "tr_specText": "ワークフローを拡張"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "グラフィックス",
                    "tr_specText": "ゲームにパワーを"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "グラフィックス",
                    "tr_specText": "ゲームにパワーを"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "グラフィックス",
                    "tr_specText": "ゲーム体験をレベルア&#xfeff;ッ&#xfeff;プ"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "創造性を解き放て",
                    "tr_specText": "<strong>業界初のハードウェアによる AV1 のエンコード</strong>および AI により強化されたコンテンツ作成など、最も包括的なメディア機能の活用は、どこにいても生産性を高めることができます。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "創造性を解き放て",
                    "tr_specText": "<strong>業界初のハードウェアによる AV1 エンコード</strong>および AI により強化されたコンテンツ作成など、最も包括的なメディア機能で、生産性を高めることができます。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "創造性を解き放て",
                    "tr_specText": "AI により強化された最先端のコンテンツ作成環境を実現する<strong>業界初のハードウェアによる AV1 エンコード</strong>および先進的なインテル® <strong>X<sup>e</sup> メディア・エンジン。</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "創造性を解き放て",
                    "tr_specText": "AI により強化された最先端のコンテンツ作成環境を実現する<strong>業界初のハードウェアによる AV1 エンコード</strong>および先進的なインテル® <strong>X<sup>e</sup> メディア・エンジン。</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "創造性を解き放て",
                    "tr_specText": "AI により強化された最先端のコンテンツ作成環境を実現する<strong>業界初のハードウェアによる AV1 エンコード</strong>および先進的なインテル® <strong>X<sup>e</sup> メディア・エンジン。</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "優れた携帯性とパフォーマンスを両立",
                    "tr_specText": "先端のゲーム機能による、強化パフォーマンスと忠実なグラフィックスで、最新のゲームを満喫できます。<br/><br/>インテル® <strong>X<sup>e</sup>SS (X<sup>e</sup> スーパーサンプリング):  </strong>インテルの AI により強化されたアップスケーリング・テクノロジーが、パフォーマンス向上と滑らかなゲームプレイを実現。<br/><br/><strong>DirectX* 12 Ultimate </strong> のサポート: レイトレーシングや可変レート・シェーディングなどのグラフィックス技術が、ゲームに新しいレベルのリアリズムを実現。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "ゲームを自在に楽しむ。",
                    "tr_specText": "<strong>インテル® Arc™ コントロール</strong>が、直感的で最新のレイアウトでゲーム体験をより上質に。シームレスなドライバーの更新、ゲームの最適化に加え、豊富な機能を備えます。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "ゲームを自在に楽しむ。",
                    "tr_specText": "<strong>インテル® Arc™ コントロール</strong>が、直感的で最新のレイアウトでゲーム体験をより上質に。シームレスなドライバーの更新、ゲームの最適化に加え、豊富な機能を備えます。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "ゲームを自在に楽しむ。",
                    "tr_specText": "<strong>インテル® Arc™ コントロール</strong>のソフトウェアでシームレスにドライバーを更新し、お気に入りゲームの多くを最適化しましょう。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "インテル® プラットフォームの魅力",
                    "tr_specText": "互換性のあるインテル® Core™ プロセッサーと組み合わせて、<strong>インテル® Deep Link テクノロジー</strong>の力を引き出し、コンテンツ作成、ゲーム、ストリーミング体験を強化。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "インテル搭載システムのパワーを引き出す",
                    "tr_specText": "互換性のあるインテル® Core™ プロセッサーと組み合わせて、<strong>インテル® Deep Link テクノロジー</strong>の力を引き出し、コンテンツ作成、ゲーム、ストリーミング体験を強化。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "インテル® プラットフォームの魅力",
                    "tr_specText": "互換性のあるインテル® Core™ プロセッサーと組み合わせて、<strong>インテル® Deep Link テクノロジー</strong>の力を引き出し、コンテンツ作成、ゲーム、ストリーミング体験を強化。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "インテル® プラットフォームの魅力",
                    "tr_specText": "互換性のあるインテル® Core™ プロセッサーと組み合わせて、<strong>インテル® Deep Link テクノロジー</strong>の力を引き出し、コンテンツ作成、ゲーム、ストリーミング体験を強化。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "インテル搭載システムのパワーを引き出す",
                    "tr_specText": "互換性のあるインテル® Core™ プロセッサーと組み合わせて、<strong>インテル® Deep Link テクノロジー</strong>の力を引き出し、コンテンツ作成、ゲーム、ストリーミング体験を強化。"
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "センターステージに",
                    "tr_specText": "<strong>インテル® Arc™ コントロール</strong>・ソフトウェアで先進ストリーミング技術によるスムーズな配信。"
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "センターステージに",
                    "tr_specText": "<strong>インテル® Arc™ コントロール</strong>・ソフトウェアで先進ストリーミング技術によるスムーズな配信。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "臨場感あふれるハイパフォーマンスなゲーム体験を実感",
                    "tr_specText": "高解像度でリフレッシュレートの高い最新の AAA ゲームタイトルで、最善のパフォーマンスを第一に。<br/><br/>インテル® <strong>X<sup>e</sup>SS (X<sup>e</sup> スーパーサンプリング):  </strong>インテルの AI により強化されたアップスケーリング・テクノロジーが、パフォーマンス向上と滑らかなゲームプレイを実現。<br/><br/><strong>DirectX* 12 Ultimate </strong> のサポート: レイトレーシングや可変レート・シェーディングなどのグラフィックス・テクノロジーで、全く新しいレベルのリアリズムを実現。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "臨場感あふれるハイパフォーマンスなゲーム体験を実感",
                    "tr_specText": "高解像度でリフレッシュレートの高い最新の AAA ゲームタイトルで、最善のパフォーマンスを第一に。<br/><br/>インテル® <strong>X<sup>e</sup>SS (X<sup>e</sup> スーパーサンプリング):  </strong>インテルの AI により強化されたアップスケーリング・テクノロジーが、パフォーマンス向上と滑らかなゲームプレイを実現。<br/><br/><strong>DirectX* 12 Ultimate </strong> のサポート: レイトレーシングや可変レート・シェーディングなどのグラフィックス・テクノロジーで、全く新しいレベルのリアリズムを実現。"
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "ゲームにパワーを",
                    "tr_specText": "ゲーム体験を次のレベルに: <br/><br/>インテル® <strong>X<sup>e</sup>SS (X<sup>e</sup> スーパーサンプリング): </strong> インテルの AI により強化されたアップスケーリング・テクノロジーが、パフォーマンス向上と滑らかなゲームプレイを実現。<br/><br/><strong>DirectX* 12 Ultimate </strong> のサポート: レイトレーシングや可変レート・シェーディングなどのグラフィックス技術が、ゲームに新しいレベルのリアリズムを実現。"
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "先進の高速ゲームを体感",
                    "tr_specText": "多くの人気 AAA タイトルでスムーズにプレイを楽しみ、処理要求の厳しい eスポーツのシューティングゲームから、ペースの速いリアルタイム対戦ゲームまで、高いリフレッシュ・レートでプレイできます。<br/><br/>インテル® <strong>X<sup>e</sup>SS (X<sup>e</sup> スーパーサンプリング):  </strong>インテルの AI により強化されたアップスケーリング・テクノロジーが、パフォーマンス向上と滑らかなゲームプレイを実現。<br/><br/><strong>DirectX* 12 Ultimate </strong> のサポート: レイトレーシングや可変レート・シェーディングなどのグラフィックス技術が、ゲームに新しいレベルのリアリズムを実現。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "臨場感あふれるハイパフォーマンスなゲーム体験を実感",
                    "tr_specText": "高解像度でリフレッシュレートの高い最新の AAA ゲームタイトルで、最善のパフォーマンスを第一に。<br/><br/>インテル® <strong>X<sup>e</sup>SS (X<sup>e</sup> スーパーサンプリング):  </strong>インテルの AI により強化されたアップスケーリング・テクノロジーが、パフォーマンス向上と滑らかなゲームプレイを実現。<br/><br/><strong>DirectX* 12 Ultimate </strong> のサポート: レイトレーシングや可変レート・シェーディングなどのグラフィックス・テクノロジーで、全く新しいレベルのリアリズムを実現。"
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "グラフィックス",
                    "tr_specText": "誰もが楽しめるグ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックスの世界"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "フレームとゲームを加速",
                    "tr_specText": "インテル® X<sup class='small_text_sup_xe'>e</sup> スーパーサンプリング (インテル® X<sup class='small_text_sup_xe'>e</sup>SS) テ&#xfeff;クノロジーは、AI で強化されたアップスケーリン&#xfeff;グ&#xfeff;によ&#xfeff;り&#xfeff;ゲームプレイをワンランク上に引き上げ、忠&#xfeff;実&#xfeff;度&#xfeff;の高&#xfeff;い&#xfeff;画&#xfeff;質で最高峰のパフォーマンスを実現&#xfeff;しま&#xfeff;す。イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>SS は&#xfeff;イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Arc™ グラフィック&#xfeff;ス&#xfeff;製&#xfeff;品&#xfeff;に最&#xfeff;適&#xfeff;化&#xfeff;さ&#xfeff;れているため、インテル® X<sup class='small_text_sup_xe'>e</sup> マトリクス・エ&#xfeff;ク&#xfeff;ス&#xfeff;テ&#xfeff;ンション (インテル® XMX) ハー&#xfeff;ド&#xfeff;ウ&#xfeff;ェ&#xfeff;ア・ア&#xfeff;ク&#xfeff;セ&#xfeff;ラ&#xfeff;レーションを最大限に活用できます。"
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "究極のビジュアルがここに",
                    "tr_specText": "すべてのインテル® Arc™ グラフィックスで DirectX* 12 Ultimate をフルサポートしているため、次世代ゲームの基本となるレイ・トレーシング、可変レート・シェーディング、メッシュ・シェーディング、サンプラー・フィードバックなどの最新のグラフィックス・テクノロジーにより、臨場感あふれるゲームプレイをお楽しみいただけます。"
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "写真、ビデオ、メ&#xfeff;デ&#xfeff;ィアの制作がシ&#xfeff;ー&#xfeff;ムレスに",
                    "tr_specText": "AI により強化され、インテルのテクノロジーで高速化された超高度なメディアエンジンを使用して、想像力を解き放ち、リッチなデジタルコンテンツ作成で視聴者を魅了しましょう。最先端の AV1 ビデオエンコード機能で最新性を保ちつつ、現行の主要なメディア・フォーマットすべてをサポートするグラフィックス・カードを活用して目を見張るようなコンテンツを作成できます。"
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "最先端の AI によるコンテンツ作成とゲ&#xfeff;ー&#xfeff;ムをご体験ください",
                    "tr_specText": "インテル® Arc™ グラフィックスは、高度なコンテンツ作成から臨場感あふれるゲームプレイに至るまで、強力な AI 機能を実現します。テキストからの画像生成や、ビデオ編集の AI による高速化など、今日の高度な作成ソフトウェアの力を最大限に活用しましょう。さらに、AI で強化されたインテル® X<sup class='small_text_sup_xe'>e</sup>SS アップスケーリングにより、高解像度のゲームプレイをお楽しみいただけます。"
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "グラフィックス",
                    "tr_specText": "プロフェッショナルなグラフィックスがここから始まる"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "クリエーター、デザイナー、エンジニアの外出先での作業に最適",
                    "tr_specText": "一部のインテル® Core™ Ultra プロセッサー H シリーズで利用可能な内蔵インテル® Arc™ Pro グラフィックスにより、グラフィックス性能が 2 倍に向上しています。また、独立系ソフトウェア・ベンダー (ISV) 認証で信頼性が向上しているほか、効率的な AI とレイ・トレーシング・ハードウェア・アクセラレーションにより、外出先でも素晴らしいコンテンツ作成を実現します。<br/><br/><span class='expandable_heading'>ソフトウェア認証<br/></span>インテルは何百ものソフトウェア会社と長年にわたり連携し、その特異な経験がプロフェッショナル向けのインテル®&nbsp;Arc™&nbsp;Pro&nbsp;A シリーズ・グラフィックスにも活かされています。認証やソフトウェア・ワークフローの認定は、インテルにもそしてユーザーにも重要です。"
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "AI に手が届く",
                    "tr_specText": "この GPU シリーズは、必要に応じて専用 AI アクセラレーションで互換性のある AI ツールをサポートするなど、優れたテクノロジーを幅広く備えています。"
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "追加費用なしのレイ・トレーシング",
                    "tr_specText": "グラフィックス・カードは、単にピクセルを画面に出力するだけではなく、美しい画像の作成を加速する必要があります。インテル® Arc™ Pro A シリーズのグラフィックスに内蔵されたレイ・トレーシング・ハードウェア技術により、対応するソフトウェアでこうした新たなプロのワークフローの探求を始めることができます。レイ・トレーシングは一般的に、高価なグラフィックスでのみ利用できると考えられていますが、インテルの最新プロフェッショナル向けグラフィックスでは、その常識を覆します。"
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "臨場感あふれるディスプレイでクリエーターにパワーを",
                    "tr_specText": "すべてのインテル® Arc™ グラフィックスで DirectX* 12 Ultimate をフルサポートしているため、次世代ゲームの基本となるレイ・トレーシング、可変レート・シェーディング、メッシュ・シェーディング、サンプラー・フィードバックなどの最新のグラフィックス・テクノロジーにより、臨場感あふれるゲームプレイをお楽しみいただけます。"
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "画像を動的に最適化",
                    "tr_specText": "Pro GPU シリーズに搭載された専用ハードウェア・デコーディングにより、Dolby Vision* は対応するディスプレイでより深い奥行き、鋭いコントラスト、豊かな色彩を表現できるよう支援します。Dolby Vision* は、サービス、ディスプレイ・デバイス、およびプラットフォームに基づいて画質を動的に最適化することによって HDR テクノロジーの潜在能力を最大限に引き出し、常に圧倒的なビジュアルを提供します。"
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "専門的に検証されたソフトウェア",
                    "tr_specText": "インテルは何百ものソフトウェア会社と長年にわたり連携し、その特異な経験がプロフェッショナル向けのインテル® Arc™ Pro A シリーズ・グラフィックスにも活かされています。認証やソフトウェア・ワークフローの認定は、インテルにもそしてユーザーにも重要です。"
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "追加費用なしのレイ・トレーシング",
                    "tr_specText": "グラフィックス・カードは、単にピクセルを画面に出力するだけではなく、美しい画像の作成を加速する必要があります。インテル® Arc™ Pro A シリーズのグラフィックスに内蔵されたレイ・トレーシング・ハードウェア技術により、対応するソフトウェアでこうした新たなプロのワークフローの探求を始めることができます。レイ・トレーシングは一般的に、高価なグラフィックスでのみ利用できると考えられていますが、インテルの最新プロフェッショナル向けグラフィックスでは、その常識を覆します。"
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "小型モバイル GPU 1 つで複数の大型ディスプレイ",
                    "tr_specText": "ノートブック PC 向けインテル® Arc™ Pro グラフィックスは、最大 4 台の超大型ディスプレイをサポートし、ワークフローの効率を向上させます。ハイダイナミック・レンジ (HDR) サポートも追加すると、ソーシャルメディアにふさわしい自宅や職場のマルチディスプレイ・セットアップに必要なものがすべて揃います。"
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "メモリー帯域幅を完全に引き出す",
                    "tr_specText": "グラフィックス・カードの帯域幅は、ワークステーションにおける重要な検討事項です。選択を誤ればプロの作&#xfeff;業&#xfeff;フローが遅くなる可能性があります。そのため、イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Arc™ Pro A シリーズ GPU は、下位互換性を&#xfeff;損&#xfeff;な&#xfeff;うことなく、最新の PCIe* 4.0 をサポートできる&#xfeff;よ&#xfeff;う&#xfeff;にしました。高帯域幅によりグラフィックス・メ&#xfeff;モ&#xfeff;リーはさらに強化され、プロジェクトのデー&#xfeff;タ&#xfeff;をソ&#xfeff;フ&#xfeff;トウェアからより速くアクセスできます。"
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "あらゆるフレームに命を吹き込む",
                    "tr_specText": "<span class='expandable_heading'>AI がブースト。インテルがサポート。<br/></span><br/>インテル® X<sup class='small_text_sup_xe'>e</sup>SS は、AI により強化されたアップスケーリング・テクノロジーで、あらゆるフレームの美しさを損なうことなく、パフォーマンスを向上させます。これにより、ゲームプレイを本来の姿で体験できます。"
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "リアルタイム。<br/>臨場感あふれる<br/> 最高のリアリズム。",
                    "tr_specText": "よりリアルな世界が待っています。内蔵のレイ・トレーシングで、リアルな影と反射、巧みな照明効果など、あらゆるものを体験してください。決して見逃したくないビジュアルなゲーム体験が実現します。"
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "ベストなゲームプレイを、ベストに配信",
                    "tr_specText": "見せるならその価値のあるシームレスなゲームプレイを。インテル® Arc™ グラフィックスのハードウェア・アクセラレーションによる AV1 エンコーディング機能で、ゲーム内の一挙手一投足がだれをも魅了します。同じビットレートで画質が向上し、ベストな画質のストリーミングを帯域幅をセーブしながら行えます。"
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "あらゆるクリエーターを歓迎",
                    "tr_specText": "新しく強化された AI 体験が、目の前ですぐご利用いただけます。インテル® Arc™ A シリーズ・グラフィックスに内蔵された高度な AI アクセラレーターにより、多くのアプリケーションで作業に費やす時間を増やし、待ち時間を減らせます。<br/><br/>インテル® Arc™ グラフィックスに内蔵された高度な AV1 ハードウェア機能が支援する高画質でストレージ効率の高い AV1 ビデオにより、ビデオ制作を次のレベルに引き上げて他者をリードしましょう。"
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "仕事を終わらせるために必要なすべて",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "薄く、軽く、耐久性に優れたパワフルなパフォーマンスなど、すべてを手に入れることができます。優れたバッテリー寿命、美しいタッチスクリーン*、最新のソリッドステートドライブ (SSD) を使用すると、Windows 10 PC で探しているものが見つかります。",
                "tr_noteSection": "*ハードウェアに依存します。",
                "titleArray": [
                  {
                    "tr_title": "低消費電力による長﻿時﻿間﻿駆﻿動",
                    "tr_subtitle": "今日の　PC は通常、消費電力が少ないため、バッテリーを長持ちさせることができます。平均して、8 時間以上のビデオ再生が可能です。**",
                    "tr_noteSection": "**バッテリー寿命は、設定、使用方法、その他の要素によって大きく異なります。"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "仕事を終わらせるために必要なすべて",
                "tr_title": "Windows* 10",
                "tr_subtitle": "薄く、軽く、耐久性に優れたパワフルなパフォーマンスなど、すべてを手に入れることができます。優れたバッテリー寿命、美しいタッチスクリーン*、最新のソリッドステートドライブ (SSD) を使用すると、Windows 10 PC で探しているものが見つかります。",
                "tr_noteSection": "*ハードウェアに依存します。",
                "titleArray": [
                  {
                    "tr_title": "低消費電力による長﻿時﻿間﻿駆﻿動",
                    "tr_subtitle": "今日の　PC は通常、消費電力が少ないため、バッテリーを長持ちさせることができます。平均して、8 時間以上のビデオ再生が可能です。**",
                    "tr_noteSection": "**バッテリー寿命は、設定、使用方法、その他の要素によって大きく異なります。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "安全性とパフォーマンスの合理化",
                "tr_pretitle": "Windows* 10 (S モード)",
                "tr_subtitle": "S モードで Windows* 10 を実行すると、高い保護機能で、仕事、遊び、その他の探索もデバイスの安全性に自信をもって行うことが可能になります。"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "安全性とパフォーマンスの合理化",
                "tr_title": "Windows* 10 (S モード)",
                "tr_subtitle": "S モードで Windows* 10 を実行すると、高い保護機能で、仕事、遊び、その他の探索もデバイスの安全性に自信をもって行うことが可能になります。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "利便性が大きく向上",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "パフォーマンスと価格、豊富な選択肢を提供。革新的な 2 in 1 や薄型軽量ノートブック PC、タッチスクリーン、着脱可能なキーボードなど、あらゆる予算とスタイルに対応した Windows* 搭載 PC の選択肢があります。"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "利便性が大きく向上",
                "tr_title": "Windows*",
                "tr_subtitle": "パフォーマンスと価格、豊富な選択肢を提供。革新的な 2 in 1 や薄型軽量ノートブック PC、タッチスクリーン、着脱可能なキーボードなど、あらゆる予算とスタイルに対応した Windows* 搭載 PC の選択肢があります。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "Linux*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "オペレーティング・システム",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "必要な RAM の量は？",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "日常的なタスク - 2GB から最大 8GB の RAM",
                    "tr_badgeSubTitle": "PC を主に文書作成とウェブサーフィンに使用し、一度に 1 つのプログラムしか動かさない傾向にある人に最適です。"
                  },
                  {
                    "tr_badgeTitle": "パワフルなタスク - 8GB から最大 16GB の RAM",
                    "tr_badgeSubTitle": "RAM 容量を増やすと、アプリケーションの実行が速くなるだけでなく、より多くのアプリケーションを同時に実行できます。"
                  },
                  {
                    "tr_badgeTitle": "高度なタスク ‐ 16GB 超の RAM",
                    "tr_badgeSubTitle": "ゲーム、写真やビデオの編集、または 3D モデリングに興味がある場合、こうした負荷の高いプログラムを扱うには RAM の追加が必要になります。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "日常のマルチタスクに必要な [XX]GB の RAM",
                "tr_subtitle": "メモリーによって、ワード・プロセッサーやウ&#65279;ェ&#65279;ブ&#65279;・ブラウジングなどの基本的なア&#65279;プ&#65279;リ&#65279;ケ&#65279;ー&#65279;シ&#65279;ョ&#65279;ンの読み込みと実行がス&#65279;ム&#65279;ー&#65279;ズ&#65279;に&#65279;なります。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>の RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "パワフルなマルチタスクに必要な [XX]GB の RAM",
                "tr_subtitle": "複数のプログラムを同時に使用する場合、[memory_total]GB のメモリーを使用すると、簡単に切り替えることができます。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>の RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "高度なマルチタスクに必&#65279;要&#65279;な [XX]GB の RAM",
                "tr_subtitle": "大容量のメモリーがあれば、PC の速度は遅くならず、複数の負荷の高いプログラムを同時に実行できます。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>の RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1]GB メモリー&nbsp;= [Value2]GB イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Optane™ メ&#xfeff;モ&#xfeff;リ&#xfeff;ー&nbsp;+ [Value3]GB RAM",
                "tr_subtitle": "このメモリー・ソリューションの組み合わせにより、インテル® Optane™ メモリーによってハードドライブのパフォーマンスが高速化すると同時に、RAM からの反応も速くなります。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'> のメモリー</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "インテル® Optane™ メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "待ち時間を減らし、大&#65279;好&#65279;き&#65279;なものをよ&#65279;り&#65279;多&#65279;く&#65279;満&#65279;喫&#65279;。",
                "tr_subtitle": "インテル® Optane™ メモリーは、システムのメモリーと連携し、よく使用するプログラムへのアクセスを高速化するため、必要なときにすぐに起動してロードできます。",
                "tr_pretitle": "[XX]GB インテル® Optane™ メモリー",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "PC にとってのメモリーの役割とは？"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "コンテンツ制作を迅速化",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "ゲームの応答性向上",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "マルチタスクをより簡単に",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "プログラム起動を高速化",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "インテル® Optane™ メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>ゲームプレイを高速化</span>",
                "tr_pretitle": "[XX]GB インテル® Optane™ メモリー",
                "tr_pretitle1": "[XX]GB<br/>イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Optane™ メ&#xfeff;モ&#xfeff;リ&#xfeff;ー&#xfeff;で高&#xfeff;速&#xfeff;ゲ&#xfeff;ー&#xfeff;ムプレイ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "ゲームの起動が最大 <br/><span class=\"optane-game\">98% 高速化</span> <br/>[optane_legal_processor_name] プロセッサーとイ&#65279;ン&#65279;テ&#65279;ル&#65279;®&nbsp;Optane™ メモリーを搭載したシステムで&#65279;の&#65279;デ&#65279;ータドラ&#xfeff;イ&#xfeff;ブ&#xfeff;の読&#xfeff;み&#xfeff;込&#xfeff;み&#xfeff;<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "読み込みレベルが最大 <br/><span class=\"optane-game\">4.1 倍高速化</span> <br/>[optane_legal_processor_name] プロセッサーとイ&#65279;ン&#65279;テ&#65279;ル&#65279;®&nbsp;Optane™ メモリーを搭載したシステムで&#65279;の&#65279;デ&#65279;ータドラ&#xfeff;イ&#xfeff;ブ&#xfeff;の読&#xfeff;み&#xfeff;込&#xfeff;み&#xfeff;<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "インテル® Optane™ メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>ゲームプレイを高速化</span>",
                "tr_pretitle": "[XX]GB インテル® Optane™ メモリー",
                "tr_pretitle1": "[XX]GB<br/>イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Optane™ メ&#xfeff;モ&#xfeff;リ&#xfeff;ー&#xfeff;で高&#xfeff;速&#xfeff;ゲ&#xfeff;ー&#xfeff;ムプレイ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "ゲームの起動が最大 <br/><span class=\"optane-game\">98% 高速化</span> <br/>[optane_legal_processor_name] プロセッサーとイ&#65279;ン&#65279;テ&#65279;ル&#65279;®&nbsp;Optane™ メモリーを搭載したシステムで&#65279;の&#65279;デ&#65279;ータドラ&#xfeff;イ&#xfeff;ブ&#xfeff;の読&#xfeff;み&#xfeff;込&#xfeff;み&#xfeff;<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "読み込みレベルが最大 <br/><span class=\"optane-game\">4.1 倍高速化</span> <br/>[optane_legal_processor_name] プロセッサーとイ&#65279;ン&#65279;テ&#65279;ル&#65279;®&nbsp;Optane™ メモリーを搭載したシステムで&#65279;の&#65279;デ&#65279;ータドラ&#xfeff;イ&#xfeff;ブ&#xfeff;の読&#xfeff;み&#xfeff;込&#xfeff;み&#xfeff;<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1]GB メモリー&nbsp;= [Value2]GB イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Optane™ メ&#xfeff;モ&#xfeff;リ&#xfeff;ー&nbsp;+ [Value3]GB RAM",
                "tr_subtitle": "このメモリー・ソリューションの組み合わせにより、インテル® Optane™ メモリーによってハードドライブのパフォーマンスが高速化すると同時に、RAM からの反応も速くなります。",
                "titleArray": [
                  {
                    "tr_title": "マルチタスクに最&#65279;適&#65279;な [XX]GB&nbsp;の RAM",
                    "tr_subtitle": "複数のプログラムを同時に使用する場合、[memory_total]GB のメモリーを使用すると、簡単に切り替えることができます。"
                  },
                  {
                    "tr_title": "日常的な作業向け",
                    "tr_subtitle": "メモリーによって、ワード・プロセッサーやウ&#65279;ェ&#65279;ブ&#65279;・ブラウジングなどの基本的なア&#65279;プ&#65279;リ&#65279;ケ&#65279;ー&#65279;シ&#65279;ョ&#65279;ンの読み込みと実行がス&#65279;ム&#65279;ー&#65279;ズ&#65279;に&#65279;なります。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]GB RAM",
                "tr_pretitle": "マルチタスク性能が向上",
                "tr_subtitle": "RAM によって、プロセッサーがプログラムファイルへアクセスしやすくなり、CPU のファイルへの高速アクセスや同時マルチタスク処理が可能になります。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "スタンダード・メモリー",
                    "tr_badgeSubTitle": "最大 8GB の RAM"
                  },
                  {
                    "tr_badgeTitle": "アドバンスト・メモリー",
                    "tr_badgeSubTitle": "8GB 以上、最大 16GB RAM"
                  },
                  {
                    "tr_badgeTitle": "エリートメモリー",
                    "tr_badgeSubTitle": "16GB 以上の RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]GB でさらにマルチタスク",
                "tr_pretitle": "マルチタスク性能が向上",
                "tr_subtitle": "RAM によって、プロセッサーがプログラムファイルへアクセスしやすくなり、CPU のファイルへの高速アクセスや同時マルチタスク処理が可能になります。",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "スタンダード・メモリー",
                    "tr_badgeSubTitle": "最大 8GB の RAM"
                  },
                  {
                    "tr_badgeTitle": "アドバンスト・メモリー",
                    "tr_badgeSubTitle": "8GB 以上、最大 16GB RAM"
                  },
                  {
                    "tr_badgeTitle": "エリートメモリー",
                    "tr_badgeSubTitle": "16GB 以上の RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "このメモリーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "標準",
                      "tr_badgeSubTitle": "<span class='memory-value'>8GB<span class='ram_value'> の&nbsp;RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "高度な機能",
                      "tr_badgeSubTitle": "<span class='memory-value'>16GB<span class='ram_value'> の&nbsp;RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "エリート",
                      "tr_badgeSubTitle": "<span class='memory-value'>32GB<span class='ram_value'> の&nbsp;RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "メモリー",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "このメモリーの性能比較",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "このメモリーの性能比較",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;ス",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "日常作業向け",
                      "tr_badgeSubTitle": "<span class='memory-value'>8GB<span class='ram_value'> の&nbsp;RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "パワフル",
                      "tr_badgeSubTitle": "<span class='memory-value'>16GB<span class='ram_value'> の&nbsp;RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "高度な機能",
                      "tr_badgeSubTitle": "<span class='memory-value'>32GB<span class='ram_value'> の&nbsp;RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ストレージ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "ご使用の PC の容量がどのくらいかご存知ですか？",
                "tr_subtitle": "憶測に頼る必要はありません。この PC に何を保存できるかを正確にお見せします。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>音楽 </span> <br/><span class=\"storage-standard\">[num_songs] 曲分のラ&#65279;イ&#65279;ブ&#65279;ラ&#65279;リ&#65279;ーを作成<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>音楽 1 曲当たり平均 5MB として算定",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>ゲーム </span><br/><span class=\"storage-standard\">[num_games] 本分のライブラリーを作成<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>1 ゲーム当たり平均 40GB に基づく",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>動画 </span><br/><span class=\"storage-standard\">[num_videos] 本分のストレージ<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>動画 1 本当たり平均 5GB に基づく",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>写真 </span><br/><span class=\"storage-standard\">[num_photos] 枚を撮影して保&#65279;存&#65279;<sup>&#65279;^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>写真 1 枚当たり平均 4MB に基づく",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity]GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity]GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory]GB インテル® Optane™ メモリー + [convertedSSD]GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ストレージ",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity]GB の SSD ストレージ",
                "tr_preTitle1": "[capacity]TB の SSD ストレージ",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "この PC には、[capacity]GB の容量を持つ SSD が搭載されています。そのため、大量の写真、動画、プログラムを保存できるうえ、ハードディスク・ドライブと比較して、より速くアクセスできます。",
                    "tr_title": "PC 上にすべてを保&#65279;存"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "この PC には、[capacity]TB の容量を持つ SSD が搭載されています。そのため、大量の写真、動画、プログラムを保存できるうえ、ハードディスク・ドライブと比較して、より速くアクセスできます。",
                    "tr_title": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;スは大&#65279;容&#65279;量&#65279;のス&#65279;ト&#65279;レ&#65279;ー&#65279;ジ&#65279;を備&#65279;え&#65279;て&#65279;い&#65279;ま&#65279;す&#65279;。"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'> のストレージ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'> のストレージ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ストレージ",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity]GB の HDD ストレージ",
                "tr_preTitle1": "[capacity]TB の HDD ストレージ",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "PC 上の大容量ストレージに直接データを保存するにはハ&#65279;ー&#65279;ド&#65279;デ&#65279;ィ&#65279;ス&#65279;ク&#65279;・ドライブが適しています。",
                    "tr_title": "PC 上にすべてを保&#65279;存"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "PC 上の大容量ストレージに直接データを保存するにはハ&#65279;ー&#65279;ド&#65279;デ&#65279;ィ&#65279;ス&#65279;ク&#65279;・ドライブが適しています。",
                    "tr_title": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;スは大&#65279;容&#65279;量&#65279;のス&#65279;ト&#65279;レ&#65279;ー&#65279;ジ&#65279;を備&#65279;え&#65279;て&#65279;い&#65279;ま&#65279;す&#65279;。"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>のス&#65279;ト&#65279;レ&#65279;ー&#65279;ジ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ストレージ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "ご使用の PC の容量がどのくらいかご存知ですか？",
                "tr_subtitle": "憶測に頼る必要はありません。この PC に何を保存できるかを正確にお見せします。",
                "titleArray": [
                  {
                    "tr_title": "[XX]GB の HDD"
                  },
                  {
                    "tr_title": "[XX]GB の SSD"
                  },
                  {
                    "tr_title": "[optane_memory_size]GB のインテル® Optane™ メモリーにより高速化された [total_capacity]GB のインテル® SSD"
                  },
                  {
                    "tr_title": "[optane_memory_size]GB インテル® Optane™ メモリーにより高&#65279;速&#65279;化された [total_capacity]TB のインテル® SSD"
                  },
                  {
                    "tr_title": "[convertedSSD]GB SSD + [convertedHDD]GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD]TB SSD + [convertedHDD]GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD]GB SSD + [convertedHDD]TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD]TB SSD + [convertedHDD]TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'> のストレージ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'> のストレージ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'> のストレージ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'> のストレージ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'> のストレージ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'> のストレージ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ストレージ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD]GB SSD + [convertedHDD]GB HDD",
                "tr_preTitle1": "[convertedSSD]TB SSD + [convertedHDD]GB HDD",
                "tr_preTitle2": "[convertedSSD]GB SSD + [convertedHDD]TB HDD",
                "tr_preTitle3": "[convertedSSD]TB SSD + [convertedHDD]TB HDD",
                "titleArray": [
                  {
                    "tr_title": "PC 上にすべてを保&#65279;存",
                    "tr_title1": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;スは大&#65279;容&#65279;量&#65279;のス&#65279;ト&#65279;レ&#65279;ー&#65279;ジ&#65279;を備&#65279;え&#65279;て&#65279;い&#65279;ま&#65279;す&#65279;。",
                    "tr_subtitle": "この PC には、[convertedSSD]GB のソリッドステート・ドライブのストレージと、[convertedHDD]GB のハードディスク・ドライブが搭載されています。これにより、膨大な容量のストレージと高速ストレージ・パフォーマンスを両立できます。",
                    "tr_subtitle1": "この PC には、[convertedSSD]TB のソリッドステート・ドライブのストレージと、[convertedHDD]GB のハードディスク・ドライブが搭載されています。これにより、膨大な容量のストレージと高速ストレージ・パフォーマンスを両立できます。",
                    "tr_subtitle2": "この PC には、[convertedSSD]GB のソリッドステート・ドライブのストレージと、[convertedHDD]TB のハードディスク・ドライブが搭載されています。これにより、膨大な容量のストレージと高速ストレージ・パフォーマンスを両立できます。",
                    "tr_subtitle3": "この PC には、[convertedSSD]TB のソリッドステート・ドライブのストレージと、[convertedHDD]TB のハードディスク・ドライブが搭載されています。これにより、膨大な容量のストレージと高速ストレージ・パフォーマンスを両立できます。"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'> のストレージ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'> のストレージ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ストレージ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "インテル® Optane™ メモリー (SSD 対応) なら、大&#65279;容&#65279;量ス&#65279;ト&#65279;レ&#65279;ー&#65279;ジへのアクセスが高速にな&#65279;り&#65279;ま&#65279;す。",
                "tr_title": "大量の大容量フ&#65279;ァ&#65279;イ&#65279;ル&#65279;に&#65279;も対応",
                "tr_pretitle": "[optane_memory_size]GB のインテル® Optane™ メモリーにより高速化された [total_capacity]GB のインテル® SSD",
                "tr_pretitle1": "[optane_memory_size]GB インテル® Optane™ メモリーにより高&#65279;速&#65279;化された [total_capacity]TB のインテル® SSD",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "この革命的なストレージ・ソリューションは、インテル® Optane™ メモリーと SSD をワンチップに統合した初の製品です。何ができるのかをご紹介します。",
                    "tr_title": "インテル® Optane™ メモリー  (SSD 対応) とは？"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "必要なものを高速起動",
                    "tr_badgeSubTitle": "頻繁に使用するファイルやプログラムがプリロードされるため、待ち時間が減り、制作作業により多くの時間を費やすことができます。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "ストレージ + スピード",
                    "tr_badgeSubTitle": "メディアファイルに欠かせない容量が SSD の高速パフォーマンスとと&#65279;も&#65279;に&#65279;手に入ります。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "使っているうちに応答性が向上",
                    "tr_badgeSubTitle": "インテル® Optane™ メモリー (SSD 対応) は、ユーザーの操作を学習し、PC をより効率的にします。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ストレージ",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "信頼の大容量であなたのすべてのメディアを手元に保存してください。",
                "tr_title": "写真、ビデオ、音&#65279;楽、すべてに対&#65279;応&#65279;す&#65279;る大&#65279;容&#65279;量"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ディスプレイ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "スワイプやピンチで実感できる驚異的な [screenResolution] 万ピクセルの解像度",
                "tr_subtitle": "直感的なコントロールとシャープで鮮明なディテールのディスプレイで、スマートフォンのように PC を操作できます。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal] <br/>インチ <br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解像度: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "ピクセル: [screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] インチのデ&#65279;ィ&#65279;ス&#65279;プ&#65279;レ&#65279;イ",
                    "tr_badgeSubTitle": "画面の高さ: [screenHeight] インチ",
                    "tr_badgePreTitle": "画面の幅: [YY] インチ"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "タッチスクリーン・ディスプレイ",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ディスプレイ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] 万ピクセルの圧倒的な臨場感",
                "tr_subtitle": "重要なのはピクセルです。ピクセル数が大きいほど、よりシャープでより鮮明な画像を表示できます。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal] <br/>インチ <br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解像度: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "ピクセル: [screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] インチのデ&#65279;ィ&#65279;ス&#65279;プ&#65279;レ&#65279;イ",
                    "tr_badgeSubTitle": "画面の高さ: [screenHeight] インチ",
                    "tr_badgePreTitle": "画面の幅: [YY] インチ"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ディスプレイ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] インチ <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "ゲームの世界へとつながるウィンドウ",
                "tr_subtitle": "テレビと同じように、ゲーム画面で重視されるのはサイズと解像度です。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解像度: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "ピクセル: [screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] インチのデ&#65279;ィ&#65279;ス&#65279;プ&#65279;レ&#65279;イ",
                    "tr_badgeSubTitle": "画面の高さ: [screenHeight] インチ",
                    "tr_badgePreTitle": "画面の幅: [screenWidth] インチ"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "タッチスクリーン・ディスプレイ"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ディスプレイ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "高品質のディスプレイを搭載",
                "tr_subtitle": "HD の 8 倍以上のピクセル数により、動画やゲームが新次元のシャープでカラフルな映像で表現されます。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解像度: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "ピクセル: [screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] インチのデ&#65279;ィ&#65279;ス&#65279;プ&#65279;レ&#65279;イ",
                    "tr_badgeSubTitle": "画面の高さ: [screenHeight] インチ",
                    "tr_badgePreTitle": "画面の幅: [YY] インチ"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ディスプレイ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution]万ピクセルでタ&#65279;ッ&#65279;プ、スワイプ、タ&#65279;ッ&#65279;チ",
                    "tr_subTitle": "お気に入りの画像をより細部まで鮮明に見ることができ、対話操作も可能です。"
                  },
                  {
                    "tr_title": "[screenResolution]万ピクセルがデ&#65279;ジ&#65279;タ&#65279;ル&#65279;の世界を拡&#65279;張",
                    "tr_subTitle": "重要なのはピクセルです。ピクセル数が大きいほど、よりシャープでより鮮明な画像を表示できます。"
                  },
                  {
                    "tr_title": "高品質のディスプレイを搭載",
                    "tr_subTitle": "HD の 4 倍近いピクセルを装備しているため、動画やゲームの鮮明さや色鮮やかさが違います。"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "接続性",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 テクノロジーにより、あらゆるドック、ディプレイ、データデバイスとの最も高速で汎用性の高い接続が実現します。",
                "tr_title": "1 つのポートで<br />すべてに対応",
                "tr_title1": "1 つのポートですべてに対応",
                "titleArray": [
                  {
                    "tr_title": "Thunderbolt™ 3 テクノロジーの利用方法",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™&nbsp;3 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K モニター",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "驚異的な解像度、コントラスト、色彩を実現するディスプレイに接続"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "外付けのグラフィックス・カ&#65279;ー&#65279;ド",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "あらゆる PC のグラフィックを大幅に改善"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "高速ネットワーキング",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "高速のピアツーピア接続を提供"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "接続性",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 テクノロジーは、従来の USB を上回る高速データ転送によって、ファイルを素早く移動することや、複数のモニターや外付けグラフィックス・カードなどの周辺機器を容易に統合することを可能にします。",
                "tr_title": "Thunderbolt™&nbsp;3 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "超高速データ転送",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™&nbsp;3 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                    "tr_badgeSubTitle": "40Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "接続性",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>汎用的なポートとケーブル 1 本でお好みのアクセサリーすべてに対応</li> <li>信頼性の高いノートブック PC の充電</li> <li>堅牢な認証による安心</li></ul>",
                "tr_title": "まさに汎用なケーブル接&#65279;続",
                "tr_title1": "まさに汎用なケーブル接&#65279;続",
                "tr_noteSection": "<sup>†</sup>最小仕様要件に基づく",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "Thunderbolt™&nbsp;4 テクノロジーの使用例",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™&nbsp;4 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "作成",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "外付けモニターと高速ストレージを接続すると、写真や動画を快適に閲覧および編集できます。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "ドック",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "ノートブック PC を 1 本のケーブルですべてのアクセサリーに接続することで、シンプルですっきりした PC デスクを実&#65279;現で&#65279;き&#65279;ま&#65279;す&#65279;。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "ゲーム",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "最大 3,000MB/s の速度で SSD や HDD を追加して容量を増やすと、ゲームライブラリーやメディアファイルを保存できます。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "最高のパフォーマンス<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2 の 4 倍高速 <br/>HDMI* 2 の 2 倍高速"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "接続性",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 テクノロジーの素晴らしいパフォーマンス、最新の Thunderbolt™ テクノロジー対応ドック、モニター、高速ストレージ、あるいはあらゆる USB* アクセサリーに簡単に接続して使用できる、さらに充実した機能性をお楽しみください。",
                "tr_title": "Thunderbolt™&nbsp;4 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
                "tr_pretitle": "まさに汎用なケーブル接&#65279;続",
                "tr_noteSection": "<sup>†</sup>最小仕様要件に基づく",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "1 本の Thunderbolt™ 4 テクノロジー汎用ケーブルがあれば、すべての USB-C* ケーブルを置き換えることができるため、迷わず簡単にケーブルを選択できます。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 テクノロジー・ドックは、最大 4 つの Thunderbolt™ ポートを備え、あらゆるアクセサリーを接続できる柔軟性を提供します。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "最高のパフォーマンス<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2 の 4 倍高速 <br/>HDMI* 2 の 2 倍高速"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "接続性",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>汎用的なポートとケーブル 1 本でお好みのアクセサリーすべてに対応</li> <li>信頼性の高いノートブック PC の充電</li> <li>堅牢な認証による安心</li></ul>",
                "tr_title": "エクスペリエンスを向上させる Thunderbolt™ アクセサリー",
                "tr_noteSection": "<sup>†</sup>最小仕様要件に基づく",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "Thunderbolt™&nbsp;4 テクノロジーの使用例",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "作成",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "外付けモニターと高速ストレージを接続すると、写真や動画を快適に閲覧および編集できます。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "ドック",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "ノートブック PC を 1 本のケーブルですべてのアクセサリーに接続することで、シンプルですっきりした PC デスクを実&#65279;現で&#65279;き&#65279;ま&#65279;す&#65279;。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "ゲーム",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "最大 3,000MB/s の速度で SSD や HDD を追加して容量を増やすと、ゲームライブラリーやメディアファイルを保存できます。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "最高のパフォーマンス<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2 の 4 倍高速 <br/>HDMI* 2 の 2 倍高速"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "最も一般的なタイプの Wi‑Fi は次のとおりです。",
                "tr_title": "Wi‑Fi 規格について",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 なら、多くのユーザーが同じ信号を使用する場合でもより高速です。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "インテル® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "インテルの最新の Wi-Fi イノベーションです。複数のユーザーが同じ信号を使いながら、ギガビット Wi-Fi より高速で接続できます。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "インテル® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "この 20 年で最大の Wi‑Fi の進化である Wi‑Fi 6E は、パフォーマンスと信頼性が向上し、干渉の低減した新たな高速チャネルを開きます。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 なら、多くのユーザーが同じ信号を使用する場合でもより高速です。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "インテル® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi-Fi 6E のあらゆる利点に加え、インテル® Killer™ プ&#xfeff;ラ&#xfeff;イ&#xfeff;オ&#xfeff;リ&#xfeff;タ&#xfeff;イ&#xfeff;ゼ&#xfeff;ーション・エンジンが最も帯域幅を必要とするウェブサイトとア&#xfeff;プ&#xfeff;リ&#xfeff;ケ&#xfeff;ーションに帯域をシフトします。"
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "高速チャネルでの競合が少ないため、パフォーマンスと信頼性が向上します。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "インテル® Wi-Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 なら、多くのユーザーが同じ信号を使用する場合でもより高速です。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "インテル® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "干渉の少ない Wi-Fi 6 とインテル® Killer™ プライオリタイゼーション・エンジンのインテリジェントなパフォーマンスで、より高速で応答性の高いオンライン体験が実現します。⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "高速チャネルでの競合が少ないため、パフォーマンスと信頼性が向上します。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "インテル® Wi-Fi 7 (5Gig)",
                    "tr_badgeSubTitle": "5Gbps を超える PC の Wi-Fi 速度と、安定した超低レイテンシー。分ではなく、秒単位でファイルを共有。"
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "インテル® Killer™ Wi-Fi 7",
                    "tr_badgeSubTitle": "インテル® Killer™ Wi-Fi 7 (5 Gig) は、AI ベースのネットワーク最適化を通じて「究極の」信頼性、低レイテンシー、速度を実現する最新テクノロジーです。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 なら、多くのユーザーが同じ信号を使用する場合でもより高速です。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "インテル® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "インテル® Wi-Fi 6E (Gig+) は、6GHz チャネルの使用により、1Gbps 超の速度とさらなる信頼性および低レイテンシーを実現します。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "混雑したネットワークにおいて Wi-Fi 5 と比較して効率、容量、速度が向上します。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi‑Fi [XX] では安定した接続状態を維持できるため、より高速<sup>†</sup> で途切れないインターネット接続を楽しむことができます。",
                "tr_title": "通信範囲と速度の向&#65279;上",
                "tr_noteSection": "<sup>†</sup>実際のワイヤレス・スループットや接続範囲は変化します。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "多数プレイヤー同時参加型のオンラインゲームの時代では、接続の維持がこれまで以上に重視されています。スピードはもちろん、そのスピードを遠距離でも持続できる無線信号が必要です。",
                "tr_title": "[XX]",
                "tr_pretitle": "重要なのは接続性",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "インテル® Wi‑Fi 6 (Gig+) を使用して 160MHz チャネル対応の Wi‑Fi 6 ルーターに接続すると、より速く、信頼性の高い接続で、より多くのアクティブなユーザーとつながることができます。",
                "tr_title": "インテル® <span class='no-warp'>Wi-Fi 6 </span> (Gig+) でスピードは約 3 倍<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup> 標準の AC Wi‑Fi との比較。特定の SKU で利用可能な機能のみを選択します。詳細については、メーカーにお問い合わせください。Wi‑Fi の詳細については、intel.com/wifi6disclaimers (英語) を参照してください。"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "エントリーレベルでデュアルバンドのインテル® Wi-Fi 6 は、混雑したネットワークでのメールやインターネット閲覧などの基本的な接続ニーズにおけるパフォーマンスの向上をサポートします。<sup>9</sup>",
                "tr_title": "Essential Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "多数プレイヤー同時参加型のオンラインゲームの時代では、接続の維持がこれまで以上に重視されています。スピードはもちろん、そのスピードを遠距離でも持続できる無線信号が必要です。",
                "tr_title": "[XX]",
                "tr_pretitle": "重要なのは接続性",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "これらの新しい Wi‑Fi チャネルにより、近隣の従来型デバイスと帯域幅を共有する必要がなくなり、滑らかなストリーミング、高速ブラウジング、シームレスなリモートワークや学習ができるようになります。",
                "tr_title": "干渉なしに接続",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "[XX] では安定した接続状態を維持できるため、高速<sup>†</sup>で途切れないインターネット接続を楽しむことができます。",
                "tr_title": "通信範囲と速度の向&#65279;上",
                "tr_noteSection": "<sup>†</sup>実際のワイヤレス・スループットや接続範囲は変化します。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "多数プレイヤー同時参加型のオンラインゲームの時代では、接続の維持がこれまで以上に重視されています。スピードはもちろん、そのスピードを遠距離でも持続できる無線信号が必要です。",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "重要なのは接続性",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "内蔵型の超﻿高﻿速 Wi‑Fi",
                "tr_subtitle": "プラグを外しても常時オンライン接続。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "干渉の少ない専用の高速レーンとゲ&#65279;ー&#65279;ム&#65279;を&#65279;最&#65279;優&#65279;先&#65279;す&#65279;るインテル® Killer™ プ&#65279;ラ&#65279;イ&#65279;オ&#65279;リ&#65279;タ&#65279;イ&#65279;ゼ&#65279;ー&#65279;シ&#65279;ョ&#65279;ン・エンジンによ&#65279;り&#65279;、オンラインゲームが大幅にアップ&#65279;グ&#65279;レ&#65279;ー&#65279;ド&#65279;さ&#65279;れ&#65279;ま&#65279;し&#65279;た&#65279;。",
                "tr_title": "対戦相手に差をつけろ",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "干渉の少ない専用の高速レーンとゲ&#65279;ー&#65279;ム&#65279;を&#65279;最&#65279;優&#65279;先&#65279;す&#65279;るインテル® Killer™ プ&#65279;ラ&#65279;イ&#65279;オ&#65279;リ&#65279;タ&#65279;イ&#65279;ゼ&#65279;ー&#65279;シ&#65279;ョ&#65279;ン・エンジンによ&#65279;り&#65279;、オンラインゲームが大幅にアップ&#65279;グ&#65279;レ&#65279;ー&#65279;ド&#65279;さ&#65279;れ&#65279;ま&#65279;し&#65279;た&#65279;。",
                "tr_title": "[XX]",
                "tr_pretitle": "対戦相手に差をつけろ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "ゲームを最優先する干渉の少ない専用の高速レーンにより、オンラインゲームが大幅にアップグレードされました。",
                "tr_title": "[XX]",
                "tr_pretitle": "対戦相手に差をつけろ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "インテル® Killer™ Wi-Fi は最も通信を必要としているアプリケーションのネットワーク・トラフィックを自動的に高速化します。ストリーミング中のビデオが細部まで克明に描写され、バッファリングもあまり生じません。",
                "tr_title": "より高速でスマートな接続方法。⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "多数プレイヤー同時参加型のオンラインゲームの時代では、接続の維持がこれまで以上に重視されています。スピードはもちろん、そのスピードを遠距離でも持続できる無線信号が必要です。",
                "tr_title": "[XX]",
                "tr_pretitle": "重要なのは接続性",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi-Fi 5 では安定した接続状態を維持できるため、高速<sup>†</sup>で途切れないインターネット接続を楽しむことができます。",
                "tr_title": "通信範囲と速度の向&#65279;上",
                "tr_noteSection": "<sup>†</sup>実際のワイヤレス・スループットや接続範囲は変化します。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "多数プレイヤー同時参加型のオンラインゲームの時代では、接続の維持がこれまで以上に重視されています。スピードはもちろん、そのスピードを遠距離でも持続できる無線信号が必要です。",
                "tr_title": "[XX]",
                "tr_pretitle": "対戦相手に差をつけろ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7 の機能は、これまでの Wi-Fi 世代の上に構築され、それを高めたものです。つまり高速化のみならず、応答性と信頼性も劇的に向上し、臨場感あるコンシューマー体験と高度な未来のテクノロジーを実現できるのです。",
                "tr_title": "有線同様の PC ネットワーキング",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7 の機能は、これまでの Wi-Fi 世代の上に構築され、それを高めたものです。つまり高速化のみならず、応答性と信頼性も劇的に向上し、臨場感あるコンシューマー体験と高度な未来のテクノロジーを実現できるのです。",
                "tr_title": "[XX]",
                "tr_pretitle": "ユーザー体験の変革",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "AI ベースのネットワ&#xfeff;ー&#xfeff;ク&#xfeff;最&#xfeff;適&#xfeff;化&#xfeff;に&#xfeff;よ&#xfeff;り速&#xfeff;度、レイテンシー、信&#xfeff;頼&#xfeff;性&#xfeff;が&#xfeff;向&#xfeff;上",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "AI ベースのネットワ&#xfeff;ー&#xfeff;ク&#xfeff;最&#xfeff;適&#xfeff;化&#xfeff;に&#xfeff;よ&#xfeff;り速&#xfeff;度、レイテンシー、信&#xfeff;頼&#xfeff;性&#xfeff;が&#xfeff;向&#xfeff;上。",
                "tr_title": "[XX]",
                "tr_pretitle": "有線同様の応答性",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "バッテリー",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "測定方法",
                    "tr_badgeSubTitle": "ほとんどのメーカーは、バッテリー持続時間を時間単位で公表しています。この計算は完全に正確とは言えませんが、複数の PC のバ&#65279;ッ&#65279;テ&#65279;リ&#65279;ー持続時間を比較する際の目安にはなります。"
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "バッテリー",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "外出先でのゲームプレイ向け",
                "tr_subtitle": "最新のゲーム向けノートブック PC のバッテリー性能なら、もう机に縛られている理由はありません。どこでも好きな場所でゲームを楽しめます。",
                "tr_title": "[Dynamic.battery.values.hours] 時間のバッテリー持&#65279;続&#65279;時&#65279;間"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "バッテリー",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "何時間も充電せずに、アプリ、ゲーム、音楽を楽しむことができます。",
                "tr_title": "1 回の充電で [XX] 時間のストリーミング<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>PC メーカー発表のバッテリー持続時間に基づく。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 時間",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "バッテリー",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "何時間も充電せずに、アプリ、ゲーム、音楽を楽しむことができます。",
                "tr_title": "外出先で [Dynamic.battery.values.hours] 時間使&#65279;用&#65279;可&#65279;能&#65279;<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>PC メーカー発表のバッテリー持続時間に基づく。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 時間",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "バッテリー",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "何時間も充電せずに、アプリ、ゲーム、音楽を楽しむことができます。",
                "tr_title": "1 回の充電で [Dynamic.battery.values.hours] 時間バ&#65279;ッ&#65279;テ&#65279;リ&#65279;ー&#65279;が持&#65279;続&#65279;<sup class='sup-symbol'>&#65279;†</sup>",
                "tr_noteSection": "<sup>†</sup>PC メーカー発表のバッテリー持続時間に基づく。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 時間",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "バッテリー",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "この PC ではバッテリー持続時間が最適化されており、30 分の充電で最&#65279;低 &#65279;4&nbsp;時&#65279;間バッテリーが持続します。<sup>4</sup>",
                "tr_title": "[Dynamic.battery.values.hours] 時間の長&#65279;持&#65279;ち&#65279;バ&#65279;ッ&#65279;テ&#65279;リ&#65279;ー&#65279;<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>PC メーカー発表のバッテリー持続時間に基づく。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 時間",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "ホーム",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "この PC が選ばれる理由",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "すべての仕様を見る",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "リモート",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "モバイルサイト",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "法務情報",
        "tr_paragraph_array": [
          "性能に関するテストに使用されるソフトウェアとワークロードは、性能がインテル® マイクロプロセッサー用に最適化されていることがあります。SYSmark* や MobileMark* などの性能テストは、特定のコンピューター・システム、コンポーネント、機能を使用して行うものです。結果はこれらの要因によって異なります。製品の購入を検討される場合は、他の製品と組み合わせた場合の本製品の性能など、ほかの情報や性能テストも参考にして、パフォーマンスを総合的に評価することをお勧めします。性能やベンチマーク結果について、さらに詳しい情報をお知りになりたい場合は、http://www.intel.com/benchmarks/ (英語) を参照してください。",
          "¹ インテル® Wi-Fi 6 (Gig+) 製品は、オプションの 160MHz チャネルをサポートしており、標準的な 2x2 802.11ax の PC 向け Wi-Fi 製品で理論上の最大通信速度 (2402Mbps) を可能にします。必須要件の 80MHz チャネルのみをサポートする標準的な 2x2 (1201Mbps) または 1x1 (600Mbps) の 802.11ax PC Wi-Fi 製品に比べ、高品質なインテル® Wi-Fi 6 (Gig+) 製品は理論上の最大速度が 2 ～ 4 倍高速。",
          "² 推奨事項は情報提供のみを目的としています。購入を検討される場合は、ほかの情報も参考にしてください。",
          "³インテルのテクノロジーの機能と利点はシステム構成によって異なり、対応するハードウェアやソフトウェア、およびサービスの有効化が必要となる場合があります。実際の性能はシステム構成によって異なります。絶対的なセキュリティーを提供できるコンピューター・システムはありません。詳細については、各システムメーカーまたは販売店にお問い合わせいただくか、intel.com を参照してください。",
          "⁴ フル HD ディスプレイ搭載システムで、ワイヤレスのウェブ・ブラウジングに使用する場合。電源オフ、OEM のデフォルトのシャットダウン・レベルからの起動。",
          "⁵ eSATA、USB、IEEE 1394* FireWire などの他の PC I/O 接続技術との比較。性能は、使用するハードウェアやソフトウェアによって異なります。Thunderbolt™ テクノロジー対応デバイスを使用する必要があります。",
          "⁶ ほぼ 3 倍高速: 802.11ax 2x2 160MHz では、理論上の最大データ転送速度である 2402Mbps を実現します。これは、IEEE 802.11* 無線規格で規定される標準的な 802.11ac 2x2 80MHz (867Mbps) Wi‑Fi の約 3 倍の速度で、同様に構成された 802.11ax ワイヤレス・ネットワーク・ルーターを使用する必要があります。",
          "⁷ レイテンシーが 75% 減少: OFDMA 対応 / 非対応の 9 クライアントを使用したインテルによる 802.11ax のシミュレーション・データ (79%) に基づきます。OFDMA 非対応クライアントでの平均レイテンシーは 36ms、OFDMA 対応クライアントでの平均レイテンシーは 7.6ms に減少。レイテンシーの改善には、802.11ax (Wi-Fi 6) ルーターとすべてのクライアントで OFDMA に対応している必要があります。",
          "⁸ 802.11 仕様の理論上の最大データレートの比較に基づくと、2x2 160MHz Wi‑Fi 6/6E (802.11ax) は 2402Mbps を実現し、2x2 80MHz Wi‑Fi 5 (802.11ac) の 867Mbps の 2.8 倍の速度を実現します。",
          "⁹ クラス最高レベルの Wi‑Fi 6: インテル® Wi‑Fi 6 (Gig+) 製品は、オプションの 160MHz チャネルをサポートし、標準的な 2x2 802.11ax PC Wi‑Fi 製品における最大通信速度の理論値 (2402Mbps) を達成可能にします。必須要件の 80MHz チャネルのみをサポートする標準的な 2x2 (1201Mbps) または 1x1 (600Mbps) の 802.11ax PC Wi‑Fi 製品に比べ、優れたインテル® Wi‑Fi 6 (Gig+) 製品は理論上の最大速度が 2 ～ 4 倍高速。",
          "一部の SKU 限定。OEM の設計により機能は異なります。詳細については、OEM または販売店にお問い合わせください。Wi‑Fi の詳細については、www.intel.com/wifi6disclaimers を参照してください。",
          "¹⁰ 40Gbps の Thunderbolt™ 4 テクノロジーは、eSATA*、USB*、IEEE 1394* FireWire など他の PC I/O 接続技術と比較して、あらゆるドック、ディスプレイ、データデバイスに対し、最も高速、シンプル、かつ信頼性の高いケーブル・ソリューションを提供します。",
          "¹¹ OFDMA 対応の 802.11ax ルーター 1 台と、AX 規格に準拠したネットワーク上に複数クライアントが必要です。802.11ax クライアントと AP で構成される OFDMA 機能によって、高密度環境での高速化が可能になります。2Gbps は、802.11ax 160MHz 2402Mbps の条件で、IEEE 802.11* 規格における理論上の最大データ転送速度の約 70% を想定した値です。",
          "¹² バックグラウンド・アクティビティー・ワークロードありのドキュメント起動で測定。第 8 世代インテル® Core™ i7-8565U プロセッサー (512GB TLC SSD) と第 8 世代インテル® Core™ i7-8565U プロセッサー (32GB + 512GB インテル® Optane™ メモリー H10 (ソリッドステート・ストレージ対応) ) を比較。",
          "¹³ インテル® スレッド・ディレクターを、第 12 世代インテル® Core™ プロセッサーの設計に組み込むことで、オペレーティング・システムがチャネルのワークロードをよりインテリジェントに適切なコアに割り当てられるようにしています。ユーザーの操作は不要です。詳細については、http://www.intel.co.jp/ を参照してください。",
          "¹⁴ 一部の第 12 世代インテル® Core™ プロセッサーでは利用できません。パフォーマンス・ハイブリッド・アーキテクチャーは、Performance-cores (P-cores) および Efficient-cores (E-cores) という 2 つの新しいコア・マイクロアーキテクチャーを、単一のプロセッサー・ダイ上で組み合わせたものです。一部の第 12 世代インテル® Core™ プロセッサー (特定の第 12 世代インテル® Core™ i5 プロセッサーおよびそれ以下) は、パフォーマンス・ハイブリッド・アーキテクチャーをサポートしておらず、P-cores のみが搭載されています。",
          "¹⁵ ゲームの起動ワークロードにより、第 8 世代インテル® Core™ i7-8750H プロセッサー (インテル® Optane™ メモリーモジュール 32GB + インテル® SSD 256GB PCIe* + 1TB HDD) と、第 8 世代インテル® Core™ i7-8750H プロセッサー (インテル® SSD 256GB PCIe* + 1TB HDD) を測定して比較。ゲーム起動ワークロード - インテルが開発したワークロードにより、Total War*: WARHAMMER* II ビルド: 5577.0 を起動し、イントロのビデオが無効な状態でメインメニューを表示するまでの時間 (起動) を測定。インテル® Optane™ メモリーによるデータドライブ高速化の構成: インテル® Core™ i7-8750H プロセッサー、PL1=45W TDP、6C12T、ターボ時最大 4GHz の出荷前の OEM システム、グラフィックス: NVIDIA* GeForce* GTX 1070、メモリー: 4GB DDR4 x2、ストレージ: インテル® SSD シリーズ 760p 256GB (PCIe*) + HDD 1TB + インテル® Optane™ メモリー 32GB、OS: Windows* 10 RS3 ビルド 1709、MCU 0x84。インテル® Core™ i7-8750H プロセッサー、PL1=45W TDP、6C12T、ターボ時最大 4GHz の出荷前の OEM システム、グラフィックス: NVIDIA* GeForce* GTX 1070、メモリー: 4GB DDR4 x2、ストレージ: インテル® SSD シリーズ 760p 256GB (PCIe*) + HDD 1TB、OS: Windows* 10 RS3 ビルド 1709、MCU 0x84。",
          "¹⁶ ゲームのレベル読み込みワークロードにより、第 8 世代インテル® Core™ i7-8750H プロセッサー (インテル® Optane™ メモリーモジュール 32GB) と、第 8 世代インテル® Core™ i7-8750H プロセッサー (HDD のみ) を測定して比較。ゲームのレベル読み込みワークロード - インテルが開発したワークロードにより、Total War*: WARHAMMER II* ビルド: 5577.0 でメインメニューからレベル読み込みの完了までの経過時間 (レベル読み込み) を測定。インテル® Optane™ メモリーによるデータドライブ高速化の構成: インテル® Core™ i7-8750H プロセッサー、PL1=45W TDP、6C12T、ターボ時最大 4GHz の出荷前の OEM システム、グラフィックス: NVIDIA* GeForce* GTX 1070、メモリー: 4GB DDR4 x2、ストレージ: インテル® SSD シリーズ 760p 256GB (PCIe*) + HDD 1TB + インテル® Optane™ メモリー 32GB、OS: Windows* 10 RS3 ビルド 1709、MCU 0x84。インテル® Core™ i7-8750H プロセッサー、PL1=45W TDP、6C12T、ターボ時最大 4GHz の出荷前の OEM システム、グラフィックス: NVIDIA* GeForce* GTX 1070、メモリー: 4GB DDR4 x2、ストレージ: インテル® SSD シリーズ 760p 256GB (PCIe*) + HDD 1TB、OS: Windows* 10 RS3 ビルド 1709、MCU 0x84。",
          "¹⁷ Performance-cores の場合。性能は、使用状況、構成、その他の要因によって異なります。詳細については、www.intel.com/PerformanceIndex (英語) を参照してください。",
          "¹⁸ 動作周波数または電圧を改変すると、プロセッサーや他のシステム・コンポーネントの故障や耐用年数の減少を引き起こしたり、システムの安定性やパフォーマンスが低下するおそれがあります。仕様の枠を超えてプロセッサーを動作させた場合、製品保証は適用されない場合があります。詳しくは、システムおよびコンポーネントのメーカーにお問い合わせください。",
          "¹⁹ Intel vPro® プラットフォームの OS 上下層でのセキュリティー機能、アプリケーションとデータの保護、さまざまな規模の企業向けの高度な脅威対策と、製品の設計、製造、サポートに対するインテルのセキュリティー・ファースト・アプローチとの比類なき組み合わせにより測定されました。Intel vPro® プラットフォーム搭載のビジネス向け PC はすべて、独自のハードウェアベースのセキュリティー機能を含む厳密な仕様に関して検証済みです。詳細については、www.intel.com/PerformanceIndex (Platforms) (英語) を参照してください。絶対的なセキュリティーを提供できる製品やコンポーネントはありません。",
          "²⁰インテル® Connectivity Performance Suite は Windows* ベースのシステムでのみ使用できます。混雑したネットワークでのインテルの無線 (OTA) Wi-Fi テストでは、インテル® Connectivity Performance Suite のソフトウェア・アプリケーションによるワイヤレス・トラフィック・プライオリタイゼーションとスマート・アクセスポイント切り替えがない状態と比較しました。詳細については、www.intel.com/PerformanceIndex (Connectivity) (英語) を参照してください。結果は状況によって変わります。",
          "²¹前世代のプロセッサーと比較。",
          "²²インテル® Arc™ グラフィックスは、市場で初めて AV1 エンコードに対応した製品として登場 (2022年第 1 四半期時点)。詳細については、intel.com/performanceindex (英語) を参照してください。",
          "²³インテル® Evo™ デザインの独自機能で測定。モバイルユーザー体験の主要な基準を満たしていることも検証されています。2022年2月時点でのテスト。",
          "²⁴インテル® Unison™ ソリューションは、現在、Windows* ベースの PC 上の対象となるインテル® Evo™ デザインでのみ利用可能で、Android または iOS ベースの携帯電話とのみペアリングできます。すべてのデバイスが、サポートされている OS バージョンを実行している必要があります。",
          "²⁵最高メモリー速度は、1 チャネル当たり 1 DIMM (1DPC) の構成に相応しています。いずれかのチャネルに DIMM を追加する場合、最高メモリー速度に影響を与える可能性があります。最大 DDR5-5600MT/s 1DPC UDIMM 1Rx8、1Rx16 および DDR5-5200 1Rx8、1Rx16、2Rx8 (一部の SKU)。最大メモリー容量は 2DPC 構成の場合に実現可能です。",
          "²⁶標準 Wi-Fi との比較。詳細については、intel.com/performance-wireless (英語) を参照してください。結果は状況によって変わります。",
          "²⁷パフォーマンス・ハイブリッド・アーキテクチャーは、Performance-cores (P-cores) および Efficient-cores (E-cores) という 2 つのコア・マイクロアーキテクチャーを、単一のプロセッサー・ダイ上で組み合わせたもので、第 12 世代インテル® Core™ プロセッサーで初めて導入されました。一部の第 12 世代以降のインテル® Core™ プロセッサーは、パフォーマンス・ハイブリッド・アーキテクチャーを採用せず P-cores のみを搭載し、キャッシュサイズも前世代と同じです。SKU の詳細については、ark.intel.com を参照してください。",
          "²⁸ハードウェアに内蔵されたインテル® スレッド・ディレクターは、第 12 世代以降のインテル® Core™ プロセッサーのパフォーマンス・ハイブリッド・アーキテクチャー構成でのみ提供されます。OS の有効化が必要です。利用可能な特徴や機能は OS によって異なります。",
          "²⁹ハードウェアに内蔵されたインテル® スレッド・ディレクターは、第 12 世代および第 13 世代インテル® Core™ プロセッサーのパフォーマンス・ハイブリッド・アーキテクチャー構成でのみ提供されます。OS の有効化が必要です。利用可能な特徴や機能は OS によって異なります。",
          "³⁰3DMark Time Spy におけるインテル® Core™ Ultra モバイル・プロセッサー (シリーズ 2 — 開発コード名: Lunar Lake) とインテル® Core™ Ultra モバイル・プロセッサー (シリーズ 1 — 開発コード名: Meteor Lake) の リファレンス検証プラットフォームによる測定値の比較。詳細については、intel.com/performanceindex (英語) を参照してください。結果は状況によって変わります。",
          "³¹背景ぼかしと顔追跡は、Windows* ベースのシステムのみで使用可能です。",
          "³²Wi-Fi 7 製品は、新しいマルチリソースユニット・パンクチャリング機能により、6GHz の 320MHz チャネルと 5GHz の新しい 160MHz チャネルの組み合わせにアクセスできます。",
          "³³IEEE 無線規格の仕様と 2 つのデータストリームが可能なデバイスの理論上の最大データレートに基づいています。",
          "³⁴混雑したネットワーク環境のインテルのエンジニアリング・シミュレーションでは、新しい Wi-Fi 7 マルチリンク・オペレーション機能によりレイテンシーを大幅に低減できることが示されています。",
          "³⁵ OEM デフォルトのシャットダウン・レベルからの最短充電時間。個々のシステムの結果は状況によって変わります。詳細については、intel.com/Performance-Evo (英語) を参照してください。",
          "³⁶ 6GHz Wi-Fi 帯域幅は、すべての国 / 地域で使用できるわけではありません。",
          "³⁷ Windows* のみ",
          "³⁸ OEM デフォルトのシャットダウン・レベルからの充電時間。性能やベンチマーク結果について、さらに詳しい情報をお知りになりたい場合は、intel.co.jp/Evo を参照してください。",
          "³⁹インテル® Core™ Ultra モバイル・プロセッサー (シリーズ 2) を指します。独自のハードウェア・ベースのセキュリティー機能に基づいて、OS セキュリティー機能の上位と下位の組み合わせで比類のないパフォーマンスを実現し、前世代と比較して低消費電力でも優れたパフォーマンスを実現します。(2024年9月時点) 詳細については、intel.com/performanceindex (英語) を参照してください。結果は状況によって変わります。",
          "⁴⁰ソフトウェア・アプリケーションの描写は、情報提供のみを目的としています。AI 機能の利用は、ソフトウェアの購入やサブスクリプション、もしくはソフトウェアまたはプラットフォームのプロバイダーによる有効化を別途必要とする場合、あるいは特定の構成や互換性を必要とする場合があります。詳細については、www.intel.com/PerformanceIndex (英語) を参照してください。結果は状況によって変わります。© Intel Corporation.",
          "⁴¹表示の画像は加工またはシミュレートされている場合があります。AI 機能の利用は、ソフトウェアの購入やサブスクリプション、もしくはソフトウェアまたはプラットフォームのプロバイダーによる有効化を別途必要とする場合、あるいは特定の構成や互換性を必要とする場合があります。詳細については、www.intel.co.jp/AIPC を参照してください。",
          "⁴²対象のグローバル市場で、Windows* 11 の最新アップデートにおいて段階的にプレビューを展開しています。提供するタイミングは、デバイスや市場により異なります。詳しくは aka.ms/WindowsAIFeatures をご覧ください。© Intel Corporation.",
          "⁴³インテル® Unison™ ソリューションは、現在対象となるデザインにおいて提供されています。詳しくは www.intel.com/PerformanceIndex (英語) を参照してください。",
          "⁴⁴インテル® Core™ Ultra 7 プロセッサー 165H 上で動作する Dying Light 2 Stay Human で測定したネイティブ FPS と、インテル® X<sup>e</sup>SS でアップスケールした FPS の比較。詳しくは www.intel.com/PerformanceIndex (英語) を参照してください。結果は状況によって変わります。",
          "⁴⁵インテル® Core™ Ultra モバイル・プロセッサー (シリーズ 2 - 開発コード名: Lunar Lake) のリファレンス検証プラットフォームとインテル® Core™ Ultra モバイル・プロセッサー (シリーズ 1 - 開発コード名: Meteor Lake) による YouTube* 4K 30FPS AV1 における低 SoC パワーの測定値の比較。詳細については、intel.com/performanceindex (英語) を参照してください。結果は状況によって変わります。",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "インテルは、価格の誤りに関して一切責任を負いません。",
          "*その他の社名、製品名などは、一般に各社の表示、商標または登録商標です。",
          "© 2022 INFLEXION GAMES.NIGHTINGALE、INFLEXION GAMES および関連デザインおよび結合商標は、INFLEXION STUDIOS INC. の商標です。無断での引用、転載を禁じます。",
          "インテル® テクノロジーを使用するには、対応したハードウェア、ソフトウェア、またはサービスの有効化が必要となる場合があります。",
          "NERO* による結果、データはすべて、利便性および情報提供のみを目的としています。Intel Corporation は、NERO* アプリの正確性、パフォーマンスの結果やスコア、www.nero.com や他のサードパーティーのウェブサイトで利用できる、他のダウンロード可能なバージョンのアプリとの潜在的な差異などについて、一切の責任を負いません。NERO* PC ベンチマーク・スコアは、特定のコンピューター・システム、コンポーネント、ソフトウェア、操作、機能を使用して測定されます。結果はこれらの要因によって異なります。製品の購入を検討される場合は、他の製品と組み合わせた場合の本製品の性能など、ほかの情報や性能テストも参考にして、パフォーマンスを総合的に評価することをお勧めします。",
          "Intel vPro® プラットフォームのすべてのバージョンには、このプラットフォームを規定している管理機能用途、セキュリティー機能、システム性能および安定性を提供するために必要な、対象となるインテル® Core™ プロセッサー、対応オペレーティング・システム、インテル® LAN または WLAN シリコン、ファームウェアの強化、およびその他のハードウェアとソフトウェアが必要です。",
          "性能は、使用状況、構成、その他の要因によって異なります。詳細については、www.intel.com/PerformanceIndex (英語) を参照してください。",
          "インテルは、サードパーティーのデータについて管理や監査を行っていません。正確さを評価するには、他のソースを参照する必要があります。",
          "© Intel Corporation.Intel、インテル、Intel ロゴ、その他のインテルの名称やロゴは、Intel Corporation またはその子会社の商標です。*その他の社名、製品名などは、一般に各社の表示、商標または登録商標です。",
          "絶対的なセキュリティーを提供できる製品またはコンポーネントはありません。",
          "コストと結果は状況によって異なります。",
          "Intel、インテル、Intel ロゴ、Intel Core、Intel Optane、Intel Iris、Thunderbolt、Thunderbolt ロゴは、Intel Corporation またはその子会社の商標です。その他の社名、製品名などは、一般に各社の表示、商標または登録商標です。",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "ようこそ",
    "tr_welcomePagetrSubtitle": "この PC をスマートフォンで遠隔コントロール",
    "tr_welcomePagembSubtitle": "ようこそ、この PC の仕様をスマートフォンでご覧ください",
    "tr_welcomePageBtnText": "スタート",
    "tr_welcomePageText": "この PC の詳細情報をスマートフォンで確&#65279;認&#65279;し&#65279;て比較してください",
    "tr_scrollText": "目の前にあるデバイスについて",
    "tr_toolTipTextc1": "これらのボタンを使用すると、目の前にあるデバイスの各ページを移動できます。",
    "tr_toolTipTextc2": "下のカードをタップすると、各デバイスの仕様の詳細ページに移動します。",
    "tr_toolTipTextc3": "これらのボタンを使用すると、目の前の画面が上下に移動します。",
    "tr_toolTipTextc4": "リモートモードをオフに切り替えると、スマートフォンにデバイスの仕様が表示されます。",
    "tr_toolTipTextmb1": "カードをタップすると詳細のページが表示されます。",
    "tr_toolTipTextmb2": "タップするとすべてのデバイス仕様の概要が表示されます",
    "tr_toolTipTextmb3": "ボタンをタップして QR コードをスキャンすると、4 台までのデバイスを比較できます",
    "tr_nextButton": "次へ",
    "tr_prevButton": "前へ",
    "tr_gotItButton": "OK",
    "tr_skipButton": "スキップ",
    "tr_placeholderText": "*プレースホルダー",
    "tr_processorHeading": "プロセッサー",
    "tr_processorSubheading": "第 10 世代イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™&nbsp;i7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
    "tr_memorySubheading": "[XX]GB",
    "tr_storageSubheading": "[XX]GB インテル® Optane™ メモリー + [YY]GB&nbsp;SSD",
    "tr_coachmarkProcessor": "XX プロセッサー",
    "tr_coachmarkGraphics": "[XX] グラフィックス",
    "tr_coachmarkMemory": "仕様の値",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999.95 米ドル",
    "tr_compare": "比較",
    "tr_viewallspecs": "すべての仕様を見る",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "AMD* プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
    "tr_QualcommProcessorNonIntel": "Qualcomm* プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
    "tr_GBText": "GB",
    "tr_remoteText": "ノートブック PC の画面に表示された仕様",
    "tr_scanErrorText": "一度にスキャンできるデ&#65279;バ&#65279;イ&#65279;ス&#65279;は 3 台までです。1&nbsp;台&#65279;以&#65279;上&#65279;の&#65279;デ&#65279;バ&#65279;イ&#65279;ス&#65279;を削&#65279;除&#65279;し&#65279;て&#65279;く&#65279;だ&#65279;さ&#65279;い。",
    "tr_scanErrorText2": "こ&#xfeff;のデ&#xfeff;バ&#xfeff;イ&#xfeff;スはスキ&#65279;ャ&#65279;ン&#65279;済&#65279;みです。別のデバイス&#65279;を&#65279;ス&#65279;キ&#65279;ャ&#65279;ン&#65279;してください。",
    "tr_scanMsg": "スキャンしてデバイスを追&#xfeff;加&#xfeff;し&#xfeff;てく&#xfeff;だ&#xfeff;さ&#xfeff;い",
    "tr_backToScan": "比較に戻る",
    "tr_scanToCompare": "スキャンして比較する",
    "tr_compareDevice": "デバイスの比較",
    "tr_processorTitle": "プロセッサー",
    "tr_graphicTitle": "グラフィックス",
    "tr_storageTitle": "ストレージ",
    "tr_displayTitle": "ディスプレイ",
    "tr_batteryTitle": "バッテリー",
    "tr_memoryTitle": "メモリー",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "接続性",
    "tr_priceTitle": "価格",
    "tr_operatingSystemTitle": "オペレーティング・シ&#65279;ス&#65279;テ&#65279;ム",
    "tr_batteryHoursText": "[XX] 時間",
    "tr_hrsText": "時間",
    "tr_touchscreeenText": "[screenDiagonal] インチのタ&#xfeff;ッ&#65279;チス&#65279;ク&#65279;リ&#65279;ー&#65279;ン",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD、タッチスクリーン",
    "tr_FHDTouchScreenText": "FHD、タッチスクリーン",
    "tr_4KTouchscreenText": "4K、タッチスクリーン",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "保存",
    "tr_device_comparison": "デバイス比較ドキュメント [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "JPEG として保存",
    "tr_Save_As_PDF": "PDF として保存",
    "tr_Downloading_PDF": "PDF をダウンロード中",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "オープン",
    "tr_logo": "ロゴ",
    "tr_laptop_name": "ノートブック PC 名",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "インテル® Optane™ メモリー",
    "tr_inchUnit": "インチ",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "インテル® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "インテル® Wi‑Fi 6 (Gig+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "インテル® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "インテル® Optane™ メモリー",
    "tr_Thunderbolt3": "Thunderbolt™&nbsp;3 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
    "tr_Thunderbolt4": "Thunderbolt™&nbsp;4 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
    "tr_processorGraphicsCaps": "プロセッサー・グラフィックス",
    "tr_processorGraphicsSmall": "プロセッサー・グラフィックス",
    "tr_graphicsCardCaps": "専用グラフィックス",
    "tr_graphicsCardSmall": "専用グラフィックス",
    "tr_processorTitleCamel": "プロセッサー",
    "tr_graphicTitleCamel": "グラフィックス",
    "tr_storageTitleCamel": "ストレージ",
    "tr_displayTitleCamel": "ディスプレイ",
    "tr_batteryTitleCamel": "バッテリー",
    "tr_memoryTitleCamel": "メモリー",
    "tr_connectivityTitleCamel": "接続性",
    "tr_priceTitleCamel": "価格",
    "tr_operatingSystemTitleCamel": "オペレーティング・システム",
    "tr_viewallspecsCamel": "すべての仕様を見る",
    "tr_displayText": "[screenDiagonal] インチ",
    "tr_displayTextResolution": "[screenDiagonal] インチ [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal] インチ [screenResolutionType] タッチスクリーン",
    "tr_OS": "OS",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "PC の仕様",
    "tr_explorePC_text": "この PC の詳細情報",
    "tr_mtlPC_text": "「すべてをこの 1 台で」の真の意味とは",
    "tr_optaneMemory": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Optane™ メモリー",
    "tr_displayText_Display": "[screenDiagonal] インチ・ディスプレイ",
    "tr_displayTextResolution_Display": "[screenDiagonal] インチ [screenResolutionType] ディスプレイ",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal] インチ [screenResolutionType] タッチスクリーン・ディスプレイ",
    "tr_displayTextTouchscreeenText": "[screenDiagonal] インチタッチスクリーン・ディスプレイ",
    "tr_learnMoreLabel": "詳しくはこちら",
    "tr_batteryHoursTextUpperCase": "[XX] 時間",
    "tr_processorGraphicsIrisXe": "第 11 世代インテル® Core™ [cpu_fam] プロセッサーとイ&#65279;ン&#65279;テ&#65279;ル&#65279;®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup> グラフィックス",
    "tr_processorGraphicsIrisXeMax": "第 11 世代インテル® Core™ [cpu_fam] プロセッサーとイ&#65279;ン&#65279;テ&#65279;ル&#65279;® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX グラフィックス",
    "tr_Thunderbolt3UpperCase": "Thunderbolt™ 3 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
    "tr_Thunderbolt4UpperCase": "Thunderbolt™ 4 テ&#65279;ク&#65279;ノ&#65279;ロ&#65279;ジ&#65279;ー",
    "tr_processorWithArcGraphics": "第 12 世代インテル® Core™ [cpu_fam] プロセッサーとインテル® Arc™ グラフィックス",
    "tr_processorGraphicsIrisXe12Gen": "第 12 世代インテル® Core™ [cpu_fam] プロセッサーとインテル® Iris® X<sup class='small_text_sup_xe'>e</sup> グラフィックス",
    "tr_processorGraphicsIrisXeMax12Gen": "第 12 世代インテル® Core™ [cpu_fam] プロセッサーとインテル® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX グラフィックス",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "第 13 世代インテル® Core™ [cpu_fam] プロセッサーとイ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Arc™ グラフィックス",
    "tr_processorGraphicsIrisXe13Gen": "第 13 世代インテル® Core™ [cpu_fam] プロセッサーとイ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup> グラフィックス",
    "tr_processorGraphicsIrisXeMax13Gen": "第 13 世代インテル® Core™ [cpu_fam] プロセッサーとイ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX グラフィックス",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "インテル® ARC™ A350M グラフィックス",
      "tr_A370M": "インテル® ARC™ A370M グラフィックス",
      "tr_A730M": "インテル® ARC™ A730M グラフィックス",
      "tr_A770M": "インテル® ARC™ A770M グラフィックス",
      "tr_A310": "インテル® ARC™ A310 グラフィックス",
      "tr_A380": "インテル® ARC™ A380 グラフィックス",
      "tr_A580": "インテル® ARC™ A580 グラフィックス",
      "tr_A750": "インテル® ARC™ A750 グラフィックス",
      "tr_A770": "インテル® ARC™ A770 グラフィックス"
    },
    "tr_memoryValue": "[XX]GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1]GB メモリー&nbsp;= [Value2]GB イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Optane™ メ&#xfeff;モ&#xfeff;リ&#xfeff;ー&nbsp;+ [Value3]GB RAM",
    "tr_textGBGB": "[convertedSSD]GB SSD + [convertedHDD]GB HDD",
    "tr_textGBTB": "[convertedSSD]GB SSD + [convertedHDD]TB HDD",
    "tr_textTBGB": "[convertedSSD]TB SSD + [convertedHDD]GB HDD",
    "tr_textTBTB": "[convertedSSD]TB SSD + [convertedHDD]TB HDD",
    "tr_textGBSSD": "[convertedSSD]GB SSD",
    "tr_textTBSSD": "[convertedSSD]TB SSD",
    "tr_textGBHDD": "[convertedHDD]GB HDD",
    "tr_textTBHDD": "[convertedHDD]TB HDD",
    "tr_textStorageWithOptaneGB": "[XX]GB イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Optane™ メ&#xfeff;モ&#xfeff;リ&#xfeff;ー +&nbsp;[convertedSSD]GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "[XX]GB イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Optane™ メ&#xfeff;モ&#xfeff;リ&#xfeff;ー +&nbsp;[convertedSSD]TB SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows® 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home (S モード)",
      "tr_OS21": "Windows* 10 Pro (S モード)",
      "tr_OS22": "Windows* 10 Enterprise (S モード)",
      "tr_OS23": "Windows* 11 Home (S モード)",
      "tr_OS24": "Windows* 11 Pro (S モード)",
      "tr_OS25": "Windows* 11 Enterprise (S モード)"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "インテル® Arc™ A310 グラフィックス",
      "tr_GR387": "インテル® Arc™ A350M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR388": "インテル® Arc™ A370M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR389": "インテル® Arc™ A380 グラフィックス",
      "tr_GR390": "インテル® Arc™ A530M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR391": "インテル® Arc™ A550M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR392": "インテル® Arc™ A570M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR393": "インテル® Arc™ A580 グラフィックス",
      "tr_GR394": "インテル® Arc™ A730M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR395": "インテル® Arc™ A750 グラフィックス",
      "tr_GR396": "インテル® Arc™ A770 グラフィックス",
      "tr_GR397": "インテル® Arc™ A770M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR398": "インテル® Arc™ グラフィックス",
      "tr_GR399": "インテル® Arc™ Pro A30M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR400": "インテル® Arc™ Pro A40/A50 グラフィックス",
      "tr_GR401": "インテル® Arc™ Pro A60 グラフィックス",
      "tr_GR402": "インテル® Arc™ Pro A60M グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィ&#xfeff;ッ&#xfeff;ク&#xfeff;ス",
      "tr_GR403": "インテル® グラフィックス",
      "tr_GR404": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 2000",
      "tr_GR405": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 2500",
      "tr_GR406": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 3000",
      "tr_GR407": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 400",
      "tr_GR408": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 4000",
      "tr_GR409": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 405",
      "tr_GR410": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 4200",
      "tr_GR411": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 4400",
      "tr_GR412": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 4600",
      "tr_GR413": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 500",
      "tr_GR414": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 5000",
      "tr_GR415": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 505",
      "tr_GR416": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 510",
      "tr_GR417": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 515",
      "tr_GR418": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 520",
      "tr_GR419": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 530",
      "tr_GR420": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 5300",
      "tr_GR421": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 5500",
      "tr_GR422": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 5600",
      "tr_GR423": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 6000",
      "tr_GR424": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 610",
      "tr_GR425": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 615",
      "tr_GR426": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 620",
      "tr_GR427": "インテル® HD グ&#xfeff;ラ&#xfeff;フ&#xfeff;ィックス 630",
      "tr_GR428": "インテル® HD グラフィックス",
      "tr_GR429": "インテル® Iris® グラフィックス 5100",
      "tr_GR430": "インテル® Iris® グラフィックス 540",
      "tr_GR431": "インテル® Iris® グラフィックス 550",
      "tr_GR432": "インテル® Iris® グラフィックス 6100",
      "tr_GR433": "インテル® Iris® Plus グラフィックス 640",
      "tr_GR434": "インテル® Iris® Plus グラフィックス 645",
      "tr_GR435": "インテル® Iris® Plus グラフィックス 650",
      "tr_GR436": "インテル® Iris® Plus グラフィックス 655",
      "tr_GR437": "インテル® Iris® Plus グラフィックス",
      "tr_GR438": "インテル® Iris® Pro グラフィックス 5200",
      "tr_GR439": "インテル® Iris® Pro グラフィックス 580",
      "tr_GR440": "インテル® Iris® Pro グラフィックス 6200",
      "tr_GR441": "インテル® Iris® X<sup>e</sup> グラフィックス",
      "tr_GR442": "インテル® Iris® X<sup>e</sup> MAX グラフィックス A220M",
      "tr_GR443": "インテル® Iris® X<sup>e</sup> MAX グラフィックス",
      "tr_GR444": "インテル® インテグレーテッド・グラフィックス",
      "tr_GR445": "インテル® UHD グラフィックス 600",
      "tr_GR446": "インテル® UHD グラフィックス 605",
      "tr_GR447": "インテル® UHD グラフィックス 610",
      "tr_GR448": "インテル® UHD グラフィックス 615",
      "tr_GR449": "インテル® UHD グラフィックス 617",
      "tr_GR450": "インテル® UHD グラフィックス 620",
      "tr_GR451": "インテル® UHD グラフィックス 630",
      "tr_GR452": "インテル® UHD グラフィックス 710",
      "tr_GR453": "インテル® UHD グラフィックス 730",
      "tr_GR454": "インテル® UHD グラフィックス 770",
      "tr_GR455": "インテル® UHD グラフィックス",
      "tr_GR456": "インテル® UHD グラフィックス",
      "tr_GR457": "インテル® UHD グラフィックス",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 with Max-Q デザイン",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti with Max-Q デザイン",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 with Max-Q デザイン",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 with Max-Q デザイン",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 with Max-Q デザイン",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 with Max-Q デザイン",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti with Max-Q デザイン",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti with Max-Q デザイン",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 with Max-Q デザイン",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 with Max-Q デザイン",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super with Max-Q デザイン",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 with Max-Q デザイン",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super with Max-Q デザイン",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 with Max-Q デザイン",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 with Max-Q デザイン",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 with Max-Q デザイン",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 with Max-Q デザイン",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 with Max-Q デザイン",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 with Max-Q デザイン",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 with Max-Q デザイン",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 with Max-Q デザイン",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 with Max-Q デザイン",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 with Max-Q デザイン",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "インテル® Arc™ グラフィックス",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 デュアルスロット"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "インテル® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "インテル® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "インテル® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "インテル® Centrino® Advanced-N 6205",
      "tr_Wifi5": "インテル® Centrino® Advanced-N 6230",
      "tr_Wifi6": "インテル® Centrino® Advanced-N 6235",
      "tr_Wifi7": "インテル® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "インテル® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "インテル® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "インテル® Centrino® Wireless-N 100",
      "tr_Wifi11": "インテル® Centrino® Wireless-N 1000",
      "tr_Wifi12": "インテル® Centrino® Wireless-N 1030",
      "tr_Wifi13": "インテル® Centrino® Wireless-N 105",
      "tr_Wifi14": "インテル® Centrino® Wireless-N 135",
      "tr_Wifi15": "インテル® Centrino® Wireless-N 2200",
      "tr_Wifi16": "インテル® Centrino® Wireless-N 2230",
      "tr_Wifi17": "インテル® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "インテル® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "インテル® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "インテル® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "インテル® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "インテル® Dual Band Wireless-N 3160",
      "tr_Wifi23": "インテル® Dual Band Wireless-N 7260",
      "tr_Wifi24": "インテル® Dual Band Wireless-N 7265",
      "tr_Wifi25": "インテル® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "インテル® Wi-Fi 6 AX101",
      "tr_Wifi27": "インテル® Wi-Fi 6 AX200",
      "tr_Wifi28": "インテル® Wi-Fi 6 AX201",
      "tr_Wifi29": "インテル® Wi-Fi 6 AX203",
      "tr_Wifi30": "インテル® Wi-Fi 6E AX210",
      "tr_Wifi31": "インテル® Wi-Fi 6E AX211",
      "tr_Wifi32": "インテル® Wi-Fi 6E AX411",
      "tr_Wifi33": "インテル® Wi-Fi 7 BE200",
      "tr_Wifi34": "インテル® Wi-Fi 7 BE202",
      "tr_Wifi35": "インテル® Wireless-AC 3165",
      "tr_Wifi36": "インテル® Wireless-AC 3168",
      "tr_Wifi37": "インテル® Wireless-AC 8265",
      "tr_Wifi38": "インテル® Wireless-AC 9260",
      "tr_Wifi39": "インテル® Wireless-AC 9461",
      "tr_Wifi40": "インテル® Wireless-AC 9462",
      "tr_Wifi41": "インテル® Wireless-AC 9560",
      "tr_Wifi42": "インテル® Wireless-N 3160",
      "tr_Wifi43": "インテル® Wireless-N 7260",
      "tr_Wifi44": "インテル® Wireless-N 7265",
      "tr_Wifi45": "インテル® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "インテル® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "インテル® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "インテル® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "インテル® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "インテル® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "インテル® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "インテル® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "インテル® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "インテル® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "インテル® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "インテル® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "インテル® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "インテル® Wi-Fi 7 BE201",
      "tr_Wifi59": "インテル® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "インテル® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "今すぐ購入",
    "tr_textTryagain": "もう一度試す",
    "tr_textBuynowErr": "接続が途切れたため、「今すぐ購入」オプションは利用できません。"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_MTL",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_MTL",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Hybrid Technology 搭&#xfeff;載イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;® Core™ i5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers (&#xfeff;ア&#xfeff;ベ&#xfeff;ン&#xfeff;ジ&#xfeff;ャ&#xfeff;ー&#xfeff;ズ&#xfeff;) ",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "インテル® プロセッサーで最強のゲーム体験にシフト",
                "tr_subtitle": "インテル® プロセッサー搭載の PC で、ゲ&#xfeff;ーム内最適化機能が Marvel’s Avengers* (アベンジャーズ) に命を吹き込む",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "インテル® Extreme Masters を体験",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "世界最高クラスのゲーマーたちが&#xfeff;競&#xfeff;う&#xfeff;場&#xfeff;所",
                "tr_subtitle": "ビデオゲーム史上最長のイベントシリーズである、インテル® Extreme Masters が e&nbsp;スポーツ標準を定めています。",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "プロレベルのゲームプレイ",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "インテルが導いた優勝",
                "tr_subtitle": "2021年レインボーシックス シージ* 北米リーグ大会で優勝したチーム Soniqs が、これまでのゲーム経歴、eスポーツの未来、インテルとのパートナーシップについて話します。",
                "tr_pretitle": "Soniqs の紹介",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR 対応",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "VR を現実のものに",
                "tr_subtitle": "まるで現実のような臨場感のある驚異的な仮想現実体験をコンピューターで実現します。最新のインテル® Core™ プロセッサーにより、だれにでも変身でき、どこにでも行くことができます。新たな次元のゲーム体験を手に入れてください。",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Xbox* Game Pass Ultimate と対応コントローラーで、Sea of Thieves* や Psychonauts* 2 など、100 本以上の高品質コンソールゲームを Windows* PC、スマートフォン、タブレットでプレイできます。",
                "titleArray": [
                  {
                    "tr_title": "デバイスの違いを超えて一緒にプレイ",
                    "tr_subtitle": "共ににプレイすることを待ち望む、何百万人ものプレイヤーのコミュニティーに出会い Xbox* の中核に触れましょう。地球の反対側にいる相手やすぐ隣にいる人まで、ゲームの共有ライブラリーから他のユーザーとつながりプレイを始めてください。"
                  },
                  {
                    "tr_title": "Windows* PC でゲーム",
                    "tr_subtitle": "Windows* 用 Xbox* アプリと対応コントローラーで、クラウド上にリストされたコンソールゲームをプレイできます。"
                  },
                  {
                    "tr_title": "手に取ってすぐにプレイ",
                    "tr_subtitle": "これまで以上に多くの場所で、素晴らしい Xbox* ゲームが楽しめます。家にいてテレビが使用中でも、カフェや様様な場所で、高速インターネットがあれば、PC、スマートフォン、そしてタブレットで 100 本以上のゲームがプレイできます。"
                  },
                  {
                    "tr_title": "コントローラーがなくても",
                    "tr_subtitle": "Xbox* のタッチ操作ゲーミングが新しい楽しみ方です。今まで慣れ親しんできたゲーム体験を、そしてコントローラー操作のレベルを提供します。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "スポットライト",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100 を超える AI 体験",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "充実の操作を少ないタッチで",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "環境に関わらずフレーム内に",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "クリエイティブな制作",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "プロレベルの照明補正",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "写真をさらに引き立てるエフェクトを簡単に追加",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "AI の力で曲をリミックス",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生産性",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "生産性とプライバシーを向上",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "コンセントがない場所でも長時間使用が可能に",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "思う世界を デバイスで",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "エンターテインメント",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "電源接続なしでもスムーズなストリーミング",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "高速で臨場感あふれるゲーム",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "スポットライト",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100 を超える AI 体験",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "お気に入りの曲を自分だけのものに",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "リアルタイムでアイディアを見える形に",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "クリエイティブな制作",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "編集は一気に仕上げ段階に",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "すべての写真を完璧なものに",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生産性",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "生産性とプライバシーを向上",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "もっと効率的に。も&#xfeff;っ&#xfeff;と&#xfeff;自&#xfeff;由&#xfeff;に&#xfeff;。",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "思う世界を デバイスで",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "エンターテインメント",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "電源接続なしでもスムーズな HD ストリーミング",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "臨場感あふれるゲームをより多くの場所で",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "さらにクリエイティブ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "写真の編集を手軽に",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "額に入れたいくらいの写真を数秒で作成",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "文字を入力。AI が生成。",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "さらに優れたパフォーマンス",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "スムーズにゲームを楽しむその「隠しコマンド」は",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "コンセントがない場所でも長時間使用が可能に",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "ULTRA- 臨場感",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "さらに生産的",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "身近に使える AI アシスタント機能",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "ビデオ会議を完璧に",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "思う世界を デバイスで",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "さらに安全",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "内蔵の保護機能がデータをより安全にプライベートに保ちます",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "さらにクリエイティブ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "写真の編集を手軽に",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI。最終カットまでのショートカット。",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "お気に入りの曲の歌詞を素早く文字に",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "AI で編集時間を短縮",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "さらに優れたパ&#xfeff;フ&#xfeff;ォ&#xfeff;ー&#xfeff;マ&#xfeff;ン&#xfeff;ス",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "スムーズにゲームを楽しむその「隠しコマンド」は",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "コンセントがない場所でも長時間使用が可能に",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra- 臨場感",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "さらに生産的",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "身近に使える AI アシスタント機能",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "ビデオ会議を完璧に",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "思う世界を デバイスで",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "さらに安全",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "内蔵の保護機能がデータをより安全にプライベートに保ちます",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "すべてのエ&#xfeff;ン&#xfeff;タ&#xfeff;ーテ&#xfeff;イ&#xfeff;ンメ&#xfeff;ン&#xfeff;ト&#xfeff;を 1&nbsp;台&#xfeff;の&nbsp;PC&nbsp;で",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC がエンターテインメント・センターとしての役割も果たす場合、お気に入りの音楽、動画、ゲームをすべて保存するために十分なストレージと、臨場感にあふれたビジュアルを表示するために適切な組み合わせのプロセッサーとディスプレイが必要です。この PC にはこの 3 つすべてが搭載されています。",
                "titleArray": [
                  {
                    "tr_title": "次世代のビジュアルに対応",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "自分だけのデジタル・ライブラリーを構築しよう",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "エンターテインメントへの窓口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "シームレスなゲームとストリーミング",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "自分だけのデジタル・ライブラリーを構築しよう",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "エンターテインメントへの窓口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生産性の向上い&#xfeff;つ&#xfeff;で&#xfeff;も&#xfeff;ど&#xfeff;こ&#xfeff;で&#xfeff;も",
              "tr_tileTitle1": "より多くの作業を高&#xfeff;速&#xfeff;に",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "この PC は、処理能力と携帯性が最適なバランスで設計されています。薄型軽量設計に適したプロセッサーだけでなく、安定した接続を提供するタイプの Wi&#8209;Fi、持続時間の長いバッテリーも特長としています。",
                "tr_subtitle1": "この PC はパワーとパフォーマンスの&#xfeff;完&#xfeff;璧な&#xfeff;バ&#xfeff;ランスが保たれており、短時間でよ&#xfeff;り多&#xfeff;く&#xfeff;のことを達成できます。十分な&#xfeff;メ&#xfeff;モリ&#xfeff;ー&#xfeff;で高速化された堅牢なプロセッサ&#xfeff;ー&#xfeff;と超&#xfeff;高&#xfeff;速 Wi-Fi の組み合わせにより、毎&#xfeff;日&#xfeff;のタ&#xfeff;ス&#xfeff;クが合理化されます。",
                "tr_subtitle2": "この PC は、処理能力と携帯性が最適なバランスで設計されています。この PC は、薄型軽量設計に適したプロセッサーだけでなく、安定した接続を提供するタイプの Wi‑Fi、マルチタスキングに適したメモリーも特長としています。",
                "titleArray": [
                  {
                    "tr_title": "より多くを",
                    "tr_title1": "圧倒的にインテリジェントなパフォーマンス",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "クラス最高レベルの接続",
                    "tr_title1": "大切なものにつながる",
                    "tr_title2": "Wi‑Fi の飛躍的な進化",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "長時間のバッテリー持続",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "複数のプログラムを瞬時に切り替え",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "より自由に創作",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC にとって、コンテンツ制作は最も負荷の高い操作の 1 つです。こ&#65279;れ&#65279;ら&#65279;のコンポーネントは、制作過程を効率化するための機能を備えています。",
                "titleArray": [
                  {
                    "tr_title": "制作をよりスマートに",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "臨場感あふれる画像",
                    "tr_title1": "快適に制作",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "マルチタスク向けのメ&#xfeff;モ&#xfeff;リ&#xfeff;ー",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "この PC の内部",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "これらは新しい PC を選ぶ際に注目すべき 3 つの主な要素です。新しい PC の用途を決めることによって、これらの 3 つの要素がどの程度必要かがわかります。",
                "titleArray": [
                  {
                    "tr_title": "優れたパフォーマンスでゲームやストリーミング",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "ストレージ",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "メモリー",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "すべてのエ&#xfeff;ン&#xfeff;タ&#xfeff;ーテ&#xfeff;イ&#xfeff;ンメ&#xfeff;ン&#xfeff;ト&#xfeff;を 1&nbsp;台&#xfeff;の&nbsp;PC&nbsp;で",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC がエンターテインメント・センターとしての役割も果たす場合、お気に入りの音楽、動画、ゲームをすべて保存するために十分なストレージと、臨場感にあふれたビジュアルを表示するために適切な組み合わせのプロセッサーとディスプレイが必要です。この PC にはこの 3 つすべてが搭載されています。",
                "titleArray": [
                  {
                    "tr_title": "次世代のビジュアルに対応",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "自分だけのデジタル・ライブラリーを構築しよう",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "エンターテインメントへの窓口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "シームレスなゲームとストリーミング",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "自分だけのデジタル・ライブラリーを構築しよう",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "エンターテインメントへの窓口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "メモリー",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "ディスプレイ",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "高速マルチタスク",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速マルチタスク",
                "tr_subtitle": "速度低下なしに、より多くの処理を実行",
                "tr_subtitle1": "この PC が高速マルチタスクに最適な理由とは。",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "複数のプログラムを同時にこなすプロセッサー"
                  },
                  {
                    "tr_title": "高速、強力で信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "複数のアプリケーション間をシームレスに切り替えられるメモリー"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "ビデオチャット",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ビデオチャット",
                "tr_subtitle": "クリアで鮮&#xfeff;明&#xfeff;な&#xfeff;接&#xfeff;続",
                "tr_subtitle1": "この PC がビデオチャットに最適な理由とは。",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "動画の録画と共有を同時に行えるプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "高速、強力で信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "クリアで鮮明な映像が体験できるウェブカメラ"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 対応",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR を現実のものに",
                "tr_subtitle": "完全な仮想世界を創造するには何が必要でしょうか。PC 上でプロセッサー、グラフィックス・カードおよびメモリーが協調するよう調整される必要があります。",
                "titleArray": [
                  {
                    "tr_title": "仮想世界を推進",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "リアルなディテール",
                    "tr_title1": "臨場感を極める",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "動画編集",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "動画編集",
                "tr_subtitle": "魅力的な動画を",
                "tr_subtitle1": "この PC が動画編集に最適な理由とは。",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "大容量のファイルを処理できるプロセッサー"
                  },
                  {
                    "tr_title": "編集ソフトウェアを高速にそして応答性を向上させるメモリー"
                  },
                  {
                    "tr_title": "レンダリング時間を短縮するグラフィックス"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "高速マルチタスク",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速マルチタスク",
                "tr_subtitle": "速度低下なしに、より多くの処理を実行",
                "tr_subtitle1": "この PC が高速マルチタスクに最適な理由とは。",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "複数のプログラムを同時にこなすプロセッサー"
                  },
                  {
                    "tr_title": "高速、強力で信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "複数のアプリケーション間をシームレスに切り替えられるメモリー"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 対応",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR を現実のものに",
                "tr_subtitle": "完全な仮想世界を創造するには何が必要でしょうか。PC 上でプロセッサー、グラフィックス・カードおよびメモリーが協調するよう調整される必要があります。",
                "titleArray": [
                  {
                    "tr_title": "仮想世界を推進",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "リアルなディテール",
                    "tr_title1": "臨場感を極める",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D モデリング",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D モデリング",
                "tr_subtitle": "ワンランク上のコンテンツ制作",
                "tr_subtitle1": "この PC が 3D モデリングに最適な理由とは。",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "先進の作成ソフトウェアを実行できるプロセッサー"
                  },
                  {
                    "tr_title": "大容量のファイルを迅速に開けるメモリー"
                  },
                  {
                    "tr_title": "パワフルに画像を作成、編集できるグラフィックス"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "リアルタイム・コ&#xfeff;ラ&#xfeff;ボ&#xfeff;レ&#xfeff;ー&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "リアルタイム・コ&#xfeff;ラ&#xfeff;ボ&#xfeff;レ&#xfeff;ー&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
                "tr_subtitle": "あらゆる場所から仕事ができる",
                "tr_subtitle1": "この PC がオンラインのコラボレーションに最適な理由とは。",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "業務ソフトウェアを力強くこなすプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "鮮明な HD で録画できるウェブカメラ"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 対応",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR を現実のものに",
                "tr_subtitle": "完全な仮想世界を創造するには何が必要でしょうか。PC 上でプロセッサー、グラフィックス・カードおよびメモリーが協調するよう調整される必要があります。",
                "titleArray": [
                  {
                    "tr_title": "仮想世界を推進",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "リアルなディテール",
                    "tr_title1": "臨場感を極める",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "インテル® Evo™ ノートブック PC",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "薄型軽量ノートブック PC とは思えないほどパワフルなパフォーマンス",
                "tr_subtitle": "優れたモバイル性能を持つインテル® Evo™ ノートブック PC は、求めるスピード、グラフィックス、そしてバッテリー持続時間をすべて備え、非常に美しい薄型軽量デザインに仕上げました。",
                "titleArray": [
                  {
                    "tr_title": "ノートブック PC はこれまでになく活用されているから、これまでになく使えるものを作りました。",
                    "tr_subtitle": "タッチスクリーンを使用して、探索、回転、拡大表示します。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬時に起動",
                    "tr_subtitle": "即時起動により、スリープ状態から 1&nbsp;秒未満で&nbsp;PC が起動します。"
                  },
                  {
                    "tr_title": "臨場感のある視聴体験",
                    "tr_subtitle": "美しいディスプレイのシャープな解像度と鮮明な色彩で本物のような映像を表示します。"
                  },
                  {
                    "tr_title": "迅速にタスクを完了",
                    "tr_subtitle": "第 11 世代インテル® Core™ プロセッサーの極めて応答性に優れたパフォーマンスをお楽しみください。"
                  },
                  {
                    "tr_title": "バッテリー駆動をもっと長く",
                    "tr_subtitle": "バッテリー持続時間を最適化して瞬時に充電で&#65279;き&#65279;る PC で、モバイルでも最高の生産性を維&#65279;持&#65279;。"
                  },
                  {
                    "tr_title": "汎用なケーブル接続",
                    "tr_subtitle": "Thunderbolt™ 4 テクノロジーを使うと PC にその他のデバイスを高速、安全、シンプルに接続できます。<sup>10</sup>"
                  },
                  {
                    "tr_title": "高速で信頼性の高い接続",
                    "tr_subtitle": "インテル® Wi‑Fi 6 (Gig+) による信頼できる接続でビデオチャットとファイルの共有。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "インテル® Evo™ ノートブック PC",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "すべてと可能性を満たす薄型軽量ノートブック PC",
                "tr_subtitle": "インテル® Evo™ ノートブック PC はモバイル・パフォーマンスに優れ、あらゆる用途において最高の使用体験を提供するべく設計されています。<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "ノートブック PC はこれまでになく活用されているから、これまでになく使えるものを作りました。",
                    "tr_subtitle": "タッチスクリーンを使用して、探索、回転、拡大表示します。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬時に起動",
                    "tr_subtitle": "即時起動により、スリープ状態から 1&nbsp;秒未満で&nbsp;PC が起動します。"
                  },
                  {
                    "tr_title": "臨場感のある視聴体験",
                    "tr_subtitle": "美しいディスプレイのシャープな解像度と鮮明な色彩で本物のような映像を表示します。"
                  },
                  {
                    "tr_title": "革新的なパフォーマンス",
                    "tr_subtitle": "第 12 世代インテル® Core™ プロセッサーのインテリジェントなパフォーマンスなら、最も必要な場面でさらにパワーを発揮できます。"
                  },
                  {
                    "tr_title": "バッテリー駆動をもっと長く",
                    "tr_subtitle": "バッテリー持続時間を最適化して瞬時に充電で&#65279;き&#65279;る PC で、モバイルでも最高の生産性を維&#65279;持&#65279;。"
                  },
                  {
                    "tr_title": "汎用なケーブル接続",
                    "tr_subtitle": "Thunderbolt™ 4 テクノロジーを使うと PC にその他のデバイスを高速、安全、シンプルに接続できます。<sup>10</sup>"
                  },
                  {
                    "tr_title": "高速で信頼性の高い接続",
                    "tr_subtitle": "ビデオチャットとファイルの共有をイ&#65279;ン&#65279;テ&#65279;ル&#65279;® Wi-Fi 6E (Gig+) による信頼で&#65279;き&#65279;る&#65279;接&#65279;続&#65279;で&#65279;。&#65279;<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "インテル® Evo™ ノートブック PC",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "薄型軽量ノートブック PC とは思えないほどパワフルなパフォーマンス",
                "tr_subtitle": "優れたモバイル性能を持つインテル® Evo™ ノートブック PC は、求めるスピード、グラフィックス、そしてバッテリー持続時間をすべて備え、非常に美しい薄型軽量デザインに仕上げました。",
                "titleArray": [
                  {
                    "tr_title": "ノートブック PC はこれまでになく活用されているから、これまでになく使えるものを作りました。",
                    "tr_subtitle": "タッチスクリーンを使用して、探索、回転、拡大表示します。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬時に起動",
                    "tr_subtitle": "即時起動により、スリープ状態から 1&nbsp;秒未満で&nbsp;PC が起動します。"
                  },
                  {
                    "tr_title": "臨場感のある視聴体験",
                    "tr_subtitle": "美しいディスプレイのシャープな解像度と鮮明な色彩で本物のような映像を表示します。"
                  },
                  {
                    "tr_title": "迅速にタスクを完了",
                    "tr_subtitle": "第 11 世代インテル® Core™ プロセッサーの極めて応答性に優れたパフォーマンスをお楽しみください。"
                  },
                  {
                    "tr_title": "バッテリー駆動をもっと長く",
                    "tr_subtitle": "バッテリー持続時間を最適化して瞬時に充電で&#65279;き&#65279;る PC で、モバイルでも最高の生産性を維&#65279;持&#65279;。"
                  },
                  {
                    "tr_title": "汎用なケーブル接続",
                    "tr_subtitle": "Thunderbolt™ 4 テクノロジーを使うと PC にその他のデバイスを高速、安全、シンプルに接続できます。<sup>10</sup>"
                  },
                  {
                    "tr_title": "高速で信頼性の高い接続",
                    "tr_subtitle": "インテル® Wi‑Fi 6 (Gig+) による信頼できる接続でビデオチャットとファイルの共有。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "インテル® Evo™ ノートブック PC",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "すべてと可能性を満たす薄型軽量ノートブック PC",
                "tr_subtitle": "インテル® Evo™ ノートブック PC はモバイル・パフォーマンスに優れ、あらゆる用途において最高の使用体験を提供するべく設計されています。<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "ノートブック PC はこれまでになく活用されているから、これまでになく使えるものを作りました。",
                    "tr_subtitle": "タッチスクリーンを使用して、探索、回転、拡大表示します。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬時に起動",
                    "tr_subtitle": "即時起動により、スリープ状態から 1&nbsp;秒未満で&nbsp;PC が起動します。"
                  },
                  {
                    "tr_title": "臨場感のある視聴体験",
                    "tr_subtitle": "美しいディスプレイのシャープな解像度と鮮明な色彩で本物のような映像を表示します。"
                  },
                  {
                    "tr_title": "革新的なパフォーマンス",
                    "tr_subtitle": "第 12 世代インテル® Core™ プロセッサーのインテリジェントなパフォーマンスなら、最も必要な場面でさらにパワーを発揮できます。"
                  },
                  {
                    "tr_title": "バッテリー駆動をもっと長く",
                    "tr_subtitle": "バッテリー持続時間を最適化して瞬時に充電で&#65279;き&#65279;る PC で、モバイルでも最高の生産性を維&#65279;持&#65279;。"
                  },
                  {
                    "tr_title": "汎用なケーブル接続",
                    "tr_subtitle": "Thunderbolt™ 4 テクノロジーを使うと PC にその他のデバイスを高速、安全、シンプルに接続できます。<sup>10</sup>"
                  },
                  {
                    "tr_title": "高速で信頼性の高い接続",
                    "tr_subtitle": "ビデオチャットとファイルの共有をイ&#65279;ン&#65279;テ&#65279;ル&#65279;® Wi-Fi 6E (Gig+) による信頼で&#65279;き&#65279;る&#65279;接&#65279;続&#65279;で&#65279;。&#65279;<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "インテル® Evo™ ノートブック PC",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "すべてと可能性を満たす薄型軽量ノートブック PC",
                "tr_subtitle": "インテル® Evo™ ノートブック PC はモバイル・パフォーマンスに優れ、あらゆる用途において最高の使用体験を提供するべく設計されています。<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "ノートブック PC はこれまでになく活用されているから、これまでになく使えるものを作りました。",
                    "tr_subtitle": "タッチスクリーンを使用して、探索、回転、拡大表示します。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬時に起動",
                    "tr_subtitle": "即時起動により、スリープ状態から 1&nbsp;秒未満で&nbsp;PC が起動します。"
                  },
                  {
                    "tr_title": "臨場感のある視聴体験",
                    "tr_subtitle": "美しいディスプレイのシャープな解像度と鮮明な色彩で本物のような映像を表示します。"
                  },
                  {
                    "tr_title": "革新的なパフォーマンス",
                    "tr_subtitle": "第 13 世代インテル® Core™ プロセッサーのインテリジェントなパフォーマンスなら、最も必要な場面でさらにパワーを発揮できます。"
                  },
                  {
                    "tr_title": "バッテリー駆動をもっと長く",
                    "tr_subtitle": "バッテリー持続時間を最適化して瞬時に充電で&#65279;き&#65279;る PC で、モバイルでも最高の生産性を維&#65279;持&#65279;。"
                  },
                  {
                    "tr_title": "汎用なケーブル接続",
                    "tr_subtitle": "Thunderbolt™ 4 テクノロジーを使うと PC にその他のデバイスを高速、安全、シンプルに接続できます。<sup>10</sup>"
                  },
                  {
                    "tr_title": "高速で信頼性の高い接続",
                    "tr_subtitle": "ビデオチャットとファイルの共有をイ&#65279;ン&#65279;テ&#65279;ル&#65279;® Wi-Fi 6E (Gig+) による信頼で&#65279;き&#65279;る&#65279;接&#65279;続&#65279;で&#65279;。&#65279;<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "インテル® Evo™ ノートブック PC",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "すべてと可能性を満たす薄型軽量ノートブック PC",
                "tr_subtitle": "インテル® Evo™ ノートブック PC はモバイル・パフォーマンスに優れ、あらゆる用途において最高の使用体験を提供するべく設計されています。<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "ノートブック PC はこれまでになく活用されているから、これまでになく使えるものを作りました。",
                    "tr_subtitle": "タッチスクリーンを使用して、探索、回転、拡大表示します。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬時に起動",
                    "tr_subtitle": "即時起動により、スリープ状態から 1&nbsp;秒未満で&nbsp;PC が起動します。"
                  },
                  {
                    "tr_title": "臨場感のある視聴体験",
                    "tr_subtitle": "美しいディスプレイのシャープな解像度と鮮明な色彩で本物のような映像を表示します。"
                  },
                  {
                    "tr_title": "革新的なパフォーマンス",
                    "tr_subtitle": "第 13 世代インテル® Core™ プロセッサーのインテリジェントなパフォーマンスなら、最も必要な場面でさらにパワーを発揮できます。"
                  },
                  {
                    "tr_title": "バッテリー駆動をもっと長く",
                    "tr_subtitle": "バッテリー持続時間を最適化して瞬時に充電で&#65279;き&#65279;る PC で、モバイルでも最高の生産性を維&#65279;持&#65279;。"
                  },
                  {
                    "tr_title": "汎用なケーブル接続",
                    "tr_subtitle": "Thunderbolt™ 4 テクノロジーを使うと PC にその他のデバイスを高速、安全、シンプルに接続できます。<sup>10</sup>"
                  },
                  {
                    "tr_title": "高速で信頼性の高い接続",
                    "tr_subtitle": "ビデオチャットとファイルの共有をイ&#65279;ン&#65279;テ&#65279;ル&#65279;® Wi-Fi 6E (Gig+) による信頼で&#65279;き&#65279;る&#65279;接&#65279;続&#65279;で&#65279;。&#65279;<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "スポットライト",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "インテル® Evo™ エディションとは",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "インテル® Evo™ エディションを選ぶ理由",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "クリエイティブな制作",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "より多くの場所で、さらにクリエイティブに",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "フルスピードで写真編集",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "オリジナルのアート作品をその場で制作",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生産性",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI で生産性を強化",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "共有前に AI を活用したディープフェイクの検出",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "思う世界を デバイスで",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "携帯性",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "ジェスチャー・コントロールの導入",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "作業効率を維持できるバッテリー持続時間",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "接続を自動維持",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "スポットライト",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "インテル® Evo™ エディションとは",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "インテル® Evo™ エディションを選ぶ理由",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "クリエイティブな制作",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "迅速かつスムーズなビデオ再生",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "AI のパワーでヒット曲をプ&#xfeff;ロ&#xfeff;デ&#xfeff;ュ&#xfeff;ー&#xfeff;ス",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "あっという間に編集を完了",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生産性",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI で生産性を強化",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "共有前に AI を活用したディープフェイクの検出",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "思う世界を デバイスで",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "携帯性",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "どんな場所からも自在にコラボレーション",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "作業効率を維持できるバッテリー持続時間",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "接続を自動維持",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "すべてのエ&#xfeff;ン&#xfeff;タ&#xfeff;ーテ&#xfeff;イ&#xfeff;ンメ&#xfeff;ン&#xfeff;ト&#xfeff;を 1&nbsp;台&#xfeff;の&nbsp;PC&nbsp;で",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC がエンターテインメント・センターとしての役割も果たす場合、お気に入りの音楽、動画、ゲームをすべて保存するために十分なストレージと、臨場感にあふれたビジュアルを表示するために適切な組み合わせのプロセッサーとディスプレイが必要です。この PC にはこの 3 つすべてが搭載されています。",
                "titleArray": [
                  {
                    "tr_title": "印象的なゲームとストリーミング",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "自分だけのデジタル・ライブラリーを構築しよう",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "エンターテインメントへの窓口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "シームレスなゲームとストリーミング",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "自分だけのデジタル・ライブラリーを構築しよう",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "エンターテインメントへの窓口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生産性の向上い&#xfeff;つ&#xfeff;で&#xfeff;も&#xfeff;ど&#xfeff;こ&#xfeff;で&#xfeff;も",
              "tr_tileTitle1": "より多くの作業を高&#xfeff;速&#xfeff;に",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "この PC は、処理能力と携帯性が最適なバランスで設計されています。薄型軽量設計に適したプロセッサーだけでなく、安定した接続を提供するタイプの Wi&#8209;Fi、持続時間の長いバッテリーも特長としています。",
                "tr_subtitle1": "この PC はパワーとパフォーマンスの&#xfeff;完&#xfeff;璧な&#xfeff;バ&#xfeff;ランスが保たれており、短時間でよ&#xfeff;り多&#xfeff;く&#xfeff;のことを達成できます。十分な&#xfeff;メ&#xfeff;モリ&#xfeff;ー&#xfeff;で高速化された堅牢なプロセッサ&#xfeff;ー&#xfeff;と超&#xfeff;高&#xfeff;速 Wi-Fi の組み合わせにより、毎&#xfeff;日&#xfeff;のタ&#xfeff;ス&#xfeff;クが合理化されます。",
                "tr_subtitle2": "この PC は、処理能力と携帯性が最適なバランスで設計されています。この PC は、薄型軽量設計に適したプロセッサーだけでなく、安定した接続を提供するタイプの Wi‑Fi、マルチタスキングに適したメモリーも特長としています。",
                "titleArray": [
                  {
                    "tr_title": "圧倒的にインテリジェントなパフォーマンス",
                    "tr_title1": "インテリジェントなパフォーマンスで高速化",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "クラス最高レベルの接続",
                    "tr_title1": "大切なものにつながる",
                    "tr_title2": "Wi‑Fi の飛躍的な進化",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "長時間のバッテリー持続",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "複数のプログラムを瞬時に切り替え",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "より自由に創作",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC にとって、コンテンツ制作は最も負荷の高い操作の 1 つです。こ&#65279;れ&#65279;ら&#65279;のコンポーネントは、制作過程を効率化するための機能を備えています。",
                "titleArray": [
                  {
                    "tr_title": "高速化する創造性",
                    "tr_title1": "クリエイティブなプロジェクトを加速する",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "創造力を引き上げる",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "マルチタスク向けのメ&#xfeff;モ&#xfeff;リ&#xfeff;ー",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "この PC の内部",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "これらは新しい PC を選ぶ際に注目すべき 3 つの主な要素です。新しい PC の用途を決めることによって、これらの 3 つの要素がどの程度必要かがわかります。",
                "titleArray": [
                  {
                    "tr_title": "プロセッサー",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "ストレージ",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "メモリー",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "より自由に創作",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC にとって、コンテンツ制作は最も負荷の高い操作の 1 つです。こ&#65279;れ&#65279;ら&#65279;のコンポーネントは、制作過程を効率化するための機能を備えています。",
                "titleArray": [
                  {
                    "tr_title": "制作をよりスマートに",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "臨場感あふれる画像",
                    "tr_title1": "快適に制作",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "マルチタスク向けのメ&#xfeff;モ&#xfeff;リ&#xfeff;ー",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生産性の向上い&#xfeff;つ&#xfeff;で&#xfeff;も&#xfeff;ど&#xfeff;こ&#xfeff;で&#xfeff;も",
              "tr_tileTitle1": "より多くの作業を高&#xfeff;速&#xfeff;に",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "この PC は、処理能力と携帯性が最適なバランスで設計されています。薄型軽量設計に適したプロセッサーだけでなく、安定した接続を提供するタイプの Wi&#8209;Fi、持続時間の長いバッテリーも特長としています。",
                "tr_subtitle1": "この PC はパワーとパフォーマンスの&#xfeff;完&#xfeff;璧な&#xfeff;バ&#xfeff;ランスが保たれており、短時間でよ&#xfeff;り多&#xfeff;く&#xfeff;のことを達成できます。十分な&#xfeff;メ&#xfeff;モリ&#xfeff;ー&#xfeff;で高速化された堅牢なプロセッサ&#xfeff;ー&#xfeff;と超&#xfeff;高&#xfeff;速 Wi-Fi の組み合わせにより、毎&#xfeff;日&#xfeff;のタ&#xfeff;ス&#xfeff;クが合理化されます。",
                "tr_subtitle2": "この PC は、処理能力と携帯性が最適なバランスで設計されています。この PC は、薄型軽量設計に適したプロセッサーだけでなく、安定した接続を提供するタイプの Wi‑Fi、マルチタスキングに適したメモリーも特長としています。",
                "titleArray": [
                  {
                    "tr_title": "より多くを",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "クラス最高レベルの接続",
                    "tr_title1": "大切なものにつながる",
                    "tr_title2": "Wi‑Fi の飛躍的な進化",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "長時間のバッテリー持続",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "複数のプログラムを瞬時に切り替え",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 対応",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR を現実のものに",
                "tr_subtitle": "完全な仮想世界を創造するには何が必要でしょうか。PC 上でプロセッサー、グラフィックス・カードおよびメモリーが協調するよう調整される必要があります。",
                "titleArray": [
                  {
                    "tr_title": "仮想世界を推進",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "リアルなディテール",
                    "tr_title1": "臨場感を極める",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>目に優しい映像",
      "tr_sub_header": "ブルーライトは、視覚的に不快に感じたり、睡眠サイクルを乱したりする可能性があります。SGS Eye Care ディスプレイ・テストを受けた Samsung の OLED は、色を変更するのではなく、有害になりうるブルーライトの波長を短くすることで、目の疲れを軽減し、快適な視界の実現に役立ちます。",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "有害なブルーライトを削減"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "あらゆる面で画期的",
      "tr_sub_header": "OLED の超軽量、超薄型のデザインは、重量を最小限に抑えながら画質を最大限に向上させています。"
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "トゥルーブラック",
      "tr_sub_header": "0.0005nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>従来型</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "ストリーミング",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ストリーミング",
                "tr_subtitle": "ずっとエンターテインメント",
                "tr_subtitle1": "この PC がストリーミングに最適な理由とは。",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "HD でストリーミングできるプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "明るく美しい画像を表示するデ&#xfeff;ィ&#xfeff;ス&#xfeff;プ&#xfeff;レ&#xfeff;イ"
                  },
                  {
                    "tr_title": "バッファリングや読み込み時間の短縮が可能な Wi‑Fi"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "ウェブ・ブラウジング",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ウェブ・ブラウジング",
                "tr_subtitle": "インターネットが簡単に",
                "tr_subtitle1": "この PC がウェブ・ブラウジングに最適な理由とは。",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "メディアリッチなページを迅速に読み込めるプロセッサー"
                  },
                  {
                    "tr_title": "高速で信頼性の高い Wi‑Fi"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "ビデオチャット",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ビデオチャット",
                "tr_subtitle": "クリアで鮮&#xfeff;明&#xfeff;な&#xfeff;接&#xfeff;続",
                "tr_subtitle1": "この PC がビデオチャットに最適な理由とは。",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "動画の録画と共有を同時に行えるプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "高速、強力で信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "クリアで鮮明な映像が体験できるウェブカメラ"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "高速マルチタスク",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速マルチタスク",
                "tr_subtitle": "速度低下なしに、より多くの処理を実行",
                "tr_subtitle1": "この PC が高速マルチタスクに最適な理由とは。",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "複数のプログラムを同時にこなすプロセッサー"
                  },
                  {
                    "tr_title": "高速、強力で信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "複数のアプリケーション間をシームレスに切り替えられるメモリー"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "ビデオチャット",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ビデオチャット",
                "tr_subtitle": "クリアで鮮&#xfeff;明&#xfeff;な&#xfeff;接&#xfeff;続",
                "tr_subtitle1": "この PC がビデオチャットに最適な理由とは。",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "動画の録画と共有を同時に行えるプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "高速、強力で信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "クリアで鮮明な映像が体験できるウェブカメラ"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "カジュアルゲーム",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "カジュアルゲーム",
                "tr_subtitle": "ゲームを存分に楽&#xfeff;し&#xfeff;む",
                "tr_subtitle1": "この PC が軽めのゲームに適している理由とは。",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "スムーズなゲームプレイを支えるプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "音声チャットとストリーミング配信を同時実行できるメモリー"
                  },
                  {
                    "tr_title": "美しい画像を表示するグラフィックス"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "写真編集",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "写真編集",
                "tr_subtitle": "ピクセルレベルで完璧な写真",
                "tr_subtitle1": "この PC が写真編集に最適な理由とは。",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "大量の画像を編集する能力を持つプロセッサー"
                  },
                  {
                    "tr_title": "編集ソフトウェアを加速するメモリー"
                  },
                  {
                    "tr_title": "高速にファイルを転送する Thunderbolt™ 4 テクノロジー"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "高速マルチタスク",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速マルチタスク",
                "tr_subtitle": "速度低下なしに、より多くの処理を実行",
                "tr_subtitle1": "この PC が高速マルチタスクに最適な理由とは。",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "複数のプログラムを同時にこなすプロセッサー"
                  },
                  {
                    "tr_title": "高速、強力で信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "複数のアプリケーション間をシームレスに切り替えられるメモリー"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "リアルタイム・コ&#xfeff;ラ&#xfeff;ボ&#xfeff;レ&#xfeff;ー&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "リアルタイム・コ&#xfeff;ラ&#xfeff;ボ&#xfeff;レ&#xfeff;ー&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
                "tr_subtitle": "あらゆる場所から仕事ができる",
                "tr_subtitle1": "この PC がオンラインのコラボレーションに最適な理由とは。",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "業務ソフトウェアを力強くこなすプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "鮮明な HD で録画できるウェブカメラ"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "動画編集",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "動画編集",
                "tr_subtitle": "魅力的な動画を",
                "tr_subtitle1": "この PC が動画編集に最適な理由とは。",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "大容量のファイルを処理できるプロセッサー"
                  },
                  {
                    "tr_title": "編集ソフトウェアを高速にそして応答性を向上させるメモリー"
                  },
                  {
                    "tr_title": "レンダリング時間を短縮するグラフィックス"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "写真編集",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "写真編集",
                "tr_subtitle": "ピクセルレベルで完璧な写真",
                "tr_subtitle1": "この PC が写真編集に最適な理由とは。",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "大量の画像を編集する能力を持つプロセッサー"
                  },
                  {
                    "tr_title": "編集ソフトウェアを加速するメモリー"
                  },
                  {
                    "tr_title": "高速にファイルを転送する Thunderbolt™ 4 テクノロジー"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "動画編集",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "動画編集",
                "tr_subtitle": "魅力的な動画を",
                "tr_subtitle1": "この PC が動画編集に最適な理由とは。",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "大容量のファイルを処理できるプロセッサー"
                  },
                  {
                    "tr_title": "編集ソフトウェアを高速にそして応答性を向上させるメモリー"
                  },
                  {
                    "tr_title": "レンダリング時間を短縮するグラフィックス"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D モデリング",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D モデリング",
                "tr_subtitle": "ワンランク上のコンテンツ制作",
                "tr_subtitle1": "この PC が 3D モデリングに最適な理由とは。",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "先進の作成ソフトウェアを実行できるプロセッサー"
                  },
                  {
                    "tr_title": "大容量のファイルを迅速に開けるメモリー"
                  },
                  {
                    "tr_title": "パワフルに画像を作成、編集できるグラフィックス"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "リアルタイム・コ&#xfeff;ラ&#xfeff;ボ&#xfeff;レ&#xfeff;ー&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "リアルタイム・コ&#xfeff;ラ&#xfeff;ボ&#xfeff;レ&#xfeff;ー&#xfeff;シ&#xfeff;ョ&#xfeff;ン",
                "tr_subtitle": "あらゆる場所から仕事ができる",
                "tr_subtitle1": "この PC がオンラインのコラボレーションに最適な理由とは。",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "業務ソフトウェアを力強くこなすプ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
                  },
                  {
                    "tr_title": "信頼性の高い Wi‑Fi"
                  },
                  {
                    "tr_title": "鮮明な HD で録画できるウェブカメラ"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "インテル® Unison™ アプリ",
    "tr_title": "つながる世界はよ&#xfeff;り&#xfeff;快&#xfeff;適&#xfeff;に",
    "tr_subtitle": "PC から携帯電話の通知、通話、テキストを管理しながら、ファイルや写真をシームレスに転&#xfeff;送&#xfeff;<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "ファイルや写真の転送",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "通話の発信や受信",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "メ&#xfeff;ッ&#xfeff;セ&#xfeff;ー&#xfeff;ジの送&#xfeff;受&#xfeff;信",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "携帯電話の通知を管理",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "つながる世界はより&#xfeff;快&#xfeff;適&#xfeff;に",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}