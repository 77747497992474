const Library_mobile_hu = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] processzor működteti"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] processzor működteti"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "A processzor az emberi agyhoz hasonlóan vezérli a számítógép működését. Ez azt jelenti, hogy a videók megtekintését, a játékot, a webhelyek megjelenítését mind-mind a processzor irányítja.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "A processzor a számítógép agya"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Teljesítmény és érték.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver processzor",
                    "tr_subtitle": "Mozogjon otthonosan a digitális világban, érje el nagy teljesítménnyel a netes fájlokat! Egyszerűen futtathat párhuzamos programokat: könnyedén lépkedhet a programok között, és több feladatot végezhet el rövidebb idő alatt.",
                    "tr_gen_core_title": "Intel® Pentium® Silver processzor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold processzor",
                    "tr_subtitle": "Fokozott hatékonyság – Megnövelt teljesítmény, hogy a legkeményebb napokon is jól menjenek a dolgok. Webböngészés a mai kor igényeinek megfelelően – A gazdag médiatartalommal megtöltött weboldalak gyorsan töltődnek be, hogy több idő jusson a kutatásra, és ne várakozással teljen az idő.",
                    "tr_gen_core_title": "Intel® Pentium® Gold processzor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® processzor",
                    "tr_subtitle": "Teljesítmény a jobb munkához és szórakozáshoz. Lenyűgöző részletezettségű filmek és webes videók. Kevesebbet kell várnia, több időt tölthet érdemi tevékenységgel.",
                    "tr_gen_core_title": "Intel® Celeron® processzor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® processzor",
                    "tr_subtitle": "Remek szórakozásra, videók streamelésére és hatékony munkára tervezték. Lenyűgöző részletezettségű filmek és webes videók. Kevesebbet kell várnia, több időt tölthet érdemi tevékenységgel.",
                    "tr_gen_core_title": "Intel® Pentium® processzor"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Teljesítmény, amelyre szükségünk van. Megfizethetőség, amire vágyunk.",
                "tr_title": "Intel® Processzor",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Kiemelkedő érték a saját kategóriájában",
                    "tr_subtitle": "Egy Intel® Processzor megfizethető áron tud kezelni szinte minden elvégzendő feladatot. Könnyedén válthat egyik szoftverről a másikra. Illetve stabil teljesítmény mellett élvezheti a minőségi látványt.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Stabilabb kapcsolat",
                    "tr_subtitle": "Az élénkebb videónak, a gazdagabb és tisztább hangzásnak, valamint a csaknem 3-szor gyorsabb internetkapcsolatnak köszönhetően mindenhol magabiztosan működhet együtt másokkal.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Ég és föld a különbség",
                    "tr_subtitle": "Az Intel® Processzor a délelőtti maratoni filmnézéstől az utolsó pillanatban végzett javításokig meghosszabbítja a készülék üzemidejét, hogy igazán magával ragadó és zavartalan legyen az élmény.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Bármilyen környezetben lehet tanulni",
                    "tr_subtitle": "Könnyedén lehet több online taneszközt használni egyszerre, a lakás bármely részében."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Tanulás bárhol",
                    "tr_subtitle": "Bárhol befejezhető a lecke, és közben különféle e-learning-eszközök is futhatnak a gépen."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Szórakozáshoz és irodai feladatokhoz beszerezheti a pénztárcájának és életmódjának megfelelő teljesítményű számítógépet.",
                "tr_title": "Intel-teljesítmény bármilyen pénztárcához.",
                "tr_gen_core_title": "Intel® Celeron® processzor",
                "processorCompare": {
                  "tr_title": "TALÁLJA MEG A MEGFELELŐ TELJESÍTMÉNY- ÉS ÁRSZINTET",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gyors webböngészés gazdag médiatartalommal",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Programok váltogatása könnyedén",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Alkalomszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K videók streamelése",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Egyszerű fénykép- és videószerkesztés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Irodai feladatok gyors végrehajtása intelligens teljesítménnyel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "7. generációs Intel® Core™ [cpu_fam] processzor működteti"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "6. generációs Intel® Core™ [cpu_fam] processzor működteti"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "5. generációs Intel® Core™ [cpu_fam] processzor működteti"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "4. generációs Intel® Core™ [cpu_fam] processzor működteti"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "3. generációs Intel® Core™ [cpu_fam] processzor működteti"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "2. generációs Intel® Core™ [cpu_fam] processzor működteti"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "1. generációs Intel® Core™ [cpu_fam] processzor működteti"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Ajánlott felhasználás:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8. generációs Intel® Core™ i3 processzor működteti",
                "tr_gen_core_title": "8. generációs Intel® Core™ i3 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 9. generációs Intel® Core™ processzorokat úgy alkották meg, hogy lépést tartsanak a digitális világ tempójával. A sebesség és a teljesítmény e kombinációját kihasználva többet és frusztráció nélkül foglalkozhat a kedvenc dolgaival.",
                "tr_title": "9. generációs Intel® Core™ i3 processzor működteti",
                "tr_gen_core_title": "9. generációs Intel® Core™ i3 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "A sokoldalúság tekintélyes akkumulátoros üzemidővel párosul, így bárhová megy, sokáig használhatja a számítógépét.",
                "tr_title": "Egész napra elegendő töltöttség",
                "tr_gen_core_title": "10. generációs Intel® Core™ i3 processzor",
                "processorCompare": {
                  "tr_title": "KERESSE A MEGFELELŐ SZINTET A 10.&nbsp;GENERÁCIÓS TELJESÍTMÉNYEN BELÜL!",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Filmek streamelése 4K minőségben",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gyors, megbízható kapcsolat",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fényképmaszkolás és videoszűrők",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék 1080p felbontásban, egyenletes képsebességgel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nagy fájlokkal végzett munkához és kreatív programokhoz a legjobb",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Online filmnézés, prezentációk készítése...,<br/> de másra is bőven jut energia.",
                "tr_title": "Egész napra elegendő töltöttség",
                "tr_gen_core_title": "10. generációs Intel® Core™ i3 processzor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Azoknak, akik folyton úton vannak – laptop kimondottan Önnek.",
                "tr_title": "Ismerje meg a laptopok új osztályát",
                "tr_gen_core_title": "10. generációs Intel® Core™ i3 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "A kifejezetten hordozható számítógépekbe szánt 11. generációs Intel® Core™ i3 processzorral még több feladatot el tud végezni, bárhol is tartózkodik.",
                "tr_title": "Hozzon ki még többet új laptopjából!",
                "tr_gen_core_title": "11. generációs Intel® Core™ i3 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i3 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Ha minden feladatra alkalmas asztali számítógépet kíván beszerezni az egész család számára, akkor egy 11. generációs Intel® Core™ i3 processzor teljesítményére és sokoldalúságára lesz szüksége.",
                "tr_title": "A forradalmi teljesítménnyel minden eshetőségre felkészülhet.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i3 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i3 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8. generációs Intel® Core™ i5 processzor működteti",
                "tr_gen_core_title": "8. generációs Intel® Core™ i5 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 9. generációs Intel® Core™ processzorokat úgy alkották meg, hogy lépést tartsanak a digitális világ tempójával. A sebesség és a teljesítmény e kombinációját kihasználva többet és frusztráció nélkül foglalkozhat a kedvenc dolgaival.",
                "tr_title": "9. generációs Intel® Core™ i5 processzor működteti",
                "tr_gen_core_title": "9. generációs Intel® Core™ i5 processzor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "EGYENLETESEN FUTÓ JÁTÉK ÉS VR A TELJESÍTMÉNYIGÉNYES JÁTÉKOKNÁL IS",
                "tr_title": "A komoly játék első lépcsőfoka",
                "tr_gen_core_title": "9. generációs Intel® Core™ i5 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék, közvetítés és rögzítés kompromisszumok nélkül",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "A legjobb 9. generációs Intel® Core™ processzor játékhoz",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>4<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Könnyedén futtathat több programot egyszerre, hogy bármilyen feladatot felkészülten várhasson bárhol, ahol a nap során megfordul.",
                "tr_title": "Több program használata egyszerre, útközben is",
                "tr_gen_core_title": "10. generációs Intel® Core™ i5 processzor",
                "processorCompare": {
                  "tr_title": "KERESSE A MEGFELELŐ SZINTET A 10.&nbsp;GENERÁCIÓS TELJESÍTMÉNYEN BELÜL!",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Filmek streamelése 4K minőségben",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gyors, megbízható kapcsolat",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fényképmaszkolás és videoszűrők",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék 1080p felbontásban, egyenletes képsebességgel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nagy fájlokkal végzett munkához és kreatív programokhoz a legjobb",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Emeljen magasabb szintre mindent, a szórakozástól a munkán át a tartalomkészítésig.",
                "tr_title": "Váltson erősebb számítógépre!",
                "tr_gen_core_title": "10. generációs Intel® Core™ i5 processzor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Azoknak, akik folyton úton vannak – laptop kimondottan Önnek.",
                "tr_title": "Ismerje meg a laptopok új osztályát",
                "tr_gen_core_title": "10. generációs Intel® Core™ i5 processzor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Zökkenőmentes streamingre és AAA játékra építve",
                "tr_title": "Játsszon a saját szabályai szerint",
                "tr_gen_core_title": "10. generációs Intel® Core™ i5 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Zökkenőmentes streamingre és AAA játékra építve",
                "tr_title": "Játsszon a saját szabályai szerint",
                "tr_gen_core_title": "10. generációs Intel® Core™ i5 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>10<br/>Szálak száma:<br/>20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Könnyedén futtathat több programot egyszerre, hogy bármilyen feladatot felkészülten várhasson bárhol, ahol a nap során megfordul.",
                "tr_title": "Több program használata egyszerre, útközben is",
                "tr_gen_core_title": "10. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "10. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generációs Intel® Core™ i5 processzorral a legvékonyabb és legkönnyebb laptopok is valósághű vizuális élményt kínálnak, és sokféle feladatra használhatók.",
                "tr_title": "Lenyűgöző laptopteljesítmény",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Ha minden feladatra alkalmas asztali számítógépet kíván beszerezni az egész család számára, akkor egy 11. generációs Intel® Core™ i5 processzor teljesítményére és sokoldalúságára lesz szüksége.",
                "tr_title": "A forradalmi teljesítménnyel minden eshetőségre felkészülhet.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generációs Intel® Core™ i5 processzorral a vékony és könnyű laptopok is valósághű vizuális élményt kínálnak, és sokféle feladatra használhatók.",
                "tr_title": "Lenyűgöző laptopteljesítmény",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "8. generációs Intel® Core™ i7 processzor működteti",
                "tr_gen_core_title": "8. generációs Intel® Core™ i7 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "A 9. generációs Intel® Core™ processzorokat úgy alkották meg, hogy lépést tartsanak a digitális világ tempójával. A sebesség és a teljesítmény e kombinációját kihasználva többet és frusztráció nélkül foglalkozhat a kedvenc dolgaival.",
                "tr_title": "9. generációs Intel® Core™ i7 processzor működteti",
                "tr_gen_core_title": "9. generációs Intel® Core™ i7 processzor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AKÁR 32%-KAL MAGASABB FPS-ÉRTÉK játék streaming és rögzítés közben, mint egy 3 ÉVES SZÁMÍTÓGÉPPEL<sup>3</sup>",
                "tr_title": "Erő a játék legfelemelőbb pillanatainak megosztásához",
                "tr_gen_core_title": "9. generációs Intel® Core™ i7 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék, közvetítés és rögzítés kompromisszumok nélkül",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "A legjobb 9. generációs Intel® Core™ processzor játékhoz",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>4<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ez a hatékonyan működő, elegáns készülék nagyban meghatározhatja a tartalomkészítés, a kapcsolódás és a streaming minőségét.",
                "tr_title": "Komoly teljesítmény, bárhol",
                "tr_gen_core_title": "10. generációs Intel® Core™ i7 processzor",
                "processorCompare": {
                  "tr_title": "KERESSE A MEGFELELŐ SZINTET A 10.&nbsp;GENERÁCIÓS TELJESÍTMÉNYEN BELÜL!",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Filmek streamelése 4K minőségben",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gyors, megbízható kapcsolat",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fényképmaszkolás és videoszűrők",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék 1080p felbontásban, egyenletes képsebességgel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nagy fájlokkal végzett munkához és kreatív programokhoz a legjobb",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Megnövelt teljesítmény, amellyel mindent magasabb szinten lehet elvégezni.",
                "tr_title": "Kellő erő mindenhez",
                "tr_gen_core_title": "10. generációs Intel® Core™ i7 processzor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Azoknak, akik folyton úton vannak – laptop kimondottan Önnek.",
                "tr_title": "Ismerje meg a laptopok új osztályát",
                "tr_gen_core_title": "10. generációs Intel® Core™ i7 processzor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Játék, közvetítés és rögzítés előnyösebb helyzetből",
                "tr_title": "Öntsön erőt a játékba",
                "tr_gen_core_title": "10. generációs Intel® Core™ i7 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Játék, közvetítés és rögzítés előnyösebb helyzetből",
                "tr_title": "Öntsön erőt a játékba",
                "tr_gen_core_title": "10. generációs Intel® Core™ i7 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>10<br/>Szálak száma:<br/>20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ez a hatékony használatra tervezett kecses készülék nagyban befolyásolhatja a tartalomkészítés, az adatkapcsolat és a streaming módját.",
                "tr_title": "Prémium teljesítmény<br/>bárhol",
                "tr_gen_core_title": "10. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "10. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generációs Intel® Core™ i7 processzorral ultrahordozható számítógépeken is lehet komolyabb tartalmakat készíteni, akadásmentesen játszani és magasabb szinten szórakozni.",
                "tr_title": "Rendkívüli laptopteljesítmény",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "A 11. generációs Intel® Core™ i7 processzor forradalmi teljesítményével bármire használhatja asztali számítógépét, amire otthoni környezetben szükség lehet.",
                "tr_title": "Munka. Tanulás. Játék. Korlátok nélkül.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generációs Intel® Core™ i7 processzorral vékony és könnyű laptopokon is lehet komolyabb tartalmakat készíteni, akadásmentesen játszani és magasabb szinten szórakozni.",
                "tr_title": "Rendkívüli laptopteljesítmény",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 11. generációs Intel® Core™ i7 processzor forradalmi teljesítményével bármire használhatja asztali számítógépét, amire otthoni környezetben szükség lehet.",
                "tr_title": "Tartalomkészítés határok nélkül",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 11. generációs Intel® Core™ i7 processzor jobb lett, ami abból is látható, hogy már vékony és könnyű laptopokon is lehet haladó szintű tartalomkészítési feladatokat végezni, akadásmentesen játszani és magasabb szinten szórakozni.",
                "tr_title": "Rendkívüli laptopteljesítmény",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8. generációs Intel® Core™ i9 processzor működteti",
                "tr_gen_core_title": "8. generációs Intel® Core™ i9 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 9. generációs Intel® Core™ processzorokat úgy alkották meg, hogy lépést tartsanak a digitális világ tempójával. A sebesség és a teljesítmény e kombinációját kihasználva többet és frusztráció nélkül foglalkozhat a kedvenc dolgaival.",
                "tr_title": "9. generációs Intel® Core™ i9 processzor működteti",
                "tr_gen_core_title": "9. generációs Intel® Core™ i9 processzor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AKÁR 41%-KAL MAGASABB FPS-ÉRTÉK játék streaming és rögzítés közben, mint egy 3 ÉVES SZÁMÍTÓGÉPPEL<sup>4</sup>",
                "tr_title": "A játékosok igénylik. Az Intel® Core™ i9 teljesíti.",
                "tr_gen_core_title": "9. generációs Intel® Core™ i9 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék, közvetítés és rögzítés kompromisszumok nélkül",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "A legjobb 9. generációs Intel® Core™ processzor játékhoz",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>4<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tágítsa ki a lehetőségeit tökéletes játékélménnyel",
                "tr_title": "Versenyezzen a legmagasabb szinteken",
                "tr_gen_core_title": "10. generációs Intel® Core™ i9 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tágítsa ki a lehetőségeit tökéletes játékélménnyel",
                "tr_title": "Versenyezzen a legmagasabb szinteken",
                "tr_gen_core_title": "10. generációs Intel® Core™ i9 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>10<br/>Szálak száma:<br/>20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tágítsa ki a tartalomkészítésben rejlő lehetőségeket a tökéletes élménnyel!",
                "tr_title": "Készítsen tartalmakat a legmagasabb szinten!",
                "tr_gen_core_title": "10. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "10. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 11. generációs Intel® Core™ i5 processzorral és Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikával szerelt Intel® Evo™ laptopok elképesztő sebességet és teljesítményt kínálnak, méghozzá vékony és könnyű kivitelben.",
                "tr_title": "Megnövelt teljesítmény egy&nbsp;kiforrott laptophoz",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 11. generációs Intel® Core™ i7 processzorral és Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikával szerelt Intel® Evo™ laptopok fokozott sebességet és teljesítményt kínálnak a prémium mobil élményhez.",
                "tr_title": "Fokozott teljesítmény egy kiforrott laptophoz",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Élvezze a teljesítmény, a médiaélmény és az adatkapcsolat remek egyensúlyát elképesztően jó áron.",
                "tr_title": "Teljesítmény és adatkapcsolat lenyűgöző ár/érték arány mellett.",
                "tr_gen_core_title": "Intel® Pentium® Silver processzor",
                "processorCompare": {
                  "tr_title": "TALÁLJA MEG A MEGFELELŐ TELJESÍTMÉNY- ÉS ÁRSZINTET",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gyors webböngészés gazdag médiatartalommal",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Programok váltogatása könnyedén",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Alkalomszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K videók streamelése",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Egyszerű fénykép- és videószerkesztés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Irodai feladatok gyors végrehajtása intelligens teljesítménnyel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Élvezze a zökkenőmentes párhuzamos programfuttatást, az egyszerű fénykép- és videószerkesztést, a 4K videókat és az élénk képeket hihetetlenül jó áron.",
                "tr_title": "Lenyűgöző teljesítmény munkához és játékhoz.",
                "tr_gen_core_title": "Intel® Pentium® Gold processzor",
                "processorCompare": {
                  "tr_title": "TALÁLJA MEG A MEGFELELŐ TELJESÍTMÉNY- ÉS ÁRSZINTET",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gyors webböngészés gazdag médiatartalommal",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Programok váltogatása könnyedén",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Alkalomszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K videók streamelése",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Egyszerű fénykép- és videószerkesztés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Irodai feladatok gyors végrehajtása intelligens teljesítménnyel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA kategóriás játék és hosszú akkumulátoros üzemidő egy ultrahordozható számítógépben. A játék módját illetően nem kell kompromisszumot kötni.",
                "tr_title": "Nem kell kompromisszumot kötni.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TALÁLJA MEG INTEL® CORE™ ULTRAHORDOZHATÓ GAMERPROCESSZORÁT.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i5-11300H processzor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i7-11370H processzor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i7-11375H Special edition processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Akár <span class='style_badge'>4,4 GHz</span> maximális órajel",
                      "tr_td2_title": "Akár <span class='style_badge'>4,8 GHz</span> maximális órajel",
                      "tr_td3_title": "Akár <span class='style_badge'>5,0 GHz</span> maximális órajel"
                    },
                    {
                      "tr_td1_title": "4 mag/8 szál",
                      "tr_td2_title": "4 mag/8 szál",
                      "tr_td3_title": "4 mag/8 szál"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost technológia",
                      "tr_td2_title": "Intel® Turbo Boost technológia",
                      "tr_td3_title": "Intel® Turbo Boost Max technológia 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® intelligens gyorsítótár",
                      "tr_td2_title": "12 MB Intel® intelligens gyorsítótár",
                      "tr_td3_title": "12 MB Intel® intelligens gyorsítótár"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Játék AAA kategóriás játékokkal 1080p felbontásban, magas FPS és magas beállítások mellett, valamint kiváló teljesítmény mobil használat közben egy ultrahordozható számítógéppel.",
                "tr_title": "Rajta! Minden sikerülni fog.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TALÁLJA MEG INTEL® CORE™ ULTRAHORDOZHATÓ GAMERPROCESSZORÁT.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i5-11300H processzor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i7-11370H processzor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i7-11375H Special edition processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Akár <span class='style_badge'>4,4 GHz</span> maximális órajel",
                      "tr_td2_title": "Akár <span class='style_badge'>4,8 GHz</span> maximális órajel",
                      "tr_td3_title": "Akár <span class='style_badge'>5,0 GHz</span> maximális órajel"
                    },
                    {
                      "tr_td1_title": "4 mag/8 szál",
                      "tr_td2_title": "4 mag/8 szál",
                      "tr_td3_title": "4 mag/8 szál"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost technológia",
                      "tr_td2_title": "Intel® Turbo Boost technológia",
                      "tr_td3_title": "Intel® Turbo Boost Max technológia 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® intelligens gyorsítótár",
                      "tr_td2_title": "12 MB Intel® intelligens gyorsítótár",
                      "tr_td3_title": "12 MB Intel® intelligens gyorsítótár"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Akár 5,0 GHz maximális órajel, hogy még többet lehessen kihozni a játékokból, és egy ultrahordozható számítógép, hogy bárhol lehessen játszani.",
                "tr_title": "A súlyához képest nagyobb teljesítményt nyújt.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 Special Edition processzor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition processzor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TALÁLJA MEG INTEL® CORE™ ULTRAHORDOZHATÓ GAMERPROCESSZORÁT.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i5-11300H processzor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i7-11370H processzor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generációs Intel® Core™ i7-11375H Special edition processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Akár <span class='style_badge'>4,4 GHz</span> maximális órajel",
                      "tr_td2_title": "Akár <span class='style_badge'>4,8 GHz</span> maximális órajel",
                      "tr_td3_title": "Akár <span class='style_badge'>5,0 GHz</span> maximális órajel"
                    },
                    {
                      "tr_td1_title": "4 mag/8 szál",
                      "tr_td2_title": "4 mag/8 szál",
                      "tr_td3_title": "4 mag/8 szál"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost technológia",
                      "tr_td2_title": "Intel® Turbo Boost technológia",
                      "tr_td3_title": "Intel® Turbo Boost Max technológia 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® intelligens gyorsítótár",
                      "tr_td2_title": "12 MB Intel® intelligens gyorsítótár",
                      "tr_td3_title": "12 MB Intel® intelligens gyorsítótár"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A 11. generációs Intel® Core™ i7 Special Edition processzornak köszönhetően ultrahordozható számítógépekkel is lehet haladó szinten tartalmakat készíteni, zökkenőmentesen játszani és magasabb szinten szórakozni.",
                "tr_title": "Rendkívüli laptopteljesítmény",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 Special Edition processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 SPECIAL EDITION PROCESSZOR"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "A 11. generációs Intel® Core™ i7 Special Edition processzornak köszönhetően ultrahordozható számítógépekkel is lehet haladó szinten tartalmakat készíteni, zökkenőmentesen játszani és magasabb szinten szórakozni.",
                "tr_title": "Rendkívüli laptopteljesítmény",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 Special Edition processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 SPECIAL EDITION PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködése még többet hoz ki a versenyszerű játékból és az összetett tartalomkészítési feladatokból.",
                "tr_title": "Masszív teljesítmény. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Az órajel és a magteljesítmény megfelelő egyensúlya még többet hoz ki a játékokból, akár magas FPS-sel futó versenyszerű játékról, akár lebilincselő AAA kategóriás játékokról van szó.",
                "tr_title": "Teljesítmény, amelynek rendeltetése van.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Futtasson, rögzítsen és streameljen nagy teljesítményigényű játékokat magas FPS mellett, és váltson át könnyedén komoly terheléssel járó párhuzamos programfuttatásra.",
                "tr_title": "Teljesítmény a határok feszegetéséhez.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködése még többet hoz ki a versenyszerű játékból és az összetett tartalomkészítési feladatokból.",
                "tr_title": "Masszív teljesítmény. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Online filmnézés, prezentációk készítése...,<br/> de másra is bőven jut energia.",
                "tr_title": "Egész napra elegendő töltöttség",
                "tr_gen_core_title": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i3 processzor",
                "tr_subTitle2": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i3 processzor"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "A sokoldalúság tekintélyes akkumulátoros üzemidővel párosul, így bárhová megy, sokáig használhatja a számítógépét.",
                "tr_title": "Egész napra elegendő töltöttség",
                "tr_gen_core_title": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i3 processzor",
                "tr_subTitle2": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i3 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Könnyedén futtathat több programot egyszerre, hogy bármilyen feladatot felkészülten várhasson bárhol, ahol a nap során megfordul.",
                "tr_title": "Több program használata egyszerre, útközben is",
                "tr_gen_core_title": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i5 processzor",
                "tr_subTitle2": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i5 processzor"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Emeljen magasabb szintre mindent, a szórakozástól a munkán át a tartalomkészítésig.",
                "tr_title": "Váltson erősebb számítógépre!",
                "tr_gen_core_title": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i5 processzor",
                "tr_subTitle2": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i5 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Asztali számítógépekre jellemző játékteljesítmény és mobilitás egyszerre, akár gyors FPS melletti versenyszintű játékról, akár élethű AAA kategóriás játékokról van szó.",
                "tr_title": "Teljesítmény, amelynek rendeltetése van.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Asztali számítógépekre jellemző játékteljesítmény és mobilitás egyszerre, akár gyors FPS melletti versenyszintű játékról, akár élethű AAA kategóriás játékokról van szó.",
                "tr_title": "Teljesítmény, amelynek rendeltetése van.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Laptop, amellyel magas FPS mellett lehet játszani, rögzíteni és streamelni, és könnyedén lehet átváltani erős igénybevételt jelentő párhuzamos programfuttatásra.",
                "tr_title": "Teljesítmény a határok feszegetéséhez.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Laptop, amellyel magas FPS mellett lehet játszani, rögzíteni és streamelni, és könnyedén lehet átváltani erős igénybevételt jelentő párhuzamos programfuttatásra.",
                "tr_title": "Teljesítmény a határok feszegetéséhez.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködésének köszönhetően még tökéletesebb minőségben élvezheti a versenyszintű játékot és az összetett tartalomkészítési feladatokat, bárhol használja a számítógépét.",
                "tr_title": "Masszív teljesítmény. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködésének köszönhetően még tökéletesebb minőségben élvezheti a versenyszintű játékot és az összetett tartalomkészítési feladatokat, bárhol használja a számítógépét.",
                "tr_title": "Masszív teljesítmény. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel, a sok mag és a szorzózármentesség zökkenőmentes együttműködésének köszönhetően még tökéletesebb minőségben élvezheti a versenyszintű játékot és az összetett tartalomkészítési feladatokat, bárhol használja a számítógépét.",
                "tr_title": "Szorzózármentesség. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel, a sok mag és a szorzózármentesség zökkenőmentes együttműködésének köszönhetően még tökéletesebb minőségben élvezheti a versenyszintű játékot és az összetett tartalomkészítési feladatokat, bárhol használja a számítógépét.",
                "tr_title": "Szorzózármentesség. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködése még többet hoz ki a versenyszerű játékból és az összetett tartalomkészítési feladatokból.",
                "tr_title": "Masszív teljesítmény. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködése még többet hoz ki az összetett tartalomkészítési feladatokból, bárhol használja a számítógépét.",
                "tr_title": "Masszív teljesítmény. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködése még többet hoz ki az összetett tartalomkészítési feladatokból.",
                "tr_title": "Masszív teljesítmény. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tágítsa ki a lehetőségeit tökéletes játékélménnyel",
                "tr_title": "Versenyezzen a legmagasabb szinteken",
                "tr_gen_core_title": "10. generációs Intel® Core™ i9 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tágítsa ki a lehetőségeit tökéletes játékélménnyel",
                "tr_title": "Versenyezzen a legmagasabb szinteken",
                "tr_gen_core_title": "10. generációs Intel® Core™ i9 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ 10. GENERÁCIÓS INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zökkenőmentes AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming és rögzítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Haladó szintű tartalomkészítés",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Csúcsszintű játékteljesítmény",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Szorzózármentes és túlhajtható<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>10<br/>Szálak száma:<br/>20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Azoknak, akik folyton úton vannak – laptop kimondottan Önnek.",
                "tr_title": "Ismerje meg a laptopok új osztályát",
                "tr_gen_core_title": "10. generációs Intel® Core™ i9 processzor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködése még többet hoz ki a versenyszerű játékból és az összetett tartalomkészítési feladatokból.",
                "tr_title": "Szorzózármentesség. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel, a sok mag és a szorzózármentesség zökkenőmentes együttműködése még többet hoz ki az összetett tartalomkészítési feladatokból, bárhol használja a számítógépét.",
                "tr_title": "Szorzózármentesség. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A magas órajel és a sok mag zökkenőmentes együttműködése még többet hoz ki az összetett tartalomkészítési feladatokból.",
                "tr_title": "Szorzózármentesség. Tökéletesen kiegyensúlyozott.",
                "tr_gen_core_title": "11. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "11. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® intelligens <br/>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Egyenletesen futó játék és VR a teljesítményigényes játékoknál is",
                "tr_title": "A komoly játék első lépcsőfoka",
                "tr_gen_core_title": "9. generációs Intel® Core™ i5 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék, közvetítés és rögzítés kompromisszumok nélkül",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "A legjobb 9. generációs Intel® Core™ processzor játékhoz",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>4<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AKÁR 32%-KAL MAGASABB FPS-ÉRTÉK <br/><span class='tr_subtitle_font'>játék, streaming és rögzítés közben, mint egy 3 éves számítógéppel<sup>3</sup></span>",
                "tr_title": "Erő a játék legfelemelőbb pillanatainak megosztásához",
                "tr_gen_core_title": "9. generációs Intel® Core™ i7 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék, közvetítés és rögzítés kompromisszumok nélkül",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "A legjobb 9. generációs Intel® Core™ processzor játékhoz",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>4<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AKÁR 41%-KAL MAGASABB FPS-ÉRTÉK <br/><span class='tr_subtitle_font'>játék, streaming és rögzítés közben, mint egy 3 éves számítógéppel<sup>4</sup></span>",
                "tr_title": "A játékosok igénylik. Az Intel® Core™ i9 teljesíti.",
                "tr_gen_core_title": "9. generációs Intel® Core™ i9 processzor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>szál</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>mag</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Akár <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>gyorsítótár</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "szorzózár&shy;mentesség",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "KERESSE MEG A JÁTÉKSZOKÁSAIHOZ ILLŐ INTEL® CORE™ PROCESSZORT.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 processzor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 processzor"
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processzor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA kategóriás játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Versenyszerű játék",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Játék, közvetítés és rögzítés kompromisszumok nélkül",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "A legjobb 9. generációs Intel® Core™ processzor játékhoz",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>4<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>6<br/>Szálak száma:<br/>6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Órajel<sup>2</sup>: akár 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Magok száma:<br/>8<br/>Szálak száma:<br/>16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "A játék következő generációjához készült",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12. generációs Intel® Core™ i5 processzor",
                    "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. generációs Intel® Core™ i7 processzor",
                    "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. generációs Intel® Core™ i9 processzor",
                    "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>mag</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>szál</span></span>",
                    "tr_badgePreTitle": "[p_thread] a Performance-core-okon",
                    "tr_badgeSubTitle": "[e_thread] az Efficient-core-okon"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Harckészültségbe helyezett munkaállomás",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. generációs Intel® Core™ i7 processzor",
                    "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. generációs Intel® Core™ i9 processzor",
                    "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>mag</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>szál</span></span>",
                    "tr_badgePreTitle": "[p_thread] a Performance-core-okon",
                    "tr_badgeSubTitle": "[e_thread] az Efficient-core-okon"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Megbízható teljesítmény, amikor a legjobban kell",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12. generációs Intel® Core™ i3 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i3 PROCESSZOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Teljesítmény fokozása ott, ahol a legnagyobb szükség van rá",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Teljesítmény fokozása ott, ahol a legnagyobb szükség van rá",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Kiváló teljesítményt nyújt ott, ahol a legnagyobb szükség van rá",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Kiváló teljesítményt nyújt ott, ahol a legnagyobb szükség van rá",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az Intel® Evo™ laptopok sok egyéb előnyük mellett jó teljesítményt, a magabiztos videocsevegéshez beépített intelligenciát, valamint gyorsabb internetet, hosszabb üzemidejű akkumulátort és egyéb fejlesztéseket kínálnak. ",
                "tr_title": "Turbóteljesítmény, ahol a legnagyobb szükség van rá.",
                "tr_gen_core_title": "12. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az Intel® Evo™ laptopok sok egyéb előnyük mellett jó teljesítményt, a magabiztos videocsevegéshez beépített intelligenciát, valamint gyorsabb internetet, hosszabb üzemidejű akkumulátort és egyéb fejlesztéseket kínálnak. ",
                "tr_title": "Kiváló teljesítményt nyújt ott, ahol a legnagyobb szükség van rá",
                "tr_gen_core_title": "12. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az Intel® Evo™ laptopok sok egyéb előnyük mellett jó teljesítményt, a magabiztos videocsevegéshez beépített intelligenciát, valamint gyorsabb internetet, hosszabb üzemidejű akkumulátort és egyéb fejlesztéseket kínálnak. ",
                "tr_title": "Extrém teljesítmény egy prémium laptopban",
                "tr_gen_core_title": "12. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost 2.0 technológiával akár [max_turbo_frequency] GHz-ig növelhető",
                "tr_title": "Vállalkozásoknak tervezve",
                "tr_gen_core_title": "12. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zajszűrés és háttérelmosás",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A teljesítmény automatikus testreszabása, hogy a leggyakoribb tevékenységek élvezzenek elsőbbséget",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Zökkenőmentes, kiváló minőségű webes hívások",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Azonnal használatba vehető hardveres biztonsági megoldások",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Távoli készülékfelügyeleti képességek",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A Wi-Fi-teljesítmény automatikus optimalizálása<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Adatvezérelt alkalmazások működtetése",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A legnagyobb hatékonyság, fokozott teljesítménnyel",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost 2.0 technológiával akár [max_turbo_frequency] GHz-ig növelhető",
                "tr_title": "Keményen dolgozik, hogy Ön a feladataira tudjon koncentrálni.",
                "tr_gen_core_title": "12. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zajszűrés és háttérelmosás",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A teljesítmény automatikus testreszabása, hogy a leggyakoribb tevékenységek élvezzenek elsőbbséget",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Zökkenőmentes, kiváló minőségű webes hívások",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Azonnal használatba vehető hardveres biztonsági megoldások",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Távoli készülékfelügyeleti képességek",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A Wi-Fi-teljesítmény automatikus optimalizálása<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Adatvezérelt alkalmazások működtetése",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A legnagyobb hatékonyság, fokozott teljesítménnyel",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost 2.0 technológiával akár [max_turbo_frequency] GHz-ig növelhető",
                "tr_title": "Gyorsítsa fel vállalkozását",
                "tr_gen_core_title": "12. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "12. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zajszűrés és háttérelmosás",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A teljesítmény automatikus testreszabása, hogy a leggyakoribb tevékenységek élvezzenek elsőbbséget",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Zökkenőmentes, kiváló minőségű webes hívások",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Azonnal használatba vehető hardveres biztonsági megoldások",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Távoli készülékfelügyeleti képességek",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A Wi-Fi-teljesítmény automatikus optimalizálása<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Adatvezérelt alkalmazások működtetése",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A legnagyobb hatékonyság, fokozott teljesítménnyel",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Megbízható teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13. generációs Intel® Core™ i3 processzor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Valódi teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Valódi teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Komoly teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Komoly teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "A teljesítményen túl",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>mag</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>szál</span></span>",
                    "tr_badgePreTitle": "[p_thread] a Performance-core-okon",
                    "tr_badgeSubTitle": "[e_thread] az Efficient-core-okon"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zökkenőmentes csatlakozás a telefonhoz<sup>24</sup>, a legjobb Wi-Fi intelligens kiválasztása és gyorstöltés egy olyan akkumulátorral, amely sokáig bírja.",
                "tr_title": "Lépjen túl a teljesítményen, és végezzen el több feladatot, bárhol",
                "tr_gen_core_title": "13. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zökkenőmentes csatlakozás a telefonhoz<sup>24</sup>, a legjobb Wi-Fi intelligens kiválasztása és gyorstöltés egy olyan akkumulátorral, amely sokáig bírja.",
                "tr_title": "Lépjen túl a teljesítményen, és hagyja, hogy a számítógépe több feladatot végezzen egyszerre",
                "tr_gen_core_title": "13. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zökkenőmentes csatlakozás a telefonhoz<sup>24</sup>, a legjobb Wi-Fi intelligens kiválasztása és gyorstöltés egy olyan akkumulátorral, amely sokáig bírja.",
                "tr_title": "Lépjen túl a teljesítményen, és száguldjon végig a legnagyobb teljesítményt igénylő feladatokon",
                "tr_gen_core_title": "13. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "A teljesítményen túl",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13. generációs Intel® Core™ i5 processzor",
                    "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13. generációs Intel® Core™ i7 processzor",
                    "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13. generációs Intel® Core™ i9 processzor",
                    "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>mag</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>szál</span></span>",
                    "tr_badgePreTitle": "[p_thread] a Performance-core-okon",
                    "tr_badgeSubTitle": "[e_thread] az Efficient-core-okon"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "A teljesítményen túl",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>mag</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>szál</span></span>",
                    "tr_badgePreTitle": "[p_thread] a Performance-core-okon",
                    "tr_badgeSubTitle": "[e_thread] az Efficient-core-okon"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Megbízható teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 processzor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Valódi teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSZOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Komoly teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSZOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "A teljesítményen túl",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSZOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>mag</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>szál</span></span>",
                    "tr_badgePreTitle": "[p_thread] a Performance-core-okon",
                    "tr_badgeSubTitle": "[e_thread] az Efficient-core-okon"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Megbízható teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 processzor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Valódi teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 processzor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 PROCESSZOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Komoly teljesítmény, több elvégzett feladat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 processzor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 PROCESSZOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processzor",
                "tr_title": "Játék kompromisszumok nélkül",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 PROCESSZOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 PROCESSZOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 processzor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 PROCESSZOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>mag</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core",
                    "tr_badgeSubTitle1": "[l_core] LPE-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>szál</span></span>",
                    "tr_badgePreTitle": "[p_thread] a Performance-core-okon",
                    "tr_badgeSubTitle": "[e_thread] az Efficient-core-okon",
                    "tr_badgeSubTitle1": "[l_thread] az LPE-core-okon"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 processzor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 processzor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Játék kompromisszumok nélkül",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Játék kompromisszumok nélkül",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Játék kompromisszumok nélkül",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Játék kompromisszumok nélkül",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Játék kompromisszumok nélkül",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Játék kompromisszumok nélkül",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "A kisvállalkozások is nagyszerű üzleti megoldásokat érdemelnek",
                "tr_gen_core_title": "13. generációs Intel® Core™ i5 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i5 PROCESSZOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zajszűrés és háttérelmosás",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "A teljesítmény automatikus testreszabása, hogy a leggyakoribb tevékenységek élvezzenek elsőbbséget",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Zökkenőmentes, kiváló minőségű webes hívások",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Azonnal használatba vehető hardveres biztonságvédelmi megoldások",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Segít megvédeni az eszközöket és az adatokat a fizikai manipulációs támadások ellen",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "A kisvállalkozások is nagyszerű üzleti megoldásokat érdemelnek",
                "tr_gen_core_title": "13. generációs Intel® Core™ i7 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i7 PROCESSZOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zajszűrés és háttérelmosás",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "A teljesítmény automatikus testreszabása, hogy a leggyakoribb tevékenységek élvezzenek elsőbbséget",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Zökkenőmentes, kiváló minőségű webes hívások",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Azonnal használatba vehető hardveres biztonságvédelmi megoldások",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Segít megvédeni az eszközöket és az adatokat a fizikai manipulációs támadások ellen",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "A kisvállalkozások is nagyszerű üzleti megoldásokat érdemelnek",
                "tr_gen_core_title": "13. generációs Intel® Core™ i9 processzor",
                "tr_gen_core_title_uppercase": "13. GENERÁCIÓS INTEL® CORE™ i9 PROCESSZOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A PROCESSZOR MÁS PROCESSZOROKHOZ?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Jelenlegi <br/>készülék",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Zajszűrés és háttérelmosás",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "A teljesítmény automatikus testreszabása, hogy a leggyakoribb tevékenységek élvezzenek elsőbbséget",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Zökkenőmentes, kiváló minőségű webes hívások",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Azonnal használatba vehető hardveres biztonságvédelmi megoldások",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Segít megvédeni az eszközöket és az adatokat a fizikai manipulációs támadások ellen",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Fedezze fel az új üzleti AI-élményeket",
                "tr_title": "Készüljön fel az újdonságokra Intel® Core™ Ultra&nbsp;[XX] processzorokkal és Intel vPro® technológiával",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] processzor",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "ADJON LENDÜLETET VÁLLALKOZÁSÁNAK AZ INTEL AI PC-IVEL",
                    "tr_badgeSubTitle": "A remek felhasználói élményt nyújtó, nagy teljesítményű PC-k meghatározó szerepet játszanak a vállalkozás termelékenységében. Az Intel vPro® fokozza a munkatársak hatékonyságát és teljesítményét a különböző munkafolyamatokban és alkalmazásokban. Ezt az új architektúrával éri el, amely kiemelkedően energiahatékony teljesítményt nyújt a különböző összetett üzleti munkafolyamatok végrehajtásához azáltal, hogy a megfelelő időben a megfelelő motorhoz küldi a megfelelő feladatot.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "GYÁRILAG AKTIVÁLT TÖBBRÉTEGŰ BIZTONSÁGI FUNKCIÓK",
                    "tr_badgeSubTitle": "Az új és kialakulófélben lévő fenyegetéseket átfogó biztonsági szemlélettel kell kezelni. Az Intel vPro® a hardver szintjén nyújt átfogó védelmet, ami nagyobb biztonságot jelent a vállalkozás számára. Ezek az egyedülálló, gyárilag aktivált biztonsági intézkedések, ezen belül az AI-alapú fenyegetésfelismerő képességek a felhasználók, az adatok és a vállalkozás védelmét szolgálják.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "BEÉPÍTETT ESZKÖZÖK, HOGY A FELHASZNÁLÓK GYAKORLATILAG BÁRHOL FOLYAMATOSAN TUDJANAK DOLGOZNI",
                    "tr_badgeSubTitle": "Mindenütt vannak irodák, akárcsak PC-k. Az Intel vPro® modern, hardveralapú felügyeleti képességeket biztosít, hogy csökkentse a vállalkozások terheit a PC-park felügyeletével kapcsolatban, egyszerűbbé tegye a támogatást, és javítsa a végfelhasználói élményeket, mindemellett lehetővé tegye a fenntartható számítástechnikai gyakorlatok alkalmazását.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Több, mint egy remek PC, ez egy AI PC",
                "tr_subtitle1": "AMI HAJTJA: INTEL® CORE™ ULTRA PROCESSZOROK (2. SOROZAT)",
                "tr_subtitle2": "Intel AI PC-vel ez is lehetséges",
                "tr_subtitle": "Az Intel leghatékonyabb, legbiztonságosabb és legnagyobb teljesítményű mobil processzorai<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processzor (2.&nbsp;Sorozat)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreatívabb</span> lehet az AI-eszközökkel, így gyorsabban alkothat"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Hatékonyabb</span> lehet az AI segítségével időt spórolva"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Biztonságosabban</span> használhatja gépét a beépített adatvédelemmel"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Több, mint egy remek PC, ez egy AI PC",
                "tr_subtitle1": "AMI HAJTJA: INTEL® CORE™ ULTRA PROCESSZOROK (2. SOROZAT)",
                "tr_subtitle2": "Intel AI PC-vel ez is lehetséges",
                "tr_subtitle": "Az Intel leghatékonyabb, legbiztonságosabb és legnagyobb teljesítményű mobil processzorai<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processzor (2.&nbsp;Sorozat)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreatívabb</span> lehet az AI-eszközökkel, így gyorsabban alkothat"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Hatékonyabb</span> lehet az AI segítségével időt spórolva"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Biztonságosabban</span> használhatja gépét a beépített adatvédelemmel"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Több, mint egy remek PC, ez egy AI PC",
                "tr_subtitle1": "AMI HAJTJA: INTEL® CORE™ ULTRA PROCESSZOROK (2. SOROZAT)",
                "tr_subtitle2": "Intel AI PC-vel ez is lehetséges",
                "tr_subtitle": "Az Intel leghatékonyabb, legbiztonságosabb és legnagyobb teljesítményű mobil processzorai<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processzor (2.&nbsp;Sorozat)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processzor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Több, mint egy remek PC, ez egy AI PC",
                "tr_subtitle1": "AMI HAJTJA: INTEL® CORE™ ULTRA PROCESSZOROK (2. SOROZAT)",
                "tr_subtitle2": "Intel AI PC-vel ez is lehetséges",
                "tr_subtitle": "Az Intel leghatékonyabb, legbiztonságosabb és legnagyobb teljesítményű mobil processzorai<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processzor (2.&nbsp;Sorozat)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_tileTitle1": "Dedikált grafika",
                "tr_title": "MI A GRAFIKUS FELDOLGOZÓEGYSÉG?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "A grafikus feldolgozóegység vagy GPU állítja elő a képernyőn látható képeket.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrált GPU",
                    "tr_badgeSubTitle": "Az integrált GPU ugyanazt a RAM-ot használja, mint a számítógép többi része, így energiahatékonyabb.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedikált GPU",
                    "tr_badgeSubTitle": "Ez a legerősebb GPU-típus, mivel saját RAM-mal és más olyan erőforrásokkal is el van látva, amelyek kifejezetten a különleges grafikus teljesítményről gondoskodnak.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hibrid GPU",
                    "tr_badgeSubTitle": "Ez a legerősebb GPU-típus, mivel saját RAM-mal és más olyan erőforrásokkal is el van látva, amelyek kifejezetten a különleges grafikus teljesítményről gondoskodnak.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Korszerű fénykép- és videószerkesztés",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "4K videotartalmak nézése zsinórban",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Népszerű játékok játszása lenyűgöző részletességgel",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Kettős GPU",
                    "tr_badgeSubTitle": "Néhány rendszer – például az Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikával rendelkezők – képes egyszerre kihasználni a processzoron található, illetve a dedikált GPU erejét a grafikus munkaterhelés megosztásához.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrált GPU",
                    "tr_badgeSubTitle": "Az integrált GPU ugyanabba a chipbe van beépítve, mint a fő processzor, azaz a CPU. Az ilyen típusú GPU ugyanazt a RAM&#8209;ot és ugyanazokat az egyéb erőforrásokat használja, mint a számítógép többi része, amitől energiahatékonyabb lesz, és vékonyabb, könnyebb készülékeket lehet létrehozni vele.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedikált GPU",
                    "tr_badgeSubTitle": "Ez a legerősebb GPU-típus, mivel saját RAM-mal és más olyan erőforrásokkal is el van látva, amelyek kifejezetten a különleges grafikus teljesítményről gondoskodnak.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Szabványos",
                    "tr_badgeSubTitle": "Akár 4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Versenyképes",
                    "tr_badgeSubTitle": "Akár 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra-",
                    "tr_badgeSubTitle": "8 GB VRAM-nál több",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Komoly grafikus teljesítmény",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Részletekben gazdag, élénk képek – beépítve",
                "tr_pretitle": "Intel® Iris® Plus grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az Intel® Iris® Plus grafikának köszönhetően elmerülhet a 4K HDR videó és a Full HD játékok világában, melyhez nincs szükség külön grafikus kártyára.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Lenyűgöző látványvilág, gördülékeny működés",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az Intel® Grafika forradalmi integrált grafikus teljesítményt biztosít, hogy elősegítse a tartalomkészítők tervezési munkálatainak gördülékenységét és a gazdag játékélményeket.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Intel® Iris® Pro grafika",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Intel® UHD grafika X<sup class='small_text_sup_xe'>e</sup> architektúrával",
                "tr_pretitle": "Lenyűgöző látvány",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Élje át a korszerű fénykép- és videószerkesztés élményét, nézzen zsinórban prémium videókat 4K felbontásban, vagy játsszon a népszerű játékokkal lenyűgöző részletezettség mellett.",
                "tr_photo_text": "Korszerű fénykép- és videószerkesztés",
                "tr_video_text": "4K videotartalmak nézése zsinórban",
                "tr_game_text": "Népszerű játékok játszása lenyűgöző részletességgel",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "A SZÉP LÁTVÁNYNÁL JÓVAL TÖBBET NYÚJT",
                "tr_subtitle": "A játékok működését és kinézetét a videokártya (vagy GPU) határozza meg, szorosan együttműködve a processzorral (CPU-val). A kizárólag képek előállításáért felelős VRAM, más néven video-RAM méretéből könnyen meghatározható a GPU minősége.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "INTENZÍV JÁTÉKGRAFIKÁHOZ",
                "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz külön videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "INTENZÍV JÁTÉKGRAFIKÁHOZ",
                "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz külön videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "A SZÉP LÁTVÁNYNÁL JÓVAL TÖBBET NYÚJT",
                "tr_subtitle": "A játékok működését és kinézetét a videokártya (vagy GPU) határozza meg, szorosan együttműködve a processzorral (CPU-val). A kizárólag képek előállításáért felelős VRAM, más néven video-RAM méretéből könnyen meghatározható a GPU minősége.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Komoly grafikus teljesítmény",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "INTENZÍV JÁTÉKGRAFIKÁHOZ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz külön videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Megfelelő grafikus teljesítményt biztosít a játék minimális követelményeihez.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Komolyabb játékosok számára, akik versenyszerűen akarnak játszani",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Haladó játékosok számára, akik komoly tartalomkészítésre is használják a számítógépüket",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Élethű videó és lebilincselő játék",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Hozza ki a legtöbbet a nagy felbontású képernyőből Intel® HD grafikával, élvezze a részletekben gazdag multimédiát és a beépített grafikus feldolgozást!",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Élénk videó és kép biztosítása",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Legyenek részletesebbek és ragyogó színűek a streamelt videók és képek!",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Lenyűgöző tartalmak és játékélmény",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Az Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika a lebilincselő szórakozástól a haladó szintű tartalomkészítésen keresztül a népszerű játékokig gyökeresen új grafikus teljesítménnyel vértezi fel a vékony és könnyű számítógépeket.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_pretitle": "INTENZÍV JÁTÉKGRAFIKÁHOZ",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafika",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz külön videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Engedje szabadjára a kreativitását",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Végezze el gyorsan a kreatív feladatait, és merüljön el a játékok világában a processzoron található videokártya, illetve az első Intel® dedikált videókártya együttes erejével.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafika",
                "tr_pretitle": "INTENZÍV JÁTÉKGRAFIKÁHOZ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Az igazán hatékony képfeldolgozáshoz külön videokártyára van szükség, amelynek az az egyetlen feladata, hogy gyönyörű, éles képeket és folyamatos animációt jelenítsen meg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafika",
                "tr_title": "Gyönyörű grafika, beépítve",
                "tr_subtitle": "Élvezze az elképesztően részletes és éles képeket a játékokban, a filmekben és a webes videókban.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Tegye hatékonyabbá a&nbsp;munkafolyamatokat!"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Turbózza fel a játékot!"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Turbózza fel a játékot!"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Lépjen szintet a játékban!"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "ENGEDJE SZABADJÁRA A KREATIVITÁSÁT",
                    "tr_specText": "Bárhol és bármikor felpörgetheti a produktivitását a legátfogóbb médiaképességekkel, például az <strong>iparági újdonságnak számító AV1 hardveres kódolással</strong>, valamint az MI-vel gyorsított tartalomkészítéssel.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "ENGEDJE SZABADJÁRA A KREATIVITÁSÁT",
                    "tr_specText": "Felpörgetheti a produktivitását a legátfogóbb médiaképességekkel, például az <strong>iparági újdonságnak számító AV1 hardveres kódolással</strong>, valamint az MI-vel gyorsított tartalomkészítéssel.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "ENGEDJE SZABADJÁRA A KREATIVITÁSÁT",
                    "tr_specText": "Átélheti az MI-vel gyorsított korszerű tartalomkészítés élményét az <strong>iparági újdonságnak számító AV1 hardveres kódolásnak</strong>, valamint a fejlett <strong>X<sup>e</sup> médiamotornak köszönhetően.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "ENGEDJE SZABADJÁRA A KREATIVITÁSÁT",
                    "tr_specText": "Átélheti az MI-vel gyorsított korszerű tartalomkészítés élményét az <strong>iparági újdonságnak számító AV1 hardveres kódolásnak</strong>, valamint a fejlett <strong>X<sup>e</sup> médiamotornak köszönhetően.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "ENGEDJE SZABADJÁRA A KREATIVITÁSÁT",
                    "tr_specText": "Átélheti az MI-vel gyorsított korszerű tartalomkészítés élményét az <strong>iparági újdonságnak számító AV1 hardveres kódolásnak</strong>, valamint a fejlett <strong>X<sup>e</sup> médiamotornak köszönhetően.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "A HORDOZHATÓSÁG ÉS A TELJESÍTMÉNY TALÁLKOZÁSA",
                    "tr_specText": "Bárhol és bármikor játszhat a legújabb játékokkal, és élvezheti a modern játékképességek által biztosított szuper teljesítményt és minőséget. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® MI-vel feljavított felskálázási technológia, amely nagyobb teljesítményt és zökkenőmentes játékélményt biztosít. <br/><br/><strong>DirectX* 12 Ultimate</strong>-támogatás: Új szintre emelheti a valósághű megjelenítést a játékban különböző grafikus technológiákkal, például fénysugárkövetéssel és változó arányú árnyékolással."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "VEGYE ÁT A JÁTÉK IRÁNYÍTÁSÁT!",
                    "tr_specText": "Az <strong>Intel® Arc™ vezérlő</strong> lehetővé teszi, hogy intuitív és modern elrendezés alkalmazásával finomítsa a felhasználói élményt. Zökkenőmentes illesztőprogram-frissítéseket kaphat, optimalizálhatja a játékbeállításokat, és sok más funkciót érhet el."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "VEGYE ÁT A JÁTÉK IRÁNYÍTÁSÁT!",
                    "tr_specText": "Az <strong>Intel® Arc™ vezérlő</strong> lehetővé teszi, hogy intuitív és modern elrendezés alkalmazásával finomítsa a felhasználói élményt. Zökkenőmentes illesztőprogram-frissítéseket kaphat, optimalizálhatja a játékbeállításokat, és sok más funkciót érhet el."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "VEGYE ÁT A JÁTÉK IRÁNYÍTÁSÁT!",
                    "tr_specText": "Zökkenőmentes illesztőprogram-frissítéseket kaphat, és optimalizálhatja sok kedvenc játéka beállításait az <strong>Intel® Arc™ vezérlő</strong> szoftverrel."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "HASZNÁLJA KI MAXIMÁLISAN AZ INTEL® PLATFORMOT!",
                    "tr_specText": "Kompatibilis Intel® Core™ processzorral kombinálva kihasználhatja az <strong>Intel® Deep Link technológiában</strong> rejlő lehetőségeket, hogy még jobbá tegye a tartalomkészítés, a játék és a streaming élményét."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "HASZNÁLJA KI MAXIMÁLISAN AZ INTEL® RENDSZERT!",
                    "tr_specText": "Kompatibilis Intel® Core™ processzorral kombinálva kihasználhatja az <strong>Intel® Deep Link technológiában</strong> rejlő lehetőségeket, hogy még jobbá tegye a tartalomkészítés, a játék és a streaming élményét."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "HASZNÁLJA KI MAXIMÁLISAN AZ INTEL® PLATFORMOT!",
                    "tr_specText": "Kompatibilis Intel® Core™ processzorral kombinálva kihasználhatja az <strong>Intel® Deep Link technológiában</strong> rejlő lehetőségeket, hogy még jobbá tegye a tartalomkészítés, a játék és a streaming élményét."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "HASZNÁLJA KI MAXIMÁLISAN AZ INTEL® PLATFORMOT!",
                    "tr_specText": "Kompatibilis Intel® Core™ processzorral kombinálva kihasználhatja az <strong>Intel® Deep Link technológiában</strong> rejlő lehetőségeket, hogy még jobbá tegye a tartalomkészítés, a játék és a streaming élményét."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "HASZNÁLJA KI MAXIMÁLISAN AZ INTEL® RENDSZERT!",
                    "tr_specText": "Kompatibilis Intel® Core™ processzorral kombinálva kihasználhatja az <strong>Intel® Deep Link technológiában</strong> rejlő lehetőségeket, hogy még jobbá tegye a tartalomkészítés, a játék és a streaming élményét."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "KERÜLJÖN A FIGYELEM KÖZÉPPONTJÁBA!",
                    "tr_specText": "Az <strong>Intel® Arc™ vezérlő</strong> szoftverrel szabályozható fejlett streaming-technológiákkal zökkenőmentesen közvetíthet."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "KERÜLJÖN A FIGYELEM KÖZÉPPONTJÁBA!",
                    "tr_specText": "Az <strong>Intel® Arc™ vezérlő</strong> szoftverrel szabályozható fejlett streaming-technológiákkal zökkenőmentesen közvetíthet."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ÉLVEZZE A NAGY TELJESÍTMÉNYEN FUTÓ LEBILINCSELŐ JÁTÉKOT!",
                    "tr_specText": "Turbózza fel magát, és játsszon a legújabb AAA kategóriás játékokkal nagy felbontás és magas képfrissítési ráta mellett! <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® MI-vel feljavított felskálázási technológia, amely nagyobb teljesítményt és zökkenőmentes játékélményt biztosít. <br/><br/><strong>DirectX* 12 Ultimate</strong>-támogatás: Új szintre emelheti a valósághű megjelenítést a játékban különböző grafikus technológiákkal, például fénysugárkövetéssel és változó arányú árnyékolással."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "ÉLVEZZE A NAGY TELJESÍTMÉNYEN FUTÓ LEBILINCSELŐ JÁTÉKOT!",
                    "tr_specText": "Turbózza fel magát, és játsszon a legújabb AAA kategóriás játékokkal nagy felbontás és magas képfrissítési ráta mellett! <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® MI-vel feljavított felskálázási technológia, amely nagyobb teljesítményt és zökkenőmentes játékélményt biztosít. <br/><br/><strong>DirectX* 12 Ultimate</strong>-támogatás: Új szintre emelheti a valósághű megjelenítést a játékban különböző grafikus technológiákkal, például fénysugárkövetéssel és változó arányú árnyékolással."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "FOKOZZA A JÁTÉKÉLMÉNYT!",
                    "tr_specText": "Magasabb szintre emelheti a játékélményt az <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling) </strong> elnevezésű Intel® MI-vel feljavított felskálázási technológiával, amely nagyobb teljesítményt és zökkenőmentes játékélményt biztosít. <br/><br/><strong>DirectX* 12 Ultimate</strong>-támogatás: Új szintre emelheti a valósághű megjelenítést a játékban különböző grafikus technológiákkal, például fénysugárkövetéssel és változó arányú árnyékolással."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "ÉLVEZZE A FEJLETT, MAGAS SZINTŰ JÁTÉKOT!",
                    "tr_specText": "Élvezze a zökkenőmentes játékélményt sok népszerű AAA kategóriás játék játszása közben, valamint a magas képfrissítési rátát, akár pörgős, lövöldözős e-sport-játékokkal, akár gyors tempójú, RTS-slágerjátékokkal játszik. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® MI-vel feljavított felskálázási technológia, amely nagyobb teljesítményt és zökkenőmentes játékélményt biztosít. <br/><br/><strong>DirectX* 12 Ultimate</strong>-támogatás: Új szintre emelheti a valósághű megjelenítést a játékban különböző grafikus technológiákkal, például fénysugárkövetéssel és változó arányú árnyékolással."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ÉLVEZZE A NAGY TELJESÍTMÉNYEN FUTÓ LEBILINCSELŐ JÁTÉKOT!",
                    "tr_specText": "Turbózza fel magát, és játsszon a legújabb AAA kategóriás játékokkal nagy felbontás és magas képfrissítési ráta mellett! <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® MI-vel feljavított felskálázási technológia, amely nagyobb teljesítményt és zökkenőmentes játékélményt biztosít. <br/><br/><strong>DirectX* 12 Ultimate</strong>-támogatás: Új szintre emelheti a valósághű megjelenítést a játékban különböző grafikus technológiákkal, például fénysugárkövetéssel és változó arányú árnyékolással."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Grafikus univerzum, ahol mindenkinek jut hely"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "GYORSABBAN MEGJELENŐ KÉPKOCKÁK, PÖRGŐSEBB JÁTÉK",
                    "tr_specText": "Az Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling technológia (X<sup class='small_text_sup_xe'>e</sup>SS) AI-alapú felskálázással – csúcsteljesítményt és kiváló képminőséget nyújtva – emeli magasabb szintre a játékélményt. Az X<sup class='small_text_sup_xe'>e</sup>SS technológiát Intel® Arc™ grafikával rendelkező termékekhez optimalizálták, így Ön maximálisan kihasználhatja az Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) hardveres gyorsítás előnyeit."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "A LÁTVÁNYVILÁG CSÚCSA MEGÉRKEZETT",
                    "tr_specText": "Mivel az összes Intel® Arc™ grafika teljes körűen támogatja a DirectX* 12 Ultimate szabványt, Önnek lenyűgöző és magával ragadó játékélményben lehet része a legújabb grafikus technológiáknak, például a sugárkövetésnek, a változó sebességű árnyékolásnak, a hálós árnyékolásnak és a mintavételi visszajelzésnek köszönhetően, amelyek a következő generációs játék alapelemeit jelentik."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "GÖRDÜLÉKENY FOTÓ-, VIDEÓ- ÉS MÉDIAKÉSZÍTÉS",
                    "tr_specText": "Engedje szabadjára képzeletét és bűvölje el közönségét részletgazdag digitális tartalmak készítésével, amihez mesterséges intelligenciával támogatott és Intel® technológiával gyorsított szupermodern médiamotort használ. Készítsen látványos tartalmakat olyan grafikával, amely az összes mostani jelentősebb médiaformátumot támogatja, miközben a legmodernebb AV1 videókódolási képességekkel is lépést tart."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "ÉLJE ÁT A CSÚCSTECHNOLÓGIÁT KÉPVISELŐ AI-ALAPÚ TARTALOMKÉSZÍTÉS ÉS JÁTÉK ÉLMÉNYÉT",
                    "tr_specText": "Az Intel® Arc™ grafika szabad teret enged a hatékony AI-képességeknek, a tartalomkészítéstől a lebilincselő játékélményig. Hozza ki a legtöbbet a mai korszerű kreatív szoftverekből, például a szövegalapú képgenerálásból vagy az AI-gyorsított videószerkesztésből. Élvezze az AI-gyorsított X<sup class='small_text_sup_xe'>e</sup>SS segítségével felskálázott nagy felbontású játékot."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "A professzionális grafika itt kezdődik"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "NAGYSZERŰ MEGOLDÁS MOBIL TARTALOMKÉSZÍTŐKNEK, TERVEZŐKNEK ÉS MÉRNÖKÖKNEK",
                    "tr_specText": "Az Intel® Core™ Ultra processzorok H sorozatának bizonyos modelljeibe beépített Intel® Arc™ Pro grafika lehetővé teszi a grafikus teljesítmény megduplázását. Független szoftverfejlesztői (ISV) tanúsítványok bizonyítják, hogy fokozott megbízhatósággal rendelkezik, és hatékony AI- és sugárkövetési hardveres gyorsítást biztosít az akkumulátoros üzemben történő lenyűgöző tartalomkészítéshez.<br/><br/><span class='expandable_heading'>Szoftvertanúsítványok<br/></span><br/>Az Intel az évek során több száz szoftvercéggel működött együtt, és az így szerzett páratlan tapasztalatait az Intel® Arc™ Pro A sorozatú grafikus kártyákhoz is felhasználta. A tanúsítványok számunkra is ugyanolyan fontosak, mint az Ön számára."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "AZ AI ELÉRHETŐVÉ TÉTELE",
                    "tr_specText": "Ez a GPU-sorozat számos nagyszerű technológiát kínál, például beépített dedikált AI-gyorsítással támogatja a kompatibilis AI-alapú eszközöket, amikor csak szükség van rá."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "SUGÁRKÖVETÉS ELÉRHETŐ ÁRON",
                    "tr_specText": "A grafikus kártyák nemcsak arra szolgálnak, hogy képpontokkal töltsék ki a képernyőt, hanem arra is, hogy felgyorsítsák a gyönyörű képek készítésének folyamatát. Az Intel® Arc™ Pro A sorozatú grafikába épített sugárkövetési hardvertechnológia lehetővé teszi, hogy a kompatibilis szoftver elkezdje felfedezni ezeket az új professzionális folyamatokat. A sugárkövetést általában a drága grafikus kártyák funkciójaként tartják számon, az Intel legújabb professzionális grafikus kártyái azonban rácáfolnak erre a vélekedésre."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "LENYŰGÖZŐ KIJELZŐK BIZTOSÍTÁSA A TARTALOMKÉSZÍTŐKNEK",
                    "tr_specText": "Mivel az összes Intel® Arc™ grafika teljes körűen támogatja a DirectX* 12 Ultimate szabványt, Önnek lenyűgöző és magával ragadó játékélményben lehet része a legújabb grafikus technológiáknak, például a sugárkövetésnek, a változó sebességű árnyékolásnak, a hálós árnyékolásnak és a mintavételi visszajelzésnek köszönhetően, amelyek a következő generációs játék alapelemeit jelentik."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DINAMIKUSAN OPTIMALIZÁLT KÉPEK",
                    "tr_specText": "A Pro GPU-k dedikált hardveres dekódolásával a Dolby Vision* nagyobb mélységet, élesebb kontrasztot és több színt biztosít a kompatibilis kijelzőkön. A Dolby Vision* úgy aknázza ki a HDR technológia teljes potenciálját, hogy a szolgáltatástól, a megjelenítő eszköztől és a platformtól függően dinamikusan optimalizálja a képminőséget, hogy mindenkor lenyűgöző látványvilágot teremtsen."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "SZAKSZERŰEN TANÚSÍTOTT SZOFTVER",
                    "tr_specText": "Az Intel az évek során több száz szoftvercéggel működött együtt, és az így szerzett páratlan tapasztalatait az Intel® Arc™ Pro A sorozatú grafikuskártya-sorozatához is felhasználta. A tanúsítványok megléte és a szoftveres munkafolyamatok minősítése számunkra is ugyanolyan fontos, mint az Ön számára."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "SUGÁRKÖVETÉS ELÉRHETŐ ÁRON",
                    "tr_specText": "A grafikus kártyák nemcsak arra szolgálnak, hogy képpontokkal töltsék ki a képernyőt, hanem arra is, hogy felgyorsítsák a gyönyörű képek készítésének folyamatát. Az Intel® Arc™ Pro A sorozatú grafikába épített sugárkövetési hardvertechnológia lehetővé teszi, hogy a kompatibilis szoftver elkezdje felfedezni ezeket az új professzionális folyamatokat. A sugárkövetést általában a drága grafikus kártyák funkciójaként tartják számon, az Intel legújabb professzionális grafikus kártyái azonban rácáfolnak erre a vélekedésre."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "TÖBB NAGY KIJELZŐ EGYETLEN KICSI MOBIL GPU-VAL",
                    "tr_specText": "A laptopokhoz szánt Intel® Arc™ Pro grafika akár négy ultranagy kijelző támogatásával növeli a munkafolyamat hatékonyságát. Ha ehhez a magas dinamikatartomány (HDR) támogatását is hozzáadja, minden szükséges dolog rendelkezésére áll az otthoni vagy irodai munkakörnyezet közösségi médiára optimalizált többkijelzős kialakításához."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "A TELJES MEMÓRIA-SÁVSZÉLESSÉGBEN REJLŐ POTENCIÁL",
                    "tr_specText": "A grafikus kártya sávszélessége fontos szempont a munkaállomás kiválasztásakor. Ha rosszul választ, lelassulhatnak a professzionális munkafolyamatok, ezért az Intel® Arc™ Pro A sorozatú GPU támogatja a modern PCIe* 4.0 szabványt, miközben visszafelé kompatibilitást is biztosít. A grafikus memória teljesítményét tovább fokozza a nagy sávszélesség, mert a szoftver még gyorsabban hozzáfér a projektadatokhoz."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "KELTSEN ÉLETRE MINDEN KÉPKOCKÁT",
                    "tr_specText": "<span class='expandable_heading'>AI által felturbózott. Intel által támogatott.<br/></span><br/>Az Intel® X<sup class='small_text_sup_xe'>e</sup>SS egy mesterséges intelligenciával támogatott felskálázási technológia, amely az egyes képkockák szépségének megőrzése mellett tudja felpörgetni a teljesítményt – így Önnek olyan játékélményben lehet része, amilyennek a tervezők megálmodták."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "VALÓS IDEJŰ.<br/> VALÓBAN MAGÁVAL RAGADÓ.<br/> REALIZMUS A JAVÁBÓL.",
                    "tr_specText": "Realisztikusabb látványvilág várja. Beépített sugárkövetéssel tobzódhat az élményekben, akár élethű árnyékokról és tükröződésekről, akár mesterien megvilágított tárgyakról van szó. Vagyis olyan vizuális játékélményben lehet része, amit vétek lenne kihagyni."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "A LEGJOBB JÁTÉKMENET A LEGJOBB MINŐSÉGBEN MEGOSZTVA",
                    "tr_specText": "Mutassa meg közönségének, milyen a gördülékeny játékmenet. Az Intel® Arc™ grafika hardveresen gyorsított AV1 kódolási képességeinek köszönhetően minden egyes lépéstől el lesznek ragadtatva. Azonos bitsebesség mellett jobb képminőséget elérni olyan, mint takarékos sávszélesség-használat mellett a legjobb minőségben streamelni."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Üdvözlünk minden alkotót!",
                    "tr_specText": "Az új és továbbfejlesztett AI-alapú élmények elérhető közelségbe kerültek. Az Intel® Arc™ A sorozatú grafikába ágyazott speciális AI-gyorsítókkal több időt tölthet alkotással, és kevesebbet kell várakoznia – ami sok alkalmazásra igaz.<br/><br/>Magasabb szintre emelheti a videók készítését, és az elsők között használhat kiváló minőségű, tárhelytakarékos AV1 videókat az Intel® Arc™ grafikába épített speciális AV1 hardveres támogatásnak köszönhetően."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Minden, amire szüksége van a feladat elvégzéséhez",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Tekintélyes teljesítény egy vékony, könnyű és tartósra tervezett számítógépben – és ez mind az Öné lehet. Kiváló akkumulátoros üzemidő, gyönyörű érintőképernyők* és a legújabb szilárdtest-meghajtók (SSD-k) – mindent megtalál, amit egy Windows* 10 számítógéptől elvár.",
                "tr_noteSection": "*Hardverfüggő",
                "titleArray": [
                  {
                    "tr_title": "A kevesebb több",
                    "tr_subtitle": "A mai számítógépek jellemzően kevesebb energiát fogyasztanak, ezért hosszabb üzemidőt – átlagosan több mint nyolc órányi videólejátszást – lehet kiszorítani az akkumulátorukból.**",
                    "tr_noteSection": "**Az akkumulátoros üzemidő a beállításoktól, a használattól és más tényezőktől függően jelentős eltéréseket mutat."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "MINDEN, AMIRE SZÜKSÉGE VAN A FELADAT ELVÉGZÉSÉHEZ",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Tekintélyes teljesítény egy vékony, könnyű és tartósra tervezett számítógépben – és ez mind az Öné lehet. Kiváló akkumulátoros üzemidő, gyönyörű érintőképernyők* és a legújabb szilárdtest-meghajtók (SSD-k) – mindent megtalál, amit egy Windows* 10 számítógéptől elvár.",
                "tr_noteSection": "*Hardverfüggő",
                "titleArray": [
                  {
                    "tr_title": "A KEVESEBB TÖBB",
                    "tr_subtitle": "A mai számítógépek jellemzően kevesebb energiát fogyasztanak, ezért hosszabb üzemidőt – átlagosan több mint nyolc órányi videólejátszást – lehet kiszorítani az akkumulátorukból.**",
                    "tr_noteSection": "**Az akkumulátoros üzemidő a beállításoktól, a használattól és más tényezőktől függően jelentős eltéréseket mutat."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Biztonságra és teljesítményre optimalizálva",
                "tr_pretitle": "S módú Windows* 10",
                "tr_subtitle": "A Windows* 10 S módban történő futtatása során fokozott védelmet élvezhet, így annak tudatában dolgozhat, játszhat, illetve barangolhat a neten, hogy a készüléke biztonságban van."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "BIZTONSÁGRA ÉS TELJESÍTMÉNYRE OPTIMALIZÁLVA",
                "tr_title": "S módú Windows* 10",
                "tr_subtitle": "A Windows* 10 S módban történő futtatása során fokozott védelmet élvezhet, így annak tudatában dolgozhat, játszhat, illetve barangolhat a neten, hogy a készüléke biztonságban van."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Nagyszerű dolgokat vihet véghez",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Teljesítmény, ár és bőséges választék. Innovatív 2 az 1-ben készülékek, vékony és könnyű laptopok, érintőképernyők, leválasztható billentyűzetek – akármennyit tud szánni rá, és akármelyik stílust kedveli, találhat magának megfelelő Windows* számítógépet."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "NAGYSZERŰ DOLGOKAT VIHET VÉGHEZ",
                "tr_title": "Windows*",
                "tr_subtitle": "Teljesítmény, ár és bőséges választék. Innovatív 2 az 1-ben készülékek, vékony és könnyű laptopok, érintőképernyők, leválasztható billentyűzetek – akármennyit tud szánni rá, és akármelyik stílust kedveli, találhat magának megfelelő Windows* számítógépet."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operációs rendszer",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "MENNYI RAM-RA VAN SZÜKSÉGE?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Átlagos – 2 GB – 8 GB RAM",
                    "tr_badgeSubTitle": "Ideális megoldás azoknak, akik főként szövegszerkesztésre és webböngészésre használják a számítógépüket, és általában egy programot futtatnak egyszerre."
                  },
                  {
                    "tr_badgeTitle": "Erős – 8 GB – 16 GB RAM",
                    "tr_badgeSubTitle": "Több RAM-mal gyorsabban futnak az alkalmazások, és több alkalmazást lehet futtatni egyszerre."
                  },
                  {
                    "tr_badgeTitle": "Haladó – TÖBB MINT 16 GB RAM",
                    "tr_badgeSubTitle": "A játékok, a fénykép- és videószerkesztés vagy a 3D modellezés iránt érdeklődő felhasználóknak még több RAM-ra van szükségük ezeknek a teljesítményigényes programoknak a kezeléséhez."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM átlagos párhuzamos programfuttatáshoz",
                "tr_subtitle": "A memória lehetővé teszi az olyan alapvető alkalmazások gördülékeny betöltődését és futását, mint például a szövegszerkesztő és a böngésző.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM hatékony párhuzamos programfuttatáshoz",
                "tr_subtitle": "Ha több programot használ egyszerre, akkor a [memory_total]&nbsp;GB memória lehetővé teszi a gyors átváltást közöttük.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM haladó szintű párhuzamos programfuttatáshoz",
                "tr_subtitle": "Sok memóriával több teljesítményigényes programot lehet futtatni egyszerre a számítógép lelassulása nélkül.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB memória = [Value2] GB Intel® Optane™ memória + [Value3] GB RAM",
                "tr_subtitle": "A memóriamegoldások kombinálásával az Intel® Optane™ memória miatt gyorsabbá válik a merevlemez, a RAM miatt pedig javul a válaszidő.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>MEMÓRIA</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ memória",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Kevesebb várakozás. Foglalkozzon még többet azzal, amit szeret!",
                "tr_subtitle": "Az Intel® Optane™ memória a számítógép rendszermemóriájával együttműködve gyorsabb hozzáférést biztosít a gyakran használt programokhoz, hogy rögtön elinduljanak és betöltődjenek, amikor szükség van rájuk.",
                "tr_pretitle": "[XX] GB Intel® Optane™ memória",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "MI A SZEREPE A MEMÓRIÁNAK A SZÁMÍTÓGÉPBEN?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Gyorsabban lehet tartalmakat létrehozni",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Jobb reakcióidővel futó játék",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Könnyebben futtatható több program egyszerre",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Gyorsabban nyílnak meg a programok",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>A játék felgyorsítása</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ memória",
                "tr_pretitle1": "[XX] GB Intel® Optane™ memória a játék felgyorsításához",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Játékok indítása akár<br/><span class=\"optane-game\">98%-kal gyorsabban</span><br/>Az adatmeghajtóról, Intel® Optane™ memóriával egybeépített [optane_legal_processor_name] processzorral<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Szintek betöltése akár<br/><span class=\"optane-game\">4,1-szer gyorsabban</span><br/>Az adatmeghajtóról, Intel® Optane™ memóriával egybeépített [optane_legal_processor_name] processzorral<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>A játék felgyorsítása</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ memória",
                "tr_pretitle1": "[XX] GB Intel® Optane™ memória a játék felgyorsításához",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Játékok indítása akár<br/><span class=\"optane-game\">98%-kal gyorsabban</span><br/>Az adatmeghajtóról, Intel® Optane™ memóriával egybeépített [optane_legal_processor_name] processzorral<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Szintek betöltése akár<br/><span class=\"optane-game\">4,1-szer gyorsabban</span><br/>Az adatmeghajtóról, Intel® Optane™ memóriával egybeépített [optane_legal_processor_name] processzorral<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB memória = [Value2] GB Intel® Optane™ memória + [Value3] GB RAM",
                "tr_subtitle": "A memóriamegoldások kombinálásával az Intel® Optane™ memória miatt gyorsabbá válik a merevlemez, a RAM miatt pedig javul a válaszidő.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB RAM párhuzamos programfuttatáshoz",
                    "tr_subtitle": "Ha több programot használ egyszerre, akkor a [memory_total]&nbsp;GB memória lehetővé teszi a gyors átváltást közöttük."
                  },
                  {
                    "tr_title": "Átlagos feladatokhoz",
                    "tr_subtitle": "A memória lehetővé teszi az olyan alapvető alkalmazások gördülékeny betöltődését és futását, mint például a szövegszerkesztő és a böngésző."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "TOVÁBBI PÁRHUZAMOS FELADATOKHOZ",
                "tr_subtitle": "A RAM lehetővé teszi a processzor számára a programfájlokhoz való hozzáférést, a PC így gyorsabban eléri a programfájlokat, és több feladaton tud dolgozni egyszerre.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Normál memória",
                    "tr_badgeSubTitle": "Akár 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Megnövelt memória",
                    "tr_badgeSubTitle": "Több mint 8&nbsp;GB, és akár 16&nbsp;GB&nbsp;RAM"
                  },
                  {
                    "tr_badgeTitle": "Elit memória",
                    "tr_badgeSubTitle": "16 GB RAM-nál több"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB a párhuzamos programfuttatás fokozásához",
                "tr_pretitle": "TOVÁBBI PÁRHUZAMOS FELADATOKHOZ",
                "tr_subtitle": "A RAM lehetővé teszi a processzor számára a programfájlokhoz való hozzáférést, a PC így gyorsabban eléri a programfájlokat, és több feladaton tud dolgozni egyszerre.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Normál memória",
                    "tr_badgeSubTitle": "Akár 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Megnövelt memória",
                    "tr_badgeSubTitle": "Több mint 8&nbsp;GB, és akár 16&nbsp;GB&nbsp;RAM"
                  },
                  {
                    "tr_badgeTitle": "Elit memória",
                    "tr_badgeSubTitle": "16 GB RAM-nál több"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A MEMÓRIA MÁS MEMÓRIÁKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Szabványos",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Haladó szintű",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elit",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HOGYAN VISZONYUL EZ A MEMÓRIA MÁS MEMÓRIÁKHOZ?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "HOGYAN VISZONYUL EZ A MEMÓRIA MÁS MEMÓRIÁKHOZ?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Jelenlegi <br/>készülék",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Normál",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Erős",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Haladó szintű",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Adattárolás",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "MENNYI MINDENT TUD TÁROLNI A SZÁMÍTÓGÉPE?",
                "tr_subtitle": "Nem kell találgatnia. Pontosan megmutatjuk, mi mindent tárolhat ezen a számítógépen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Létrehozhat egy könyvtárat, ahol elfér</span> <br/><span class=\"storage-standard\">[num_songs] zeneszám<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>dalonként átlagosan 5 MB-tal számolva",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Létrehozhat egy könyvtárat, ahol elfér</span><br/><span class=\"storage-standard\">[num_games] játék<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>játékonként átlagosan 40 GB-tal számolva",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Tárolhat</span><br/><span class=\"storage-standard\">[num_videos] videót<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>videónként átlagosan 5 GB-tal számolva",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Készíthet és tárolhat</span><br/><span class=\"storage-standard\">[num_photos] fényképet<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>képenként átlagosan 4 MB-tal számolva",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ memória + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ADATTÁROLÁS",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD-tárhely",
                "tr_preTitle1": "[capacity] TB SSD-tárhely",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Ez a számítógép [capacity] GB kapacitású SSD-vel rendelkezik. Ez azt jelenti, hogy tekintélyes mennyiségű fényképet, videót és programot lehet tárolni rajta, és ezekhez sokkal gyorsabban hozzá lehet férni, mint egy merevlemezről.",
                    "tr_title": "Egy hely mindennek a&nbsp;PC&#8209;n"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Ez a számítógép [capacity] TB kapacitású SSD-vel rendelkezik. Ez azt jelenti, hogy tekintélyes mennyiségű fényképet, videót és programot lehet tárolni rajta, és ezekhez sokkal gyorsabban hozzá lehet férni, mint egy merevlemezről.",
                    "tr_title": "Ez a készülék figyelemre méltó tárkapacitással rendelkezik"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ADATTÁROLÁS",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD-tárhely",
                "tr_preTitle1": "[capacity] TB HDD-tárhely",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "A merevlemezek jó megoldást jelentenek azoknak, akiknek nagy tárterületre van szükségük közvetlenül a számítógépen.",
                    "tr_title": "Egy hely mindennek a&nbsp;PC&#8209;n"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "A merevlemezek jó megoldást jelentenek azoknak, akiknek nagy tárterületre van szükségük közvetlenül a számítógépen.",
                    "tr_title": "Ez a készülék figyelemre méltó tárkapacitással rendelkezik"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ADATTÁROLÁS",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "MENNYI MINDENT TUD TÁROLNI A SZÁMÍTÓGÉPE?",
                "tr_subtitle": "Nem kell találgatnia. Pontosan megmutatjuk, mi mindent tárolhat ezen a számítógépen.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "[total_capacity] GB Intel® SSD, [optane_memory_size] GB Intel® Optane™ memóriával gyorsítva"
                  },
                  {
                    "tr_title": "[total_capacity] TB Intel® SSD, [optane_memory_size] GB Intel® Optane™ memóriával gyorsítva"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ADATTÁROLÁS",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD-tárhely + [convertedHDD] GB HDD",
                "tr_preTitle1": "[convertedSSD] TB SSD-tárhely + [convertedHDD] GB HDD",
                "tr_preTitle2": "[convertedSSD] GB SSD-tárhely + [convertedHDD] TB HDD",
                "tr_preTitle3": "[convertedSSD] TB SSD-tárhely + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "Egy hely mindennek a&nbsp;PC&#8209;n",
                    "tr_title1": "Ez a készülék figyelemre méltó tárkapacitással rendelkezik",
                    "tr_subtitle": "Ebben a PC-ben egy [convertedSSD] GB kapacitású SSD, valamint egy [convertedHDD] GB kapacitású merevlemez van. Ez tekintélyes tárhelyet és gyors adattárolást biztosít egyszerre.",
                    "tr_subtitle1": "Ebben a PC-ben egy [convertedSSD] TB kapacitású SSD, valamint egy [convertedHDD] GB kapacitású merevlemez van. Ez tekintélyes tárhelyet és gyors adattárolást biztosít egyszerre.",
                    "tr_subtitle2": "Ebben a PC-ben egy [convertedSSD] GB kapacitású SSD, valamint egy [convertedHDD] TB kapacitású merevlemez van. Ez tekintélyes tárhelyet és gyors adattárolást biztosít egyszerre.",
                    "tr_subtitle3": "Ebben a PC-ben egy [convertedSSD] TB kapacitású SSD, valamint egy [convertedHDD] TB kapacitású merevlemez van. Ez tekintélyes tárhelyet és gyors adattárolást biztosít egyszerre."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>TÁRHELY</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ADATTÁROLÁS",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "SSD-vel kombinált Intel® Optane™ memóriával gyorsabban hozzáférhet a nagy kapacitású adattárolóhoz.",
                "tr_title": "Óriási fájlok is jöhetnek!",
                "tr_pretitle": "[total_capacity] GB Intel® SSD, [optane_memory_size] GB Intel® Optane™ memóriával gyorsítva",
                "tr_pretitle1": "[total_capacity] TB Intel® SSD, [optane_memory_size] GB Intel® Optane™ memóriával gyorsítva",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Ez a forradalmi tárolómegoldás elsőként ötvözi egymással az Intel® Optane™ memóriát és az SSD-t ugyanazon a chipen. Miért jó ez Önnek?",
                    "tr_title": "MI AZ SSD-VEL KOMBINÁLT INTEL® OPTANE™ MEMÓRIA?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Bármit gyorsan megnyithat.",
                    "tr_badgeSubTitle": "Mivel a gyakran használt fájlok és programok előre betöltődnek, Önnek több ideje jut az alkotásra, és kevesebbet kell várakoznia.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Adattároló plusz gyorsaság",
                    "tr_badgeSubTitle": "A gyors működésű SSD elegendő helyet biztosít a médiafájlok számára.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Idővel egyre gyorsabb lesz a válaszideje",
                    "tr_badgeSubTitle": "Az SSD-vel kombinált Intel® Optane™ memória megtanulja, hogyan dolgozik a felhasználó, és ezzel hatékonyabbá teszi a PC-t.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ADATTÁROLÁS",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Ilyen nagy és megbízható tárkapacitással a készüléken tárolhat minden médiatartalmat.",
                "tr_title": "Elegendő hely minden fénykép, videó és dal számára"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kijelző",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] millió képpont, amely csúsztatható, koppintható és lenyűgöző",
                "tr_subtitle": "Használja interaktívan számítógépét, akár csak egy okostelefont, az intuitív vezérlők, és az élesebb, élénkebb részletek segítségével.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Felbontás: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Képpontok száma: [screenResolution] millió"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] hüvelykes kijelző",
                    "tr_badgeSubTitle": "Képernyő magassága: [screenHeight] hüvelyk",
                    "tr_badgePreTitle": "Képernyő szélessége: [YY] hüvelyk"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Érintőképernyős kijelző",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kijelző",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Merüljön el a [screenResolution]&nbsp;millió&nbsp;képpontban",
                "tr_subtitle": "A képpontok száma a lényeg. Minél több van belőlük, annál élesebb és élénkebb a kép.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Felbontás: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Képpontok száma: [screenResolution] millió"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] hüvelykes kijelző",
                    "tr_badgeSubTitle": "Képernyő magassága: [screenHeight] hüvelyk",
                    "tr_badgePreTitle": "Képernyő szélessége: [YY] hüvelyk"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kijelző",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] hüvelyk <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "ABLAK A JÁTÉKOK VILÁGÁRA",
                "tr_subtitle": "Ahogy egy tévékészülékek esetében, úgy játék esetében is a méret és a felbontás a két legfontosabb mérlegelendő szempont.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Felbontás: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Képpontok száma: [screenResolution] millió"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] hüvelykes kijelző",
                    "tr_badgeSubTitle": "Képernyő magassága: [screenHeight] hüvelyk",
                    "tr_badgePreTitle": "Képernyő szélessége: [screenWidth] hüvelyk"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Érintőképernyős kijelző"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kijelző",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Ez a készülék figyelemre méltó kijelzővel rendelkezik",
                "tr_subtitle": "A HD felbontáshoz képest több mint nyolcszor annyi képpontot tartalmazó videók és játékok teljesen új szintre emelik a képek élességét és színvilágát.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Felbontás: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Képpontok száma: [screenResolution] millió"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] hüvelykes kijelző",
                    "tr_badgeSubTitle": "Képernyő magassága: [screenHeight] hüvelyk",
                    "tr_badgePreTitle": "Képernyő szélessége: [YY] hüvelyk"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kijelző",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] millió képpont a koppintáshoz, a simításhoz és az érintéshez ",
                    "tr_subTitle": "Nézze és használja kedvenc dolgait élesebb képekkel és élénkebb részletekkel!"
                  },
                  {
                    "tr_title": "[screenResolution] millió képpont a digitális világ kitágításához",
                    "tr_subTitle": "A képpontok száma a lényeg. Minél több van belőlük, annál élesebb és élénkebb a kép."
                  },
                  {
                    "tr_title": "Ez a készülék figyelemre méltó kijelzővel rendelkezik",
                    "tr_subTitle": "A HD képpontjainak közel négyszerese teljesen új szintre emeli a képélességet és a színeket a videókban és a játékokban."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kapcsolódás",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Bármely dokkolót, kijelzőt vagy adattároló készüléket Thunderbolt™ 3 technológiával lehet a leggyorsabban és a legrugalmasabban csatlakoztatni.",
                "tr_title": "Egyetlen port minden célra",
                "tr_title1": "EGYETLEN PORT MINDEN CÉLRA",
                "titleArray": [
                  {
                    "tr_title": "HOGYAN KELL HASZNÁLNI A&nbsp;THUNDERBOLT™&nbsp;3 TECHNOLÓGIÁT?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 technológia"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K monitorok",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Lenyűgöző felbontást, éles kontrasztot és páratlan színeket kínáló kijelzők csatlakoztatása"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Külső grafikus kártya",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Azonnali jelentős grafikai frissítés bármely PC-hez"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Gyors hálózati kapcsolat",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Gyors kapcsolat az egyenrangú eszközök között"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kapcsolódás",
                "detailBGUrl": "",
                "tr_subtitle": "A Thunderbolt™ 3 technológia gyorsabban továbbítja az adatokat, mint a hagyományos USB-k, ezért gyorsan lehet mozgatni a fájlokat, és egyszerűen lehet perifériákat, például több monitort vagy külső videokártyákat csatlakoztatni.",
                "tr_title": "Thunderbolt™ 3 technológia",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "VILLÁMGYORS ADATÁTVITEL",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 technológia",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kapcsolódás",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Minden kedvenc tartozékát ugyanazon az univerzális porton és kábelen keresztül csatlakoztathatja</li> <li>Megbízható notebooktöltés</li> <li>A nívós tanúsítás biztonságérzetet ad</li></ul>",
                "tr_title": "Igazán univerzális kábeles csatlakozás",
                "tr_title1": "IGAZÁN UNIVERZÁLIS KÁBELES CSATLAKOZÁS",
                "tr_noteSection": "<sup>†</sup>A minimumkövetelmények alapján",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HOGYAN KELL HASZNÁLNI A&nbsp;THUNDERBOLT™&nbsp;4&nbsp;TECHNOLÓGIÁT?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 technológia"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Alkotás",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Külső monitor és gyors adattároló csatlakoztatásával fényképeket és videókat tud nézegetni és szerkeszteni."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokkoló",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ha egy kábelt használ minden tartozék csatlakoztatásához, áttekinthetővé és rendezetté teheti az íróasztalát."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Játék",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Akár 3000 MB/s sebességű SSD- és HDD-kapacitás hozzáadásával játékokat és médiafájlokat tárolhat."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximális teljesítmény<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4-szer gyorsabb, mint az USB* 3.2 <br/>2-szer gyorsabb, mint a HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kapcsolódás",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Élvezheti ugyanazt a lenyűgöző teljesítményt, amit a Thunderbolt™ 3 technológia nyújt, de további képességekkel kiegészítve, amelyekkel egyszerűen csatlakoztathatja és használhatja a legújabb Thunderbolt™ dokkolókat, monitorokat, gyors adattárolókat vagy USB*-s kiegészítőket.",
                "tr_title": "Thunderbolt™ 4 technológia",
                "tr_pretitle": "IGAZÁN UNIVERZÁLIS KÁBELES CSATLAKOZÁS",
                "tr_noteSection": "<sup>†</sup>A minimumkövetelmények alapján",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Egyetlen Thunderbolt™ 4 univerzális kábellel kiválthat minden USB-C* kábelt, ami gyorssá és egyszerűvé teszi a kábel kiválasztását."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "A Thunderbolt™ 4 technológiát használó dokkolók a nagyobb rugalmasság érdekében akár négy Thunderbolt™ porttal is rendelkezhetnek, hogy többféle tartozékot lehessen csatlakoztatni."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximális teljesítmény<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4-szer gyorsabb, mint az USB* 3.2 <br/>2-szer gyorsabb, mint a HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Kapcsolódás",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Minden kedvenc tartozékát ugyanazon az univerzális porton és kábelen keresztül csatlakoztathatja</li> <li>Megbízható notebooktöltés</li> <li>A nívós tanúsítás biztonságérzetet ad</li></ul>",
                "tr_title": "Tegye tökéletessé az élményt Thunderbolt™ tartozékokkal",
                "tr_noteSection": "<sup>†</sup>A minimumkövetelmények alapján",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HOGYAN KELL HASZNÁLNI A&nbsp;THUNDERBOLT™&nbsp;4&nbsp;TECHNOLÓGIÁT?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Alkotás",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Külső monitor és gyors adattároló csatlakoztatásával fényképeket és videókat tud nézegetni és szerkeszteni."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokkoló",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ha egy kábelt használ minden tartozék csatlakoztatásához, áttekinthetővé és rendezetté teheti az íróasztalát."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Játék",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Akár 3000 MB/s sebességű SSD- és HDD-kapacitás hozzáadásával játékokat és médiafájlokat tárolhat."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximális teljesítmény<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4-szer gyorsabb, mint az USB* 3.2 <br/>2-szer gyorsabb, mint a HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "A Wi‑Fi legelterjedtebb típusai:",
                "tr_title": "A WI‑FISZABVÁNYOKRÓL",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "A Wi-Fi 6 akkor is nagyobb sebességeket kínál, ha sok felhasználó használja ugyanazt a jelet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Az Intel legújabb Wi‑Fi‑innovációjának köszönhetően akkor is gigabit fölötti Wi‑Fi‑sebesség érhető el, ha többen csatlakoznak ugyanahhoz a jelhez.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Az elmúlt 20 év legnagyobb Wi‑Fi-fejlesztését jelentő Wi‑Fi 6E új, nagy sebességű csatornákkal járul hozzá a teljesítmény és a megbízhatóság növeléséhez és az interferencia csökkentéséhez."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "A Wi-Fi 6 akkor is nagyobb sebességeket kínál, ha sok felhasználó használja ugyanazt a jelet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "A Wi-Fi 6E és az Intel® Killer™ rangsorolási motor által közösen nyújtott előny, hogy a sávszélességet olyan webhelyek és alkalmazások felé irányítja, amelyeknek arra leginkább szükségük van."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Mivel a nagy sebességű csatornákon kisebb a verseny, jobb teljesítményre és megbízhatóságra számíthat."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "A Wi-Fi 6 akkor is nagyobb sebességeket kínál, ha sok felhasználó használja ugyanazt a jelet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "A Wi-Fi 6 csökkenti az interferenciát, az Intel® Killer™ rangsorolási motor pedig intelligens módon javítja a hálózati teljesítményt, így még gyorsabb és még dinamikusabb online élményekben lehet része.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Mivel a nagy sebességű csatornákon kisebb a verseny, jobb teljesítményre és megbízhatóságra számíthat."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "5 Gbps feletti PC-Wi-Fi-sebesség egyenletesen fenntartott ultraalacsony késleltetéssel – fájlmegosztás percek helyett másodperceken belül."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Az Intel® Killer™ Wi-Fi 7 (5 Gig) a legújabb technológia, amely lehetővé teszi az „extrém” szintű megbízhatóságot, sebességet és az alacsony várakozási időt az AI-alapú hálózatépítés-optimalizáció segítségével."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "A Wi-Fi 6 akkor is nagyobb sebességeket kínál, ha sok felhasználó használja ugyanazt a jelet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Az Intel® Wi-Fi 6E (Gig+) megbízhatóan és alacsony várakozási idő mellett biztosít 1 Gbps-nél nagyobb adatátviteli sebességet a 6 GHz-es csatorna használata során."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Jobb hatékonyság, kapacitás és sebesség a Wi-Fi 5-höz képest a nagy forgalmú hálózatok esetében."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Javítsa a kapcsolat minőségét Wi‑Fi [XX] használatával, és élvezze a gyorsabb<sup>†</sup> és megbízhatóbb internetkapcsolatot.",
                "tr_title": "Jobb lefedettség, gyorsabb kapcsolat",
                "tr_noteSection": "<sup>†</sup>A tényleges vezeték nélküli adatáteresztő képesség és/vagy hatótávolság eltérhet ettől.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "A többrésztvevős és online játékok korában a folyamatos internetkapcsolat fenntartása sokkal fontosabb, mint valaha. A vezeték nélküli jelnek nemcsak gyorsnak kell lennie, hanem távolabbi helyeken is meg kell őriznie a sebességét.",
                "tr_title": "[XX]",
                "tr_pretitle": "AZ INTERNETKAPCSOLAT KULCSFONTOSSÁGÚ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Csatlakozzon 160 MHz-es csatornákat támogató Intel® Wi‑Fi 6 (Gig+) vagy Wi‑Fi 6 útválasztókkal, és élvezze a gyorsabb, megbízhatóbb és több aktív felhasználót támogató kapcsolatot!",
                "tr_title": "Csaknem 3-szor nagyobb sebesség Intel® <span class='no-warp'>Wi‑Fi 6  </span>(Gig+) technológiával<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>A szabványos AC Wi‑Fi-hez képest. Egyes funkciók csak bizonyos SKU-kkal érhetők el. A részletek felől érdeklődjön a gyártónál. A Wi‑Fi-vel kapcsolatban az intel.com/wifi6disclaimers oldalon található bővebb információ"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A belépő szintű kétsávos Intel® Wi-Fi 6 támogatja a megnövelt teljesítményt olyan alapvető kapcsolódási igények esetében, mint a nagy forgalmú hálózatokon történő e-mailezés vagy internetböngészés.<sup>9</sup>",
                "tr_title": "Nélkülözhetetlen Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "A többrésztvevős és online játékok korában a folyamatos internetkapcsolat fenntartása sokkal fontosabb, mint valaha. A vezeték nélküli jelnek nemcsak gyorsnak kell lennie, hanem távolabbi helyeken is meg kell őriznie a sebességét.",
                "tr_title": "[XX]",
                "tr_pretitle": "AZ INTERNETKAPCSOLAT KULCSFONTOSSÁGÚ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Ezeknek az új Wi‑Fi-csatornáknak a jelenléte azt jelenti, hogy nem kell osztoznia a sávszélességen a közelben lévő régebbi készülékekkel, így gördülékenyen streamelhet, gyorsan böngészhet, és zökkenőmentesen dolgozhat és tanulhat távolról.",
                "tr_title": "Versengésmentes kapcsolódás",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Javítsa a kapcsolat minőségét [XX] használatával, és élvezze a gyorsabb<sup>†</sup> és megbízhatóbb internetkapcsolatot.",
                "tr_title": "Jobb lefedettség, gyorsabb kapcsolat",
                "tr_noteSection": "<sup>†</sup>A tényleges vezeték nélküli adatáteresztő képesség és/vagy hatótávolság eltérhet ettől.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "A többrésztvevős és online játékok korában a folyamatos internetkapcsolat fenntartása sokkal fontosabb, mint valaha. A vezeték nélküli jelnek nemcsak gyorsnak kell lennie, hanem távolabbi helyeken is meg kell őriznie a sebességét.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "AZ INTERNETKAPCSOLAT KULCSFONTOSSÁGÚ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Beépített ultragyors Wi‑Fi",
                "tr_subtitle": "Húzza ki az internetkábelt, <br/>és maradjon kapcsolatban az online világgal!"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Az interferenciát csökkentő dedikált, nagy sebességű sávok jelenléte, valamint a játék elsőbbségét biztosító Intel® Killer™ rangsorolási motor komoly szintre emelte az online játékot.",
                "tr_title": "Elfelejtheti a versengést",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Az interferenciát csökkentő dedikált, nagy sebességű sávok jelenléte, valamint a játék elsőbbségét biztosító Intel® Killer™ rangsorolási motor komoly szintre emelte az online játékot.",
                "tr_title": "[XX]",
                "tr_pretitle": "ELFELEJTHETI A VERSENGÉST",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Az interferenciát csökkentő dedikált, nagy sebességű sávokkal, amelyek elsőbbséget biztosítanak a játéknak, az online játék komoly frissítést kapott.",
                "tr_title": "[XX]",
                "tr_pretitle": "ELFELEJTHETI A VERSENGÉST",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Az Intel® Killer™ Wi-Fi automatikusan felgyorsítja azoknak az alkalmazásoknak a hálózati forgalmát, amelyeknek leginkább szükségük van rá, így élesebb képpel, illetve kevesebb várakozással nézhet streamingszolgáltatásokat.",
                "tr_title": "Ez egy gyorsabb és intelligensebb csatlakozási módszer.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "A többrésztvevős és online játékok korában a folyamatos internetkapcsolat fenntartása sokkal fontosabb, mint valaha. A vezeték nélküli jelnek nemcsak gyorsnak kell lennie, hanem távolabbi helyeken is meg kell őriznie a sebességét.",
                "tr_title": "[XX]",
                "tr_pretitle": "AZ INTERNETKAPCSOLAT KULCSFONTOSSÁGÚ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Javítson a hálózati kapcsolatai minőségén a Wi-Fi 5 használatával, és élvezze a gyorsabb<sup>†</sup> és megbízhatóbb internetkapcsolatot.",
                "tr_title": "Jobb lefedettség, gyorsabb kapcsolat",
                "tr_noteSection": "<sup>†</sup>A tényleges vezeték nélküli adatáteresztő képesség és/vagy hatótávolság eltérhet ettől.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "A többrésztvevős és online játékok korában a folyamatos internetkapcsolat fenntartása sokkal fontosabb, mint valaha. A vezeték nélküli jelnek nemcsak gyorsnak kell lennie, hanem távolabbi helyeken is meg kell őriznie a sebességét.",
                "tr_title": "[XX]",
                "tr_pretitle": "ELFELEJTHETI A VERSENGÉST",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A Wi-Fi 7-funkciók az előző Wi-Fi-generációkra épülnek, de magasabb szintet képviselnek. Ez nemcsak nagyobb sebességeket, hanem drámaian jobb reakcióidőt és megbízhatóságot is jelent a lebilincselő felhasználói élmények és a kifinomult jövőbeli technológiák biztosításához.",
                "tr_title": "Vezetékes hálózatokhoz hasonló PC-hálózat",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "A Wi-Fi 7-funkciók az előző Wi-Fi-generációkra épülnek, de magasabb szintet képviselnek. Ez nemcsak nagyobb sebességeket, hanem drámaian jobb reakcióidőt és megbízhatóságot is jelent a lebilincselő felhasználói élmények és a kifinomult jövőbeli technológiák biztosításához.",
                "tr_title": "[XX]",
                "tr_pretitle": "A FELHASZNÁLÓI ÉLMÉNYEK ÁTALAKÍTÁSA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Jobb sebesség és megbízhatóság, illetve kevesebb várakozási idő az AI&#8209;alapú hálózatépítés-optimalizáció segítségével.",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Jobb sebesség és megbízhatóság, illetve kevesebb várakozási idő az AI&#8209;alapú hálózatépítés-optimalizáció segítségével.",
                "tr_title": "[XX]",
                "tr_pretitle": "VEZETÉKES HÁLÓZATOKRA JELLEMZŐ REAKCIÓIDŐ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akkumulátor",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Hogyan mérik?",
                    "tr_badgeSubTitle": "A legtöbb gyártó órákban fejezi ki az akkumulátoros üzemidőt. Bár ez az érték nem 100%-os pontosságú, a különböző PC-k akkumulátoros üzemidejének összehasonlításához jól használható."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akkumulátor",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MOBIL JÁTÉKHOZ",
                "tr_subtitle": "A játékra szánt modern laptopokkal semmi szükség arra, hogy odakösse magát az íróasztalhoz. A játékai mostantól bárhová követik Önt.",
                "tr_title": "[Dynamic.battery.values.hours] óra akkumulátoros üzemidő"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akkumulátor",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Húzza ki a tápkábelt, és használja tovább alkalmazásait, játsszon, hallgasson zenét akkumulátorról órákon át!",
                "tr_title": "[XX] órányi streaming egyetlen feltöltéssel<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>A PC-gyártó által közölt akkumulátoros üzemidő alapján.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] óra",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akkumulátor",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Húzza ki a tápkábelt, és használja tovább alkalmazásait, játsszon, hallgasson zenét akkumulátorról órákon át!",
                "tr_title": "[Dynamic.battery.values.hours] órányi működés akkumulátorról<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>A PC-gyártó által közölt akkumulátoros üzemidő alapján.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] óra",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akkumulátor",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Húzza ki a tápkábelt, és használja tovább alkalmazásait, játsszon, hallgasson zenét akkumulátorról órákon át!",
                "tr_title": "[Dynamic.battery.values.hours] óra az egész napos működéshez egyetlen feltöltéssel<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>A PC-gyártó által közölt akkumulátoros üzemidő alapján.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] óra",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akkumulátor",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Ez a számítógép hosszabb mobil használatra optimalizálja az üzemidőt, és 30 perc töltéssel legalább 4 órányi üzemidőt biztosít.<sup>4</sup>",
                "tr_title": "Hosszabb mobil használat [Dynamic.battery.values.hours] órányi akkumulátoros üzemidővel<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>A PC-gyártó által közölt akkumulátoros üzemidő alapján.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] óra",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Kezdőlap",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Miért pont ez a PC?",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Az összes műszaki adat megtekintése",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Távirányító",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobil",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Jogi nyilatkozat",
        "tr_paragraph_array": [
          "Lehet, hogy a teljesítménytesztekhez használt szoftver és munkaterhelés csak Intel® mikroprocesszorokra vonatkozóan lett optimalizálva. A teljesítményteszt, például a SYSmark* és a MobileMark* futtatása speciális számítógépes rendszerek, összetevők és funkciók alkalmazásával történik. A felsoroltak bármelyikének megváltoztatása az eredmények módosulását okozhatja. Vásárlás előtt érdemes további információkat és teszteredményeket is figyelembe venni, és tájékozódni arról, hogy az adott termék más termékekkel kombinálva milyen teljesítményt nyújt. A teljesítményről és a teljesítménymérő tesztek eredményeiről további információk találhatók a www.intel.com/benchmarks oldalon.",
          "¹Az Intel® Wi-Fi 6 (Gig+) termékek támogatják az opcionális 160 MHz-es csatornákat, lehetővé téve a lehető leggyorsabb elméleti maximális sebességet (2402 Mb/s) normál 2x2 802.11ax PC Wi-Fi-termékek esetén. A prémium Intel® Wi-Fi 6 (Gig+) termékek lehetővé teszik a 2–4-szer gyorsabb maximális elméleti sebességet a szokásos 2x2 (1201 Mbps) vagy 1x1 (600 Mbps) 802.11ax PC Wi-Fi-termékekkel összehasonlítva, amelyek csak a 80 MHz-es csatornák kötelező követelményeinek felelnek meg.",
          "²Ajánlás, kizárólag tájékoztatás céljából. A vásárlási döntés meghozatalához tájékozódjon más forrásokból is.",
          "³Az Intel-technológiák funkciói és előnyei függenek a rendszer konfigurációjától, és a működésükhöz megfelelő hardverre, szoftverre és szolgáltatásaktiválásra is szükség lehet. A teljesítmény a rendszer konfigurációjától függően eltérő lehet. Egyetlen számítógépes rendszer sem lehet teljesen biztonságos. Érdeklődjön a rendszer gyártójánál vagy a kereskedőnél, vagy keressen további információt az intel.com weboldalon.",
          "⁴FHD-kijelzővel rendelkező rendszereknél, vezeték nélküli webböngészés esetén. Kikapcsoláskor, az OEM alapértelmezett leállási szintről.",
          "⁵Más PC I/O összekapcsolási technológiákhoz – eSATA-hoz, USB-hez és IEEE 1394* FireWire-hez – képest. A teljesítmény a használt hardverektől és szoftverektől függően eltérő lehet. Thunderbolt™ technológiával kompatibilis készüléket kell használni.",
          "⁶Csaknem 3-szor nagyobb sebesség: 802.11ax 2x2 160 MHz használatával 2402 Mbps maximális elméleti adatátviteli sebességet lehet elérni. Körülbelül 3-szor gyorsabb, mint a szokásos 802.11* IEEE-ben leírt 802.11ac 2x2 80 MHz (867 Mbps/s) Wi‑Fi, és a használatához hasonlóan konfigurált 802.11ax vezeték nélküli hálózati útválasztóra van szükség.",
          "⁷A 75%-os késleltetéscsökkenés az Intel szimulációs adatain alapul (79%) 802.11ax használatával, OFDMA-val és OFDMA nélkül, 9 kliensen. OFDM nélkül az átlagos késleltetési idő 36 ms, OFDMA-val az átlagos késleltetési idő 7,6 ms-ra csökken. A késleltetési idő csökkenéséhez a 802.11ax (Wi-Fi 6) útválasztónak és valamennyi kliensnek támogatnia kell az OFDMA-t.",
          "⁸A 802.11 szabványleírásban megadott elméleti maximális adatátviteli sebességek összehasonlítása alapján a 2x2 160 MHz-es Wi‑Fi 6/6E (802.11ax) adatátviteli sebessége 2402 Mbps, a 2x2 80 MHz-es Wi‑Fi 5 (802.11ac) adatátviteli sebessége pedig 867 Mbps, ami 2,8-szeres sebességnövekedést jelent.",
          "⁹Kategóriájában a legjobb Wi‑Fi 6: Az Intel® Wi‑Fi 6 (Gig+) termékek támogatják az opcionális 160 MHz-es csatornákat, így a tipikus 2x2-es 802.11ax szabványú számítógépes Wi‑Fi-termékek között a legmagasabb elméleti maximális sebességet (2402 Mb/s) kínálják. A prémium Intel® Wi‑Fi 6 (Gig+) termékek lehetővé teszik a 2–4-szer gyorsabb maximális elméleti sebességet a szokásos 2x2 (1201 Mbps) vagy 1x1 (600 Mbps) 802.11ax PC Wi‑Fi-termékekkel összehasonlítva, amelyek csak a 80 MHz-es csatornák kötelező követelményeinek felelnek meg.",
          "Csak bizonyos termékváltozatok esetén, a funkció az OEM-kialakítástól függően eltérhet. A részletek felől érdeklődjön az OEM-nél vagy a kereskedőnél. A Wi‑Fi-vel kapcsolatban az intel.com/wifi6disclaimers oldalon található bővebb információ.",
          "¹⁰A 40 Gbps sebességgel működő Thunderbolt™ 4 technológia kínálja a leggyorsabb, legegyszerűbb és legstabilabb kábeles csatlakoztatási lehetőséget bármely dokkolóhoz, kijelzőhöz vagy adattároló eszközhöz más PC-s I/O csatlakozási technológiákkal, például eSATA*, USB* és IEEE 1394* FireWire technológiával való összehasonlításban.",
          "¹¹802.11ax-alapú, OFDMA-t és több hálózati klienst támogató útválasztó szükséges hozzá, AX-támogatással. A nagy eszközsűrűségű környezetben nyújtott jobb teljesítmény a 802.11ax kliensek és AP-k által támogatott OFDMA-funkciókkal érhető el. 2 Gbps, a 802.11ax, 160 MHz, 2402 Mbps szabvány IEEE 802.11* szabványleírásban megadott elméleti maximális adatátviteli sebességének kb. 70%-át feltételezve.",
          "¹²Dokumentum indítását mérték háttértevékenység közben, 8. generációs Intel® Core™ i7-8565U processzor (512 GB TLC SSD) és 8. generációs Intel® Core™ i7-8565U processzor (32 GB + 512 GB Intel® Optane™ H10 memória szilárdtest-tárolóval) összehasonlításával.",
          "¹³Az Intel® Thread Director be van építve a 12. generációs Intel® Core™ processzorokba, és abban segíti az operációs rendszert, hogy intelligensebben irányítsa a munkaterheléseket a megfelelő maghoz. Nincs szükség felhasználói beavatkozásra. Részletek az intel.com oldalon.",
          "¹⁴Egyes 12. generációs Intel® Core™ processzorokon nem érhető el. A teljesítményorientált hibrid architektúra két új mikromagarchitektúrát, azaz Performance-core-okat (P-core-okat) és Efficient-core-okat (E-core-okat) egyesít ugyanazon a processzorlapkán. Egyes 12. generációs Intel® Core™ processzorok (egyes 12. generációs Intel® Core™ i5 és az alatti processzorok) nem támogatják a teljesítményorientált hibrid architektúrát, csak a P-core-okat.",
          "¹⁵Mérés játék indítását tartalmazó munkaterheléssel, 8. generációs Intel® Core™ i7-8750H processzor (32 GB Intel® Optane™ memóriamodul + Intel® SSD 256 GB PCIe* + 1 TB HDD) és 8. generációs Intel® Core™ i7-8750H processzor (Intel® SSD 256 GB PCIe* + 1 TB HDD) összehasonlításával. Játék indítását tartalmazó munkaterhelés – az Intel által kialakított munkaterhelés, amellyel azt méri, hogy mennyi idő telik el a Total War*: WARHAMMER* II (build: 5577.0) indítása és a főmenü elérése között, miközben a bevezető videók le vannak tiltva (indítás). Intel® Optane™ memória adatmeghajtó-gyorsító konfigurációk: Intel® Core™ i7-8750H processzor, PL1=45 W TDP, 6 mag, 12 szál, Turbo max. 4 GHz gyártás előtti OEM-rendszeren, grafika: NVIDIA* GeForce* GTX 1070, memória: 2 × 4 GB DDR4, adattároló: Intel® SSD Series 760p 256 GB (PCIe*) + 1 TB HDD + 32 GB Intel® Optane™ memória, operációs rendszer: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H processzor, PL1=45 W TDP, 6 mag, 12 szál, Turbo max. 4 GHz gyártás előtti OEM-rendszeren, grafika: NVIDIA* GeForce* GTX 1070, memória: 2 × 4 GB DDR4, adattároló: Intel® SSD Series 760p 256 GB (PCIe*) + 1 TB HDD, operációs rendszer: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Mérés pálya betöltését tartalmazó munkaterheléssel, 8. generációs Intel® Core™ i7-8750H processzor (32 GB Intel® Optane™ memóriamodul) és 8. generációs Intel® Core™ i7-8750H processzor (csak HDD) összehasonlításával. Pálya betöltését tartalmazó munkaterhelés – az Intel által kialakított munkaterhelés, amellyel azt méri, hogy mennyi idő telik el a főmenü megnyitása és egy pálya betöltésének befejeződése (pályabetöltés) között a Total War*: WARHAMMER* II játékban (build: 5577.0). Intel® Optane™ memória adatmeghajtó-gyorsító konfigurációk: Intel® Core™ i7-8750H processzor, PL1=45 W TDP, 6 mag, 12 szál, Turbo max. 4 GHz gyártás előtti OEM-rendszeren, grafika: NVIDIA* GeForce* GTX 1070, memória: 2 × 4 GB DDR4, adattároló: Intel® SSD Series 760p 256 GB (PCIe*)+ 1 TB HDD + 32 GB Intel® Optane™ memória, operációs rendszer: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H processzor, PL1=45 W TDP, 6 mag, 12 szál, Turbo max. 4 GHz gyártás előtti OEM-rendszeren, grafika: NVIDIA* GeForce* GTX 1070, memória: 2 × 4 GB DDR4, adattároló: Intel® SSD Series 760p 256 GB (PCIe*) + 1 TB HDD, operációs rendszer: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Performance-core-okon. A teljesítmény felhasználási módtól, konfigurációtól és egyéb tényezőktől függően eltérő lehet. További információk: www.intel.com/PerformanceIndex.",
          "¹⁸Az órajel vagy a feszültség megváltoztatása károsíthatja a processzort és a rendszer más részeit, illetve csökkentheti a hasznos élettartamukat, és ronthatja a rendszer stabilitását és teljesítményét. Előfordulhat, hogy a termékgarancia nem vonatkozik arra az esetre, ha a processzort a műszaki adatokban megadottaknál magasabb értékeken működtetik. A részletek felől érdeklődjön a rendszer és az alkatrészek gyártójánál.",
          "¹⁹Annak alapján, hogy az Intel vPro® platform az operációs rendszer biztonsági képességei alatti és feletti funkciók, az alkalmazás- és adatvédelem, valamint a veszélyforrások elleni fejlett védelem egyedülálló kombinációját kínálja bármilyen méretű vállalkozás számára, továbbá annak alapján, hogy az Intel a termékek tervezésével, gyártásával és támogatásával kapcsolatban a biztonságot előtérbe helyező szemléletet alkalmaz. Az Intel vPro® platformra épülő valamennyi üzleti számítógépet szigorú előírások szerint vizsgáltak be, az egyedi hardveres biztonsági funkciókat is beleértve. Részletek: www.intel.com/PerformanceIndex (platformok). Egyetlen termék vagy alkotóelem sem lehet teljesen biztonságos.",
          "²⁰Az Intel® Connectivity Performance Suite csak Windows*-alapú rendszereken érhető el. Intel által végzett vezeték nélküli (Over The Air, OTA) Wi-Fi-tesztek túlterhelt hálózaton, összehasonlítva azzal, amikor nem használtak ICPS szoftveralkalmazást a vezeték nélküli forgalom rangsorolására és a hozzáférési pontok közötti intelligens átváltásra. Részletek: www.intel.com/PerformanceIndex (kapcsolódás). Az eredmények eltérőek lehetnek.",
          "²¹Az előző processzorgenerációhoz képest.",
          "²²Az Intel® Arc™ grafika az első olyan megoldás a piacon, amely támogatja az AV1 kódolást (2022. I. negyedévi állapot szerint). Részletek: intel.com/performanceIndex.",
          "²³Az Intel® Evo™ modellek egyedi jellemzői alapján, amelyek a legfontosabb mobil felhasználói élményekre vonatkozó küszöbértékeknek is igazoltan megfelelnek. 2022. februári tesztek.",
          "²⁴Az Intel® Unison™ megoldás jelenleg csak a követelményeknek megfelelő Intel® Evo™ modelleken, Windows*-alapú számítógépeken érhető el, és a párosításuk kizárólag Android- vagy iOS-alapú telefonokkal lehetséges; minden készüléken támogatott operációsrendszer-verziónak kell futnia.",
          "²⁵A maximális memóriasebességek az 1 DIMM/csatorna (1DPC) konfigurációkra vonatkoznak. A maximális memóriasebességre kihatással lehet, ha bármelyik csatornán további DIMM-ek betöltésére kerül sor. Akár DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 és DDR5-5200 1Rx8, 1Rx16, 2Rx8 egyes SKU-kon. A maximális memóriakapacitást 2DPC konfigurációkkal lehet elérni.",
          "²⁶A hagyományos Wi-Fi-hez képest. Részletek: intel.com/performance-wireless. Az eredmények eltérőek lehetnek.",
          "²⁷A teljesítményorientált hibrid architektúra két mag-mikroarchitektúrát – Performance-core-okat (P-core-okat) és Efficient-core-okat (E-core-okat) – egyesít ugyanazon a processzorlapkán, amely a 12. generációs Intel® Core™ processzorokon jelent meg először. Egyes 12. generációs és újabb Intel® Core™ processzorok nem rendelkeznek teljesítményorientált hibrid architektúrával, csak P-core-okkal, és a gyorsítótáruk mérete megegyezik az előző generációéval; a típuskódokkal (SKU) kapcsolatos részletekért lásd: ark.intel.com.",
          "²⁸A hardverbe épített Intel® Thread Director csak a 12. generációs vagy újabb Intel® Core™ processzorok teljesítményorientált hibrid architektúrával rendelkező konfigurációiban áll rendelkezésre; operációs rendszer általi engedélyezés szükséges. A rendelkezésre álló jellemzők és funkciók operációs rendszerenként eltérőek.",
          "²⁹A hardverbe épített Intel® Thread Director csak a 12. és 13. generációs Intel® Core™ processzorok teljesítményorientált hibrid architektúrával rendelkező konfigurációiban áll rendelkezésre; operációs rendszer általi engedélyezés szükséges. A rendelkezésre álló jellemzők és funkciók operációs rendszerenként eltérőek.",
          "³⁰Az Intel® Core™ Ultra mobil processzor (2. sorozat – kódnév: Lunar Lake) referenciaként szolgáló validációs platformjának teljesítménye alapján mérve, az Intel® Core™ Ultra mobil processzorhoz (1. sorozat – kódnév: Meteor Lake) képest, 3DMark Time Spy használatával. Részletek az intel.com/performanceindex oldalon. Az eredmények eltérőek lehetnek.",
          "³¹A háttérelmosás és az arckövetés csak Windows*-alapú rendszereken érhető el.",
          "³²A Wi-Fi 7 termékek képesek hozzáférni 320 MHz-es csatornákhoz 6 GHz-nél, továbbá új 160 MHz-es csatornakombinációkhoz 5 GHz-nél az új Multi-Resource Unit Puncturing képességeknek köszönhetően.",
          "³³Az IEEE vezeték nélküli szabványelőírásán és két adatfolyamot egyidejűleg kezelni képes eszközök esetében érvényes maximális elméleti adatátviteli sebességen alapul.",
          "³⁴Az Intel nagy forgalmú hálózati környezetekkel végzett tervezési szimulációi azt mutatják, hogy lehetséges a nagy mértékű várakozásiidő-csökkenés az új Wi-Fi 7 Multi-Link Operation képességeknek köszönhetően.",
          "³⁵OEM által megadott alapértelmezett kikapcsolási szintről elért minimális töltöttség. Az egyes rendszerek eredményei eltérőek lehetnek. Részletek: intel.com/performance-evo.",
          "³⁶A 6 GHz-es Wi-Fi-sáv nem minden országban/régióban használható.",
          "³⁷Csak Windows*",
          "³⁸OEM által megadott alapértelmezett kikapcsolási szintről elért töltöttség. A teljesítményről és a teljesítménymérő tesztek eredményeiről bővebb információ található az intel.com/Evo oldalon.",
          "³⁹Intel® Core™ Ultra mobil processzorokra (Series 2) vonatkozik, és az egyedülálló hardveres biztonsági funkciókon, az operációs rendszer feletti és alatti biztonsági képességek páratlan kombinációján, valamint az előző generációnál alacsonyabb energiafogyasztás mellett is lenyűgöző teljesítményen alapul (2024. szeptemberi állapot szerint). Részletek az intel.com/performanceindex oldalon. Az eredmények eltérőek lehetnek.",
          "⁴⁰A bemutatott szoftveralkalmazások csak illusztrációként szolgálnak. Az AI-funkciók használatához további szoftver vásárlására, előfizetésre vagy szoftver- vagy platformszolgáltató általi aktiválásra lehet szükség, illetve előfordulhat, hogy konfigurációval vagy kompatibilitással kapcsolatos követelményeket kell teljesíteni. Részletek: www.intel.com/PerformanceIndex. Az eredmények eltérőek lehetnek. © Intel Corporation.",
          "⁴¹A megjelenített képeket módosíthatták vagy szimulálhatták. Az AI-funkciók használatához további szoftver vásárlására, előfizetésre vagy szoftver- vagy platformszolgáltató általi aktiválásra lehet szükség, illetve előfordulhat, hogy konfigurációval vagy kompatibilitással kapcsolatos követelményeket kell teljesíteni. Részletek: www.intel.com/AIPC.",
          "⁴²Egyes globális piacokon fokozatosan jelenik meg előnézeti verzióként a Windows* 11 legújabb frissítésében. Az elérhetőség időzítése eszközönként és piaconként eltérő. További információ: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Az Intel® Unison™ megoldás jelenleg a megfelelő kialakítású termékekhez érhető el. Részletek: www.intel.com/PerformanceIndex.",
          "⁴⁴Az Intel® X<sup>e</sup>SS által felskálázott FPS a natív FPS-sel összehasonlítva az Intel® Core™ Ultra 7 165H processzoron futtatott Dying Light 2 Stay Human című játékban. Részletek: www.intel.com/PerformanceIndex. Az eredmények eltérőek lehetnek.",
          "⁴⁵Az Intel® Core™ Ultra mobil processzor (Series 2 – kódnév: Lunar Lake) referenciaként szolgáló validációs platformjának gyengébb SoC-teljesítménye alapján mérve, az Intel® Core™ Ultra mobil processzor (Series 1 – kódnév: Meteor Lake) referenciaként szolgáló validációs platformjához képest, a YouTube* 4K-s, 30 képkocka/s sebességű AV1-videójának használatával. Részletek az intel.com/performanceindex oldalon. Az eredmények eltérőek lehetnek.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Az Intel nem vállal felelősséget a hibásan közölt árakért.",
          "*A többi név és márka mások tulajdonában állhat.",
          "© INFLEXION GAMES, 2022. A NIGHTINGALE, AZ INFLEXION GAMES, VALAMINT A KAPCSOLÓDÓ FORMATERVEZÉS ÉS ÖSSZETETT VÉDJEGYEK AZ INFLEXION STUDIOS INC. VÉDJEGYEI. MINDEN JOG FENNTARTVA.",
          "Az Intel technológiák működéséhez megfelelő hardverre, meghatározott szoftverre vagy szolgáltatásaktiválásra lehet szükség.",
          "A NERO* által közölt eredmények és adatok csak kényelmi és tájékoztatási célokat szolgálnak. Az Intel Corporation nem vállal felelősséget egyebek mellett a NERO* alkalmazás pontosságáért, a teljesítménnyel kapcsolatban közölt eredményekért és pontszámokért, valamint az alkalmazás www.nero.com webhelyről vagy más webhelyekről letölthető egyéb verzióinak esetleges eltéréseiért. A NERO* teljesítménymérő teszttel mért pontszámok meghatározott számítógépes rendszerekre, összetevőkre, szoftverekre, műveletekre és funkciókra vonatkoznak. A felsoroltak bármelyikének megváltoztatása az eredmények módosulását okozhatja. Vásárlás előtt érdemes további információkat és teszteredményeket is figyelembe venni, és tájékozódni arról, hogy az adott termék más termékekkel kombinálva milyen teljesítményt nyújt.",
          "Az Intel vPro® platform minden verziójához megfelelő Intel® Core™ processzorra, támogatott operációs rendszerre, Intel® LAN és/vagy WLAN szilíciumra, firmware-fejlesztésekre, valamint egyéb hardverekre és szoftverekre van szükség, hogy biztosítani lehessen a platformot meghatározó, felügyelhetőséggel összefüggő használati eseteket, biztonsági funkciókat, rendszerteljesítményt és stabilitást.",
          "A teljesítmény felhasználási módtól, konfigurációtól és egyéb tényezőktől függően eltérő lehet. További információk: www.intel.com/PerformanceIndex.",
          "Az Intel nem ellenőrzi és nem vizsgálja harmadik felek adatait. Az adatok pontosságát Önnek más forrásokból kell ellenőriznie.",
          "© Intel Corporation. Az Intel, az Intel logó és más Intel jelek az Intel Corporation vagy leányvállalatainak védjegyei. *A többi név és márka mások tulajdonában állhat.",
          "Egyetlen termék vagy alkotóelem sem lehet teljesen biztonságos.",
          "Az Ön költségei és eredményei eltérhetnek.",
          "Az Intel, az Intel logó, az Intel Core, az Intel Optane, az Intel Iris, a Thunderbolt és a Thunderbolt logó az Intel Corporation vagy leányvállalatainak védjegyei. A többi név és márka mások tulajdonában állhat.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "ÜDVÖZÖLJÜK!",
    "tr_welcomePagetrSubtitle": "Irányítsa a számítógépet távolról, okostelefonjával",
    "tr_welcomePagembSubtitle": "Üdvözöljük, tanulmányozza a számítógép műszaki adatait az okostelefonján",
    "tr_welcomePageBtnText": "Kezdhetjük",
    "tr_welcomePageText": "Tanulmányozza és hasonlítsa össze a számítógép adatait az okostelefonján",
    "tr_scrollText": "Navigáljon az Ön előtt lévő készüléken",
    "tr_toolTipTextc1": "Ezekkel a gombokkal az Ön előtt lévő készülék különböző oldalaira ugorhat.",
    "tr_toolTipTextc2": "Az alábbi kártyákra koppintva elérheti a készülék egyes műszaki jellemzőit ismertető részletes oldalakat.",
    "tr_toolTipTextc3": "Az Ön előtt lévő készülék képernyőjén ezekkel a gombokkal mozoghat fel és le.",
    "tr_toolTipTextc4": "Kapcsolja ki a távirányítómódot, ha az okostelefonján szeretné megtekinteni a készülék adatait.",
    "tr_toolTipTextmb1": "A részletes tartalomoldalakat a kártyákra koppintva tekintheti meg.",
    "tr_toolTipTextmb2": "Koppintson a készülék összes műszaki adatát tartalmazó összefoglaló megtekintéséhez",
    "tr_toolTipTextmb3": "A QR-kód beolvasásához és legfeljebb 4 készülék összehasonlításához koppintson a gombra",
    "tr_nextButton": "Következő",
    "tr_prevButton": "Előző",
    "tr_gotItButton": "ÉRTEM",
    "tr_skipButton": "Kihagyás",
    "tr_placeholderText": "*helyőrző",
    "tr_processorHeading": "Processzor",
    "tr_processorSubheading": "10. generációs Intel® Core™ i7 processzor",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel® Optane™ memória + [YY] GB SSD",
    "tr_coachmarkProcessor": "XX processzor",
    "tr_coachmarkGraphics": "[XX] grafika",
    "tr_coachmarkMemory": "Spec. érték",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 $",
    "tr_compare": "Összehasonlítás",
    "tr_viewallspecs": "AZ ÖSSZES MŰSZAKI ADAT MEGTEKINTÉSE",
    "tr_operatingSystem": "OPERÁCIÓS RENDSZER",
    "tr_AmdProcessorNonIntel": "AMD* processzor",
    "tr_QualcommProcessorNonIntel": "Qualcomm* processzor",
    "tr_GBText": "GB",
    "tr_remoteText": "Műszaki adatok megjelenítése a laptop képernyőjén",
    "tr_scanErrorText": "Legfeljebb 3 készüléket lehet egyszerre beolvasni. Töröljön egy vagy több készüléket.",
    "tr_scanErrorText2": "Ezt a készüléket már beolvasta, olvasson be egy másikat.",
    "tr_scanMsg": "Készülék hozzáadásához olvassa be",
    "tr_backToScan": "Visszalépés és összehasonlítás",
    "tr_scanToCompare": "Beolvasás és összehasonlítás",
    "tr_compareDevice": "Készülékek összehasonlítása",
    "tr_processorTitle": "PROCESSZOR",
    "tr_graphicTitle": "GRAFIKA",
    "tr_storageTitle": "ADATTÁROLÁS",
    "tr_displayTitle": "KIJELZŐ",
    "tr_batteryTitle": "AKKUMULÁTOR",
    "tr_memoryTitle": "MEMÓRIA",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "KAPCSOLÓDÁS",
    "tr_priceTitle": "ÁR",
    "tr_operatingSystemTitle": "OPERÁCIÓS RENDSZER",
    "tr_batteryHoursText": "[XX] óra",
    "tr_hrsText": "óra",
    "tr_touchscreeenText": "[screenDiagonal]″ képátlójú érintőképernyő",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, érintőképernyő",
    "tr_FHDTouchScreenText": "FHD, érintőképernyő",
    "tr_4KTouchscreenText": "4K, érintőképernyő",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Mentés",
    "tr_device_comparison": "Készülék-összehasonlító dokumentum [yyyy]-[mm]-[dd]",
    "tr_Save_As_JPEG": "Mentés JPEG-ként",
    "tr_Save_As_PDF": "Mentés PDF-ként",
    "tr_Downloading_PDF": "PDF letöltése",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Megnyitás",
    "tr_logo": "logó",
    "tr_laptop_name": "Laptop neve",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ memória",
    "tr_inchUnit": "hüvelyk",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ MEMÓRIA",
    "tr_Thunderbolt3": "Thunderbolt™ 3 technológia",
    "tr_Thunderbolt4": "Thunderbolt™ 4 technológia",
    "tr_processorGraphicsCaps": "PROCESSZORGRAFIKA",
    "tr_processorGraphicsSmall": "Processzorgrafika",
    "tr_graphicsCardCaps": "DEDIKÁLT GRAFIKA",
    "tr_graphicsCardSmall": "Dedikált grafika",
    "tr_processorTitleCamel": "Processzor",
    "tr_graphicTitleCamel": "Grafika",
    "tr_storageTitleCamel": "Adattárolás",
    "tr_displayTitleCamel": "Kijelző",
    "tr_batteryTitleCamel": "Akkumulátor",
    "tr_memoryTitleCamel": "Memória",
    "tr_connectivityTitleCamel": "Kapcsolódás",
    "tr_priceTitleCamel": "Ár",
    "tr_operatingSystemTitleCamel": "Operációs rendszer",
    "tr_viewallspecsCamel": "Az összes műszaki adat megtekintése",
    "tr_displayText": "[screenDiagonal]″",
    "tr_displayTextResolution": "[screenDiagonal]″ képátlójú [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]″ képátlójú [screenResolutionType] érintőképernyő",
    "tr_OS": "OPERÁCIÓS RENDSZER",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "PC műszaki adatai",
    "tr_explorePC_text": "Ismerje meg alaposan ezt a számítógépet",
    "tr_mtlPC_text": "Nézze meg, mit takar valójában az „úgy tervezték, hogy minden feladatot elvégezzen” kifejezés",
    "tr_optaneMemory": "Intel® Optane™ memória",
    "tr_displayText_Display": "[screenDiagonal]″ KÉPÁTLÓJÚ KIJELZŐ",
    "tr_displayTextResolution_Display": "[screenDiagonal]″ KÉPÁTLÓJÚ [screenResolutionType] KIJELZŐ",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]″ KÉPÁTLÓJÚ [screenResolutionType] ÉRINTŐKÉPERNYŐ",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]″ KÉPÁTLÓJÚ ÉRINTŐKÉPERNYŐ",
    "tr_learnMoreLabel": "Részletek",
    "tr_batteryHoursTextUpperCase": "[XX] ÓRA",
    "tr_processorGraphicsIrisXe": "11. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIKA",
    "tr_processorGraphicsIrisXeMax": "11. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIKA",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 TECHNOLÓGIA",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 TECHNOLÓGIA",
    "tr_processorWithArcGraphics": "12. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® ARC™ GRAFIKA",
    "tr_processorGraphicsIrisXe12Gen": "12. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIKA",
    "tr_processorGraphicsIrisXeMax12Gen": "12. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIKA",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® ARC™ GRAFIKA",
    "tr_processorGraphicsIrisXe13Gen": "13. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIKA",
    "tr_processorGraphicsIrisXeMax13Gen": "13. GENERÁCIÓS INTEL® CORE™ [cpu_fam] PROCESSZOR ÉS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIKA",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M GRAFIKA",
      "tr_A370M": "INTEL® ARC™ A370M GRAFIKA",
      "tr_A730M": "INTEL® ARC™ A730M GRAFIKA",
      "tr_A770M": "INTEL® ARC™ A770M GRAFIKA",
      "tr_A310": "INTEL® ARC™ A310 GRAFIKA",
      "tr_A380": "INTEL® ARC™ A380 GRAFIKA",
      "tr_A580": "INTEL® ARC™ A580 GRAFIKA",
      "tr_A750": "INTEL® ARC™ A750 GRAFIKA",
      "tr_A770": "INTEL® ARC™ A770 GRAFIKA"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB memória = [Value2] GB Intel® Optane™ memória + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel® Optane™ memória + [convertedSSD] GB SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel® Optane™ memória + [convertedSSD] TB SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home S módban",
      "tr_OS21": "Windows* 10 Pro S módban",
      "tr_OS22": "Windows* 10 Enterprise S módban",
      "tr_OS23": "Windows* 11 Home S módban",
      "tr_OS24": "Windows* 11 Pro S módban",
      "tr_OS25": "Windows* 11 Enterprise S módban"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 grafika",
      "tr_GR387": "Intel® Arc™ A350M grafika",
      "tr_GR388": "Intel® Arc™ A370M grafika",
      "tr_GR389": "Intel® Arc™ A380 grafika",
      "tr_GR390": "Intel® Arc™ A530M grafika",
      "tr_GR391": "Intel® Arc™ A550M grafika",
      "tr_GR392": "Intel® Arc™ A570M grafika",
      "tr_GR393": "Intel® Arc™ A580 grafika",
      "tr_GR394": "Intel® Arc™ A730M grafika",
      "tr_GR395": "Intel® Arc™ A750 grafika",
      "tr_GR396": "Intel® Arc™ A770 grafika",
      "tr_GR397": "Intel® Arc™ A770M grafika",
      "tr_GR398": "Intel® Arc™ grafika",
      "tr_GR399": "Intel® Arc™ Pro A30M grafika",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 grafika",
      "tr_GR401": "Intel® Arc™ Pro A60 grafika",
      "tr_GR402": "Intel® Arc™ Pro A60M grafika",
      "tr_GR403": "Intel® Grafika",
      "tr_GR404": "Intel® HD 2000 grafika",
      "tr_GR405": "Intel® HD 2500 grafika",
      "tr_GR406": "Intel® HD 3000 grafika",
      "tr_GR407": "Intel® HD 400 grafika",
      "tr_GR408": "Intel® HD 4000 grafika",
      "tr_GR409": "Intel® HD 405 grafika",
      "tr_GR410": "Intel® HD 4200 grafika",
      "tr_GR411": "Intel® HD 4400 grafika",
      "tr_GR412": "Intel® HD 4600 grafika",
      "tr_GR413": "Intel® HD 500 grafika",
      "tr_GR414": "Intel® HD 5000 grafika",
      "tr_GR415": "Intel® HD 505 grafika",
      "tr_GR416": "Intel® HD 510 grafika",
      "tr_GR417": "Intel® HD 515 grafika",
      "tr_GR418": "Intel® HD 520 grafika",
      "tr_GR419": "Intel® HD 530 grafika",
      "tr_GR420": "Intel® HD 5300 grafika",
      "tr_GR421": "Intel® HD 5500 grafika",
      "tr_GR422": "Intel® HD 5600 grafika",
      "tr_GR423": "Intel® HD 6000 grafika",
      "tr_GR424": "Intel® HD 610 grafika",
      "tr_GR425": "Intel® HD 615 grafika",
      "tr_GR426": "Intel® HD 620 grafika",
      "tr_GR427": "Intel® HD 630 grafika",
      "tr_GR428": "Intel® HD grafika",
      "tr_GR429": "Intel® Iris® 5100 grafika",
      "tr_GR430": "Intel® Iris® 540 grafika",
      "tr_GR431": "Intel® Iris® 550 grafika",
      "tr_GR432": "Intel® Iris® 6100 grafika",
      "tr_GR433": "Intel® Iris® Plus 640 grafika",
      "tr_GR434": "Intel® Iris® Plus 645 grafika",
      "tr_GR435": "Intel® Iris® Plus 650 grafika",
      "tr_GR436": "Intel® Iris® Plus 655 grafika",
      "tr_GR437": "Intel® Iris® Plus grafika",
      "tr_GR438": "Intel® Iris® Pro 5200 grafika",
      "tr_GR439": "Intel® Iris® Pro 580 grafika",
      "tr_GR440": "Intel® Iris® Pro 6200 grafika",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> grafika",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX grafika A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX grafika",
      "tr_GR444": "Intel® integrált grafika",
      "tr_GR445": "Intel® UHD 600 grafika",
      "tr_GR446": "Intel® UHD 605 grafika",
      "tr_GR447": "Intel® UHD 610 grafika",
      "tr_GR448": "Intel® UHD 615 grafika",
      "tr_GR449": "Intel® UHD 617 grafika",
      "tr_GR450": "Intel® UHD 620 grafika",
      "tr_GR451": "Intel® UHD 630 grafika",
      "tr_GR452": "Intel® UHD 710 grafika",
      "tr_GR453": "Intel® UHD 730 grafika",
      "tr_GR454": "Intel® UHD 770 grafika",
      "tr_GR455": "Intel® UHD grafika",
      "tr_GR456": "Intel® UHD grafika",
      "tr_GR457": "Intel® UHD grafika",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050, Max-Q kialakítással",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti, Max-Q kialakítással",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060, Max-Q kialakítással",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070, Max-Q kialakítással",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080, Max-Q kialakítással",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650, Max-Q kialakítással",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti, Max-Q kialakítással",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti, Max-Q kialakítással",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060, Max-Q kialakítással",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070, Max-Q kialakítással",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super, Max-Q kialakítással",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080, Max-Q kialakítással",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super, Max-Q kialakítással",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000, Max-Q kialakítással",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200, Max-Q kialakítással",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000, Max-Q kialakítással",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200, Max-Q kialakítással",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200, Max-Q kialakítással",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000, Max-Q kialakítással",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000, Max-Q kialakítással",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000, Max-Q kialakítással",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000, Max-Q kialakítással",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000, Max-Q kialakítással",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada generáció",
      "tr_GR773": "NVIDIA* RTX 3000 Ada generáció",
      "tr_GR774": "NVIDIA* RTX 3500 Ada generáció",
      "tr_GR775": "NVIDIA* RTX 4000 Ada generáció",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada generáció",
      "tr_GR777": "NVIDIA* RTX 4500 Ada generáció",
      "tr_GR778": "NVIDIA* RTX 5000 Ada generáció",
      "tr_GR779": "NVIDIA* RTX 5880 Ada generáció",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada generáció",
      "tr_GR811": "NVIDIA* RTX 1000 Ada generáció",
      "tr_GR812": "Intel® Arc™ grafika",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E, Ada generáció",
      "tr_GR815": "AMD Radeon* Pro W7900, kétfoglalatos"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Vásárlás",
    "tr_textTryagain": "Próbálja újra!",
    "tr_textBuynowErr": "A kapcsolat megszakadása miatt a Vásárlás lehetőség nem érhető el"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_MTL",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_MTL",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i3 processzor"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Hybrid Technology-alapú Intel®&nbsp;Core™&nbsp;i5 processzor"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Kapcsoljon magasabb fokozatra Intel® processzorral a még ütősebb játékélményért!",
                "tr_subtitle": "Keltse életre a Marvel's Avengers* jeleneteit játékon belüli PC-optimalizációkkal, amelyek Intel® technológián alapulnak.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Élje át az Intel® Extreme Masters élményét!",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Ahol a világ legjobb játékosai versenyeznek egymással",
                "tr_subtitle": "A videójátékok történetének legnagyobb múltú eseménysorozata, az Intel® Extreme Masters meghatározó tényezője az e-sportok világának.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Játsszon úgy, mint a profik",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Bajnokok és Intel.",
                "tr_subtitle": "A 2021-es Rainbow Six Siege* észak-amerikai bajnokság megnyerése után a Soniqs tagjai játékosi múltjukról, az e-sport jövőjéről és az Intellel való együttműködésükről beszélnek.",
                "tr_pretitle": "Rivaldafényben a Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR-kompatibilis",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Ahol a VR életre kel",
                "tr_subtitle": "A legszebb, legélethűbb és a leginkább magával ragadó virtuális valóság, amelyet egy számítógép megteremthet. A legújabb Intel® Core™ processzorokkal a felhasználó bárki bőrébe belebújhat, bárhová elmehet, és új dimenzióban játszhat.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Játsszon több mint 100 kiváló minőségű konzoljátékkal, például Sea of Thieves*-zel és Psychonauts* 2-vel Windows* számítógépen, mobiltelefonon és táblagépen Xbox* Game Pass Ultimate előfizetéssel és kompatibilis kontrollerrel.",
                "titleArray": [
                  {
                    "tr_title": "Játék különféle készülékeket használó játékosokkal",
                    "tr_subtitle": "Csatlakozzon a több millió játékosból álló Xbox* közösséghez, amelynek tagjai készen állnak a közös játékra. Lépjen kapcsolatba és játsszon a világ másik felén tartózkodó vagy Ön mellett ülő játékosokkal, egy megosztott könyvtár játékai közül válogatva."
                  },
                  {
                    "tr_title": "Játék Windows* számítógépen",
                    "tr_subtitle": "Játsszon a katalógusban szereplő felhőalapú konzoljátékokkal a Windows* számítógépekhez szánt Xbox* alkalmazás és egy kompatibilis kontroller segítségével."
                  },
                  {
                    "tr_title": "Kapja fel, és játsszon",
                    "tr_subtitle": "Élvezze a nagyszerű Xbox* játékokat az eddigieknél több helyről. Játsszon több mint 100 játékkal számítógépen, mobiltelefonon és táblagépen, akár otthon, bekapcsolt tévékészülék mellett, akár egy kávézóban, akár bárhol másutt, ahol van gyors internetkapcsolat."
                  },
                  {
                    "tr_title": "Szórakozás kontroller nélkül",
                    "tr_subtitle": "Az érintőképernyős Xbox* játék új lehetőségeket kínál a videojátékok elkezdéséhez és a játékhoz, mert ismerős élményt nyújt, és támogatja a fizikai kontrollereknél megszokott játékszintet."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Reflektorfényben",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ AI-élmény",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Több mindent irányíthat, kevesebb érintéssel",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Bármilyen környezetben látható marad a képkeretben",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatív tevékenységek",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Profiként korrigálhatja a megvilágítást",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Könnyedén adhat hozzá rendkívül látványos effekteket",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Dalokat keverhet az AI erejével",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Irodai feladatok",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fokozhatja produktivitását és adatai védelmét",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Több időt tölthet távol a konnektortól",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Saját világ. Saját készülékeken.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Szórakozás",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zökkenőmentes streaming, kihúzott töltővel is",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Gyors, lebilincselő játék",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Reflektorfényben",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ AI-élmény",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Tegye igazán saját stílusúvá a kedvenc dalait",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Valós időben tekintheti meg az elképzeléseit",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatív tevékenységek",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "A leggyorsabb út a végleges változatig",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Tökéletessé tehet minden fotót",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Irodai feladatok",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fokozhatja produktivitását és adatai védelmét",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Hatékonyabb. Nagyobb szabadság.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Saját világ. Saját készülékeken.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Szórakozás",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zökkenőmentes HD-streaming, kihúzott töltővel is",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Lebilincselő játék még több helyen",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kreatívabb",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fotószerkesztés egyszerűen",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Készítsen keretbe kívánkozó fotókat másodpercek alatt",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Ön begépeli. Az AI elkészíti.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Nagyobb teljesítmény",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "A zökkenőmentes játék titkos kódja",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Több időt tölthet távol a konnektortól",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Rendkívül magával ragadó",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hatékonyabb",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Az Ön mindennapi AI-társa",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Tökéletes videóhívás",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Saját világ. Saját készülékeken.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Biztonságosabb",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Beépített védelmi funkciók az adatok és a magánélet fokozott biztonsága érdekében",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kreatívabb",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fotószerkesztés egyszerűen",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. A végleges verzióhoz vezető legrövidebb út.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Gyorsan átiratot készíthet kedvenc daláról",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Spóroljon órákat a szerkesztésen AI segítségével",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Nagyobb teljesítmény",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "A zökkenőmentes játék titkos kódja",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Több időt tölthet távol a konnektortól",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Rendkívül magával ragadó",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hatékonyabb",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Az Ön mindennapi AI-társa",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Tökéletes videóhívás",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Saját világ. Saját készülékeken.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Biztonságosabb",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Beépített védelmi funkciók az adatok és a magánélet fokozott biztonsága érdekében",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Sokféle szórakozás. Egy&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Egy szórakoztatóközpontként is használható PC-ben elegendő tárhelynek kell lennie a kedvenc zeneszámok, videók és játékok tárolásához, és megfelelően párosított processzornak és kijelzőnek kell gondoskodnia a lenyűgöző látványról. Ez a PC mindhárom feltételnek megfelel.",
                "titleArray": [
                  {
                    "tr_title": "Felkészítve a következő generációs vizuális hatásokra",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hozzon létre digitális könyvtárat",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ablak a szórakozásra",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zökkenőmentes játék és streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hozzon létre digitális könyvtárat",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ablak a szórakozásra",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hatékonyabb munka. Bárhol.",
              "tr_tileTitle1": "Végezzen el még több feladatot. Gyorsan.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ez a PC a teljesítmény és a hordozhatóság tökéletes egyensúlyát teremti meg. A vékony és könnyű modellekhez készült processzoron kívül folyamatos online kapcsolatot biztosító Wi&#8209;Fi&#8209;t és hosszú akkumulátoros üzemidőt kínál.",
                "tr_subtitle1": "Ez a PC az energiaellátás és a teljesítmény tökéletes egyensúlyát testesíti meg, hogy Ön több feladatot tudjon rövidebb idő alatt elvégezni. A robusztus processzor, a bőséges memória és a villámgyors Wi-Fi egyszerűbbé teszi a napi feladatok elvégzését.",
                "tr_subtitle2": "Ez a PC a teljesítmény és a hordozhatóság tökéletes egyensúlyát teremti meg. Ez a PC a vékony és könnyű modellekhez tervezett processzor mellett folyamatos online kapcsolatot biztosító Wi‑Fi-t és a párhuzamos programfuttatásra alkalmas memóriát kínál.",
                "titleArray": [
                  {
                    "tr_title": "Hatékonyabbra tervezve",
                    "tr_title1": "Intelligens teljesítmény – ami igazán számít",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Kategóriájában a legjobb adatkapcsolat",
                    "tr_title1": "Kapcsolódjon ahhoz, ami fontos",
                    "tr_title2": "Jelentős előrelépés a Wi‑Fi-ben",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Hosszabb ideig tartó vezeték nélküli használat",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Zsonglőrködjön úgy a programokkal, mint egy profi!",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Az alkotás szabadsága",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A tartalomkészítés az egyik legnagyobb igénybevételt jelentő feladat a PC számára. Ezek az alkotóelemek szerencsére elég erősek ahhoz, hogy hatékonnyá tegyék a kreatív folyamatot.",
                "titleArray": [
                  {
                    "tr_title": "A tartalomkészítés intelligensebb módja",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Keltse életre a képeket!",
                    "tr_title1": "Alkossunk!",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória párhuzamos programfuttatáshoz",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mi van a PC belsejében?",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Új PC választásakor ezt a három fontos alkotóelemet kell figyelembe venni. Ha eldöntötte, mire fogja használni az új PC-jét, máris könnyebb lesz meghatározni, hogy milyen legyen ez a három fő alkotóelem. Ezek a következők:",
                "titleArray": [
                  {
                    "tr_title": "Teljesítmény játékhoz és streaminghez",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Adattárolás",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memória",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Sokféle szórakozás. Egy&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Egy szórakoztatóközpontként is használható PC-ben elegendő tárhelynek kell lennie a kedvenc zeneszámok, videók és játékok tárolásához, és megfelelően párosított processzornak és kijelzőnek kell gondoskodnia a lenyűgöző látványról. Ez a PC mindhárom feltételnek megfelel.",
                "titleArray": [
                  {
                    "tr_title": "Felkészítve a következő generációs vizuális hatásokra",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hozzon létre digitális könyvtárat",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ablak a szórakozásra",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zökkenőmentes játék és streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hozzon létre digitális könyvtárat",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ablak a szórakozásra",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Memória",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Kijelző",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Nagy sebességű párhuzamos programfuttatás",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAGY SEBESSÉGŰ PÁRHUZAMOS PROGRAMFUTTATÁS",
                "tr_subtitle": "Több feladat elvégzése lassulás nélkül",
                "tr_subtitle1": "A következők miatt megy annyira jól a párhuzamos programfuttatás ezen a számítógépen:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely több programmal zsonglőrködik egyszerre."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors, erős és megbízható."
                  },
                  {
                    "tr_title": "Memória, amellyel zökkenőmentesen lehet váltogatni az alkalmazásokat."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videocsevegés",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCSEVEGÉS",
                "tr_subtitle": "Kristálytiszta kapcsolat",
                "tr_subtitle1": "A következők miatt megy annyira jól a videocsevegés ezen a számítógépen:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely egyszerre tudja rögzíteni és megosztani a képeket."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors, erős és megbízható."
                  },
                  {
                    "tr_title": "Webkamera, amellyel kristálytisztán megy át a kép."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-kompatibilis",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Ahol a VR életre kel",
                "tr_subtitle": "Mire van szükség egy teljesen virtuális világ létrehozásához? PC-k esetében a processzornak, a grafikus kártyának és a memóriának összehangoltan kell működnie.",
                "titleArray": [
                  {
                    "tr_title": "Virtuális világok születnek",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A valószerűség a részletekben rejlik",
                    "tr_title1": "Adja át magát az élménynek",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Videószerkesztés",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEÓSZERKESZTÉS",
                "tr_subtitle": "Életre kelnek a filmek",
                "tr_subtitle1": "A következők miatt megy annyira jól a&nbsp;videószerkesztés ezen a számítógépen:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely tudja kezelni a nagy fájlokat."
                  },
                  {
                    "tr_title": "Memória, amely gyorssá teszi a&nbsp;szerkesztőszoftver működését és válaszidejét."
                  },
                  {
                    "tr_title": "Grafika, amely lerövidíti a renderelési időt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Nagy sebességű párhuzamos programfuttatás",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAGY SEBESSÉGŰ PÁRHUZAMOS PROGRAMFUTTATÁS",
                "tr_subtitle": "Több feladat elvégzése lassulás nélkül",
                "tr_subtitle1": "A következők miatt megy annyira jól a párhuzamos programfuttatás ezen a számítógépen:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely több programmal zsonglőrködik egyszerre."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors, erős és megbízható."
                  },
                  {
                    "tr_title": "Memória, amellyel zökkenőmentesen lehet váltogatni az alkalmazásokat."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-kompatibilis",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Ahol a VR életre kel",
                "tr_subtitle": "Mire van szükség egy teljesen virtuális világ létrehozásához? PC-k esetében a processzornak, a grafikus kártyának és a memóriának összehangoltan kell működnie.",
                "titleArray": [
                  {
                    "tr_title": "Virtuális világok születnek",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A valószerűség a részletekben rejlik",
                    "tr_title1": "Adja át magát az élménynek",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D modellezés",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELLEZÉS",
                "tr_subtitle": "Alkotás magasabb szinten",
                "tr_subtitle1": "A következők miatt megy annyira jól a 3D modellezés ezen a számítógépen:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely kezeli a fejlett kreatív szoftvereket."
                  },
                  {
                    "tr_title": "Memória, amely gyorsan előhívja a nagy fájlokat."
                  },
                  {
                    "tr_title": "Grafika a képek hatékony létrehozásához és szerkesztéséhez."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Valós idejű csoportmunka",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VALÓS IDEJŰ CSOPORTMUNKA",
                "tr_subtitle": "Bárhonnan lehet dolgozni",
                "tr_subtitle1": "A következők miatt megy annyira jól az online csoportmunka ezen a számítógépen:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Processzor az irodai szoftverek működéséhez."
                  },
                  {
                    "tr_title": "Wi‑Fi, amelyre számítani lehet."
                  },
                  {
                    "tr_title": "Webkamera, amely ragyogó HD-ben rögzít."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-kompatibilis",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Ahol a VR életre kel",
                "tr_subtitle": "Mire van szükség egy teljesen virtuális világ létrehozásához? PC-k esetében a processzornak, a grafikus kártyának és a memóriának összehangoltan kell működnie.",
                "titleArray": [
                  {
                    "tr_title": "Virtuális világok születnek",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A valószerűség a részletekben rejlik",
                    "tr_title1": "Adja át magát az élménynek",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ laptopok",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Az ilyen vékony és könnyű laptopok általában nem ennyire erősek",
                "tr_subtitle": "A mobil teljesítményhez tervezett Intel® Evo™ laptopok sebesség, megjelenítés és akkumulátoros üzemidő szempontjából minden igényt kielégítenek, és igen tetszetős vékony és könnyű kivitelben kaphatók.",
                "titleArray": [
                  {
                    "tr_title": "A laptop ma többet jelent, mint valaha, ezért gondoskodtunk róla, hogy többet is tudjon, mint valaha.",
                    "tr_subtitle": "Felfedezéshez, forgatáshoz és nagyításhoz/kicsinyítéshez használja az érintőképernyőt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Villámgyors ébredés",
                    "tr_subtitle": "Az azonnali ébredésnek köszönhetően az alvó számítógép egy másodpercen belül üzemkész állapotba kerülhet."
                  },
                  {
                    "tr_title": "Lenyűgöző látvány",
                    "tr_subtitle": "A gyönyörű kijelző éles felbontással és élénk színekkel kelti életre a képeket."
                  },
                  {
                    "tr_title": "Gyorsan&nbsp;elvégezheti a&nbsp;feladatait",
                    "tr_subtitle": "11. generációs Intel® Core™ processzorral rendkívül gyorsan reagál&nbsp;a&nbsp;számítógép."
                  },
                  {
                    "tr_title": "Hosszabb üzemidő",
                    "tr_subtitle": "Nyugodt lehet, mert a számítógép optimalizálja az akkumulátoros üzemidőt, és&nbsp;nagyon gyorsan feltöltődik."
                  },
                  {
                    "tr_title": "Univerzális kábeles csatlakozás",
                    "tr_subtitle": "A Thunderbolt™ 4 technológia egyszerűvé, gyorssá és biztonságossá teszi más készülékek csatlakoztatását.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Gyors, megbízható csatlakozás",
                    "tr_subtitle": "Videocsevegést folytathat vagy fájlokat oszthat meg megbízható kapcsolaton keresztül, Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+) technológiával.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ laptopok",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Minden, amire szüksége van, és még annál is több egy vékony és könnyű laptopban.",
                "tr_subtitle": "Az Intel® Evo™ laptopokat mobil teljesítményhez tervezték, és úgy alkották meg, hogy a végzett tevékenységtől függetlenül a legjobb felhasználói élményt nyújtsák.<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "A laptop ma többet jelent, mint valaha, ezért gondoskodtunk róla, hogy többet is tudjon, mint valaha.",
                    "tr_subtitle": "Felfedezéshez, forgatáshoz és nagyításhoz/kicsinyítéshez használja az érintőképernyőt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Villámgyors ébredés",
                    "tr_subtitle": "Az azonnali ébredésnek köszönhetően az alvó számítógép egy másodpercen belül üzemkész állapotba kerülhet."
                  },
                  {
                    "tr_title": "Lenyűgöző látvány",
                    "tr_subtitle": "A gyönyörű kijelző éles felbontással és élénk színekkel kelti életre a képeket."
                  },
                  {
                    "tr_title": "Forradalmi teljesítmény",
                    "tr_subtitle": "A 12. generációs Intel® Core™ processzorok intelligens teljesítményével többletenergiára tehet szert ott, ahol a legnagyobb szüksége van rá."
                  },
                  {
                    "tr_title": "Hosszabb üzemidő",
                    "tr_subtitle": "Nyugodt lehet, mert a számítógép optimalizálja az akkumulátoros üzemidőt, és&nbsp;nagyon gyorsan feltöltődik."
                  },
                  {
                    "tr_title": "Univerzális kábeles csatlakozás",
                    "tr_subtitle": "A Thunderbolt™ 4 technológia egyszerűvé, gyorssá és biztonságossá teszi más készülékek csatlakoztatását.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Gyors, megbízható csatlakozás",
                    "tr_subtitle": "Videocsevegést folytathat vagy fájlokat oszthat meg megbízható kapcsolaton keresztül, Intel® Wi-Fi 6E (Gig+) technológiával.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ laptopok",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Az ilyen vékony és könnyű laptopok általában nem ennyire erősek",
                "tr_subtitle": "A mobil teljesítményhez tervezett Intel® Evo™ laptopok sebesség, megjelenítés és akkumulátoros üzemidő szempontjából minden igényt kielégítenek, és igen tetszetős vékony és könnyű kivitelben kaphatók.",
                "titleArray": [
                  {
                    "tr_title": "A laptop ma többet jelent, mint valaha, ezért gondoskodtunk róla, hogy többet is tudjon, mint valaha.",
                    "tr_subtitle": "Felfedezéshez, forgatáshoz és nagyításhoz/kicsinyítéshez használja az érintőképernyőt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Villámgyors ébredés",
                    "tr_subtitle": "Az azonnali ébredésnek köszönhetően az alvó számítógép egy másodpercen belül üzemkész állapotba kerülhet."
                  },
                  {
                    "tr_title": "Lenyűgöző látvány",
                    "tr_subtitle": "A gyönyörű kijelző éles felbontással és élénk színekkel kelti életre a képeket."
                  },
                  {
                    "tr_title": "Gyorsan&nbsp;elvégezheti a&nbsp;feladatait",
                    "tr_subtitle": "11. generációs Intel® Core™ processzorral rendkívül gyorsan reagál&nbsp;a&nbsp;számítógép."
                  },
                  {
                    "tr_title": "Hosszabb üzemidő",
                    "tr_subtitle": "Nyugodt lehet, mert a számítógép optimalizálja az akkumulátoros üzemidőt, és&nbsp;nagyon gyorsan feltöltődik."
                  },
                  {
                    "tr_title": "Univerzális kábeles csatlakozás",
                    "tr_subtitle": "A Thunderbolt™ 4 technológia egyszerűvé, gyorssá és biztonságossá teszi más készülékek csatlakoztatását.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Gyors, megbízható csatlakozás",
                    "tr_subtitle": "Videocsevegést folytathat vagy fájlokat oszthat meg megbízható kapcsolaton keresztül, Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+) technológiával.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ laptopok",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Minden, amire szüksége van, és még annál is több egy vékony és könnyű laptopban.",
                "tr_subtitle": "Az Intel® Evo™ laptopokat mobil teljesítményhez tervezték, és úgy alkották meg, hogy a végzett tevékenységtől függetlenül a legjobb felhasználói élményt nyújtsák.<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "A laptop ma többet jelent, mint valaha, ezért gondoskodtunk róla, hogy többet is tudjon, mint valaha.",
                    "tr_subtitle": "Felfedezéshez, forgatáshoz és nagyításhoz/kicsinyítéshez használja az érintőképernyőt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Villámgyors ébredés",
                    "tr_subtitle": "Az azonnali ébredésnek köszönhetően az alvó számítógép egy másodpercen belül üzemkész állapotba kerülhet."
                  },
                  {
                    "tr_title": "Lenyűgöző látvány",
                    "tr_subtitle": "A gyönyörű kijelző éles felbontással és élénk színekkel kelti életre a képeket."
                  },
                  {
                    "tr_title": "Forradalmi teljesítmény",
                    "tr_subtitle": "A 12. generációs Intel® Core™ processzorok intelligens teljesítményével többletenergiára tehet szert ott, ahol a legnagyobb szüksége van rá."
                  },
                  {
                    "tr_title": "Hosszabb üzemidő",
                    "tr_subtitle": "Nyugodt lehet, mert a számítógép optimalizálja az akkumulátoros üzemidőt, és&nbsp;nagyon gyorsan feltöltődik."
                  },
                  {
                    "tr_title": "Univerzális kábeles csatlakozás",
                    "tr_subtitle": "A Thunderbolt™ 4 technológia egyszerűvé, gyorssá és biztonságossá teszi más készülékek csatlakoztatását.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Gyors, megbízható csatlakozás",
                    "tr_subtitle": "Videocsevegést folytathat vagy fájlokat oszthat meg megbízható kapcsolaton keresztül, Intel® Wi-Fi 6E (Gig+) technológiával.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ laptopok",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Minden, amire szüksége van, és még annál is több egy vékony és könnyű laptopban.",
                "tr_subtitle": "Az Intel® Evo™ laptopokat mobil teljesítményhez tervezték, és úgy alkották meg, hogy a végzett tevékenységtől függetlenül a legjobb felhasználói élményt nyújtsák.<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "A laptop ma többet jelent, mint valaha, ezért gondoskodtunk róla, hogy többet is tudjon, mint valaha.",
                    "tr_subtitle": "Felfedezéshez, forgatáshoz és nagyításhoz/kicsinyítéshez használja az érintőképernyőt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Villámgyors ébredés",
                    "tr_subtitle": "Az azonnali ébredésnek köszönhetően az alvó számítógép egy másodpercen belül üzemkész állapotba kerülhet."
                  },
                  {
                    "tr_title": "Lenyűgöző látvány",
                    "tr_subtitle": "A gyönyörű kijelző éles felbontással és élénk színekkel kelti életre a képeket."
                  },
                  {
                    "tr_title": "Forradalmi teljesítmény",
                    "tr_subtitle": "A 13. generációs Intel® Core™ processzorok intelligens teljesítményével többletenergiára tehet szert ott, ahol a legnagyobb szüksége van rá."
                  },
                  {
                    "tr_title": "Hosszabb üzemidő",
                    "tr_subtitle": "Nyugodt lehet, mert a számítógép optimalizálja az akkumulátoros üzemidőt, és&nbsp;nagyon gyorsan feltöltődik."
                  },
                  {
                    "tr_title": "Univerzális kábeles csatlakozás",
                    "tr_subtitle": "A Thunderbolt™ 4 technológia egyszerűvé, gyorssá és biztonságossá teszi más készülékek csatlakoztatását.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Gyors, megbízható csatlakozás",
                    "tr_subtitle": "Videocsevegést folytathat vagy fájlokat oszthat meg megbízható kapcsolaton keresztül, Intel® Wi-Fi 6E (Gig+) technológiával.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ laptopok",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Minden, amire szüksége van, és még annál is több egy vékony és könnyű laptopban.",
                "tr_subtitle": "Az Intel® Evo™ laptopokat mobil teljesítményhez tervezték, és úgy alkották meg, hogy a végzett tevékenységtől függetlenül a legjobb felhasználói élményt nyújtsák.<sup>2</sup>",
                "titleArray": [
                  {
                    "tr_title": "A laptop ma többet jelent, mint valaha, ezért gondoskodtunk róla, hogy többet is tudjon, mint valaha.",
                    "tr_subtitle": "Felfedezéshez, forgatáshoz és nagyításhoz/kicsinyítéshez használja az érintőképernyőt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Villámgyors ébredés",
                    "tr_subtitle": "Az azonnali ébredésnek köszönhetően az alvó számítógép egy másodpercen belül üzemkész állapotba kerülhet."
                  },
                  {
                    "tr_title": "Lenyűgöző látvány",
                    "tr_subtitle": "A gyönyörű kijelző éles felbontással és élénk színekkel kelti életre a képeket."
                  },
                  {
                    "tr_title": "Forradalmi teljesítmény",
                    "tr_subtitle": "A 13. generációs Intel® Core™ processzorok intelligens teljesítményével többletenergiára tehet szert ott, ahol a legnagyobb szüksége van rá."
                  },
                  {
                    "tr_title": "Hosszabb üzemidő",
                    "tr_subtitle": "Nyugodt lehet, mert a számítógép optimalizálja az akkumulátoros üzemidőt, és&nbsp;nagyon gyorsan feltöltődik."
                  },
                  {
                    "tr_title": "Univerzális kábeles csatlakozás",
                    "tr_subtitle": "A Thunderbolt™ 4 technológia egyszerűvé, gyorssá és biztonságossá teszi más készülékek csatlakoztatását.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Gyors, megbízható csatlakozás",
                    "tr_subtitle": "Videocsevegést folytathat vagy fájlokat oszthat meg megbízható kapcsolaton keresztül, Intel® Wi-Fi 6E (Gig+) technológiával.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Reflektorfényben",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Miért jó az Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Miért pont Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatív tevékenységek",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Legyen kreatívabb még több helyen",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Fotószerkesztés teljes sebességgel",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Pillanatok alatt készíthet eredeti műalkotásokat",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Irodai feladatok",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI segítségével maximalizálhatja a produktivitását",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "AI segítségével azonosítja a deepfake-eket, mielőtt megosztaná azokat másokkal",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Saját világ. Saját készülékeken.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitás",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Üdvözölje integetéssel a gesztusvezérlést",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Üzemidő, amely nem fogja vissza Önt",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automatikusan és folyamatosan biztosítja az online kapcsolatot",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Reflektorfényben",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Miért jó az Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Miért pont Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatív tevékenységek",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Gyors, zökkenőmentes videólejátszás",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Slágereket gyárthat AI segítségével",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "A leggyorsabb út a végleges változatig",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Irodai feladatok",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI segítségével maximalizálhatja a produktivitását",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "AI segítségével azonosítja a deepfake-eket, mielőtt megosztaná azokat másokkal",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Saját világ. Saját készülékeken.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitás",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Gyakorlatilag bárhonnan részt vehet a csoportmunkában",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Üzemidő, amely nem fogja vissza Önt",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automatikusan és folyamatosan biztosítja az online kapcsolatot",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Sokféle szórakozás. Egy&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Egy szórakoztatóközpontként is használható PC-ben elegendő tárhelynek kell lennie a kedvenc zeneszámok, videók és játékok tárolásához, és megfelelően párosított processzornak és kijelzőnek kell gondoskodnia a lenyűgöző látványról. Ez a PC mindhárom feltételnek megfelel.",
                "titleArray": [
                  {
                    "tr_title": "Lenyűgöző játék és streamelés",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hozzon létre digitális könyvtárat",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ablak a szórakozásra",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zökkenőmentes játék és streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hozzon létre digitális könyvtárat",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ablak a szórakozásra",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hatékonyabb munka. Bárhol.",
              "tr_tileTitle1": "Végezzen el még több feladatot. Gyorsan.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ez a PC a teljesítmény és a hordozhatóság tökéletes egyensúlyát teremti meg. A vékony és könnyű modellekhez készült processzoron kívül folyamatos online kapcsolatot biztosító Wi&#8209;Fi&#8209;t és hosszú akkumulátoros üzemidőt kínál.",
                "tr_subtitle1": "Ez a PC az energiaellátás és a teljesítmény tökéletes egyensúlyát testesíti meg, hogy Ön több feladatot tudjon rövidebb idő alatt elvégezni. A robusztus processzor, a bőséges memória és a villámgyors Wi-Fi egyszerűbbé teszi a napi feladatok elvégzését.",
                "tr_subtitle2": "Ez a PC a teljesítmény és a hordozhatóság tökéletes egyensúlyát teremti meg. Ez a PC a vékony és könnyű modellekhez tervezett processzor mellett folyamatos online kapcsolatot biztosító Wi‑Fi-t és a párhuzamos programfuttatásra alkalmas memóriát kínál.",
                "titleArray": [
                  {
                    "tr_title": "Intelligens teljesítmény – ami igazán számít",
                    "tr_title1": "Fokozza a tempót intelligens teljesítménnyel!",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Kategóriájában a legjobb adatkapcsolat",
                    "tr_title1": "Kapcsolódjon ahhoz, ami fontos",
                    "tr_title2": "Jelentős előrelépés a Wi‑Fi-ben",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Hosszabb ideig tartó vezeték nélküli használat",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Zsonglőrködjön úgy a programokkal, mint egy profi!",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Az alkotás szabadsága",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A tartalomkészítés az egyik legnagyobb igénybevételt jelentő feladat a PC számára. Ezek az alkotóelemek szerencsére elég erősek ahhoz, hogy hatékonnyá tegyék a kreatív folyamatot.",
                "titleArray": [
                  {
                    "tr_title": "Gyorsabb kreatív munka",
                    "tr_title1": "Végezze el gyorsan a kreatív feladatokat!",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Emelt szintű tartalomkészítés",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória párhuzamos programfuttatáshoz",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mi van a PC belsejében?",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Új PC választásakor ezt a három fontos alkotóelemet kell figyelembe venni. Ha eldöntötte, mire fogja használni az új PC-jét, máris könnyebb lesz meghatározni, hogy milyen legyen ez a három fő alkotóelem. Ezek a következők:",
                "titleArray": [
                  {
                    "tr_title": "Processzor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Adattárolás",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memória",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Az alkotás szabadsága",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A tartalomkészítés az egyik legnagyobb igénybevételt jelentő feladat a PC számára. Ezek az alkotóelemek szerencsére elég erősek ahhoz, hogy hatékonnyá tegyék a kreatív folyamatot.",
                "titleArray": [
                  {
                    "tr_title": "A tartalomkészítés intelligensebb módja",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Keltse életre a képeket!",
                    "tr_title1": "Alkossunk!",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória párhuzamos programfuttatáshoz",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hatékonyabb munka. Bárhol.",
              "tr_tileTitle1": "Végezzen el még több feladatot. Gyorsan.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ez a PC a teljesítmény és a hordozhatóság tökéletes egyensúlyát teremti meg. A vékony és könnyű modellekhez készült processzoron kívül folyamatos online kapcsolatot biztosító Wi&#8209;Fi&#8209;t és hosszú akkumulátoros üzemidőt kínál.",
                "tr_subtitle1": "Ez a PC az energiaellátás és a teljesítmény tökéletes egyensúlyát testesíti meg, hogy Ön több feladatot tudjon rövidebb idő alatt elvégezni. A robusztus processzor, a bőséges memória és a villámgyors Wi-Fi egyszerűbbé teszi a napi feladatok elvégzését.",
                "tr_subtitle2": "Ez a PC a teljesítmény és a hordozhatóság tökéletes egyensúlyát teremti meg. Ez a PC a vékony és könnyű modellekhez tervezett processzor mellett folyamatos online kapcsolatot biztosító Wi‑Fi-t és a párhuzamos programfuttatásra alkalmas memóriát kínál.",
                "titleArray": [
                  {
                    "tr_title": "Hatékonyabbra tervezve",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Kategóriájában a legjobb adatkapcsolat",
                    "tr_title1": "Kapcsolódjon ahhoz, ami fontos",
                    "tr_title2": "Jelentős előrelépés a Wi‑Fi-ben",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Hosszabb ideig tartó vezeték nélküli használat",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Zsonglőrködjön úgy a programokkal, mint egy profi!",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-kompatibilis",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Ahol a VR életre kel",
                "tr_subtitle": "Mire van szükség egy teljesen virtuális világ létrehozásához? PC-k esetében a processzornak, a grafikus kártyának és a memóriának összehangoltan kell működnie.",
                "titleArray": [
                  {
                    "tr_title": "Virtuális világok születnek",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A valószerűség a részletekben rejlik",
                    "tr_title1": "Adja át magát az élménynek",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Nem bántja a szemet",
      "tr_sub_header": "A kék fény kellemetlen lehet a szemnek, és megzavarhatja az alvási ciklust. A Samsung cég SGS Eye Care Display tanúsítvánnyal rendelkező OLED képernyői nem a színek megváltoztatásával, hanem az esetlegesen ártalmas kék fény hullámhosszának csökkentésével kímélik a szemet és mérséklik a szemfáradtságot.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Kevésbé ártalmas kék fény"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Minden tekintetben ultra",
      "tr_sub_header": "Az OLED ultrakönnyű, ultravékony kialakítása a lehető legkisebb súly mellett a lehető legjobb képminőséget biztosítja."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Valódi fekete",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Hagyományos</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Soha véget nem érő szórakozás",
                "tr_subtitle1": "A következők miatt megy annyira jól a streaming ezen a számítógépen:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely HD-ben tud streamelni."
                  },
                  {
                    "tr_title": "Kijelző, amelyen ragyogóak és gyönyörűek a képek."
                  },
                  {
                    "tr_title": "Wi‑Fi, amellyel rövidebb ideig tart a pufferelés és a betöltés."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Webböngészés",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WEBBÖNGÉSZÉS",
                "tr_subtitle": "Internet, egyszerűen",
                "tr_subtitle1": "A következők miatt megy annyira jól a webböngészés ezen a számítógépen:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely gyorsan tölti be a médiában gazdag oldalakat."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors és megbízható."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videocsevegés",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCSEVEGÉS",
                "tr_subtitle": "Kristálytiszta kapcsolat",
                "tr_subtitle1": "A következők miatt megy annyira jól a videocsevegés ezen a számítógépen:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely egyszerre tudja rögzíteni és megosztani a képeket."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors, erős és megbízható."
                  },
                  {
                    "tr_title": "Webkamera, amellyel kristálytisztán megy át a kép."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Nagy sebességű párhuzamos programfuttatás",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAGY SEBESSÉGŰ PÁRHUZAMOS PROGRAMFUTTATÁS",
                "tr_subtitle": "Több feladat elvégzése lassulás nélkül",
                "tr_subtitle1": "A következők miatt megy annyira jól a párhuzamos programfuttatás ezen a számítógépen:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely több programmal zsonglőrködik egyszerre."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors, erős és megbízható."
                  },
                  {
                    "tr_title": "Memória, amellyel zökkenőmentesen lehet váltogatni az alkalmazásokat."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videocsevegés",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCSEVEGÉS",
                "tr_subtitle": "Kristálytiszta kapcsolat",
                "tr_subtitle1": "A következők miatt megy annyira jól a videocsevegés ezen a számítógépen:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely egyszerre tudja rögzíteni és megosztani a képeket."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors, erős és megbízható."
                  },
                  {
                    "tr_title": "Webkamera, amellyel kristálytisztán megy át a kép."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Alkalomszerű játék",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ALKALOMSZERŰ JÁTÉK",
                "tr_subtitle": "Itt az idő a játékra",
                "tr_subtitle1": "A következők miatt megy annyira jól a játék ezen a számítógépen:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amelytől egyenletes lesz a képek lejátszása a játékban."
                  },
                  {
                    "tr_title": "Memória, amellyel egyszerre mehet a hangcsevegés és a streaming."
                  },
                  {
                    "tr_title": "Grafika, amely lenyűgöző képeket jelenít meg."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotószerkesztés",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTÓSZERKESZTÉS",
                "tr_subtitle": "Tökéletes képpontosságú fényképek",
                "tr_subtitle1": "A következők miatt megy annyira jól a fotószerkesztés ezen a számítógépen:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amelynek teljesítménye alkalmas nagy képcsoportok szerkesztésére."
                  },
                  {
                    "tr_title": "Memória, amely felgyorsítja a szerkesztőszoftver működését."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 technológia, amely lehetővé teszi a gyors adatátvitelt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Nagy sebességű párhuzamos programfuttatás",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAGY SEBESSÉGŰ PÁRHUZAMOS PROGRAMFUTTATÁS",
                "tr_subtitle": "Több feladat elvégzése lassulás nélkül",
                "tr_subtitle1": "A következők miatt megy annyira jól a párhuzamos programfuttatás ezen a számítógépen:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely több programmal zsonglőrködik egyszerre."
                  },
                  {
                    "tr_title": "Wi‑Fi, amely gyors, erős és megbízható."
                  },
                  {
                    "tr_title": "Memória, amellyel zökkenőmentesen lehet váltogatni az alkalmazásokat."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Valós idejű csoportmunka",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VALÓS IDEJŰ CSOPORTMUNKA",
                "tr_subtitle": "Bárhonnan lehet dolgozni",
                "tr_subtitle1": "A következők miatt megy annyira jól az online csoportmunka ezen a számítógépen:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Processzor az irodai szoftverek működéséhez."
                  },
                  {
                    "tr_title": "Wi‑Fi, amelyre számítani lehet."
                  },
                  {
                    "tr_title": "Webkamera, amely ragyogó HD-ben rögzít."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videószerkesztés",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEÓSZERKESZTÉS",
                "tr_subtitle": "Életre kelnek a filmek",
                "tr_subtitle1": "A következők miatt megy annyira jól a&nbsp;videószerkesztés ezen a számítógépen:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely tudja kezelni a nagy fájlokat."
                  },
                  {
                    "tr_title": "Memória, amely gyorssá teszi a&nbsp;szerkesztőszoftver működését és válaszidejét."
                  },
                  {
                    "tr_title": "Grafika, amely lerövidíti a renderelési időt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotószerkesztés",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTÓSZERKESZTÉS",
                "tr_subtitle": "Tökéletes képpontosságú fényképek",
                "tr_subtitle1": "A következők miatt megy annyira jól a fotószerkesztés ezen a számítógépen:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amelynek teljesítménye alkalmas nagy képcsoportok szerkesztésére."
                  },
                  {
                    "tr_title": "Memória, amely felgyorsítja a szerkesztőszoftver működését."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 technológia, amely lehetővé teszi a gyors adatátvitelt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Videószerkesztés",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEÓSZERKESZTÉS",
                "tr_subtitle": "Életre kelnek a filmek",
                "tr_subtitle1": "A következők miatt megy annyira jól a&nbsp;videószerkesztés ezen a számítógépen:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely tudja kezelni a nagy fájlokat."
                  },
                  {
                    "tr_title": "Memória, amely gyorssá teszi a&nbsp;szerkesztőszoftver működését és válaszidejét."
                  },
                  {
                    "tr_title": "Grafika, amely lerövidíti a renderelési időt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D modellezés",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELLEZÉS",
                "tr_subtitle": "Alkotás magasabb szinten",
                "tr_subtitle1": "A következők miatt megy annyira jól a 3D modellezés ezen a számítógépen:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Processzor, amely kezeli a fejlett kreatív szoftvereket."
                  },
                  {
                    "tr_title": "Memória, amely gyorsan előhívja a nagy fájlokat."
                  },
                  {
                    "tr_title": "Grafika a képek hatékony létrehozásához és szerkesztéséhez."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Valós idejű csoportmunka",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VALÓS IDEJŰ CSOPORTMUNKA",
                "tr_subtitle": "Bárhonnan lehet dolgozni",
                "tr_subtitle1": "A következők miatt megy annyira jól az online csoportmunka ezen a számítógépen:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Processzor az irodai szoftverek működéséhez."
                  },
                  {
                    "tr_title": "Wi‑Fi, amelyre számítani lehet."
                  },
                  {
                    "tr_title": "Webkamera, amely ragyogó HD-ben rögzít."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ app",
    "tr_title": "Kitárul Ön előtt az összekapcsolt világ",
    "tr_subtitle": "Zökkenőmentesen vihet át fájlokat és fotókat, miközben a számítógépéről kezelheti a telefonos értesítéseket, hívásokat és szöveges üzeneteket.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "FÁJLOK ÉS FOTÓK ÁTVITELE",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "HÍVÁSOK INDÍTÁSA ÉS FOGADÁSA",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "SZÖVEGES ÜZENETEK KÜLDÉSE ÉS FOGADÁSA",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "TELEFONOS ÉRTESÍTÉSEK KEZELÉSE",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "KITÁRUL ÖN ELŐTT AZ ÖSSZEKAPCSOLT VILÁG",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}