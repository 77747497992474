const Library_mobile_pl = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Z&nbsp;procesorem [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Z&nbsp;procesorem [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Tak jak mózg, kontroluje on pracę komputera. Oznacza to, że za wszystko, co robisz na komputerze — oglądane filmy, uruchamiane gry i&nbsp;odwiedzane strony internetowe — odpowiedzialny jest procesor.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Procesor to mózg komputera"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Wydajność i&nbsp;przystępna cena.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Silver",
                    "tr_subtitle": "Nadążaj za światem cyfrowym dzięki szybkiemu dostępowi do plików pobieranych z&nbsp;Internetu. Prosta wielozadaniowość dzięki łatwemu przełączaniu się między programami i&nbsp;wykonywaniu większej liczby zadań w&nbsp;krótszym czasie.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Gold",
                    "tr_subtitle": "Zwiększona produktywność — wysoka wydajność, dzięki której sprostasz nawet najbardziej wymagającym zadaniom. Usprawnione przeglądanie Internetu — szybkie ładowanie stron zaawansowanych multimedialnie, co zapewnia więcej odkrywania i&nbsp;mniej czekania.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Celeron®",
                    "tr_subtitle": "Wysoka wydajność, która sprawdzi się przy pracy i&nbsp;rozrywce. Niezwykła szczegółowość filmów i&nbsp;internetowych materiałów wideo. Więcej działania, mniej czekania.",
                    "tr_gen_core_title": "Procesor Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium®",
                    "tr_subtitle": "Wysoka jakość rozrywki, streamowanie wideo i&nbsp;produktywność. Niezwykła szczegółowość filmów i&nbsp;internetowych materiałów wideo. Więcej działania, mniej czekania.",
                    "tr_gen_core_title": "Procesor Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Wydajność, jakiej potrzebujesz. Przystępność cenowa, jakiej pragniesz.",
                "tr_title": "Procesor Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Osobna kategoria wartości",
                    "tr_subtitle": "Procesor Intel® może obsługiwać niemal każde zadanie, w&nbsp;cenie, na którą możesz sobie pozwolić. Z&nbsp;łatwością poruszaj się po różnych programach. Lub ciesz się wysoką jakością grafiki przy stałym poziomie wydajności.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Nawiązuj silniejsze połączenia",
                    "tr_subtitle": "Bardziej żywe i&nbsp;wyższej jakości filmy, większa czystość dźwięku i&nbsp;niemal 3&nbsp;razy szybsza łączność z&nbsp;Internetem umożliwiają pewną współpracę, gdzie tylko tego potrzebujesz<sup>26</sup>.",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Różnica jak między dniem a&nbsp;nocą",
                    "tr_subtitle": "Od porannych maratonów filmowych po poprawki na ostatnią chwilę, procesor Intel® wydłuża czas pracy baterii urządzenia, aby zapewnić prawdziwie wciągające i&nbsp;nieprzerwane wrażenia.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Każde środowisko jest dobre do nauki",
                    "tr_subtitle": "Z&nbsp;łatwością korzystaj z&nbsp;wielu narzędzi do nauki zdalnej w&nbsp;dowolnym zakątku swojego domu."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Ucz się, gdzie chcesz",
                    "tr_subtitle": "Dokańczaj lekcje tam, gdzie się obecnie znajdujesz, równocześnie korzystając z&nbsp;różnych narzędzi e&#8209;learningowych."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Uzyskaj moc rozrywki i&nbsp;produktywności, która pasuje do Twojego budżetu oraz stylu życia.",
                "tr_title": "Wydajność marki Intel na każdą okazję.",
                "tr_gen_core_title": "Procesor Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "ZNAJDŹ ODPOWIEDNI POZIOM WYDAJNOŚCI I&nbsp;CENĘ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Procesor Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Szybkie przeglądanie stron zaawansowanych multimedialnie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Łatwe przełączanie programów",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Proste gry",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oglądanie filmów 4K z Internetu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Łatwa edycja zdjęć i&nbsp;filmów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Większa produktywność z&nbsp;inteligentną wydajnością",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Z&nbsp;procesorem Intel® Core™ [cpu_fam] siódmej&nbsp;generacji"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Z&nbsp;procesorem Intel® Core™ [cpu_fam] szóstej&nbsp;generacji"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Z&nbsp;procesorem Intel® Core™ [cpu_fam] piątej&nbsp;generacji"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Z&nbsp;procesorem Intel® Core™ [cpu_fam] czwartej&nbsp;generacji"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Z&nbsp;procesorem Intel® Core™ [cpu_fam] trzeciej&nbsp;generacji"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Z&nbsp;procesorem Intel® Core™ [cpu_fam] drugiej&nbsp;generacji"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Z&nbsp;procesorem Intel® Core™ [cpu_fam] pierwszej&nbsp;generacji"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Korzyści:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i3 ósmej generacji",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 ósmej generacji"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory Intel® Core™ dziewiątej generacji powstały, aby nadążać za Twoim cyfrowym światem. Dzięki połączeniu szybkości i&nbsp;wydajności możesz bez problemów wykonywać więcej Twoich ulubionych czynności na komputerze.",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i3 dziewiątej&nbsp;generacji",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>dziewiątej generacji"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ogromna wydajność i&nbsp;długi czas pracy baterii umożliwiają pracę w&nbsp;dowolnym miejscu.",
                "tr_title": "Moc na cały dzień",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>dziesiątej generacji",
                "processorCompare": {
                  "tr_title": "ZNAJDŹ ODPOWIEDNI POZIOM WYDAJNOŚCI DZIESIĄTEJ GENERACJI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamowanie filmów w&nbsp;jakości 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Szybka i&nbsp;niezawodna łączność",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maskowanie zdjęć i&nbsp;filtrowanie wideo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Płynne gry w rozdzielczości 1080p",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepsze do pracy z&nbsp;dużymi plikami i&nbsp;programami dla twórców",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dzięki dużemu zapasowi wydajności możesz łatwo przechodzić od&nbsp;streamowania filmów do tworzenia prezentacji.",
                "tr_title": "Moc na cały dzień",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>dziesiątej generacji"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dla osób będących stale w&nbsp;podróży – <br/>laptop zaprojektowany specjalnie dla Ciebie.",
                "tr_title": "Oto nowa klasa laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>dziesiątej generacji"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i3 jedenastej generacji został zaprojektowany specjalnie do komputerów przenośnych i&nbsp;zapewnia więcej możliwości niż kiedykolwiek wcześniej, w&nbsp;dowolnym miejscu.",
                "tr_title": "Wykorzystaj potencjał swojego nowego laptopa",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Gdy cała rodzina szuka jednego komputera stacjonarnego do wszystkich zadań, potrzebujesz mocy i&nbsp;wszechstronności procesora Intel® Core™ i3 jedenastej generacji.",
                "tr_title": "Przygotuj się na wszystko dzięki przełomowej wydajności.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i5 ósmej&nbsp;generacji",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 ósmej generacji"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory Intel® Core™ dziewiątej generacji powstały, aby nadążać za Twoim cyfrowym światem. Dzięki połączeniu szybkości i&nbsp;wydajności możesz bez problemów wykonywać więcej Twoich ulubionych czynności na komputerze.",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i5 dziewiątej&nbsp;generacji",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziewiątej generacji"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "PŁYNNA ROZGRYWKA I OBSŁUGA RZECZYWISTOŚCI WIRTUALNEJ W PRZYPADKU NAJBARDZIEJ WYMAGAJĄCYCH TYTUŁÓW",
                "tr_title": "Prawdziwa gra zaczyna się tutaj",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziewiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Graj, streamuj i&nbsp;nagrywaj bez kompromisów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepszy procesor Intel® Core™ dziewiątej generacji do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 4<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Łatwe przełączanie między wieloma programami pozwala być gotowym na wszystko.",
                "tr_title": "Wielozadaniowość w&nbsp;podróży",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziesiątej generacji",
                "processorCompare": {
                  "tr_title": "ZNAJDŹ ODPOWIEDNI POZIOM WYDAJNOŚCI DZIESIĄTEJ GENERACJI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamowanie filmów w&nbsp;jakości 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Szybka i&nbsp;niezawodna łączność",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maskowanie zdjęć i&nbsp;filtrowanie wideo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Płynne gry w rozdzielczości 1080p",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepsze do pracy z&nbsp;dużymi plikami i&nbsp;programami dla twórców",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Teraz wszystko, czym się zajmujesz&nbsp;— od rozrywki, przez pracę biurową, po tworzenie materiałów&nbsp;— możesz przenieść na wyższy poziom.",
                "tr_title": "Zmień komputer na mocniejszy model",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziesiątej generacji"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dla osób będących stale w&nbsp;podróży – <br/>laptop zaprojektowany specjalnie dla Ciebie.",
                "tr_title": "Oto nowa klasa laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziesiątej generacji"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Stworzony do płynnego streamowania i&nbsp;grania w&nbsp;wysokobudżetowe gry",
                "tr_title": "Graj na swoich warunkach",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Stworzony do płynnego streamowania i&nbsp;grania w&nbsp;wysokobudżetowe gry",
                "tr_title": "Graj na swoich warunkach",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 10<br/>Wątki: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Łatwe przełączanie między wieloma programami pozwala być gotowym na wszystko.",
                "tr_title": "Wielozadaniowość w&nbsp;podróży",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziesiątej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DZIESIĄTEJ&nbsp;GENERACJI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesory Intel® Core™ i5 jedenastej generacji zapewniają dynamiczne wrażenia wizualne oraz wszechstronną produktywność na najsmuklejszych i&nbsp;najlżejszych komputerach.",
                "tr_title": "Imponująca moc laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Gdy cała rodzina szuka jednego komputera stacjonarnego do wszystkich zadań, potrzebujesz mocy i&nbsp;wszechstronności procesora Intel® Core™ i5 jedenastej generacji.",
                "tr_title": "Przygotuj się na wszystko dzięki przełomowej wydajności.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory Intel® Core™ i5 jedenastej generacji zapewniają dynamiczne wrażenia wizualne oraz wszechstronną produktywność na smukłych i&nbsp;lekkich laptopach.",
                "tr_title": "Imponująca moc laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 JEDENASTEJ&nbsp;GENERACJI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i7 ósmej&nbsp;generacji",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 ósmej generacji"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Procesory Intel® Core™ dziewiątej generacji powstały, aby nadążać za Twoim cyfrowym światem. Dzięki połączeniu szybkości i&nbsp;wydajności możesz bez problemów wykonywać więcej Twoich ulubionych czynności na komputerze.",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i7 dziewiątej&nbsp;generacji",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziewiątej generacji"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "NAWET 32% WIĘCEJ KL./S podczas grania, streamowania i&nbsp;nagrywania w&nbsp;porównaniu z&nbsp;TRZYLETNIM KOMPUTEREM<sup>3</sup>",
                "tr_title": "Moc, dzięki której podzielisz się swoimi chwilami chwały",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziewiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Graj, streamuj i&nbsp;nagrywaj bez kompromisów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepszy procesor Intel® Core™ dziewiątej generacji do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 4<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "To eleganckie urządzenie, zaprojektowane z&nbsp;myślą o&nbsp;wydajności, może znacznie ułatwić tworzenie, komunikację i&nbsp;streamowanie.",
                "tr_title": "Zaawansowana wydajność w&nbsp;dowolnym miejscu",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziesiątej generacji",
                "processorCompare": {
                  "tr_title": "ZNAJDŹ ODPOWIEDNI POZIOM WYDAJNOŚCI DZIESIĄTEJ GENERACJI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamowanie filmów w&nbsp;jakości 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Szybka i&nbsp;niezawodna łączność",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maskowanie zdjęć i&nbsp;filtrowanie wideo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Płynne gry w rozdzielczości 1080p",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepsze do pracy z&nbsp;dużymi plikami i&nbsp;programami dla twórców",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zaawansowana wydajność umożliwiająca przeniesienie wykonywania wszystkich zadań na&nbsp;nowy&nbsp;poziom.",
                "tr_title": "Moc do wszystkiego",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziesiątej generacji"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dla osób będących stale w&nbsp;podróży – <br/>laptop zaprojektowany specjalnie dla Ciebie.",
                "tr_title": "Oto nowa klasa laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziesiątej generacji"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Konkurencyjne granie, streamowanie i&nbsp;nagrywanie",
                "tr_title": "Moc do świata gier",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Konkurencyjne granie, streamowanie i&nbsp;nagrywanie",
                "tr_title": "Moc do świata gier",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 10<br/>Wątki: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "To eleganckie urządzenie, zaprojektowane z&nbsp;myślą o&nbsp;wydajności, może mieć ogromny wpływ na sposób, w&nbsp;jaki tworzysz, łączysz się i&nbsp;streamujesz.",
                "tr_title": "Doskonała wydajność w&nbsp;dowolnym miejscu",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziesiątej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DZIESIĄTEJ&nbsp;GENERACJI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesory Intel® Core™ i7 jedenastej generacji umożliwiają tworzenie zaawansowanych materiałów, płynne działanie gier oraz rozrywkę nowej generacji na ultraprzenośnych komputerach.",
                "tr_title": "Przełomowa wydajność laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Spraw, aby Twój komputer stacjonarny stał się miejscem docelowym dla wszystkiego, czego potrzebuje Twój dom za pomocą przełomowej wydajności procesora Intel® Core™ i7 jedenastej generacji.",
                "tr_title": "Praca. Nauka. Gra. Bez granic.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory Intel® Core™ i7 jedenastej generacji umożliwiają tworzenie zaawansowanych materiałów, płynne działanie gier oraz rozrywkę nowej generacji na smukłych i&nbsp;lekkich laptopach.",
                "tr_title": "Przełomowa wydajność laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 JEDENASTEJ&nbsp;GENERACJI"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Spraw, aby Twój komputer stacjonarny stał się miejscem docelowym dla wszystkiego, czego potrzebuje Twój dom za pomocą przełomowej wydajności procesora Intel® Core™ i7 jedenastej generacji.",
                "tr_title": "Twórz bez granic",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 JEDENASTEJ&nbsp;GENERACJI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dzięki tworzeniu zaawansowanych materiałów, płynnemu graniu i&nbsp;rozrywce na najwyższym poziomie na smukłych i&nbsp;lekkich laptopach, ten procesor Intel® Core™ i7 jedenastej generacji jest jeszcze lepszy.",
                "tr_title": "Przełomowa wydajność laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 JEDENASTEJ&nbsp;GENERACJI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i9 ósmej&nbsp;generacji",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 ósmej generacji"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory Intel® Core™ dziewiątej generacji powstały, aby nadążać za Twoim cyfrowym światem. Dzięki połączeniu szybkości i&nbsp;wydajności możesz bez problemów wykonywać więcej Twoich ulubionych czynności na komputerze.",
                "tr_title": "Z&nbsp;procesorem Intel® Core™ i9 dziewiątej&nbsp;generacji",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziewiątej generacji"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "NAWET 41% WIĘCEJ KL./S podczas grania, streamowania i&nbsp;nagrywania w&nbsp;porównaniu z&nbsp;TRZYLETNIM KOMPUTEREM<sup>4</sup>",
                "tr_title": "Gracze mają potrzeby. Procesor Intel® Core™ i9 je spełnia.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziewiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Graj, streamuj i&nbsp;nagrywaj bez kompromisów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepszy procesor Intel® Core™ dziewiątej generacji do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 4<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Uwolnij swój potencjał dzięki niezrównanej obsłudze gier",
                "tr_title": "Rywalizacja na najwyższym poziomie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Uwolnij swój potencjał dzięki niezrównanej obsłudze gier",
                "tr_title": "Rywalizacja na najwyższym poziomie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 10<br/>Wątki: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Uwolnij swój potencjał dzięki niezrównanej obsłudze tworzenia",
                "tr_title": "Twórz na najwyższym poziomie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziesiątej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DZIESIĄTEJ&nbsp;GENERACJI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptopy Intel® Evo™ wyposażone w&nbsp;procesor Intel® Core™ i5 jedenastej generacji i&nbsp;grafikę Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> zapewniają niesamowitą szybkość i&nbsp;wydajność, a&nbsp;wszystko to w&nbsp;smukłej i&nbsp;lekkiej obudowie.",
                "tr_title": "Zwiększona moc do udoskonalonego laptopa",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptopy Intel® Evo™ wyposażone w&nbsp;procesor Intel® Core™ i7 jedenastej generacji oraz grafikę Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> zapewniają zaawansowaną szybkość i&nbsp;wydajność rozwiązaniom mobilnym najwyższej klasy.",
                "tr_title": "Zaawansowana moc w zaawansowanym laptopie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ciesz się znakomitą równowagą wydajności, mediów i&nbsp;łączności przy niesamowitej cenie.",
                "tr_title": "Wydajność i&nbsp;łączność w&nbsp;niesamowitej cenie.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "ZNAJDŹ ODPOWIEDNI POZIOM WYDAJNOŚCI I&nbsp;CENĘ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Szybkie przeglądanie stron zaawansowanych multimedialnie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Łatwe przełączanie programów",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Proste gry",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oglądanie filmów 4K z Internetu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Łatwa edycja zdjęć i&nbsp;filmów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Większa produktywność z&nbsp;inteligentną wydajnością",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ciesz się płynną wielozadaniową, bezproblemową edycją zdjęć i&nbsp;filmów, nagraniami wideo 4K oraz żywą grafiką za niewiarygodnie niską cenę.",
                "tr_title": "Imponująca wydajność do pracy i&nbsp;zabawy.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "ZNAJDŹ ODPOWIEDNI POZIOM WYDAJNOŚCI I&nbsp;CENĘ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Szybkie przeglądanie stron zaawansowanych multimedialnie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Łatwe przełączanie programów",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Proste gry",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oglądanie filmów 4K z Internetu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Łatwa edycja zdjęć i&nbsp;filmów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Większa produktywność z&nbsp;inteligentną wydajnością",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Wysokobudżetowe gry i&nbsp;długi czas pracy baterii w&nbsp;ultraprzenośnym komputerze. Nie zgadzaj się na kompromisy podczas grania.",
                "tr_title": "Nie zgadzaj się na kompromisy.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ZNAJDŹ SWÓJ ULTRAPRZENOŚNY PROCESOR INTEL® CORE™ DO GIER.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5-11300H jedenastej generacji"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11370H jedenastej generacji"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11375H Special Edition jedenastej generacji"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Nawet <span class='style_badge'>4,4&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu",
                      "tr_td2_title": "Nawet <span class='style_badge'>4,8&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu",
                      "tr_td3_title": "Nawet <span class='style_badge'>5,0&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu"
                    },
                    {
                      "tr_td1_title": "4 rdzenie/ 8&nbsp;wątków",
                      "tr_td2_title": "4 rdzenie/ 8&nbsp;wątków",
                      "tr_td3_title": "4 rdzenie/ 8&nbsp;wątków"
                    },
                    {
                      "tr_td1_title": "Technologia Intel® Turbo Boost",
                      "tr_td2_title": "Technologia Intel® Turbo Boost",
                      "tr_td3_title": "Technologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB pamięci Intel® Smart Cache",
                      "tr_td2_title": "12 MB pamięci Intel® Smart Cache",
                      "tr_td3_title": "12 MB pamięci Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Na ultraprzenośnym komputerze możesz grać w&nbsp;najbardziej wymagające gry w&nbsp;rozdzielczości 1080p, z&nbsp;dużą liczbą klatek na sekundę na wysokich ustawieniach.",
                "tr_title": "Bez obaw. Możesz mieć wszystko.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ZNAJDŹ SWÓJ ULTRAPRZENOŚNY PROCESOR INTEL® CORE™ DO GIER.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5-11300H jedenastej generacji"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11370H jedenastej generacji"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11375H Special Edition jedenastej generacji"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Nawet <span class='style_badge'>4,4&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu",
                      "tr_td2_title": "Nawet <span class='style_badge'>4,8&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu",
                      "tr_td3_title": "Nawet <span class='style_badge'>5,0&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu"
                    },
                    {
                      "tr_td1_title": "4 rdzenie/ 8&nbsp;wątków",
                      "tr_td2_title": "4 rdzenie/ 8&nbsp;wątków",
                      "tr_td3_title": "4 rdzenie/ 8&nbsp;wątków"
                    },
                    {
                      "tr_td1_title": "Technologia Intel® Turbo Boost",
                      "tr_td2_title": "Technologia Intel® Turbo Boost",
                      "tr_td3_title": "Technologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB pamięci Intel® Smart Cache",
                      "tr_td2_title": "12 MB pamięci Intel® Smart Cache",
                      "tr_td3_title": "12 MB pamięci Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Maksymalne taktowanie do 5,0 GHz, dzięki któremu Twoje rozgrywki będą jeszcze lepsze, oraz ultraprzenośny komputer, dzięki któremu możesz zabrać je wszędzie.",
                "tr_title": "Więcej mocy, mniej&nbsp;kilogramów.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ZNAJDŹ SWÓJ ULTRAPRZENOŚNY PROCESOR INTEL® CORE™ DO GIER.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5-11300H jedenastej generacji"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11370H jedenastej generacji"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11375H Special Edition jedenastej generacji"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Nawet <span class='style_badge'>4,4&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu",
                      "tr_td2_title": "Nawet <span class='style_badge'>4,8&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu",
                      "tr_td3_title": "Nawet <span class='style_badge'>5,0&nbsp;GHz</span> przy maksymal&shy;nym taktowaniu"
                    },
                    {
                      "tr_td1_title": "4 rdzenie/ 8&nbsp;wątków",
                      "tr_td2_title": "4 rdzenie/ 8&nbsp;wątków",
                      "tr_td3_title": "4 rdzenie/ 8&nbsp;wątków"
                    },
                    {
                      "tr_td1_title": "Technologia Intel® Turbo Boost",
                      "tr_td2_title": "Technologia Intel® Turbo Boost",
                      "tr_td3_title": "Technologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB pamięci Intel® Smart Cache",
                      "tr_td2_title": "12 MB pamięci Intel® Smart Cache",
                      "tr_td3_title": "12 MB pamięci Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor Intel® Core™ i7 Special Edition jedenastej generacji umożliwia tworzenie zaawansowanych materiałów, płynne działanie gier oraz rozrywkę nowej generacji na ultraprzenośnych komputerach.",
                "tr_title": "Przełomowa wydajność laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 SPECIAL EDITION JEDENASTEJ&nbsp;GENERACJI"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i7 Special Edition jedenastej generacji umożliwia tworzenie zaawansowanych materiałów, płynne działanie gier oraz rozrywkę nowej generacji na ultraprzenośnych komputerach.",
                "tr_title": "Przełomowa wydajność laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 SPECIAL EDITION JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują ze sobą, uprzyjemniając rywalizację podczas rozgrywki i&nbsp;tworzenia zaawansowanych materiałów multimedialnych.",
                "tr_title": "Zoptymalizowana moc",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Właściwa równowaga między szybkością taktowania a&nbsp;wydajnością rdzeni uprzyjemnia rozgrywkę, tak w&nbsp;grach akcji z&nbsp;dużą liczbą klatek na sekundę, jak i&nbsp;tych o&nbsp;wysokich wymaganiach wydajnościowych.",
                "tr_title": "Moc z&nbsp;przeznaczeniem.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Graj, nagrywaj i&nbsp;streamuj wymagające tytuły z&nbsp;dużą liczbą klatek na sekundę i&nbsp;korzystaj z&nbsp;zaawansowanej wielozadaniowości, nie tracąc na płynności.",
                "tr_title": "Moc przekraczania granic.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują ze sobą, uprzyjemniając rywalizację podczas rozgrywki i&nbsp;tworzenia zaawansowanych materiałów multimedialnych.",
                "tr_title": "Zoptymalizowana moc",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dzięki dużemu zapasowi wydajności możesz łatwo przechodzić od&nbsp;streamowania filmów do tworzenia prezentacji.",
                "tr_title": "Moc na cały dzień",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 z&nbsp;technologią Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i3 z&nbsp;technologią Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ogromna wydajność i&nbsp;długi czas pracy baterii umożliwiają pracę w&nbsp;dowolnym miejscu.",
                "tr_title": "Moc na cały dzień",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 z&nbsp;technologią Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i3 z&nbsp;technologią Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Łatwe przełączanie między wieloma programami pozwala być gotowym na wszystko.",
                "tr_title": "Wielozadaniowość w&nbsp;podróży",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 z&nbsp;technologią Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i5 z&nbsp;technologią Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Teraz wszystko, czym się zajmujesz&nbsp;— od rozrywki, przez pracę biurową, po tworzenie materiałów&nbsp;— możesz przenieść na wyższy poziom.",
                "tr_title": "Zmień komputer na mocniejszy model",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 z&nbsp;technologią Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i5 z&nbsp;technologią Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Wydajność godna desktopa i&nbsp;mobilność zarówno w&nbsp;profesjonalnych rozgrywkach, gdy liczy się każda KL./S, jak i&nbsp;we wciągających grach wysokobudżetowych.",
                "tr_title": "Moc z&nbsp;przeznaczeniem.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Wydajność godna desktopa i&nbsp;mobilność zarówno w&nbsp;profesjonalnych rozgrywkach, gdy liczy się każda KL./S, jak i&nbsp;we wciągających grach wysokobudżetowych.",
                "tr_title": "Moc z&nbsp;przeznaczeniem.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Laptop, na którym można grać, nagrywać i&nbsp;streamować jednocześnie z&nbsp;dużą liczbą KL./S, a&nbsp;także płynnie korzystać z&nbsp;zaawansowanej wielozadaniowości.",
                "tr_title": "Moc przekraczania granic.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Laptop, na którym można grać, nagrywać i&nbsp;streamować jednocześnie z&nbsp;dużą liczbą KL./S, a&nbsp;także płynnie korzystać z&nbsp;zaawansowanej wielozadaniowości.",
                "tr_title": "Moc przekraczania granic.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują, usprawniając e-sportową rywalizację i&nbsp;tworzenie zaawansowanych materiałów multimedialnych w&nbsp;dowolnym miejscu.",
                "tr_title": "Zoptymalizowana moc",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują, usprawniając e-sportową rywalizację i&nbsp;tworzenie zaawansowanych materiałów multimedialnych w&nbsp;dowolnym miejscu.",
                "tr_title": "Zoptymalizowana moc",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie, duża liczba rdzeni i&nbsp;odblokowana wydajność płynnie współpracują, usprawniając e-sportową rywalizację i&nbsp;tworzenie zaawansowanych materiałów multimedialnych w&nbsp;dowolnym miejscu.",
                "tr_title": "Nieograniczona moc. Pełna równowaga.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie, duża liczba rdzeni i&nbsp;odblokowana wydajność płynnie współpracują, usprawniając e-sportową rywalizację i&nbsp;tworzenie zaawansowanych materiałów multimedialnych w&nbsp;dowolnym miejscu.",
                "tr_title": "Nieograniczona moc. Pełna równowaga.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują ze sobą, uprzyjemniając rywalizację podczas rozgrywki i&nbsp;tworzenia zaawansowanych materiałów multimedialnych.",
                "tr_title": "Zoptymalizowana moc",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują ze sobą, usprawniając tworzenie zaawansowanych materiałów multimedialnych w&nbsp;dowolnym miejscu.",
                "tr_title": "Zoptymalizowana moc",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują ze sobą, usprawniając&nbsp;tworzenie zaawansowanych materiałów multimedialnych.",
                "tr_title": "Zoptymalizowana moc",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Uwolnij swój potencjał dzięki niezrównanej obsłudze gier",
                "tr_title": "Rywalizacja na najwyższym poziomie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Uwolnij swój potencjał dzięki niezrównanej obsłudze gier",
                "tr_title": "Rywalizacja na najwyższym poziomie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziesiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DZIESIĄTEJ GENERACJI DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bezproblemowe granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamowanie i&nbsp;nagrywanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tworzenie zaawansowanych materiałów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najwyższa wydajność do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odblokowane procesory z&nbsp;możliwością podkręcania<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 10<br/>Wątki: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dla osób będących stale w&nbsp;podróży – <br/>laptop zaprojektowany specjalnie dla Ciebie.",
                "tr_title": "Oto nowa klasa laptopów",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziesiątej generacji"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują ze sobą, uprzyjemniając rywalizację podczas rozgrywki i&nbsp;tworzenia zaawansowanych materiałów multimedialnych.",
                "tr_title": "Nieograniczona moc. Pełna równowaga.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie, duża liczba rdzeni i&nbsp;odblokowana wydajność płynnie współpracują, usprawniając tworzenie zaawansowanych materiałów multimedialnych w&nbsp;dowolnym miejscu.",
                "tr_title": "Nieograniczona moc. Pełna równowaga.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Szybkie taktowanie i&nbsp;duża liczba rdzeni płynnie współpracują ze sobą, usprawniając&nbsp;tworzenie zaawansowanych materiałów multimedialnych.",
                "tr_title": "Nieograniczona moc. Pełna równowaga.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>jedenastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 JEDENASTEJ&nbsp;GENERACJI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>pamięci Intel® Smart&nbsp;Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Płynna rozgrywka i&nbsp;VR w&nbsp;najbardziej wymagających tytułach",
                "tr_title": "Prawdziwa gra zaczyna się tutaj",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dziewiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Graj, streamuj i&nbsp;nagrywaj bez kompromisów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepszy procesor Intel® Core™ dziewiątej generacji do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 4<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "NAWET 32% WIĘCEJ<br/><span class='tr_subtitle_font'> KL./S podczas grania, streamowania i&nbsp;nagrywania w&nbsp;porównaniu z&nbsp;3-letnim komputerem<sup>3</sup></span>",
                "tr_title": "Moc, dzięki której podzielisz się swoimi chwilami chwały",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziewiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Graj, streamuj i&nbsp;nagrywaj bez kompromisów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepszy procesor Intel® Core™ dziewiątej generacji do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 4<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "NAWET 41% WIĘCEJ <br/><span class='tr_subtitle_font'>KL./S podczas grania, streamowania i&nbsp;nagrywania w&nbsp;porównaniu z&nbsp;3-letnim komputerem<sup>4</sup></span>",
                "tr_title": "Gracze mają potrzeby. Procesor Intel® Core™ i9 je spełnia.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dziewiątej generacji",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Wątki:</span><span class='badgetitle_font'><br/>[XX] </span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Rdzenie:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Nawet <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>pamięci podręcznej</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odblokowano",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ZNAJDŹ PROCESOR INTEL® CORE™ DOPASOWANY DO TWOJEGO STYLU GRY.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Granie w&nbsp;gry wysokobudżetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Obsługa zaawansowanych gier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Graj, streamuj i&nbsp;nagrywaj bez kompromisów",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepszy procesor Intel® Core™ dziewiątej generacji do gier",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 4<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 6<br/>Wątki: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktowanie<sup>2</sup>: do&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Rdzenie: 8<br/>Wątki: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Stworzone do gamingu nowej generacji",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dwunastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DWUNASTEJ&nbsp;GENERACJI"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dwunastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DWUNASTEJ&nbsp;GENERACJI"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dwunastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DWUNASTEJ&nbsp;GENERACJI"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Rdzenie: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Wątki: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] dla Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] dla Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Stanowisko do pracy i&nbsp;gamingu w&nbsp;jednym",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dwunastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DWUNASTEJ&nbsp;GENERACJI"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dwunastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DWUNASTEJ&nbsp;GENERACJI"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Rdzenie: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Wątki: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] dla Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] dla Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Niezawodna wydajność, gdy potrzebujesz jej najbardziej",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 DWUNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Zwiększenie wydajności, tam, gdzie jest najbardziej potrzebna",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DWUNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Zwiększenie wydajności, tam, gdzie jest najbardziej potrzebna",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DWUNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Najwyższa wydajność tam, gdzie jej najbardziej potrzebujesz",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DWUNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Najwyższa wydajność tam, gdzie jej najbardziej potrzebujesz",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DWUNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dzięki laptopom Intel® Evo™ zyskujesz wydajność, wbudowaną inteligencję umożliwiającą prowadzenie wideorozmów, szybszy Internet, długotrwałą pracę na baterii i&nbsp;wiele więcej.",
                "tr_title": "Większa wydajność, tam, gdzie jej najbardziej potrzebujesz.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DWUNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dzięki laptopom Intel® Evo™ zyskujesz wydajność, wbudowaną inteligencję umożliwiającą prowadzenie wideorozmów, szybszy Internet, długotrwałą pracę na baterii i&nbsp;wiele więcej.",
                "tr_title": "Najwyższa wydajność tam, gdzie jej najbardziej potrzebujesz",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DWUNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dzięki laptopom Intel® Evo™ zyskujesz wydajność, wbudowaną inteligencję umożliwiającą prowadzenie wideorozmów, szybszy Internet, długotrwałą pracę na baterii i&nbsp;wiele więcej.",
                "tr_title": "Ekstremalna wydajność w&nbsp;laptopie najwyższej klasy",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DWUNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Z&nbsp;technologią Intel® Turbo Boost 2.0 — przyspieszenie do [max_turbo_frequency] GHz",
                "tr_title": "Idealny dla firm",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DWUNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Tłumienie szumów i&nbsp;rozmywanie tła",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatyczne dostosowywanie wydajności do najczęściej wykonywanych czynności",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Płynne, wysokiej jakości połączenia internetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wbudowane zabezpieczenia sprzętowe",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Możliwość zdalnego zarządzania urządzeniami",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatyczna optymalizacja wydajności&nbsp;Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Moc na potrzeby aplikacji bazodanowych",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Największa produktywność ze&nbsp;zwiększoną wydajnością",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Z&nbsp;technologią Intel® Turbo Boost 2.0 — przyspieszenie do [max_turbo_frequency] GHz",
                "tr_title": "Ciężko pracuje, dzięki czemu Ty możesz skupić się na swojej pracy.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DWUNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Tłumienie szumów i&nbsp;rozmywanie tła",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatyczne dostosowywanie wydajności do najczęściej wykonywanych czynności",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Płynne, wysokiej jakości połączenia internetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wbudowane zabezpieczenia sprzętowe",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Możliwość zdalnego zarządzania urządzeniami",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatyczna optymalizacja wydajności&nbsp;Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Moc na potrzeby aplikacji bazodanowych",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Największa produktywność ze&nbsp;zwiększoną wydajnością",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Z&nbsp;technologią Intel® Turbo Boost 2.0 — przyspieszenie do [max_turbo_frequency] GHz",
                "tr_title": "Zwiększ obroty",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>dwunastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DWUNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Tłumienie szumów i&nbsp;rozmywanie tła",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatyczne dostosowywanie wydajności do najczęściej wykonywanych czynności",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Płynne, wysokiej jakości połączenia internetowe",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wbudowane zabezpieczenia sprzętowe",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Możliwość zdalnego zarządzania urządzeniami",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatyczna optymalizacja wydajności&nbsp;Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Moc na potrzeby aplikacji bazodanowych",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Największa produktywność ze&nbsp;zwiększoną wydajnością",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Niezawodna wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 <br/>trzynastej generacji",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Prawdziwa wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 TRZYNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Prawdziwa wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 TRZYNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Wysoka wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 TRZYNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Wysoka wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 TRZYNASTEJ&nbsp;GENERACJI",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Więcej niż wydajność",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 TRZYNASTEJ&nbsp;GENERACJI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Rdzenie: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Wątki: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] dla Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] dla Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bezproblemowo łącz się ze smartfonem<sup>24</sup>, inteligentnie wybieraj najlepszą sieć Wi-Fi i&nbsp;szybko ładuj urządzenie z&nbsp;baterią o&nbsp;dużej pojemności.",
                "tr_title": "Więcej niż wydajność na potrzeby szybszej pracy w&nbsp;dowolnym miejscu",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 TRZYNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bezproblemowo łącz się ze smartfonem<sup>24</sup>, inteligentnie wybieraj najlepszą sieć Wi-Fi i&nbsp;szybko ładuj urządzenie z&nbsp;baterią o&nbsp;dużej pojemności.",
                "tr_title": "Więcej niż wydajność na potrzeby usprawnionej wielozadaniowości komputera",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 TRZYNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bezproblemowo łącz się ze smartfonem<sup>24</sup>, inteligentnie wybieraj najlepszą sieć Wi-Fi i&nbsp;szybko ładuj urządzenie z&nbsp;baterią o&nbsp;dużej pojemności.",
                "tr_title": "Więcej niż wydajność, aby szybko wykonywać najbardziej wymagające zadania",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 TRZYNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Więcej niż wydajność",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>trzynastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 TRZYNASTEJ&nbsp;GENERACJI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>trzynastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 TRZYNASTEJ&nbsp;GENERACJI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>trzynastej generacji",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 TRZYNASTEJ&nbsp;GENERACJI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Rdzenie: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Wątki: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] dla Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] dla Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Więcej niż wydajność",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Rdzenie: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Wątki: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] dla Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] dla Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Niezawodna wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Prawdziwa wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Wysoka wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Więcej niż wydajność",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Rdzenie: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Wątki: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] dla Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] dla Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Niezawodna wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Prawdziwa wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Wysoka wydajność, aby móc więcej zdziałać",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ 7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ 7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Gra bez kompromisów",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Rdzenie: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core]&nbsp;LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Wątki: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] dla Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] dla Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] dla LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gra bez kompromisów",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gra bez kompromisów",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gra bez kompromisów",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gra bez kompromisów",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gra bez kompromisów",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gra bez kompromisów",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Małe firmy też zasługują na&nbsp;najlepsze rozwiązania",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 TRZYNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Tłumienie szumów i&nbsp;rozmywanie tła",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatyczne dostosowywanie wydajności do najczęściej wykonywanych czynności",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Płynne, wysokiej jakości połączenia internetowe",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Wbudowane zabezpieczenia sprzętowe",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomaga chronić urządzenia i&nbsp;dane przed fizyczną ingerencją",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Małe firmy też zasługują na&nbsp;najlepsze rozwiązania",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 TRZYNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Tłumienie szumów i&nbsp;rozmywanie tła",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatyczne dostosowywanie wydajności do najczęściej wykonywanych czynności",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Płynne, wysokiej jakości połączenia internetowe",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Wbudowane zabezpieczenia sprzętowe",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomaga chronić urządzenia i&nbsp;dane przed fizyczną ingerencją",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Małe firmy też zasługują na&nbsp;najlepsze rozwiązania",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 <br/>trzynastej generacji",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 TRZYNASTEJ&nbsp;GENERACJI",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "JAK TEN PROCESOR WYPADA NA TLE INNYCH?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Bieżące urządzenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Tłumienie szumów i&nbsp;rozmywanie tła",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatyczne dostosowywanie wydajności do najczęściej wykonywanych czynności",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Płynne, wysokiej jakości połączenia internetowe",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Wbudowane zabezpieczenia sprzętowe",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomaga chronić urządzenia i&nbsp;dane przed fizyczną ingerencją",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Uruchamianie nowej mocy AI dla firm",
                "tr_title": "Bądź o&nbsp;krok do przodu dzięki procesorom Intel®&nbsp;Core™&nbsp;Ultra&nbsp;[XX] oraz&nbsp;Intel&nbsp;vPro®",
                "tr_gen_core_title": "Procesor Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "ROZWIJAJ FIRMĘ DZIĘKI KOMPUTEROM AI FIRMY INTEL",
                    "tr_badgeSubTitle": "Wydajne komputery zapewniające doskonałe doświadczenie są kluczem do zwiększenia wydajności biznesowej. Intel vPro® zwiększa efektywność pracowników i&nbsp;wydajność uruchamianych obciążeń oraz aplikacji dzięki nowej architekturze zapewniającej najwyższą wydajność i&nbsp;energooszczędność na potrzeby szeregu złożonych obciążeń biznesowych poprzez wysyłanie odpowiedniego zadania do odpowiedniego silnika we właściwym czasie.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "GOTOWE DO UŻYCIA FUNKCJE WIELOWARSTWOWYCH ZABEZPIECZEŃ",
                    "tr_badgeSubTitle": "Nowe i przyszłe zagrożenia wymagają kompleksowego podejścia do bezpieczeństwa. Intel vPro® zapewnia zabezpieczenia na poziomie sprzętu i całego stosu, lepiej chroniąc firmę. Unikatowe, gotowe rozwiązania bezpieczeństwa, w tym funkcje wykrywania zagrożeń wykorzystujące sztuczną inteligencję, chronią użytkowników, dane i firmę.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "WBUDOWANE NARZĘDZIA NA POTRZEBY PRODUKTYWNOŚCI UŻYTKOWNIKÓW Z&nbsp;PRAKTYCZNIE DOWOLNEGO MIEJSCA",
                    "tr_badgeSubTitle": "Biuro jest tam, gdzie Twój komputer. Intel vPro® zapewnia nowoczesne możliwości zarządzania oparte na sprzęcie, ułatwiające firmom zarządzanie flotą komputerów, uproszczenie procesu pomocy technicznej oraz poprawę doświadczeń użytkowników końcowych, jednocześnie umożliwiając wdrożenie zrównoważonych procesów obliczeniowych.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Nie tylko wyjątkowy komputer, to komputer AI",
                "tr_subtitle1": "Z PROCESORAMI INTEL® CORE™ ULTRA (SERII 2)",
                "tr_subtitle2": "Już teraz możliwe na Twoim komputerze Intel AI",
                "tr_subtitle": "Najbardziej wydajny, bezpieczny i skuteczny procesor firmy Intel do urządzeń przenośnych<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Serii&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Większa kreatywność</span> dzięki narzędziom AI do szybszego tworzenia"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Większa produktywność</span> dzięki oszczędzającym czas funkcjom sztucznej inteligencji"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Większe bezpieczeństwo</span> dzięki wbudowanym zabezpieczeniom danych"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Nie tylko wyjątkowy komputer, to komputer AI",
                "tr_subtitle1": "Z PROCESORAMI INTEL® CORE™ ULTRA (SERII 2)",
                "tr_subtitle2": "Już teraz możliwe na Twoim komputerze Intel AI",
                "tr_subtitle": "Najbardziej wydajny, bezpieczny i skuteczny procesor firmy Intel do urządzeń przenośnych<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Serii&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Większa kreatywność</span> dzięki narzędziom AI do szybszego tworzenia"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Większa produktywność</span> dzięki oszczędzającym czas funkcjom sztucznej inteligencji"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Większe bezpieczeństwo</span> dzięki wbudowanym zabezpieczeniom danych"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Nie tylko wyjątkowy komputer, to komputer AI",
                "tr_subtitle1": "Z PROCESORAMI INTEL® CORE™ ULTRA (SERII 2)",
                "tr_subtitle2": "Już teraz możliwe na Twoim komputerze Intel AI",
                "tr_subtitle": "Najbardziej wydajny, bezpieczny i skuteczny procesor firmy Intel do urządzeń przenośnych<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Serii&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Nie tylko wyjątkowy komputer, to komputer AI",
                "tr_subtitle1": "Z PROCESORAMI INTEL® CORE™ ULTRA (SERII 2)",
                "tr_subtitle2": "Już teraz możliwe na Twoim komputerze Intel AI",
                "tr_subtitle": "Najbardziej wydajny, bezpieczny i skuteczny procesor firmy Intel do urządzeń przenośnych<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Serii&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_tileTitle1": "Dedykowany układ graficzny",
                "tr_title": "CZYM JEST PROCESOR GRAFICZNY?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Procesor graficzny (GPU) tworzy obraz wyświetlany na ekranie.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Zintegrowany procesor graficzny",
                    "tr_badgeSubTitle": "Zintegrowana karta graficzna korzysta z&nbsp;tej samej pamięci RAM, co reszta komputera, co czyni ją bardziej energooszczędnym.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedykowany procesor graficzny",
                    "tr_badgeSubTitle": "To najbardziej wydajna klasa kart graficznych – ma własną pamięć RAM i&nbsp;inne zasoby przeznaczone do tworzenia niesamowitej grafiki.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybrydowa karta graficzna",
                    "tr_badgeSubTitle": "To najbardziej wydajna klasa kart graficznych – ma własną pamięć RAM i&nbsp;inne zasoby przeznaczone do tworzenia niesamowitej grafiki.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Usprawniona edycja zdjęć i&nbsp;wideo",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Oglądaj najwyższej jakości materiały wideo w&nbsp;rozdzielczości 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Graj w&nbsp;popularne gry z&nbsp;niesamowitą szczegółowością",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Dwa procesory graficzne",
                    "tr_badgeSubTitle": "Niektóre systemy, takie jak te z grafiką Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> mogą korzystać zarówno z wbudowanego, jak i dedykowanego procesora graficznego, aby rozdysponować nakład pracy.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Zintegrowany procesor graficzny",
                    "tr_badgeSubTitle": "Zintegrowana karta graficzna jest wbudowana w&nbsp;ten sam układ, co główny procesor. Taki rodzaj kart graficznych wykorzystuje tę samą pamięć RAM i&nbsp;inne zasoby, co pozostałe podzespoły, dzięki czemu zużywa mniej energii, a&nbsp;wykorzystujące go urządzenia są lżejsze i&nbsp;smuklejsze.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedykowany procesor graficzny",
                    "tr_badgeSubTitle": "To najbardziej wydajna klasa kart graficznych – ma własną pamięć RAM i&nbsp;inne zasoby przeznaczone do tworzenia niesamowitej grafiki.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Nawet 4&nbsp;GB pamięci VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wysoka",
                    "tr_badgeSubTitle": "Nawet 8&nbsp;GB pamięci VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Ponad 8&nbsp;GB pamięci VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Znakomita moc graficzna",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga karty graficznej przeznaczonej wyłącznie do tworzenia pięknych, wyrazistych obrazów i&nbsp;płynnych animacji.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Wbudowana bogata i&nbsp;żywa grafika",
                "tr_pretitle": "Grafika Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafika Intel® Iris® Plus pozwala zanurzyć się w&nbsp;filmach 4K HDR i&nbsp;grach Full HD bez zewnętrznej karty graficznej.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Oszałamiające efekty wizualne, bezproblemowa wydajność",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafika Intel® to transformacyjna wydajność zintegrowanej karty graficznej do płynnej obsługi projektowania dla twórców i bogatych wrażeń z grania.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Grafika Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Grafika UHD Intel® z&nbsp;architekturą&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Niesamowite doznania wizualne",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Ciesz się usprawnioną edycją zdjęć i&nbsp;filmów, oglądaj najwyższej jakości filmy w&nbsp;rozdzielczości 4K lub graj w&nbsp;popularne gry z&nbsp;niesamowitą szczegółowością.",
                "tr_photo_text": "Usprawniona edycja zdjęć i&nbsp;wideo",
                "tr_video_text": "Oglądaj najwyższej jakości materiały wideo w&nbsp;rozdzielczości 4K",
                "tr_game_text": "Graj w&nbsp;popularne gry z&nbsp;niesamowitą szczegółowością",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "DUŻO WIĘCEJ NIŻ ŁADNY OBRAZ",
                "tr_subtitle": "Dzięki ścisłej współpracy z&nbsp;procesorem (lub CPU) karta graficzna (lub GPU) pomaga określić sposób działania gier i&nbsp;ich wygląd. Pamięć VRAM lub pamięć Video RAM, przeznaczona wyłącznie do tworzenia grafiki, pozwala szybko sprawdzić, jaką wydajność ma karta graficzna.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "KARTA GRAFICZNA DO ZAAWANSOWANYCH GIER",
                "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga osobnej karty graficznej przeznaczonej wyłącznie do tworzenia wyjątkowych obrazów i&nbsp;płynnych animacji",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "KARTA GRAFICZNA DO ZAAWANSOWANYCH GIER",
                "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga osobnej karty graficznej przeznaczonej wyłącznie do tworzenia wyjątkowych obrazów i&nbsp;płynnych animacji",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "DUŻO WIĘCEJ NIŻ ŁADNY OBRAZ",
                "tr_subtitle": "Dzięki ścisłej współpracy z&nbsp;procesorem (lub CPU) karta graficzna (lub GPU) pomaga określić sposób działania gier i&nbsp;ich wygląd. Pamięć VRAM lub pamięć Video RAM, przeznaczona wyłącznie do tworzenia grafiki, pozwala szybko sprawdzić, jaką wydajność ma karta graficzna.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Znakomita moc graficzna",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga karty graficznej przeznaczonej wyłącznie do tworzenia pięknych, wyrazistych obrazów i&nbsp;płynnych animacji.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "KARTA GRAFICZNA DO ZAAWANSOWANYCH GIER",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga karty graficznej przeznaczonej wyłącznie do tworzenia pięknych, wyrazistych obrazów i&nbsp;płynnych animacji.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga osobnej karty graficznej przeznaczonej wyłącznie do tworzenia wyjątkowych obrazów i&nbsp;płynnych animacji",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Zapewnia dużą moc grafiki, aby spełnić minimalne wymagania gry.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Dla graczy wysokiej klasy, którzy grają na najwyższym poziomie",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Dla zaawansowanych graczy, którzy na komputerze tworzą również zaawansowane materiały multimedialne",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Niesamowita grafika i&nbsp;realistyczne gry",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Wykorzystaj potencjał wysokiej rozdzielczości ekranu za pomocą wbudowanej grafiki HD Intel®. Dzięki niemu otrzymasz niezwykle bogate możliwości odtwarzania multimediów i&nbsp;przetwarzania grafiki.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Zapewnianie żywych obrazów i&nbsp;filmów",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Uzyskaj więcej szczegółów i&nbsp;wspaniałe kolory ze streamowanych filmów i&nbsp;zdjęć.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Niesamowite możliwości tworzenia i&nbsp;doznania z&nbsp;gier",
                "tr_pretitle": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> zapewnia rewolucyjną wydajność grafiki na smukłych i&nbsp;lekkich komputerach, począwszy od wciągającej rozrywki i&nbsp;tworzenia zaawansowanych materiałów, po granie w&nbsp;popularne gry.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_pretitle": "KARTA GRAFICZNA DO ZAAWANSOWANYCH GIER",
                "tr_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga osobnej karty graficznej przeznaczonej wyłącznie do tworzenia wyjątkowych obrazów i&nbsp;płynnych animacji",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]&nbsp;GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Uwolnij swoją kreatywność",
                "tr_pretitle": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Suń przez kreatywne projekty i&nbsp;daj się wciągnąć szerokiej gamie gier dzięki połączonej mocy grafiki wbudowanej i&nbsp;pierwszego dedykowanego silnika graficznego Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "KARTA GRAFICZNA DO ZAAWANSOWANYCH GIER",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Najwydajniejsze przetwarzanie graficzne wymaga osobnej karty graficznej przeznaczonej wyłącznie do tworzenia wyjątkowych obrazów i&nbsp;płynnych animacji",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]&nbsp;GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Karta graficzna",
                "tr_title": "Niezwykłe możliwości wbudowanego układu graficznego",
                "tr_subtitle": "Graj w gry oraz oglądaj filmy i video w sieci w niezwykłej jakości i liczbie szczegółów.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Karta graficzna",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Karta graficzna",
                    "tr_specText": "Usprawnij swoje przepływy pracy"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Karta graficzna",
                    "tr_specText": "Doładuj rozgrywkę"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Karta graficzna",
                    "tr_specText": "Doładuj rozgrywkę"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Karta graficzna",
                    "tr_specText": "Nowy poziom rozgrywki"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "UWOLNIJ SWOJĄ KREATYWNOŚĆ",
                    "tr_specText": "Przyspiesz produktywność niezależnie od lokalizacji dzięki najbardziej kompleksowym możliwościom multimedialnym, takim jak <strong>pierwsze w&nbsp;branży kodowanie sprzętowe AV1</strong> i&nbsp;przyspieszane przez sztuczną inteligencję tworzenie treści<sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "UWOLNIJ SWOJĄ KREATYWNOŚĆ",
                    "tr_specText": "Przyspiesz produktywność dzięki najbardziej kompleksowym możliwościom multimedialnym, takim jak <strong>pierwsze w&nbsp;branży kodowanie sprzętowe AV1</strong> i&nbsp;przyspieszane przez sztuczną inteligencję tworzenie treści<sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "UWOLNIJ SWOJĄ KREATYWNOŚĆ",
                    "tr_specText": "Ciesz się najnowocześniejszymi możliwościami przyspieszanego przez sztuczną inteligencję tworzenia treści dzięki <strong>pierwszemu w&nbsp;branży kodowaniu sprzętowemu AV1</strong> i&nbsp;zaawansowanemu <strong>silnikowi multimedialnemu X<sup>e</sup></strong><sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "UWOLNIJ SWOJĄ KREATYWNOŚĆ",
                    "tr_specText": "Ciesz się najnowocześniejszymi możliwościami przyspieszanego przez sztuczną inteligencję tworzenia treści dzięki <strong>pierwszemu w&nbsp;branży kodowaniu sprzętowemu AV1</strong> i&nbsp;zaawansowanemu <strong>silnikowi multimedialnemu X<sup>e</sup></strong><sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "UWOLNIJ SWOJĄ KREATYWNOŚĆ",
                    "tr_specText": "Ciesz się najnowocześniejszymi możliwościami przyspieszanego przez sztuczną inteligencję tworzenia treści dzięki <strong>pierwszemu w&nbsp;branży kodowaniu sprzętowemu AV1</strong> i&nbsp;zaawansowanemu <strong>silnikowi multimedialnemu X<sup>e</sup></strong><sup>22</sup>."
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "MOBILNOŚĆ Z&nbsp;WYDAJNOŚCIĄ",
                    "tr_specText": "Graj w&nbsp;podróży w&nbsp;najnowsze gry i&nbsp;uzyskaj doładowaną wydajność i&nbsp;wierność obrazu dzięki nowoczesnym możliwościom grania. <br/><br/>Intel® <strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>ulepszona przez sztuczną inteligencję technologia skalowania Intel® zapewniająca większą wydajność i&nbsp;płynność gry. <br/><br/>Obsługa <strong>DirectX*&nbsp;12 Ultimate:  </strong>odkryj nowy poziom realizmu w&nbsp;grach dzięki technologiom graficznym takim jak śledzenie promieni i&nbsp;cieniowanie ze&nbsp;zmienną częstotliwością."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "PRZEJMIJ KONTROLĘ NAD ROZGRYWKĄ",
                    "tr_specText": "<strong>Panel sterowania Intel® Arc™</strong> umożliwia udoskonalanie wrażeń z&nbsp;gry w&nbsp;intuicyjnej i&nbsp;nowoczesnej formie. Uzyskaj płynne aktualizacje sterowników, optymalizuj swoje gry i&nbsp;nie tylko."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "PRZEJMIJ KONTROLĘ NAD ROZGRYWKĄ",
                    "tr_specText": "<strong>Panel sterowania Intel® Arc™</strong> umożliwia udoskonalanie wrażeń z&nbsp;gry w&nbsp;intuicyjnej i&nbsp;nowoczesnej formie. Uzyskaj płynne aktualizacje sterowników, optymalizuj swoje gry i&nbsp;nie tylko."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "PRZEJMIJ KONTROLĘ NAD ROZGRYWKĄ",
                    "tr_specText": "Uzyskaj bezproblemowe aktualizacje sterowników i&nbsp;zoptymalizuj wiele ulubionych gier przy pomocy <strong>panelu sterowania Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAKSYMALNIE WYKORZYSTAJ PLATFORMĘ INTEL®",
                    "tr_specText": "Połącz ją z&nbsp;kompatybilnymi procesorami Intel® Core™, aby móc zwiększyć możliwości tworzenia, grania i&nbsp;streamowania przy pomocy <strong>technologii Intel® Deep Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAKSYMALNIE WYKORZYSTAJ SYSTEM INTEL®",
                    "tr_specText": "Połącz ją z&nbsp;kompatybilnymi procesorami Intel® Core™, aby móc zwiększyć możliwości tworzenia, grania i&nbsp;streamowania przy pomocy <strong>technologii Intel® Deep Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAKSYMALNIE WYKORZYSTAJ PLATFORMĘ INTEL®",
                    "tr_specText": "Połącz ją z&nbsp;kompatybilnymi procesorami Intel® Core™, aby móc zwiększyć możliwości tworzenia, grania i&nbsp;streamowania przy pomocy <strong>technologii Intel® Deep Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSYMALNIE WYKORZYSTAJ PLATFORMĘ INTEL®",
                    "tr_specText": "Połącz ją z&nbsp;kompatybilnymi procesorami Intel® Core™, aby móc zwiększyć możliwości tworzenia, grania i&nbsp;streamowania przy pomocy <strong>technologii Intel® Deep Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSYMALNIE WYKORZYSTAJ SYSTEM INTEL®",
                    "tr_specText": "Połącz ją z&nbsp;kompatybilnymi procesorami Intel® Core™, aby móc zwiększyć możliwości tworzenia, grania i&nbsp;streamowania przy pomocy <strong>technologii Intel® Deep Link</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "BĄDŹ W&nbsp;CENTRUM UWAGI",
                    "tr_specText": "Przesyłaj bezproblemowo dzięki zaawansowanym technologiom streamowania zapewnianym przez oprogramowanie <strong>panelu sterowania Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "BĄDŹ W&nbsp;CENTRUM UWAGI",
                    "tr_specText": "Przesyłaj bezproblemowo dzięki zaawansowanym technologiom streamowania zapewnianym przez oprogramowanie <strong>panelu sterowania Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "CIESZ SIĘ WCIĄGAJĄCĄ ROZGRYWKĄ Z&nbsp;DUŻĄ WYDAJNOŚCIĄ",
                    "tr_specText": "Stawiaj swoje najlepsze wyniki na pierwszym miejscu w&nbsp;najnowszych grach wysokobudżetowych o&nbsp;wysokich rozdzielczościach i&nbsp;częstotliwościach odświeżania. <br/><br/>Intel® <strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>ulepszona przez sztuczną inteligencję technologia skalowania Intel® zapewniająca większą wydajność i&nbsp;płynność gry. <br/><br/>Obsługa <strong>DirectX*&nbsp;12 Ultimate:  </strong>odkryj nowy poziom realizmu w&nbsp;grach dzięki technologiom graficznym takim jak śledzenie promieni i&nbsp;cieniowanie ze&nbsp;zmienną częstotliwością."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "CIESZ SIĘ WCIĄGAJĄCĄ ROZGRYWKĄ Z&nbsp;DUŻĄ WYDAJNOŚCIĄ",
                    "tr_specText": "Stawiaj swoje najlepsze wyniki na pierwszym miejscu w&nbsp;najnowszych grach wysokobudżetowych o&nbsp;wysokich rozdzielczościach i&nbsp;częstotliwościach odświeżania. <br/><br/>Intel® <strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>ulepszona przez sztuczną inteligencję technologia skalowania Intel® zapewniająca większą wydajność i&nbsp;płynność gry. <br/><br/>Obsługa <strong>DirectX*&nbsp;12 Ultimate:  </strong>odkryj nowy poziom realizmu w&nbsp;grach dzięki technologiom graficznym takim jak śledzenie promieni i&nbsp;cieniowanie ze&nbsp;zmienną częstotliwością."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "NADAJ GROM NOWY WYMIAR",
                    "tr_specText": "Odkryj nową jakość rozgrywki dzięki: Intel® <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>ulepszonej przez sztuczną inteligencję technologii skalowania Intel® zapewniającej większą wydajność i&nbsp;płynność gry. <br/><br/>Obsługa <strong>DirectX*&nbsp;12 Ultimate:  </strong>odkryj nowy poziom realizmu w&nbsp;grach dzięki technologiom graficznym takim jak śledzenie promieni i&nbsp;cieniowanie ze&nbsp;zmienną częstotliwością."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "CIESZ SIĘ ZAAWANSOWANĄ DYNAMIKĄ ROZGRYWKI",
                    "tr_specText": "Ciesz się płynną rozgrywką w&nbsp;wielu popularnych grach wysokobudżetowych oraz wysoką częstotliwością odświeżania wymagających strzelanek e-sportowych po szybkie hity RTS. <br/><br/>Intel® <strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>ulepszona przez sztuczną inteligencję technologia skalowania Intel® zapewniająca większą wydajność i&nbsp;płynność gry. <br/><br/>Obsługa <strong>DirectX*&nbsp;12 Ultimate:  </strong>odkryj nowy poziom realizmu w&nbsp;grach dzięki technologiom graficznym takim jak śledzenie promieni i&nbsp;cieniowanie ze&nbsp;zmienną częstotliwością."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "CIESZ SIĘ WCIĄGAJĄCĄ ROZGRYWKĄ Z&nbsp;DUŻĄ WYDAJNOŚCIĄ",
                    "tr_specText": "Stawiaj swoje najlepsze wyniki na pierwszym miejscu w&nbsp;najnowszych grach wysokobudżetowych o&nbsp;wysokich rozdzielczościach i&nbsp;częstotliwościach odświeżania. <br/><br/>Intel® <strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>ulepszona przez sztuczną inteligencję technologia skalowania Intel® zapewniająca większą wydajność i&nbsp;płynność gry. <br/><br/>Obsługa <strong>DirectX*&nbsp;12 Ultimate:  </strong>odkryj nowy poziom realizmu w&nbsp;grach dzięki technologiom graficznym takim jak śledzenie promieni i&nbsp;cieniowanie ze&nbsp;zmienną częstotliwością."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Karta graficzna",
                    "tr_specText": "Makrokosmos grafiki dla każdego"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ZWIĘKSZ LICZBĘ KLATEK, PRZYSPIESZ SWOJĄ GRĘ",
                    "tr_specText": "Technologia Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) wnosi rozgrywkę na wyższy poziom dzięki skalowaniu wspomaganemu sztuczną inteligencją, aby zapewnić wysoką wydajność i wierność obrazu. Technologia X<sup class='small_text_sup_xe'>e</sup>SS została zoptymalizowana pod kątem produktów z linii grafiki Intel® Arc™, dzięki czemu można w pełni wykorzystać przyspieszenie sprzętowe Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "NAJLEPSZE WRAŻENIA WIZUALNE JUŻ TU SĄ",
                    "tr_specText": "Dzięki pełnej obsłudze DirectX* 12 Ultimate we wszystkich grafikach Intel® Arc™ można cieszyć się oszałamiającą, wciągającą rozgrywką wykorzystującą najnowsze technologie graficzne, takie jak śledzenie promieni, cieniowanie ze zmienną częstotliwością, cieniowanie siatkowe i teksturowanie ze sprzężeniem zwrotnym (sampler feedback) – podstawowymi cechami gier nowej generacji."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "BEZPROBLEMOWE TWORZENIE ZDJĘĆ, FILMÓW I MATERIAŁÓW MULTIMEDIALNYCH",
                    "tr_specText": "Uwolnij swoją wyobraźnię i zachwyć odbiorców olbrzymimi możliwościami tworzenia treści cyfrowych za pomocą hiperzaawansowanego silnika multimedialnego, wspomaganego przez sztuczną inteligencję i przyspieszonego przez technologię Intel®. Twórz spektakularne treści dzięki grafice z obsługą wszystkich popularnych obecnie formatów multimedialnych, jednocześnie zapewniając dostęp do najbardziej zaawansowanych możliwości kodowania wideo AV1."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "DOŚWIADCZ NAJNOWOCZEŚNIEJSZYCH TECHNOLOGII SZTUCZNEJ INTELIGENCJI DO TWORZENIA I GRANIA",
                    "tr_specText": "Grafika Intel® Arc™ odblokowuje potężne możliwości sztucznej inteligencji, od zaawansowanego tworzenia po wciągające granie. Wykorzystaj w pełni dzisiejsze zaawansowane oprogramowanie do tworzenia, w tym generowanie obrazu na podstawie tekstu i edycję filmów przyspieszoną sztuczną inteligencją. Ponadto ciesz się rozgrywką w wysokiej rozdzielczości dzięki przyspieszonemu przez sztuczną inteligencję skalowaniu X<sup class='small_text_sup_xe'>e</sup>SS."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Karta graficzna",
                    "tr_specText": "Tu zaczyna się profesjonalna grafika"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "ŚWIETNA DLA MOBILNYCH TWÓRCÓW, PROJEKTANTÓW I INŻYNIERÓW",
                    "tr_specText": "Wbudowana grafika Intel® Arc™ Pro dostępna w wybranych procesorach Intel® Core™ Ultra z serii H, 2-krotnie poprawia wydajność graficzną, a certyfikaty niezależnych dostawców oprogramowania (ISV) zapewniają niezawodność i wydajne przyspieszenie sprzętowe sztucznej inteligencji i śledzenia promieni do tworzenia oszałamiających materiałów w podróży.<br/><br/><span class='expandable_heading'>Certyfikaty oprogramowania<br/></span><br/>Intel od lat ściśle współpracuje z setkami firm programistycznych i wykorzystuje to niezrównane doświadczenie do opracowania kart graficznych Intel® Arc™ Pro z serii A. Certyfikaty są dla nas równie ważne jak dla użytkowników."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "UDOSTĘPNIANIE AI",
                    "tr_specText": "Ta seria procesorów graficznych obejmuje szeroki wybór znakomitych technologii, w tym wsparcie dla zgodnych narzędzi AI z wbudowanym dedykowanym przyspieszeniem AI w zależności od potrzeb."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "ŚLEDZENIE PROMIENI BEZ NADMIERNYCH WYDATKÓW",
                    "tr_specText": "Zadaniem kart graficznych jest nie tylko przesyłanie pikseli na wyświetlacze, lecz także przyspieszanie tworzenia atrakcyjnych obrazów. Wbudowana sprzętowa technologia śledzenia promieni grafiki Intel® Arc™ Pro z serii A umożliwia obsługę tych nowych, profesjonalnych zadań przez zgodne oprogramowanie. Uważa się zazwyczaj, że śledzenie promieni jest dostępne tylko w drogich kartach graficznych, ale najnowsza oferta profesjonalnych kart graficznych Intel® zaprzecza tym oczekiwaniom."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "ZWIĘKSZANIE MOŻLIWOŚCI TWÓRCÓW DZIĘKI WCIĄGAJĄCYM WYŚWIETLACZOM",
                    "tr_specText": "Dzięki pełnej obsłudze DirectX* 12 Ultimate we wszystkich grafikach Intel® Arc™ można cieszyć się oszałamiającą, wciągającą rozgrywką wykorzystującą najnowsze technologie graficzne, takie jak śledzenie promieni, cieniowanie ze zmienną częstotliwością, cieniowanie siatkowe i teksturowanie ze sprzężeniem zwrotnym (sampler feedback) – podstawowymi cechami gier nowej generacji."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMICZNIE OPTYMALIZOWANE OBRAZY",
                    "tr_specText": "Dedykowane sprzętowe dekodowanie procesorów graficznych Pro umożliwia uzyskanie większej głębi, lepszego kontrastu i większej liczby kolorów na zgodnym wyświetlaczu z zastosowaniem standardu Dolby Vision*. Dolby Vision* umożliwia wykorzystanie pełnego potencjału technologii HDR poprzez dynamiczną optymalizację jakości obrazu w zależności od usługi, wyświetlacza i platformy, aby za każdym razem zagwarantować zachwycające efekty wizualne."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "OPROGRAMOWANIE SPRAWDZONE PRZEZ EKSPERTÓW",
                    "tr_specText": "Intel od lat ściśle współpracuje z setkami firm programistycznych i wykorzystuje to niezrównane doświadczenie do opracowania profesjonalnej grafiki Intel® Arc™ Pro z serii A. Certyfikaty i kwalifikacje pracy oprogramowania są dla nas równie ważne jak dla użytkowników."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "ŚLEDZENIE PROMIENI BEZ NADMIERNYCH WYDATKÓW",
                    "tr_specText": "Zadaniem kart graficznych jest nie tylko przesyłanie pikseli na wyświetlacze, lecz także przyspieszanie tworzenia atrakcyjnych obrazów. Wbudowana sprzętowa technologia śledzenia promieni grafiki Intel® Arc™ Pro z serii A umożliwia obsługę tych nowych, profesjonalnych zadań przez zgodne oprogramowanie. Uważa się zazwyczaj, że śledzenie promieni jest dostępne tylko w drogich kartach graficznych, ale najnowsza oferta profesjonalnych kart graficznych Intel® zaprzecza tym oczekiwaniom."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "WIĘKSZA LICZBA DUŻYCH WYŚWIETLACZY Z POJEDYNCZYM MAŁYM PROCESOREM GRAFICZNYM DO URZĄDZEŃ PRZENOŚNYCH",
                    "tr_specText": "Grafika Intel® Arc™ Pro do laptopów może obsługiwać do czterech bardzo dużych wyświetlaczy, aby zwiększyć wydajność procesów roboczych. Obsługa standardu High Dynamic Range (HDR) uzupełnia konfigurację na potrzeby mediów społecznościowych nowej generacji lub pracy biurowej na kilku wyświetlaczach."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "PEŁNY POTENCJAŁ PRZEPUSTOWOŚCI PAMIĘCI",
                    "tr_specText": "Przepustowość karty graficznej ma duże znaczenie w przypadku stacji roboczej. Zbyt mała może wpłynąć na spowolnienie profesjonalnych przepływów pracy, dlatego procesory graficzne Intel® Arc™ Pro z serii A obsługują nowoczesny standard PCIe* 4.0, jednocześnie zapewniając zgodność z wcześniejszymi rozwiązaniami. Potencjał dużej pojemności pamięci grafiki jest zwiększany przez wysoką przepustowość, umożliwiając jeszcze szybszy dostęp oprogramowania do danych projektu."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "INTENSYWNOŚĆ WRAŻEŃ W KAŻDYM KADRZE",
                    "tr_specText": "<span class='expandable_heading'>Potencjał AI. Wsparcie Intela.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS to technologia skalowania wzmocniona sztuczną inteligencją, która zwiększa wydajność bez poświęcania piękna każdej klatki. Dzięki temu można doświadczać rozgrywki tak, jak należy."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "RZECZYWISTY CZAS.<br/> RZECZYWISTE EMOCJE.<br/> RZECZYWISTOŚĆ W NAJLEPSZYM WYDANIU.",
                    "tr_specText": "Bardziej realistyczne światy czekają. Dzięki wbudowanemu śledzeniu promieni doświadczysz wszystkiego, od realistycznych cieni i odblasków po mistrzowskie oświetlenie. Innymi słowy, to wrażenia wizualne z grania, których nie chcesz przegapić."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "NAJLEPSZA ROZGRYWKA W NAJLEPSZYM WYDANIU",
                    "tr_specText": "Zapewnij odbiorcom płynną rozgrywkę, na którą zasługują. Dzięki przyspieszonym sprzętowo możliwościom kodowania AV1 grafiki Intel® Arc™ zachwycisz ich każdym ruchem. Wyższa jakość wizualna przy tej samej szybkości transmisji oznacza, że możesz uzyskać najwyższą jakość streamowania przy zachowaniu przepustowości."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Witamy wszystkich twórców",
                    "tr_specText": "Nowe i ulepszone doświadczenia ze sztuczną inteligencją są dostępne na wyciągnięcie ręki. Dzięki zaawansowanym akceleratorom sztucznej inteligencji wbudowanym w grafikę Intel® Arc™ z serii A można spędzać więcej czasu na tworzeniu i mniej na czekaniu w wielu aplikacjach.<br/><br/>Wynieś swoje możliwości tworzenia filmów na wyższy poziom i zdobądź przewagę z wideo AV1 o wysokiej jakości i wydajnym przechowywaniu dzięki zaawansowanym możliwościom sprzętowym AV1 wbudowanym w grafikę Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Wszystko, czego potrzebujesz do działania",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Wysoka wydajność zamknięta w&nbsp;smukłej, lekkiej i&nbsp;trwałej konstrukcji - możesz mieć to wszystko. Dzięki bardzo długiemu czasowi pracy baterii, ekranom dotykowym wyświetlającym atrakcyjny obraz* i&nbsp;najnowyszh dyskom SSD komputer z&nbsp;Windows 10 uosabia to wszystko, czego szukasz.",
                "tr_noteSection": "* w&nbsp;zależności od sprzętu",
                "titleArray": [
                  {
                    "tr_title": "Mniej znaczy dłużej",
                    "tr_subtitle": "Współczesne komputery zazwyczaj zużywają mniej energii, dlatego możesz cieszyć się dłuższym czasem pracy baterii wystarczającym średnio na więcej niż osiem godzin odtwarzania wideo**.",
                    "tr_noteSection": "**&nbsp;Czas pracy baterii różni się znacznie w&nbsp;zależności od ustawień, sposobu użytkowania i&nbsp;innych czynników."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "WSZYSTKO, CZEGO POTRZEBUJESZ DO DZIAŁANIA",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Wysoka wydajność zamknięta w&nbsp;smukłej, lekkiej i&nbsp;trwałej konstrukcji - możesz mieć to wszystko. Dzięki bardzo długiemu czasowi pracy baterii, ekranom dotykowym wyświetlającym atrakcyjny obraz* i&nbsp;najnowyszh dyskom SSD komputer z&nbsp;Windows 10 uosabia to wszystko, czego szukasz.",
                "tr_noteSection": "* w&nbsp;zależności od sprzętu",
                "titleArray": [
                  {
                    "tr_title": "MNIEJ ZNACZY DŁUŻEJ",
                    "tr_subtitle": "Współczesne komputery zazwyczaj zużywają mniej energii, dlatego możesz cieszyć się dłuższym czasem pracy baterii wystarczającym średnio na więcej niż osiem godzin odtwarzania wideo**.",
                    "tr_noteSection": "**&nbsp;Czas pracy baterii różni się znacznie w&nbsp;zależności od ustawień, sposobu użytkowania i&nbsp;innych czynników."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Usprawnione zabezpieczenia i&nbsp;wydajność",
                "tr_pretitle": "Windows® 10 w&nbsp;trybie S",
                "tr_subtitle": "System Windows* 10 w&nbsp;trybie S zapewnia dodatkową warstwę zabezpieczeń, umożliwiającą pracę, zabawę i&nbsp;odkrywanie na bezpiecznym urządzeniu."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "USPRAWNIONE ZABEZPIECZENIA I&nbsp;WYDAJNOŚĆ",
                "tr_title": "Windows® 10 w&nbsp;trybie S",
                "tr_subtitle": "System Windows* 10 w&nbsp;trybie S zapewnia dodatkową warstwę zabezpieczeń, umożliwiającą pracę, zabawę i&nbsp;odkrywanie na bezpiecznym urządzeniu."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Korzystaj z&nbsp;niezwykłych możliwości",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Wydajność, cena i&nbsp;olbrzymi wybór. Tutaj możesz znaleźć komputer z&nbsp;systemem Windows* odpowiedni dla Ciebie, niezależnie od Twojego budżetu czy preferowanego rodzaju urządzenia — od innowacyjnych urządzeń 2w1 po smukłe i&nbsp;lekkie laptopy, ekrany dotykowe oraz odłączane klawiatury."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "KORZYSTAJ Z&nbsp;NIEZWYKŁYCH MOŻLIWOŚCI",
                "tr_title": "Windows*",
                "tr_subtitle": "Wydajność, cena i&nbsp;olbrzymi wybór. Tutaj możesz znaleźć komputer z&nbsp;systemem Windows* odpowiedni dla Ciebie, niezależnie od Twojego budżetu czy preferowanego rodzaju urządzenia — od innowacyjnych urządzeń 2w1 po smukłe i&nbsp;lekkie laptopy, ekrany dotykowe oraz odłączane klawiatury."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "System operacyjny",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamięć operacyjna",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "JAK DUŻO PAMIĘCI RAM POTRZEBUJESZ?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Zwykłe użytkowanie — 2&nbsp;GB DO 8&nbsp;GB&nbsp;RAM ",
                    "tr_badgeSubTitle": "Idealna ilość pamięci dla użytkowników, którzy korzystają z&nbsp;komputera do edycji tekstu i&nbsp;przeglądania Internetu, nie używając wielu programów jednocześnie."
                  },
                  {
                    "tr_badgeTitle": "Intensywne użytkowanie — 8&nbsp;GB DO 16&nbsp;GB RAM",
                    "tr_badgeSubTitle": "Dzięki większej ilości pamięci RAM aplikacje nie tylko będą działały szybciej, ale będzie można również uruchamiać więcej aplikacji jednocześnie."
                  },
                  {
                    "tr_badgeTitle": "Zaawansowane użytkowanie — PONAD 16&nbsp;GB RAM",
                    "tr_badgeSubTitle": "Jeśli masz zamiar grać, edytować zdjęcia lub filmy bądź tworzyć modele 3D, do obsługi zaawansowanych programów niezbędna będzie dodatkowa pamięć RAM."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM do codziennego użytkowania wielozadaniowego",
                "tr_subtitle": "Pamięć zapewnia płynne uruchamianie i&nbsp;działanie podstawowych aplikacji, np. edytorów tekstu i&nbsp;przeglądarek internetowych.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM do wydajnego użytkowania wielozadaniowego",
                "tr_subtitle": "Jeśli korzystasz z&nbsp;kilku programów jednocześnie, [memory_total] GB pamięci zapewni płynne działanie wszystkich.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM do zaawansowanego użytkowania wielozadaniowego",
                "tr_subtitle": "Wystarczająca ilość pamięci do korzystania z&nbsp;kilku zaawansowanych programów jednocześnie bez spowalniania komputera.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Pamięć [Value1] GB = [Value2]&nbsp;GB&nbsp;pamięci Intel®&nbsp;Optane™ +&nbsp;[Value3]&nbsp;GB&nbsp;RAM",
                "tr_subtitle": "Ta kombinacja rozwiązań pamięci zapewnia wyższą wydajność dysku twardego dzięki pamięci Intel® Optane™, a&nbsp;także krótszy czas reakcji pamięci RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;GB<br/></span><span class='subtitle-value'>PAMIĘCI</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamięć Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Mniej czekania. Więcej tego, co lubisz.",
                "tr_subtitle": "Kiedy pamięć Intel® Optane™ współpracuje z&nbsp;pamięcią systemową, zapewnia szybszy dostęp do często używanych programów, a&nbsp;tym samym ich natychmiastowe uruchamianie i&nbsp;ładowanie w&nbsp;razie potrzeby.",
                "tr_pretitle": "[XX] GB pamięci Intel® Optane™",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "JAKĄ ROLĘ PEŁNI PAMIĘĆ DLA KOMPUTERA?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Szybsze tworzenie treści",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Krótszy czas reakcji w grach",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Łatwiejsza wielozadaniowość",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Szybsze otwieranie programów",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamięć Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Przyspiesz swoją rozgrywkę</span>",
                "tr_pretitle": "[XX] GB pamięci Intel® Optane™",
                "tr_pretitle1": "Szybsze działanie gier dzięki [XX] GB pamięci Intel® Optane™",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Uruchamiaj gry do<br/><span class=\"optane-game\">98% szybciej</span><br/>Wymień dysk z&nbsp;danymi na procesor [optane_legal_processor_name] z&nbsp;pamięcią Intel® Optane™<sup>15</sup>.",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Ładuj poziomy do<br/><span class=\"optane-game\">4,1&nbsp;raza szybciej</span><br/>Wymień dysk z&nbsp;danymi na procesor [optane_legal_processor_name] z&nbsp;pamięcią Intel® Optane™<sup>16</sup>.",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Pamięć Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Przyspiesz swoją rozgrywkę</span>",
                "tr_pretitle": "[XX] GB pamięci Intel® Optane™",
                "tr_pretitle1": "Szybsze działanie gier dzięki [XX] GB pamięci Intel® Optane™",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Uruchamiaj gry do<br/><span class=\"optane-game\">98% szybciej</span><br/>Wymień dysk z&nbsp;danymi na procesor [optane_legal_processor_name] z&nbsp;pamięcią Intel® Optane™<sup>15</sup>.",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Ładuj poziomy do<br/><span class=\"optane-game\">4,1&nbsp;raza szybciej</span><br/>Wymień dysk z&nbsp;danymi na procesor [optane_legal_processor_name] z&nbsp;pamięcią Intel® Optane™<sup>16</sup>.",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Pamięć [Value1] GB = [Value2]&nbsp;GB&nbsp;pamięci Intel®&nbsp;Optane™ +&nbsp;[Value3]&nbsp;GB&nbsp;RAM",
                "tr_subtitle": "Ta kombinacja rozwiązań pamięci zapewnia wyższą wydajność dysku twardego dzięki pamięci Intel® Optane™, a&nbsp;także krótszy czas reakcji pamięci RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;GB pamięci RAM do wielozadanio&shy;wości",
                    "tr_subtitle": "Jeśli korzystasz z&nbsp;kilku programów jednocześnie, [memory_total] GB pamięci zapewni płynne działanie wszystkich."
                  },
                  {
                    "tr_title": "Wykonywanie codziennych zadań",
                    "tr_subtitle": "Pamięć zapewnia płynne uruchamianie i&nbsp;działanie podstawowych aplikacji, np. edytorów tekstu i&nbsp;przeglądarek internetowych."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "ZWIĘKSZ WIELOZADANIOWOŚĆ",
                "tr_subtitle": "Pamięć RAM zapewnia procesorowi szybszy dostęp do plików programów, umożliwiając pracę nad wieloma zadaniami jednocześnie.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardowa pamięć",
                    "tr_badgeSubTitle": "Nawet 8&nbsp;GB pamięci RAM"
                  },
                  {
                    "tr_badgeTitle": "Zaawansowana pamięć",
                    "tr_badgeSubTitle": "Ponad 8&nbsp;GB i&nbsp;nawet 16&nbsp;GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elitarna pamięć",
                    "tr_badgeSubTitle": "Ponad 16 GB pamięci RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "Więcej możliwości wielozadaniowości dzięki [XX] GB pamięci",
                "tr_pretitle": "ZWIĘKSZ WIELOZADANIOWOŚĆ",
                "tr_subtitle": "Pamięć RAM zapewnia procesorowi szybszy dostęp do plików programów, umożliwiając pracę nad wieloma zadaniami jednocześnie.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardowa pamięć",
                    "tr_badgeSubTitle": "Nawet 8&nbsp;GB pamięci RAM"
                  },
                  {
                    "tr_badgeTitle": "Zaawansowana pamięć",
                    "tr_badgeSubTitle": "Ponad 8&nbsp;GB i&nbsp;nawet 16&nbsp;GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elitarna pamięć",
                    "tr_badgeSubTitle": "Ponad 16 GB pamięci RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAK TA PAMIĘĆ WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <span class='ram_value'>pamięci RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Zaawansowana",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;GB <span class='ram_value'>pamięci RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elita",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <span class='ram_value'>pamięci RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamięć operacyjna",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "JAK TA PAMIĘĆ WYPADA NA TLE INNYCH?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "JAK TA PAMIĘĆ WYPADA NA TLE INNYCH?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Bieżące urządzenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Codzienne potrzeby",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <span class='ram_value'>pamięci RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Wydajna",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;GB <span class='ram_value'>pamięci RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Zaawansowana",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <span class='ram_value'>pamięci RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamięć masowa",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "JAKĄ POJEMNOŚĆ PAMIĘCI MASOWEJ MA TWÓJ KOMPUTER?",
                "tr_subtitle": "Nie musisz zgadywać. Dokładnie przedstawimy, co można zapisać na tym komputerze.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Utwórz bibliotekę dla</span> <br/><span class=\"storage-standard\">[num_songs] piosenek<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>przy założeniu, że średni rozmiar pliku muzycznego to 5&nbsp;MB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Utwórz bibliotekę dla</span><br/><span class=\"storage-standard\">[num_games] gier<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>przy założeniu, że średni rozmiar plików gry to 40&nbsp;GB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Miejsce na</span><br/><span class=\"storage-standard\">[num_videos] plików wideo<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>przy założeniu, że średni rozmiar filmu to 5&nbsp;GB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Zrób i&nbsp;zapisz</span><br/><span class=\"storage-standard\">[num_photos] zdjęć<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>przy założeniu, że średni rozmiar zdjęcia to 4&nbsp;MB",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "Dysk HDD o&nbsp;pojemności [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "Dysk SSD o&nbsp;pojemności [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Pamięć Intel® Optane™ [total_optane_memory] GB + dysk SSD o&nbsp;pojemności [convertedSSD] GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ MASOWA",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Dysk SSD [capacity] GB",
                "tr_preTitle1": "Dysk SSD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Ten komputer jest wyposażony w&nbsp;dysk SSD o&nbsp;pojemności [capacity] GB. Oznacza to, że można na nim zapisać imponującą liczbę zdjęć, filmów i&nbsp;programów, a&nbsp;także uzyskać do nich dostęp znacznie szybciej niż na tradycyjnym dysku twardym.",
                    "tr_title": "Przestrzeń na wszystko na komputerze"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Ten komputer jest wyposażony w&nbsp;dysk SSD o&nbsp;pojemności [capacity] TB. Oznacza to, że można na nim zapisać imponującą liczbę zdjęć, filmów i&nbsp;programów, a&nbsp;także uzyskać do nich dostęp znacznie szybciej niż na tradycyjnym dysku twardym.",
                    "tr_title": "To urządzenie wyposażono w&nbsp;imponującą pamięć masową"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ MASOWA",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Dysk HDD [capacity] GB",
                "tr_preTitle1": "Dysk HDD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Dyski twarde to dobry wybór dla osób, które chcą mieć dużą ilość pamięci masowej bezpośrednio na swoim komputerze.",
                    "tr_title": "Przestrzeń na wszystko na komputerze"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Dyski twarde to dobry wybór dla osób, które chcą mieć dużą ilość pamięci masowej bezpośrednio na swoim komputerze.",
                    "tr_title": "To urządzenie wyposażono w&nbsp;imponującą pamięć masową"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ MASOWA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "JAKĄ POJEMNOŚĆ PAMIĘCI MASOWEJ MA TWÓJ KOMPUTER?",
                "tr_subtitle": "Nie musisz zgadywać. Dokładnie przedstawimy, co można zapisać na tym komputerze.",
                "titleArray": [
                  {
                    "tr_title": "Dysk twardy o&nbsp;pojemności [XX]&nbsp;GB"
                  },
                  {
                    "tr_title": "Dysk SSD o&nbsp;pojemności [XX]&nbsp;GB"
                  },
                  {
                    "tr_title": "Dysk SSD Intel® o&nbsp;pojemności [total_capacity]&nbsp;GB przyspieszony przy użyciu pamięci Intel®&nbsp;Optane™ o&nbsp;pojemności [optane_memory_size]&nbsp;GB"
                  },
                  {
                    "tr_title": "Dysk SSD Intel® o&nbsp;pojemności [total_capacity] TB przyspieszony przy użyciu pamięci Intel®&nbsp;Optane™ o&nbsp;pojemności [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "Dysk SSD o&nbsp;pojemności [convertedSSD] GB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD] GB"
                  },
                  {
                    "tr_title": "Dysk SSD o&nbsp;pojemności [convertedSSD] TB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD] GB"
                  },
                  {
                    "tr_title": "Dysk SSD o&nbsp;pojemności [convertedSSD] GB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD] TB"
                  },
                  {
                    "tr_title": "Dysk SSD o&nbsp;pojemności [convertedSSD] TB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD] TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ MASOWA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Dysk SSD [convertedSSD] GB + dysk HDD [convertedHDD] GB",
                "tr_preTitle1": "Dysk SSD [convertedSSD] TB + dysk HDD [convertedHDD] GB",
                "tr_preTitle2": "Dysk SSD [convertedSSD] GB + dysk HDD [convertedHDD] TB",
                "tr_preTitle3": "Dysk SSD [convertedSSD] TB + dysk HDD [convertedHDD] TB",
                "titleArray": [
                  {
                    "tr_title": "Przestrzeń na wszystko na komputerze",
                    "tr_title1": "To urządzenie wyposażono w&nbsp;imponującą pamięć masową",
                    "tr_subtitle": "Ten komputer jest wyposażony w&nbsp;dysk SSD o&nbsp;pojemności [convertedSSD] GB oraz dysk HDD o&nbsp;pojemności [convertedHDD] GB. Zapewnia to zarówno ogromną pojemność, jak i&nbsp;dużą wydajność pamięci masowej.",
                    "tr_subtitle1": "Ten komputer jest wyposażony w&nbsp;dysk SSD o&nbsp;pojemności [convertedSSD] TB oraz dysk HDD o&nbsp;pojemności [convertedHDD] GB. Zapewnia to zarówno ogromną pojemność, jak i&nbsp;dużą wydajność pamięci masowej.",
                    "tr_subtitle2": "Ten komputer jest wyposażony w&nbsp;dysk SSD o&nbsp;pojemności [convertedSSD] GB oraz dysk HDD o&nbsp;pojemności [convertedHDD] TB. Zapewnia to zarówno ogromną pojemność, jak i&nbsp;dużą wydajność pamięci masowej.",
                    "tr_subtitle3": "Ten komputer jest wyposażony w&nbsp;dysk SSD o&nbsp;pojemności [convertedSSD] TB oraz dysk HDD o&nbsp;pojemności [convertedHDD] TB. Zapewnia to zarówno ogromną pojemność, jak i&nbsp;dużą wydajność pamięci masowej."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>PAMIĘCI MASOWEJ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ MASOWA",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Szybszy dostęp do pamięci masowej o&nbsp;dużej pojemności dzięki pamięci Intel® Optane™ z&nbsp;dyskiem SSD.",
                "tr_title": "Łatwa obsługa dużych plików",
                "tr_pretitle": "Dysk SSD Intel® o&nbsp;pojemności [total_capacity]&nbsp;GB przyspieszony przy użyciu pamięci Intel®&nbsp;Optane™ o&nbsp;pojemności [optane_memory_size]&nbsp;GB",
                "tr_pretitle1": "Dysk SSD Intel® o&nbsp;pojemności [total_capacity] TB przyspieszony przy użyciu pamięci Intel®&nbsp;Optane™ o&nbsp;pojemności [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "To rewolucyjne rozwiązanie pamięci masowej jako pierwsze łączy pamięć Intel® Optane™ i&nbsp;dysk SSD w&nbsp;jednym układzie scalonym. Oto, co umożliwia:",
                    "tr_title": "CZYM JEST PAMIĘĆ INTEL® OPTANE™ Z&nbsp;DYSKIEM SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Szybkie otwieranie ważnych zasobów",
                    "tr_badgeSubTitle": "Dzięki wstępnemu ładowaniu często używanych plików i&nbsp;programów możesz poświęcić więcej czasu na tworzenie, a&nbsp;mniej na czekanie.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Pojemność i&nbsp;szybkość",
                    "tr_badgeSubTitle": "Dużo miejsca na przechowywanie plików multimedialnych na szybkim dysku SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Szybsze działanie z&nbsp;biegiem czasu",
                    "tr_badgeSubTitle": "Pamięć Intel® Optane™ z&nbsp;dyskiem SSD uczy się sposobu pracy użytkownika, zwiększając wydajność komputera.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMIĘĆ MASOWA",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Duża pojemność niezawodnej pamięci umożliwia przechowywanie wszystkich materiałów multimedialnych pod ręką.",
                "tr_title": "Miejsce na wszystkie zdjęcia, filmy i&nbsp;utwory"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wyświetlacz",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution]&nbsp;mln pikseli: przesuwaj, szczyp i podziwiaj do woli",
                "tr_subtitle": "Korzystaj z&nbsp;komputera tak, jak ze smartfona — z&nbsp;intuicyjnymi elementami sterującymi i&nbsp;ostrzejszymi, żywymi szczegółami.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozdzielczość: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Piksele: [screenResolution] milionów(y)"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ekran [screenDiagonal]&nbsp;cala(i)",
                    "tr_badgeSubTitle": "Wysokość ekranu: [screenHeight] cala(i)",
                    "tr_badgePreTitle": "Szerokość ekranu: [YY] cala(i)"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Ekran dotykowy",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wyświetlacz",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Zanurz się w&nbsp;[screenResolution]&nbsp;mln pikseli",
                "tr_subtitle": "Liczba pikseli ma znaczenie. Im ich więcej, tym ostrzejszy i bardziej wyrazisty obraz.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozdzielczość: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Piksele: [screenResolution] milionów(y)"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ekran [screenDiagonal]&nbsp;cala(i)",
                    "tr_badgeSubTitle": "Wysokość ekranu: [screenHeight] cala(i)",
                    "tr_badgePreTitle": "Szerokość ekranu: [YY] cala(i)"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wyświetlacz",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] cala(i) <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "OKNO NA ŚWIAT GIER",
                "tr_subtitle": "Podobnie jak telewizor, monitor dla graczy musi mieć odpowiedni rozmiar i&nbsp;rozdzielczość.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozdzielczość: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Piksele: [screenResolution] milionów(y)"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ekran [screenDiagonal]&nbsp;cala(i)",
                    "tr_badgeSubTitle": "Wysokość ekranu: [screenHeight] cala(i)",
                    "tr_badgePreTitle": "Szerokość ekranu: [screenWidth] cala(i)"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Ekran dotykowy"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wyświetlacz",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "To urządzenie wyposażono w&nbsp;imponujący wyświetlacz",
                "tr_subtitle": "Dzięki ponad ośmiokrotnie większej liczbie pikseli niż w&nbsp;standardzie HD Twoje filmy i&nbsp;gry zyskają zupełnie nowy poziom ostrości i&nbsp;kolorów.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozdzielczość: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Piksele: [screenResolution] milionów(y)"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ekran [screenDiagonal]&nbsp;cala(i)",
                    "tr_badgeSubTitle": "Wysokość ekranu: [screenHeight] cala(i)",
                    "tr_badgePreTitle": "Szerokość ekranu: [YY] cala(i)"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wyświetlacz",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] mln pikseli do stukania, przesuwania i&nbsp;dotykania",
                    "tr_subTitle": "Wyświetlaj ulubioną zawartość w&nbsp;niezwykłej ostrości i&nbsp;z&nbsp;niezwykłą liczbą szczegółów."
                  },
                  {
                    "tr_title": "[screenResolution] mln pikseli wzbogaci Twój cyfrowy świat",
                    "tr_subTitle": "Liczba pikseli ma znaczenie. Im ich więcej, tym ostrzejszy i bardziej wyrazisty obraz."
                  },
                  {
                    "tr_title": "To urządzenie wyposażono w&nbsp;imponujący wyświetlacz",
                    "tr_subTitle": "Dzięki niemal czterokrotnie większej liczbie pikseli niż w&nbsp;jakości HD uzyskasz zupełnie nowy poziom ostrości i&nbsp;kolorów w&nbsp;filmach i&nbsp;grach."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Łączność",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Technologia Thunderbolt™ 3 to najszybszy i&nbsp;najbardziej wszechstronny sposób podłączania stacji dokujących, wyświetlaczy i&nbsp;urządzeń pamięci masowej.",
                "tr_title": "Jeden port do wszystkiego",
                "tr_title1": "JEDEN PORT DO WSZYSTKIEGO",
                "titleArray": [
                  {
                    "tr_title": "JAK KORZYSTAĆ Z&nbsp;TECHNOLOGII THUNDERBOLT™&nbsp;3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technologia Thunderbolt™&nbsp;3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitory 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Podłącz wyświetlacze o&nbsp;oszałamiającej rozdzielczości, kontraście i&nbsp;kolorach"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Zewnętrzna karta graficzna",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Błyskawiczna modernizacja grafiki w&nbsp;dowolnym komputerze"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Szybka sieć",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Zapewnia szybkie połączenia typu peer-to-peer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Łączność",
                "detailBGUrl": "",
                "tr_subtitle": "Technologia Thunderbolt™ 3 umożliwia przesyłanie danych z&nbsp;większą szybkością niż tradycyjne porty USB, co pozwala na szybkie przenoszenie plików i&nbsp;łatwą integrację urządzeń peryferyjnych, takich jak wiele monitorów lub zewnętrzne karty graficzne.",
                "tr_title": "Technologia Thunderbolt™&nbsp;3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "BŁYSKAWICZNA SZYBKOŚĆ PRZESYŁANIA DANYCH",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480&nbsp;Mb/s",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5&nbsp;Gb/s",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Technologia Thunderbolt™&nbsp;3",
                    "tr_badgeSubTitle": "40 Gb/s",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Łączność",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Jeden uniwersalny port i&nbsp;przewód obsługują wszystkie Twoje ulubione akcesoria</li> <li>Niezawodne ładowanie notebooków</li> <li>Solidna certyfikacja zapewnia spokój ducha</li></ul>",
                "tr_title": "Prawdziwie uniwersalna łączność przewodowa",
                "tr_title1": "PRAWDZIWIE UNIWERSALNA ŁĄCZNOŚĆ PRZEWODOWA",
                "tr_noteSection": "<sup>†</sup> W&nbsp;oparciu o&nbsp;minimalne wymogi specyfikacji",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "JAK KORZYSTAĆ Z&nbsp;TECHNOLOGII THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technologia Thunderbolt™&nbsp;4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Tworzenie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Podłącz zewnętrzny monitor oraz szybką pamięć masową, by wyświetlać i edytować zdjęcia i filmy."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokowanie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Zorganizuj swoje biurko w&nbsp;prosty, wolny od bałaganu sposób poprzez podłączenie wszystkich swoich akcesoriów do laptopa za pomocą pojedynczego przewodu."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Gra",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Dodaj dyski SSD i&nbsp;HDD o&nbsp;większej pojemności, z&nbsp;prędkościami do 3000&nbsp;MB/s, by przechowywać biblioteki gier oraz pliki multimedialne."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksymalna wydajność<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4&nbsp;razy szybsza niż USB* 3.2 <br/>2 razy szybsza niż HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Łączność",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Rozkoszuj się tą samą niesamowitą wydajnością technologii Thunderbolt™ 3, ale z&nbsp;jeszcze większą liczbą funkcji, które umożliwiają łatwe połączenie z&nbsp;najnowszymi stacjami dokującymi Thunderbolt™, monitorami, szybką pamięcią masową lub dowolnymi akcesoriami USB*.",
                "tr_title": "Technologia Thunderbolt™&nbsp;4",
                "tr_pretitle": "PRAWDZIWIE UNIWERSALNA ŁĄCZNOŚĆ PRZEWODOWA",
                "tr_noteSection": "<sup>†</sup> W&nbsp;oparciu o&nbsp;minimalne wymogi specyfikacji",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Jeden uniwersalny przewód Thunderbolt™ 4 może zastąpić wszystkie Twoje kable USB-C*, aby wybór przewodów był szybki i&nbsp;łatwy."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Stacje dokujące w&nbsp;technologii Thunderbolt™ 4 są wyposażone w&nbsp;nawet cztery porty Thunderbolt™, aby zapewnić większą elastyczność w&nbsp;podłączaniu dowolnych akcesoriów."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksymalna wydajność<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4&nbsp;razy szybsza niż USB* 3.2 <br/>2 razy szybsza niż HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Łączność",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Jeden uniwersalny port i&nbsp;przewód obsługują wszystkie Twoje ulubione akcesoria</li> <li>Niezawodne ładowanie notebooków</li> <li>Solidna certyfikacja zapewnia spokój ducha</li></ul>",
                "tr_title": "Zmaksymalizuj swoje wrażenia dzięki akcesoriom Thunderbolt™",
                "tr_noteSection": "<sup>†</sup> W&nbsp;oparciu o&nbsp;minimalne wymogi specyfikacji",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "JAK KORZYSTAĆ Z&nbsp;TECHNOLOGII THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Tworzenie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Podłącz zewnętrzny monitor oraz szybką pamięć masową, by wyświetlać i edytować zdjęcia i filmy."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokowanie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Zorganizuj swoje biurko w&nbsp;prosty, wolny od bałaganu sposób poprzez podłączenie wszystkich swoich akcesoriów do laptopa za pomocą pojedynczego przewodu."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Gra",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Dodaj dyski SSD i&nbsp;HDD o&nbsp;większej pojemności, z&nbsp;prędkościami do 3000&nbsp;MB/s, by przechowywać biblioteki gier oraz pliki multimedialne."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksymalna wydajność<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4&nbsp;razy szybsza niż USB* 3.2 <br/>2 razy szybsza niż HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Najczęstsze rodzaje Wi&#8209;Fi to:",
                "tr_title": "STANDARDY ŁĄCZNOŚCI WI&#8209;FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 umożliwia uzyskanie większych prędkości, nawet gdy wielu użytkowników korzysta z tego samego sygnału<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Dzięki najnowszym innowacjom firmy Intel w&nbsp;zakresie sieci Wi-Fi możesz osiągnąć ponadgigabitową szybkość Wi-Fi, nawet jeśli wielu użytkowników korzysta z&nbsp;tego samego sygnału.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Technologia Wi&#8209;Fi 6E, największe osiągnięcie w zakresie Wi&#8209;Fi od 20 lat, otwiera nowe kanały o dużej prędkości, zapewniając lepszą wydajność i niezawodność oraz mniej zakłóceń."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 umożliwia uzyskanie większych prędkości, nawet gdy wielu użytkowników korzysta z tego samego sygnału<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wszystkie zalety Wi-Fi 6E w&nbsp;połączeniu z&nbsp;silnikiem priorytetyzacji Intel® Killer™ przekierowującym przepustowość do stron internetowych i&nbsp;aplikacji, które potrzebują jej najbardziej."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E",
                    "tr_badgeSubTitle": "Dzięki mniejszej konkurencji na szybkich kanałach będziesz cieszyć się wyższą wydajnością i&nbsp;niezawodnością."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi&nbsp;6",
                    "tr_badgeSubTitle": "Wi-Fi 6 umożliwia uzyskanie większych prędkości, nawet gdy wielu użytkowników korzysta z tego samego sygnału<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi&nbsp;6 (Gig+)",
                    "tr_badgeSubTitle": "Dzięki mniejszym zakłóceniom ze strony Wi-Fi&nbsp;6 i&nbsp;inteligentnej wydajności silnika priorytetyzacji Intel® Killer™, korzystanie z&nbsp;sieci będzie szybsze i&nbsp;bardziej responsywne⁶."
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Dzięki mniejszej konkurencji na szybkich kanałach będziesz cieszyć się wyższą wydajnością i&nbsp;niezawodnością."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5&nbsp;Gig)",
                    "tr_badgeSubTitle": "Szybkość sieci Wi-Fi dla komputerów stacjonarnych przekraczająca 5&nbsp;Gb/s i&nbsp;stałe, bardzo małe opóźnienia – udostępniaj pliki w&nbsp;ciągu sekund, a&nbsp;nie minut."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) to najnowsza technologia zapewniająca „Ekstremalną” niezawodność, niewielkie opóźnienia i&nbsp;duże szybkości dzięki optymalizacji sieci z&nbsp;wykorzystaniem sztucznej inteligencji."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 umożliwia uzyskanie większych prędkości, nawet gdy wielu użytkowników korzysta z tego samego sygnału<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) oznacza niezawodność, niewielkie opóźnienia i&nbsp;szybkości większe niż 1&nbsp;Gb/s z&nbsp;wykorzystaniem pasma 6&nbsp;GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Lepsza wydajność, przepustowość i szybkość w porównaniu z zatłoczonymi sieciami Wi&#8209;Fi&nbsp;5."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Zachowaj łączność ze światem dzięki technologii Wi&#8209;Fi [XX], dzięki której Internet będzie działał szybciej<sup>†</sup> i niezawodniej.",
                "tr_title": "Uzyskaj lepszy zakres i&nbsp;prędkość",
                "tr_noteSection": "<sup>†</sup>Rzeczywisty zasięg i&nbsp;przepustowość łącza bezprzewodowego mogą się różnić.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "W czasach gry wieloosobowej i&nbsp;internetowej utrzymanie łączności jest ważniejsze niż kiedykolwiek. Oprócz szybkiego transferu, potrzebujesz także niezawodnego, bezprzewodowego sygnału, zapewniającego stały transfer nawet z dużej odległości.",
                "tr_title": "[XX]",
                "tr_pretitle": "NAJWAŻNIEJSZE JEST POŁĄCZENIE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Aby zapewnić szybsze połączenia i większą niezawodność aktywniejszym użytkownikom, zastosuj routery Intel® Wi&#8209;Fi 6 (Gig+) lub Wi&#8209;Fi 6 z obsługą kanałów 160 MHz.",
                "tr_title": "Nawet 3&nbsp;razy większe prędkości dzięki technologii Intel®&nbsp;<span class='no-warp'>Wi&#8209;Fi&nbsp;6&nbsp;</span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Niż technologia AC Wi&#8209;Fi. Wybrane funkcje dostępne są tylko w niektórych produktach. Szczegółowe dane zapewnia producent komputera. Więcej informacji o Wi&#8209;Fi można znaleźć na stronie intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Podstawowe, dwuzakresowe rozwiązanie Intel® Wi-Fi 6 obsługuje lepszą wydajność w&nbsp;przypadku podstawowych potrzeb związanych z&nbsp;łącznością, takich jak poczta elektroniczna i&nbsp;przeglądanie Internetu w&nbsp;zatłoczonych sieciach.<sup>9</sup>",
                "tr_title": "Niezbędne Wi-Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "W czasach gry wieloosobowej i&nbsp;internetowej utrzymanie łączności jest ważniejsze niż kiedykolwiek. Oprócz szybkiego transferu, potrzebujesz także niezawodnego, bezprzewodowego sygnału, zapewniającego stały transfer nawet z dużej odległości.",
                "tr_title": "[XX]",
                "tr_pretitle": "NAJWAŻNIEJSZE JEST POŁĄCZENIE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Nowe kanały Wi&#8209;Fi oznaczają, że nie musisz dzielić przepustowości ze starszymi urządzeniami w okolicy. Dzięki temu możesz płynnie streamować, szybko przeglądać strony internetowe oraz bez problemu pracować i uczyć się zdalnie.",
                "tr_title": "Połącz się bez konkurencji",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Zachowaj łączność ze światem dzięki technologii [XX] zapewniającej większą niezawodność i&nbsp;szybkość<sup>†</sup> korzystania z&nbsp;Internetu.",
                "tr_title": "Uzyskaj lepszy zakres i&nbsp;prędkość",
                "tr_noteSection": "<sup>†</sup>Rzeczywisty zasięg i&nbsp;przepustowość łącza bezprzewodowego mogą się różnić.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "W czasach gry wieloosobowej i&nbsp;internetowej utrzymanie łączności jest ważniejsze niż kiedykolwiek. Oprócz szybkiego transferu, potrzebujesz także niezawodnego, bezprzewodowego sygnału, zapewniającego stały transfer nawet z dużej odległości.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "NAJWAŻNIEJSZE JEST POŁĄCZENIE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wbudowana ultraszybka technologia Wi&#8209;Fi",
                "tr_subtitle": "Wyciągnij wtyczkę i&nbsp;pozostań w&nbsp;kontakcie z&nbsp;Internetem."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Dzięki dedykowanym szybkim pasmom zapewniającym mniejsze zakłócenia oraz silnikowi priorytetyzacji Intel® Killer™, który stawia graczy na pierwszym miejscu, granie online przeszło na wyższy poziom.",
                "tr_title": "Zostaw&nbsp;konkurencję daleko w&nbsp;tyle",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Dzięki dedykowanym szybkim pasmom zapewniającym mniejsze zakłócenia oraz silnikowi priorytetyzacji Intel® Killer™, który stawia graczy na pierwszym miejscu, granie online przeszło na wyższy poziom.",
                "tr_title": "[XX]",
                "tr_pretitle": "ZOSTAW KONKURENCJĘ DALEKO W&nbsp;TYLE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Dzięki dedykowanym szybkim pasmom, ograniczającym zakłócenia, które stawiają graczy na pierwszym miejscu, granie online przeszło na wyższy poziom.",
                "tr_title": "[XX]",
                "tr_pretitle": "ZOSTAW KONKURENCJĘ DALEKO W&nbsp;TYLE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi automatycznie przyspiesza ruch sieciowy dla aplikacji, które potrzebują go najbardziej, więc streamowane filmy mają wyraźne szczegóły i&nbsp;mniej się buforują.",
                "tr_title": "Szybszy, inteligentniejszy sposób łączności⁶.",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "W czasach gry wieloosobowej i&nbsp;internetowej utrzymanie łączności jest ważniejsze niż kiedykolwiek. Oprócz szybkiego transferu, potrzebujesz także niezawodnego, bezprzewodowego sygnału, zapewniającego stały transfer nawet z dużej odległości.",
                "tr_title": "[XX]",
                "tr_pretitle": "NAJWAŻNIEJSZE JEST POŁĄCZENIE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Zachowaj łączność dzięki technologii Wi-Fi&nbsp;5 zapewniającej większą niezawodność i&nbsp;szybkość<sup>†</sup> korzystania z&nbsp;Internetu.",
                "tr_title": "Uzyskaj lepszy zakres i&nbsp;prędkość",
                "tr_noteSection": "<sup>†</sup>Rzeczywisty zasięg i&nbsp;przepustowość łącza bezprzewodowego mogą się różnić.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "W czasach gry wieloosobowej i&nbsp;internetowej utrzymanie łączności jest ważniejsze niż kiedykolwiek. Oprócz szybkiego transferu, potrzebujesz także niezawodnego, bezprzewodowego sygnału, zapewniającego stały transfer nawet z dużej odległości.",
                "tr_title": "[XX]",
                "tr_pretitle": "ZOSTAW KONKURENCJĘ DALEKO W&nbsp;TYLE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Funkcje Wi-Fi 7 będą bazować na wcześniejszych generacjach Wi-Fi i&nbsp;wynosić je na wyższy poziom. Będzie to oznaczać nie tylko większą szybkość, ale też znacznie lepszą reakcję i&nbsp;niezawodność wciągających doświadczeń konsumenckich i&nbsp;zaawansowanych technologii przyszłości.",
                "tr_title": "Sieć komputerowa jak po kablu",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Funkcje Wi-Fi 7 będą bazować na wcześniejszych generacjach Wi-Fi i&nbsp;wynosić je na wyższy poziom. Będzie to oznaczać nie tylko większą szybkość, ale też znacznie lepszą reakcję i&nbsp;niezawodność wciągających doświadczeń konsumenckich i&nbsp;zaawansowanych technologii przyszłości.",
                "tr_title": "[XX]",
                "tr_pretitle": "PRZEKSZTAŁCANIE DOŚWIADCZEŃ UŻYTKOWNIKÓW",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Zwiększona szybkość i&nbsp;niezawodność oraz mniejsze opóźnienia dzięki optymalizacji sieci z&nbsp;wykorzystaniem sztucznej inteligencji",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Zwiększona szybkość i&nbsp;niezawodność oraz mniejsze opóźnienia dzięki optymalizacji sieci z&nbsp;wykorzystaniem sztucznej inteligencji.",
                "tr_title": "[XX]",
                "tr_pretitle": "CZAS REAKCJI JAK PO KABLU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Jak to jest mierzone?",
                    "tr_badgeSubTitle": "Większość producentów wyraża czas pracy baterii w&nbsp;godzinach. Choć obliczenie to nie jest w&nbsp;100% dokładne, powinno być pomocne w&nbsp;porównywaniu czasów pracy baterii różnych komputerów."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ROZGRYWKA Z KAŻDEGO MIEJSCA",
                "tr_subtitle": "Dzięki mocy dzisiejszych laptopów do gier nie ma powodu, by być przywiązanym do biurka. Teraz&nbsp;możesz grać w&nbsp;gry z&nbsp;dowolnego miejsca.",
                "tr_title": "[Dynamic.battery.values.hours] godz. czasu pracy baterii"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odłącz urządzenie od zasilania i&nbsp;korzystaj z&nbsp;aplikacji, graj i&nbsp;słuchaj muzyki przez wiele godzin.",
                "tr_title": "[XX] godz. streamowania na jednym ładowaniu<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na podstawie czasu pracy baterii deklarowanego przez producenta.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] godz.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odłącz urządzenie od zasilania i&nbsp;korzystaj z&nbsp;aplikacji, graj i&nbsp;słuchaj muzyki przez wiele godzin.",
                "tr_title": "[Dynamic.battery.values.hours] godz. pracy w&nbsp;podróży<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na podstawie czasu pracy baterii deklarowanego przez producenta.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] godz.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odłącz urządzenie od zasilania i&nbsp;korzystaj z&nbsp;aplikacji, graj i&nbsp;słuchaj muzyki przez wiele godzin.",
                "tr_title": "[Dynamic.battery.values.hours] godz. do całodziennej pracy na jednym ładowaniu<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na podstawie czasu pracy baterii deklarowanego przez producenta.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] godz.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Ten komputer optymalizuje baterię, a&nbsp;tym samym zapewnia przynajmniej 4&nbsp;godziny czasu pracy przy 30-minutowym okresie ładowania<sup>4</sup>.",
                "tr_title": "Dłuższa praca na baterii dzięki [Dynamic.battery.values.hours] godz. czasu pracy baterii<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na podstawie czasu pracy baterii deklarowanego przez producenta.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] godz.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Strona główna",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Dlaczego warto wybrać ten komputer",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Wyświetl wszystkie dane techniczne",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Zdalnie",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Telefon komórkowy",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Prawne",
        "tr_paragraph_array": [
          "Oprogramowanie i&nbsp;obciążenia wykorzystane w&nbsp;testach wydajności mogły zostać zoptymalizowane pod kątem wydajnego działania tylko na mikroprocesorach Intel®. Testy wydajności, takie jak SYSmark* i&nbsp;MobileMark*, mierzą wydajność określonych systemów komputerowych, komponentów i&nbsp;funkcji. Dowolna zmiana wyżej wymienionych czynników może spowodować uzyskanie innych wyników. Aby wszechstronnie ocenić planowany zakup, w&nbsp;tym wydajność danego produktu w&nbsp;porównaniu z&nbsp;konkurencyjnymi, należy zapoznać się z&nbsp;informacjami z&nbsp;innych źródeł oraz innymi testami wydajności. Więcej szczegółowych informacji na temat wydajności i testów porównawczych można znaleźć na stronie www.intel.com/benchmarks.",
          "¹Produkty Intel® Wi-Fi 6 (GIG+) obsługują opcjonalne kanały 160 MHz, co umożliwia najszybsze osiągnięcie teoretycznych prędkości maksymalnych (2402 Mb/s) dla typowych produktów 2×2 802.11ax PC Wi-Fi. Produkty Intel® Wi-Fi 6 (Gig+) klasy premium umożliwiają uzyskanie 2–4 razy większych teoretycznych prędkości maksymalnych w&nbsp;porównaniu ze standardowymi produktami 2x2 (1201 Mb/s) lub 1x1 (600 Mb/s) 802.11ax PC Wi-Fi, które obsługują jedynie obowiązkowe kanały 80&nbsp;MHz.",
          "²Rekomendacja tylko w&nbsp;celach informacyjnych. Skorzystaj z&nbsp;innych źródeł podczas podejmowania decyzji o&nbsp;zakupie.",
          "³Cechy i zalety technologii Intel zależą od konfiguracji systemu i mogą wymagać obsługującego je sprzętu, oprogramowania lub aktywacji usług. Wydajność może różnić się od podanej w zależności od konfiguracji systemu. Całkowite zabezpieczenie systemu komputerowego jest niemożliwe. Więcej informacji można uzyskać od sprzedawcy lub producenta systemu, bądź na stronie intel.com.",
          "⁴W&nbsp;przypadku systemów z&nbsp;wyświetlaczami FHD używanych do bezprzewodowego przeglądania stron internetowych. Przy wyłączonym zasilaniu, z&nbsp;domyślnego poziomu wyłączenia producenta OEM.",
          "⁵W&nbsp;porównaniu z&nbsp;innymi technologiami połączeń I/O komputera, takimi jak eSATA, USB i&nbsp;IEEE 1394* FireWire. Wydajność zależy od konfiguracji używanego sprzętu i&nbsp;oprogramowania. Wymaga urządzenia obsługującego technologię Thunderbolt™.",
          "⁶Prawie 3 razy szybciej: standard 802.11ax 2x2 160 MHz zapewnia maksymalną teoretyczną szybkość transmisji danych 2402 Mb/s. Około 3 razy szybciej niż standardowa sieć 802.11ac 2x2 80 MHz (867 Mb/s) (zgodnie ze specyfikacjami standardu bezprzewodowego IEEE 802.11*) i wymaga zastosowania podobnie skonfigurowanych routerów sieci bezprzewodowej 802.11ax.",
          "⁷Stwierdzenie „Opóźnienia niższe o&nbsp;75%” opiera się na danych symulacji firmy Intel (79%) w&nbsp;standardzie 802.11ax z&nbsp;zastosowaniem i&nbsp;bez zastosowania metody transmisji OFDMA przy użyciu dziewięciu klientów. Średnie opóźnienie bez OFDMA wynosi 36&nbsp;ms, a średnie opóźnienie z OFDMA tylko 7,6&nbsp;ms. Aby uzyskać niższe opóźnienie transmisji, router 802.11ax (Wi-Fi 6) i wszystkie urządzenia klienckie muszą obsługiwać OFDMA.",
          "⁸Na podstawie porównania teoretycznej maksymalnej prędkości transmisji danych w standardzie 802.11 produkty 2x2 Wi&#8209;Fi 6/6E (802.11ax) o 160 MHz mogą zapewnić 2402 Mb/s, podczas gdy produkty 2x2 Wi&#8209;Fi 5 (802.11ac) o 80 MHz mogą zapewnić 867 Mb/s, co oznacza 2,8 raza większe prędkości.",
          "⁹Najlepszy w klasie standard Wi&#8209;Fi 6: produkty Intel® Wi&#8209;Fi 6 (Gig+) obsługują opcjonalne kanały 160 MHz, co umożliwia najszybsze osiągnięcie teoretycznych prędkości maksymalnych (2402 Mb/s) dla typowych produktów 2x2 802.11ax PC Wi&#8209;Fi. Produkty Intel® Wi&#8209;Fi 6 (Gig+) klasy premium umożliwiają uzyskanie 2–4 razy większych teoretycznych prędkości maksymalnych w porównaniu ze standardowymi produktami 2x2 (1201 Mb/s) lub 1x1 (600 Mb/s) 802.11ax PC Wi&#8209;Fi, które obsługują jedynie obowiązkowe kanały 80 MHz.",
          "Tylko wybrane modele. Funkcjonalność zależy od konstrukcji producenta OEM. Szczegółowe informacje można uzyskać u producenta OEM lub sprzedawcy. Więcej informacji o Wi&#8209;Fi można znaleźć na stronie intel.com/wifi6disclaimers.",
          "¹⁰Technologia Thunderbolt™&nbsp;4 o&nbsp;przepustowości 40&nbsp;Gb/s zapewnia najszybsze, najprostsze i&nbsp;najbardziej niezawodne połączenie przewodowe z&nbsp;dowolną stacją dokującą, wyświetlaczem lub urządzeniem do przetwarzania danych w&nbsp;porównaniu z&nbsp;innymi technologiami połączeń we/wy komputera, takimi jak eSATA*, USB* i&nbsp;FireWire IEEE 1394*.",
          "¹¹&nbsp;Wymaga routera opartego na standardzie 802.11ax z&nbsp;obsługą OFDMA oraz wielu klientów w&nbsp;sieci z&nbsp;obsługą AX. Lepsze działanie w&nbsp;środowiskach o&nbsp;dużej gęstości jest możliwe, jeśli funkcje OFDMA są obsługiwane przez urządzenia klienckie i&nbsp;punkty dostępu oparte na standardzie 802.11ax. 2&nbsp;Gb/s przy założeniu około 70% teoretycznych maksymalnych prędkości przesyłu danych w&nbsp;standardzie IEEE 802.11* — 802.11ax, 160&nbsp;MHz, 2402&nbsp;Mb/s.",
          "¹²&nbsp;Zgodnie z&nbsp;pomiarem w&nbsp;zakresie otwierania dokumentu przy działaniu w&nbsp;tle — porównanie procesora Intel® Core™ i7-8565U ósmej generacji (dysk SSD TLC 512&nbsp;GB) z&nbsp;procesorem Intel® Core™ i7-8565U ósmej generacji (32&nbsp;GB + 512&nbsp;GB pamięci Intel® Optane™ H10 z&nbsp;dyskiem SSD).",
          "¹³&nbsp;Technologia Intel® Thread Director wbudowana w&nbsp;procesory Intel® Core™ dwunastej generacji pomaga systemowi operacyjnemu kierować obciążenia do odpowiednich rdzeni w&nbsp;bardziej inteligentny sposób. Nie jest wymagane żadne działanie użytkownika. Szczegółowe informacje można znaleźć na stronie intel.com.",
          "¹⁴&nbsp;Niedostępne w&nbsp;niektórych procesorach Intel® Core™ dwunastej generacji. Architektura hybrydowa o&nbsp;wysokiej wydajności łączy w&nbsp;sobie dwie nowe mikroarchitektury rdzeni — Performance-cores (P-cores) i&nbsp;Efficient-cores (E-cores) w&nbsp;jednym układzie scalonym procesora. Wybrane procesory Intel® Core™ dwunastej generacji (niektóre procesory Intel® Core™ i5 dwunastej generacji i&nbsp;starsze) nie obsługują wydajnej architektury hybrydowej, tylko rdzenie&nbsp;P-cores.",
          "¹⁵W&nbsp;oparciu o&nbsp;wynik uzyskany podczas ładowania gry na&nbsp;komputerze z&nbsp;następującą konfiguracją: procesor Intel® Core™ i7-8750H ósmej generacji (moduł pamięci Intel® Optane™ 32&nbsp;GB + dysk Intel® SSD 256&nbsp;GB PCIe* + dysk twardy 1&nbsp;TB) w&nbsp;porównaniu z&nbsp;konfiguracją: procesor Intel® Core™ i7-8750H ósmej generacji (dysk Intel® SSD 256&nbsp;GB PCIe* + dysk twardy 1&nbsp;TB). Ładowanie gry – obciążenie robocze Intel® mierzące czas potrzebny na&nbsp;uruchomienie gry Total War*: WARHAMMER* II wersja: 5577.0 i&nbsp;przejście do&nbsp;głównego menu z&nbsp;wyłączonymi filmami wprowadzającymi (uruchomienie). Konfiguracje przyspieszające działanie dysku z&nbsp;pamięcią Intel® Optane™: procesor Intel® Core™ i7-8750H, PL1&nbsp;=&nbsp;45&nbsp;W&nbsp;TDP, 6 rdzeni / 12 wątków, przyspieszenie do&nbsp;4&nbsp;GHz w&nbsp;przedprodukcyjnym komputerze OEM, karta graficzna: NVIDIA* GeForce* GTX 1070, pamięć RAM: 2 × 4&nbsp;GB DDR4, pamięć masowa: dysk Intel® SSD 760p (PCIe*) 256&nbsp;GB + dysk twardy 1&nbsp;TB + pamięć Intel® Optane™ 32&nbsp;GB, system operacyjny: Windows* 10 RS3, kompilacja 1709, MCU 0x84 w&nbsp;porównaniu z&nbsp;procesorem Intel® Core™ i7-8750H, PL1&nbsp;=&nbsp;45W&nbsp;TDP, 6 rdzeni / 12 wątków, przyspieszenie do&nbsp;4&nbsp;GHz w&nbsp;przedprodukcyjnym komputerze OEM, karta graficzna: NVIDIA* GeForce* GTX 1070, pamięć RAM: 2 × 4&nbsp;GB DDR4, pamięć masowa: dysk Intel® SSD 760p (PCIe*) 256&nbsp;GB + dysk twardy 1&nbsp;TB, system operacyjny: Windows* 10 RS3, kompilacja 1709, MCU 0x84.",
          "¹⁶W&nbsp;oparciu o&nbsp;wynik uzyskany podczas uruchamiania gry, porównujący komputer z&nbsp;procesorem Intel® Core™ i7-8750H ósmej generacji (z&nbsp;modułem pamięci Intel® Optane™ 32&nbsp;GB) oraz komputer z&nbsp;procesorem Intel® Core™ i7-8750H ósmej generacji (z&nbsp;samym dyskiem twardym). Obciążenie w&nbsp;zakresie załadowania poziomu w&nbsp;grze – opracowane przez firmę Intel, mierzące czas, jaki upłynął między przejściem od&nbsp;głównego menu do&nbsp;zakończenia ładowania poziomu w&nbsp;grze Total War*: WARHAMMER* II wersja: 5577.0. Konfiguracje przyspieszające działanie dysku z&nbsp;pamięcią Intel® Optane™: procesor Intel® Core™ i7-8750H, PL1&nbsp;=&nbsp;45&nbsp;W&nbsp;TDP, 6 rdzeni / 12 wątków, przyspieszenie do&nbsp;4&nbsp;GHz w&nbsp;przedprodukcyjnym komputerze OEM, karta graficzna: NVIDIA* GeForce* GTX 1070, pamięć RAM: 2 × 4&nbsp;GB DDR4, pamięć masowa: dysk Intel® SSD 760p (PCIe*) 256&nbsp;GB + dysk twardy 1&nbsp;TB + pamięć Intel® Optane™ 32&nbsp;GB, system operacyjny: Windows* 10 RS3, kompilacja 1709, MCU 0x84 w&nbsp;porównaniu z&nbsp;procesorem Intel® Core™ i7-8750H, PL1&nbsp;=&nbsp;45W&nbsp;TDP, 6 rdzeni / 12 wątków, przyspieszenie do&nbsp;4&nbsp;GHz w&nbsp;przedprodukcyjnym komputerze OEM, karta graficzna: NVIDIA* GeForce* GTX 1070, pamięć RAM: 2 × 4&nbsp;GB DDR4, pamięć masowa: dysk Intel® SSD 760p (PCIe*) 256&nbsp;GB + dysk twardy 1&nbsp;TB, system operacyjny: Windows* 10 RS3, kompilacja 1709, MCU 0x84.",
          "¹⁷&nbsp;Dotyczy Performance-cores. Wydajność różni się w&nbsp;zależności od użytkowania, konfiguracji i&nbsp;innych czynników. Dowiedz się więcej na stronie www.intel.com/PerformanceIndex.",
          "¹⁸&nbsp;Zmiana częstotliwości zegara lub napięcia może spowodować uszkodzenie lub skrócenie czasu eksploatacji procesora i&nbsp;innych komponentów systemu, a&nbsp;także zmniejszyć stabilność i&nbsp;wydajność systemu. Gwarancje na produkty mogą nie mieć zastosowania, jeśli procesor będzie pracować w&nbsp;warunkach innych niż wymienione w&nbsp;specyfikacji. Dodatkowych informacji udzielają producenci systemu i&nbsp;komponentów.",
          "¹⁹&nbsp;Biorąc pod uwagę bezkonkurencyjne połączenie funkcji platformy Intel vPro®, takich jak zabezpieczenia w&nbsp;warstwie powyżej i&nbsp;poniżej systemu operacyjnego, zabezpieczenia aplikacji i&nbsp;danych oraz zaawansowaną ochronę przed zagrożeniami dla firm każdego rozmiaru, jak również podejście firmy Intel do projektowania, produkcji i&nbsp;obsługi produktów, stawiające bezpieczeństwo na pierwszym miejscu. Wszystkie komputery biznesowe zbudowane na platformie Intel vPro® zostały zweryfikowane pod kątem rygorystycznych specyfikacji, w&nbsp;tym unikalnych funkcji zabezpieczeń sprzętowych. Szczegółowe informacje można znaleźć na stronie www.intel.com/PerformanceIndex (platformy). Żaden produkt ani komponent nie jest całkowicie bezpieczny.",
          "²⁰&nbsp;Pakiet Intel® Connectivity Performance Suite jest dostępny wyłącznie na urządzeniach z&nbsp;systemem Windows*. Testy Wi-Fi Intel Over The Air (OTA) w&nbsp;zatłoczonej sieci w&nbsp;porównaniu z&nbsp;brakiem priorytetyzacji ruchu bezprzewodowego aplikacji ICPS i&nbsp;inteligentnego przełączania punktów dostępowych. Szczegółowe informacje można znaleźć na stronie www.intel.com/PerformanceIndex (łączność). Wyniki mogą się różnić.",
          "²¹&nbsp;W&nbsp;porównaniu z&nbsp;poprzednią generacją procesorów.",
          "²² Grafika Intel® Arc™ to pierwszy na rynku produkt z&nbsp;obsługą kodowania AV1 (stan na pierwszy kwartał 2022&nbsp;r.). Szczegóły na stronie intel.com/performanceindex.",
          "²³&nbsp;Mierzona unikalnymi cechami konstrukcji Intel® Evo™, które są również sprawdzone pod kątem spełnienia kluczowych progów doświadczeń mobilnych użytkowników. Testy z&nbsp;lutego 2022&nbsp;r.",
          "²⁴Rozwiązanie Intel® Unison™ jest obecnie dostępne jedynie w&nbsp;spełniających kryteria konstrukcjach Intel® Evo™ na komputerach z&nbsp;systemem Windows* oraz jedynie w&nbsp;połączeniu z&nbsp;telefonami z&nbsp;systemem Android lub iOS. Wszystkie urządzenia muszą mieć zainstalowaną obsługiwaną wersję systemu operacyjnego.",
          "²⁵Maksymalne szybkości pamięci są osiągalne w&nbsp;konfiguracjach 1&nbsp;DIMM na kanał (1DPC). Dodatkowe moduły DIMM zainstalowane w&nbsp;dowolnym kanale mogą mieć wpływ na maksymalną szybkość pamięci. Nawet DDR5-5600 MT/s 1&nbsp;DPC UDIMM 1Rx8, 1Rx16 i&nbsp;DDR5-5200 1Rx8, 1Rx16, 2Rx8 w&nbsp;wybranych produktach. Maksymalna pojemność pamięci jest osiągalna w&nbsp;konfiguracjach 2&nbsp;DPC.",
          "²⁶W&nbsp;porównaniu ze&nbsp;standardową siecią Wi-Fi. Szczegółowe informacje można znaleźć na stronie intel.com/performance-wireless. Wyniki mogą się różnić.",
          "²⁷&nbsp;Wydajna architektura hybrydowa łączy dwie mikroarchitektury rdzeni, rdzenie Performance-cores (P-cores) i&nbsp;rdzenie Efficient-cores (E-cores), w&nbsp;jednym układzie procesora i&nbsp;została wprowadzona po raz pierwszy w&nbsp;procesorach Intel® Core™ dwunastej generacji. Wybrane procesory Intel® Core™ dwunastej generacji i&nbsp;nowsze nie mają wydajnej architektury hybrydowej, wyłącznie rdzenie P-cores, ale mają taki sam rozmiar pamięci podręcznej jak poprzednia generacja. Szczegółowe informacje o&nbsp;produktach znajdują się na stronie ark.intel.com.",
          "²⁸&nbsp;Wbudowana w&nbsp;sprzęt technologia Intel® Thread Director jest dostępna tylko w&nbsp;konfiguracjach procesorów Intel® Core™ dwunastej generacji lub nowszych z&nbsp;wydajną architekturą hybrydową. Wymagane jest włączenie w&nbsp;systemie operacyjnym. Dostępne funkcje i&nbsp;funkcjonalność różnią się w&nbsp;zależności od systemu operacyjnego.",
          "²⁹&nbsp;Wbudowana w&nbsp;sprzęt technologia Intel® Thread Director jest dostępna wyłącznie w&nbsp;konfiguracjach procesorów Intel® Core™ dwunastej i&nbsp;trzynastej generacji o&nbsp;wydajnej architekturze hybrydowej. Wymagane jest włączenie w&nbsp;systemie operacyjnym. Dostępne funkcje i&nbsp;funkcjonalność różnią się w&nbsp;zależności od systemu operacyjnego.",
          "³⁰ Pomiar wydajności platformy weryfikacyjnej procesora Intel® Core™ Ultra do urządzeń przenośnych (serii 2, nazwa kodowa: Lunar Lake) w porównaniu z procesorem Intel® Core™ Ultra do urządzeń przenośnych (serii 1, nazwa kodowa: Meteor Lake) w programie 3DMark Time Spy. Szczegółowe informacje można znaleźć na stronie intel.com/performanceindex. Wyniki mogą się różnić.",
          "³¹&nbsp;Funkcje rozmywania tła i&nbsp;śledzenia twarzy są dostępne wyłącznie na urządzeniach z&nbsp;systemem Windows*.",
          "³²&nbsp;Produkty obsługujące standard Wi-Fi 7 mają dostęp do kanałów 320&nbsp;MHz w&nbsp;paśmie 6&nbsp;GHz i&nbsp;nowych kombinacji kanałów 160&nbsp;MHz w&nbsp;paśmie 5&nbsp;GHz dzięki nowym możliwościom technologii Multi-Resource Unit Puncturing.",
          "³³&nbsp;Na podstawie specyfikacji standardu bezprzewodowego IEEE i&nbsp;maksymalnej przepustowości teoretycznej dla urządzeń obsługujących 2 strumienie danych jednocześnie.",
          "³⁴&nbsp;Symulacje inżynieryjne firmy Intel dotyczące zatłoczonych środowisk sieciowych wskazują, że dzięki nowym funkcjom technologii Wi-Fi 7 Multi-Link Operation możliwa jest znaczna redukcja opóźnień.",
          "³⁵ Ładowania minimalnego dokonano z domyślnego poziomu wyłączenia producenta OEM. Wyniki są zależne od systemu. Szczegółowe informacje można znaleźć na stronie intel.com/performance-evo.",
          "³⁶ Pasmo Wi-Fi o częstotliwości 6 GHz nie jest dostępne we wszystkich krajach/regionach.",
          "³⁷ Tylko Windows*",
          "³⁸ Ładowania dokonano z domyślnego poziomu wyłączenia producenta OEM. Więcej szczegółowych informacji na temat wydajności i testów porównawczych można znaleźć na stronie intel.pl/Evo.",
          "³⁹Odnosi się do procesorów Intel® Core™ Ultra do urządzeń przenośnych (Seria 2) w oparciu o unikatowe sprzętowe funkcje zabezpieczeń, bezkonkurencyjne połączenie funkcji zabezpieczeń powyżej i poniżej systemu operacyjnego, imponującą wydajność nawet przy niższym poborze energii w porównaniu z poprzednią generacją (stan na wrzesień 2024 r.). Szczegółowe informacje można znaleźć na stronie intel.com/performanceindex. Wyniki mogą się różnić.",
          "⁴⁰ Zastosowania oprogramowania przedstawiono wyłącznie w celach poglądowych. Funkcje sztucznej inteligencji mogą wymagać zakupu dodatkowego oprogramowania, subskrypcji lub włączenia przez dostawcę oprogramowania lub platformy, lub mogą mieć określone wymagania dotyczące konfiguracji lub zgodności. Szczegóły na stronie www.intel.com/PerformanceIndex. Wyniki mogą się różnić. © Intel Corporation.",
          "⁴¹ Wyświetlane obrazy mogły zostać zmienione lub zasymulowane. Funkcje sztucznej inteligencji mogą wymagać zakupu dodatkowego oprogramowania, subskrypcji lub włączenia przez dostawcę oprogramowania lub platformy, lub mogą mieć określone wymagania dotyczące konfiguracji lub zgodności. Szczegóły na stronie www.intel.com/AIPC.",
          "⁴² Stopniowe wprowadzanie w wersji poglądowej w ramach najnowszej aktualizacji systemu Windows 11 na wybranych rynkach globalnych. Termin dostępności różni się w zależności od urządzenia i rynku. Dowiedz się więcej tutaj: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³ Rozwiązanie Intel® Unison™ jest obecnie dostępne w wybranych konstrukcjach. Szczegółowe informacje można znaleźć na stronie www.intel.com/PerformanceIndex.",
          "⁴⁴ Skalowanie klatek na sekundę przy użyciu technologii Intel® X<sup>e</sup>SS w porównaniu z natywną częstotliwością klatek na sekundę w grze Dying Light 2 Stay Human na procesorze Intel® Core™ Ultra 7 165H. Szczegółowe informacje można znaleźć na stronie www.intel.com/PerformanceIndex. Wyniki mogą się różnić.",
          "⁴⁵Pomiar niższego poboru energii układu SoC procesora Intel® Core™ Ultra do urządzeń przenośnych (Seria 2, nazwa kodowa: Lunar Lake) w porównaniu z platformą referencyjną procesora Intel® Core™ Ultra (Seria 1, nazwa kodowa: Meteor Lake) na platformie YouTube* 4K 30 klatek na sekundę AV1. Szczegółowe informacje można znaleźć na stronie intel.com/performanceindex. Wyniki mogą się różnić.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Firma Intel nie ponosi odpowiedzialności za nieprawidłowe ceny.",
          "*Inne nazwy oraz marki mogą być przedmiotem praw osób trzecich.",
          "PRAWA AUTORSKIE 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES ORAZ POWIĄZANE ZNAKI PROJEKTOWE I&nbsp;KOMPOZYTOWE TO ZNAKI TOWAROWE INFLEXION STUDIOS INC. WSZELKIE PRAWA ZASTRZEŻONE.",
          "Technologie Intel mogą wymagać obsługującego je sprzętu lub oprogramowania bądź aktywacji usług.",
          "Wszelkie wyniki i&nbsp;dane NERO* są podawane dla wygody i&nbsp;wyłącznie w&nbsp;celach informacyjnych. Intel Corporation nie ponosi odpowiedzialności za, na przykład, dokładność aplikacji NERO*, wyniki wydajności oraz potencjalne różnice w&nbsp;stosunku do innych wersji aplikacji do pobrania dostępnych na stronie www.nero.com lub stronach internetowych innych firm. Wyniki testów porównawczych NERO* dla komputerów są mierzone przy użyciu określonych systemów komputerowych, komponentów, oprogramowania, operacji i&nbsp;funkcji. Dowolna zmiana wyżej wymienionych czynników może spowodować uzyskanie innych wyników. Aby wszechstronnie ocenić planowany zakup, w&nbsp;tym wydajność danego produktu w&nbsp;porównaniu z&nbsp;konkurencyjnymi, należy zapoznać się z&nbsp;informacjami z&nbsp;innych źródeł oraz innymi testami wydajności.",
          "Wszystkie wersje platformy Intel vPro® wymagają spełniającego kryteria procesora Intel® Core™, obsługiwanego systemu operacyjnego, układu Intel® LAN lub/i&nbsp;WLAN, rozszerzenia oprogramowania sprzętowego oraz innego sprzętu i&nbsp;oprogramowania niezbędnego do zapewniania przypadków użycia w&nbsp;zakresie zarządzania, funkcji zabezpieczeń, wydajności i&nbsp;stabilności systemu, które definiują platformę.",
          "Wydajność różni się w&nbsp;zależności od użytkowania, konfiguracji i&nbsp;innych czynników. Więcej informacji znajdziesz na stronie www.intel.com/PerformanceIndex.",
          "Firma Intel nie sprawdza ani nie weryfikuje danych podawanych przez strony trzecie. Aby ocenić ich dokładność, należy zapoznać się z&nbsp;innymi źródłami.",
          "© Intel Corporation. Intel, logo Intel i&nbsp;inne znaki Intel są znakami towarowymi firmy Intel Corporation lub jej spółek zależnych. *Inne nazwy oraz marki mogą być przedmiotem praw osób trzecich.",
          "Żaden produkt ani komponent nie jest całkowicie bezpieczny.",
          "Rzeczywiste koszty i&nbsp;wyniki mogą się różnić.",
          "Intel, logo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt oraz logo Thunderbolt są znakami towarowymi firmy Intel Corporation lub jej spółek zależnych. Inne nazwy oraz marki mogą być przedmiotem praw ich właścicieli.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "ZAPRASZAMY",
    "tr_welcomePagetrSubtitle": "Steruj tym komputerem zdalnie za pomocą smartfona",
    "tr_welcomePagembSubtitle": "Witaj, zapoznaj się z&nbsp;danymi technicznymi tego komputera na smartfonie",
    "tr_welcomePageBtnText": "Pierwsze kroki",
    "tr_welcomePageText": "Odkrywaj i&nbsp;porównuj szczegóły tego komputera na smartfonie",
    "tr_scrollText": "Poruszaj się po urządzeniu znajdującym się przed Tobą",
    "tr_toolTipTextc1": "Skorzystaj z&nbsp;tych przycisków, aby przechodzić pomiędzy różnymi stronami na urządzeniu przed Tobą.",
    "tr_toolTipTextc2": "Dotknięcie poniższych kart umożliwi przejście do strony ze szczegółowymi danymi technicznymi każdego urządzenia.",
    "tr_toolTipTextc3": "Skorzystaj z&nbsp;tych przycisków, aby poruszać się w&nbsp;górę i&nbsp;dół na ekranie.",
    "tr_toolTipTextc4": "Aby wyświetlić dane techniczne urządzenia na smartfonie, wyłącz tryb zdalny.",
    "tr_toolTipTextmb1": "Dotykaj kart, aby wyświetlać szczegółową zawartość stron.",
    "tr_toolTipTextmb2": "Dotknij, aby wyświetlić podsumowanie wszystkich danych technicznych urządzenia",
    "tr_toolTipTextmb3": "Dotknij przycisku, aby zeskanować kod QR i&nbsp;porównać nawet do 4 urządzeń",
    "tr_nextButton": "Dalej",
    "tr_prevButton": "Wstecz",
    "tr_gotItButton": "MAMY TO",
    "tr_skipButton": "Pomiń",
    "tr_placeholderText": "*element zastępczy",
    "tr_processorHeading": "Procesor",
    "tr_processorSubheading": "Procesor Intel®&nbsp;Core™&nbsp;i7 <br/>dziesiątej generacji",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Pamięć Intel® Optane™ [XX]&nbsp;GB + dysk SSD o&nbsp;pojemności [YY]&nbsp;GB",
    "tr_coachmarkProcessor": "Procesor XX",
    "tr_coachmarkGraphics": "Grafika [XX]",
    "tr_coachmarkMemory": "Wartość specyfikacji",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "$ 999.95",
    "tr_compare": "Porównaj",
    "tr_viewallspecs": "WYŚWIETL WSZYSTKIE SPECYFIKACJE",
    "tr_operatingSystem": "SYSTEM OPERACYJNY",
    "tr_AmdProcessorNonIntel": "Procesor AMD*",
    "tr_QualcommProcessorNonIntel": "Procesor Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Dane techniczne wyświetlane na ekranie laptopa",
    "tr_scanErrorText": "Możesz zeskanować tylko do 3&nbsp;urządzeń jednocześnie. Usuń jedno lub więcej urządzeń.",
    "tr_scanErrorText2": "To urządzenie zostało już zeskanowane. Zeskanuj kolejne.",
    "tr_scanMsg": "Skanuj w&nbsp;celu dodania urządzenia",
    "tr_backToScan": "Powrót do porównania",
    "tr_scanToCompare": "Skanuj, aby porównać",
    "tr_compareDevice": "Porównaj urządzenia",
    "tr_processorTitle": "PROCESOR",
    "tr_graphicTitle": "UKŁAD GRAFICZNY",
    "tr_storageTitle": "PAMIĘĆ MASOWA",
    "tr_displayTitle": "WYŚWIETLACZ",
    "tr_batteryTitle": "BATERIA",
    "tr_memoryTitle": "PAMIĘĆ",
    "tr_wifiTitle": "Wi&#8209;Fi",
    "tr_connectivityTitle": "ŁĄCZNOŚĆ",
    "tr_priceTitle": "CENA",
    "tr_operatingSystemTitle": "SYSTEM OPERACYJNY",
    "tr_batteryHoursText": "Liczba godzin: [XX]",
    "tr_hrsText": "godz.",
    "tr_touchscreeenText": "Ekran dotykowy [screenDiagonal]”",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, ekran dotykowy",
    "tr_FHDTouchScreenText": "FHD, ekran dotykowy",
    "tr_4KTouchscreenText": "4K, ekran dotykowy",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Zapisz",
    "tr_device_comparison": "Dokument zawierający porównanie urządzeń [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "Zapisz jako JPEG",
    "tr_Save_As_PDF": "Zapisz jako PDF",
    "tr_Downloading_PDF": "Pobieranie pliku PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Otwarte",
    "tr_logo": "logo",
    "tr_laptop_name": "Nazwa laptopa",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Pamięć Intel® Optane™",
    "tr_inchUnit": "cal",
    "tr_wifi1": "Wi&#8209;Fi 1",
    "tr_wifi2": "Wi&#8209;Fi 2",
    "tr_wifi3": "Wi&#8209;Fi 3",
    "tr_wifi4": "Wi&#8209;Fi 4",
    "tr_wifi5": "Wi&#8209;Fi 5",
    "tr_wifi6": "Intel® Wi&#8209;Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI&#8209;FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi&#8209;Fi 6E",
    "tr_wifiXX": "Wi&#8209;Fi [XX]",
    "tr_wifiXXCaps": "WI&#8209;FI [XX]",
    "tr_wifi6E": "Intel® Wi&#8209;Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "PAMIĘĆ INTEL® OPTANE™",
    "tr_Thunderbolt3": "Technologia Thunderbolt™&nbsp;3",
    "tr_Thunderbolt4": "Technologia Thunderbolt™&nbsp;4",
    "tr_processorGraphicsCaps": "UKŁAD GRAFICZNY PROCESORA",
    "tr_processorGraphicsSmall": "Układ graficzny procesora",
    "tr_graphicsCardCaps": "DEDYKOWANY UKŁAD GRAFICZNY",
    "tr_graphicsCardSmall": "Dedykowany układ graficzny",
    "tr_processorTitleCamel": "Procesor",
    "tr_graphicTitleCamel": "Karta graficzna",
    "tr_storageTitleCamel": "Pamięć masowa",
    "tr_displayTitleCamel": "Wyświetlacz",
    "tr_batteryTitleCamel": "Bateria",
    "tr_memoryTitleCamel": "Pamięć operacyjna",
    "tr_connectivityTitleCamel": "Łączność",
    "tr_priceTitleCamel": "Cena",
    "tr_operatingSystemTitleCamel": "System operacyjny",
    "tr_viewallspecsCamel": "Wyświetl wszystkie dane techniczne",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "Ekran dotykowy [screenDiagonal]” [screenResolutionType]",
    "tr_OS": "SYSTEM OPERACYJNY",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Dane techniczne komputera",
    "tr_explorePC_text": "Dowiedz się wszystkiego o&nbsp;tym komputerze",
    "tr_mtlPC_text": "Zobacz, co naprawdę oznacza „stworzone do wszystkiego”.",
    "tr_optaneMemory": "Pamięć Intel® Optane™",
    "tr_displayText_Display": "WYŚWIETLACZ [screenDiagonal]″",
    "tr_displayTextResolution_Display": "EKRAN [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen_Display": "EKRAN DOTYKOWY [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextTouchscreeenText": "EKRAN DOTYKOWY [screenDiagonal]”",
    "tr_learnMoreLabel": "Dowiedz się więcej",
    "tr_batteryHoursTextUpperCase": "[XX]&nbsp;GODZ.",
    "tr_processorGraphicsIrisXe": "PROCESOR INTEL® CORE™ [cpu_fam] JEDENASTEJ GENERACJI Z&nbsp;GRAFIKĄ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESOR INTEL® CORE™ [cpu_fam] JEDENASTEJ GENERACJI Z&nbsp;GRAFIKĄ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "TECHNOLOGIA THUNDERBOLT™&nbsp;3",
    "tr_Thunderbolt4UpperCase": "TECHNOLOGIA THUNDERBOLT™&nbsp;4",
    "tr_processorWithArcGraphics": "PROCESOR INTEL® CORE™ [cpu_fam] DWUNASTEJ GENERACJI I&nbsp;GRAFIKA INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESOR INTEL® CORE™ [cpu_fam] DWUNASTEJ GENERACJI Z&nbsp;GRAFIKĄ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESOR INTEL® CORE™ [cpu_fam] DWUNASTEJ GENERACJI Z&nbsp;GRAFIKĄ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] TRZYNASTEJ GENERACJI I&nbsp;GRAFIKA INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] TRZYNASTEJ GENERACJI Z&nbsp;GRAFIKĄ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] TRZYNASTEJ GENERACJI Z&nbsp;GRAFIKĄ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRAFIKA INTEL® ARC™&nbsp;A350M",
      "tr_A370M": "GRAFIKA INTEL® ARC™&nbsp;A370M",
      "tr_A730M": "GRAFIKA INTEL® ARC™&nbsp;A730M",
      "tr_A770M": "GRAFIKA INTEL® ARC™&nbsp;A770M",
      "tr_A310": "GRAFIKA INTEL® ARC™&nbsp;A310",
      "tr_A380": "GRAFIKA INTEL® ARC™&nbsp;A380",
      "tr_A580": "GRAFIKA INTEL® ARC™&nbsp;A580",
      "tr_A750": "GRAFIKA INTEL® ARC™&nbsp;A750",
      "tr_A770": "GRAFIKA INTEL® ARC™&nbsp;A770"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "Pamięć [Value1] GB = [Value2]&nbsp;GB&nbsp;pamięci Intel®&nbsp;Optane™ +&nbsp;[Value3]&nbsp;GB&nbsp;RAM",
    "tr_textGBGB": "Dysk SSD o&nbsp;pojemności [convertedSSD]&nbsp;GB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD]&nbsp;GB",
    "tr_textGBTB": "Dysk SSD o&nbsp;pojemności [convertedSSD]&nbsp;GB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD]&nbsp;TB",
    "tr_textTBGB": "Dysk SSD o&nbsp;pojemności [convertedSSD]&nbsp;TB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD]&nbsp;GB",
    "tr_textTBTB": "Dysk SSD o&nbsp;pojemności [convertedSSD]&nbsp;TB +&nbsp;dysk&nbsp;HDD o&nbsp;pojemności [convertedHDD]&nbsp;TB",
    "tr_textGBSSD": "Dysk SSD o&nbsp;pojemności [convertedSSD]&nbsp;GB",
    "tr_textTBSSD": "Dysk SSD o&nbsp;pojemności [convertedSSD]&nbsp;TB",
    "tr_textGBHDD": "Dysk HDD o&nbsp;pojemności [convertedHDD]&nbsp;GB",
    "tr_textTBHDD": "Dysk HDD o&nbsp;pojemności [convertedHDD]&nbsp;TB",
    "tr_textStorageWithOptaneGB": "Pamięć Intel®&nbsp;Optane™ [XX]&nbsp;GB +&nbsp;[convertedSSD]&nbsp;GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "Pamięć Intel®&nbsp;Optane™ [XX]&nbsp;GB +&nbsp;[convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home w&nbsp;trybie S",
      "tr_OS21": "Windows* 10 Pro w&nbsp;trybie S",
      "tr_OS22": "Windows* 10 Enterprise w&nbsp;trybie S",
      "tr_OS23": "Windows* 11 Home w&nbsp;trybie S",
      "tr_OS24": "Windows* 11 Pro w&nbsp;trybie S",
      "tr_OS25": "Windows* 11 Enterprise w&nbsp;trybie S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Grafika Intel® Arc™ A310",
      "tr_GR387": "Grafika Intel® Arc™ A350M",
      "tr_GR388": "Grafika Intel® Arc™ A370M",
      "tr_GR389": "Grafika Intel® Arc™ A380",
      "tr_GR390": "Grafika Intel® Arc™ A530M",
      "tr_GR391": "Grafika Intel® Arc™ A550M",
      "tr_GR392": "Grafika Intel® Arc™ A570M",
      "tr_GR393": "Grafika Intel® Arc™ A580",
      "tr_GR394": "Grafika Intel® Arc™ A730M",
      "tr_GR395": "Grafika Intel® Arc™ A750",
      "tr_GR396": "Grafika Intel® Arc™ A770",
      "tr_GR397": "Grafika Intel® Arc™ A770M",
      "tr_GR398": "Grafika Intel® Arc™",
      "tr_GR399": "Grafika Intel® Arc™ Pro A30M",
      "tr_GR400": "Grafika Intel® Arc™ Pro A40/A50",
      "tr_GR401": "Grafika Intel® Arc™ Pro A60",
      "tr_GR402": "Grafika Intel® Arc™ Pro A60M",
      "tr_GR403": "Grafika Intel®",
      "tr_GR404": "Grafika HD Intel® 2000",
      "tr_GR405": "Grafika HD Intel® 2500",
      "tr_GR406": "Grafika HD Intel® 3000",
      "tr_GR407": "Grafika HD Intel® 400",
      "tr_GR408": "Grafika HD Intel® 4000",
      "tr_GR409": "Grafika HD Intel® 405",
      "tr_GR410": "Grafika HD Intel® 4200",
      "tr_GR411": "Grafika HD Intel® 4400",
      "tr_GR412": "Grafika HD Intel® 4600",
      "tr_GR413": "Grafika HD Intel® 500",
      "tr_GR414": "Grafika HD Intel® 5000",
      "tr_GR415": "Grafika HD Intel® 505",
      "tr_GR416": "Grafika HD Intel® 510",
      "tr_GR417": "Grafika HD Intel® 515",
      "tr_GR418": "Grafika HD Intel® 520",
      "tr_GR419": "Grafika HD Intel® 530",
      "tr_GR420": "Grafika HD Intel® 5300",
      "tr_GR421": "Grafika HD Intel® 5500",
      "tr_GR422": "Grafika HD Intel® 5600",
      "tr_GR423": "Grafika HD Intel® 6000",
      "tr_GR424": "Grafika HD Intel® 610",
      "tr_GR425": "Grafika HD Intel® 615",
      "tr_GR426": "Grafika HD Intel® 620",
      "tr_GR427": "Grafika HD Intel® 630",
      "tr_GR428": "Grafika HD Intel®",
      "tr_GR429": "Grafika Intel® Iris® 5100",
      "tr_GR430": "Grafika Intel® Iris® 540",
      "tr_GR431": "Grafika Intel® Iris® 550",
      "tr_GR432": "Grafika Intel® Iris® 6100",
      "tr_GR433": "Grafika Intel® Iris® Plus 640",
      "tr_GR434": "Grafika Intel® Iris® Plus 645",
      "tr_GR435": "Grafika Intel® Iris® Plus 650",
      "tr_GR436": "Grafika Intel® Iris® Plus 655",
      "tr_GR437": "Grafika Intel® Iris® Plus",
      "tr_GR438": "Grafika Intel® Iris® Pro 5200",
      "tr_GR439": "Grafika Intel® Iris® Pro 580",
      "tr_GR440": "Grafika Intel® Iris® Pro 6200",
      "tr_GR441": "Grafika Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Grafika Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Grafika Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Zintegrowany układ graficzny Intel®",
      "tr_GR445": "Grafika UHD Intel® 600",
      "tr_GR446": "Grafika UHD Intel® 605",
      "tr_GR447": "Grafika UHD Intel® 610",
      "tr_GR448": "Grafika UHD Intel® 615",
      "tr_GR449": "Grafika UHD Intel® 617",
      "tr_GR450": "Grafika UHD Intel® 620",
      "tr_GR451": "Grafika UHD Intel® 630",
      "tr_GR452": "Grafika UHD Intel® 710",
      "tr_GR453": "Grafika UHD Intel® 730",
      "tr_GR454": "Grafika UHD Intel® 770",
      "tr_GR455": "Grafika UHD Intel®",
      "tr_GR456": "Grafika UHD Intel®",
      "tr_GR457": "Grafika UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 o konstrukcji Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti o konstrukcji Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 o konstrukcji Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 o konstrukcji Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 o konstrukcji Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 o konstrukcji Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti o konstrukcji Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti o konstrukcji Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 o konstrukcji Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 o konstrukcji Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super o konstrukcji Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 o konstrukcji Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super o konstrukcji Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 o konstrukcji Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 o konstrukcji Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 o konstrukcji Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 o konstrukcji Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 o konstrukcji Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 o konstrukcji Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 o konstrukcji Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 o konstrukcji Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 o konstrukcji Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 o konstrukcji Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Grafika Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "Dwugniazdowa AMD Radeon* Pro W7900"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Kup teraz",
    "tr_textTryagain": "Spróbuj ponownie",
    "tr_textBuynowErr": "Wskutek przerwania połączenia opcja „Kup teraz” nie jest dostępna"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_MTL",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_MTL",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 z&nbsp;technologią Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 z&nbsp;technologią Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel’s Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Potężniejsze wrażenia w&nbsp;grach dzięki technologiom Intel®",
                "tr_subtitle": "Ożyw grę Marvel’s Avengers* dzięki optymalizacjom technologii Intel® pod kątem gier.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Odwiedź stronę Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Gdzie rywalizują najlepsi gracze na świecie",
                "tr_subtitle": "Najdłuższy cykl imprez w&nbsp;historii gier wideo, Intel® Extreme Masters, wyznacza standard w&nbsp;dziedzinie e-sportu.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Graj jak zawodowcy",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Mistrzostwo z&nbsp;firmą Intel.",
                "tr_subtitle": "Po zdobyciu mistrzostwa w&nbsp;2021&nbsp;roku w&nbsp;północnoamerykańskiej lidze Rainbow Six Siege*, Soniqs zastanawiają się nad swoją historią związaną z&nbsp;grami, przyszłością e-sportu i&nbsp;współpracą z&nbsp;firmą Intel.",
                "tr_pretitle": "Z&nbsp;udziałem Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Obsługa rzeczywistości wirtualnej",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Technologia VR budzi się do życia",
                "tr_subtitle": "Dzięki komputerom możesz uzyskać najlepsze, najbardziej wciągające i&nbsp;realistyczne wrażenia w&nbsp;wirtualnej rzeczywistości. Najnowsze procesory Intel® Core™ pozwalają wcielić się w&nbsp;każdą postać, odwiedzić dowolne miejsce i&nbsp;poznać nowy wymiar gier komputerowych.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Ponad 100 gier na konsolę, między innymi: Sear of Thieves* i&nbsp;Psychonauts* 2 na komputerze z&nbsp;systemem Windows*, smartfonie i&nbsp;tablecie przy użyciu Xbox* Game Pass Ultimate i&nbsp;kompatybilnego kontrolera.",
                "titleArray": [
                  {
                    "tr_title": "Graj razem na różnych urządzeniach",
                    "tr_subtitle": "Dotrzyj do serca konsoli Xbox* razem z milionami graczy gotowych i&nbsp;czekających na wspólną rozgrywkę. Łącz się i&nbsp;graj z&nbsp;innymi poprzez współdzieloną bibliotekę gier, niezależnie od tego, czy są oni na drugim końcu świata, czy siedzą tuż obok."
                  },
                  {
                    "tr_title": "Granie na komputerze z&nbsp;systemem Windows*",
                    "tr_subtitle": "Korzystaj z&nbsp;chmurowego katalogu gier na konsolę, przy użyciu aplikacji Xbox* dla systemu Windows* i&nbsp;kompatybilnego kontrolera."
                  },
                  {
                    "tr_title": "Wybierz I&nbsp;graj",
                    "tr_subtitle": "Ciesz się wspaniałymi grami na konsolę Xbox* w miejscach, gdzie było to do tej pory niemożliwe. Graj w&nbsp;ponad 100&nbsp;gier na komputerze, smartfonie i&nbsp;tablecie — w&nbsp;domu, gdy telewizor jest włączony, w&nbsp;kawiarni lub w&nbsp;dowolnym innym miejscu, z&nbsp;szybkim połączeniem internetowym."
                  },
                  {
                    "tr_title": "Zabawa bez kontrolera",
                    "tr_subtitle": "Sterowanie dotykowe na konsoli Xbox* umożliwia korzystanie z&nbsp;nowych sposobów grania, które zapewniają znajome wrażenia i&nbsp;pozwalają na grę na poziomie, do którego przyzwyczaili się użytkownicy fizycznych kontrolerów."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ponad 100&nbsp;doświadczeń ze sztuczną inteligencją",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Więcej kontroli, mniej dotyku",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Pozostań w&nbsp;kadrze w&nbsp;dowolnym otoczeniu",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatywność",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Poprawiaj oświetlenie jak profesjonalista",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Z&nbsp;łatwością dodawaj zachwycające efekty",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remiksuj utwory wykorzystując moc sztucznej inteligencji",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktywność",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Najwyższa wydajność i&nbsp;prywatność",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Spędzaj więcej czasu z&nbsp;dala od gniazdka ściennego",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Twój świat. Na Twoich urządzeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Rozrywka",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Płynne streamowanie, nawet po odłączeniu od zasilania",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Szybkie i&nbsp;wciągające granie",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ponad 100&nbsp;doświadczeń ze sztuczną inteligencją",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Spraw, że Twoje ulubione piosenki staną się naprawdę Twoje",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Zobacz swoją wizję w&nbsp;czasie rzeczywistym",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatywność",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Najszybsza droga do realizacji zadania",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Spraw, aby każde zdjęcie było idealne",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktywność",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Najwyższa wydajność i&nbsp;prywatność",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Większa wydajność. Więcej swobody.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Twój świat. Na Twoich urządzeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Rozrywka",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Płynne streamowanie w&nbsp;jakości HD, nawet po odłączeniu od zasilania",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Wciągające granie w&nbsp;różnych miejscach",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Wyjątkowo kreatywny",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Łatwa edycja zdjęć",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Twórz zdjęcia warte oprawienia w ramki w kilka sekund",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Ty wprowadzasz. Sztuczna inteligencja wykonuje.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wyjątkowo skuteczny",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cheat code pozwalający na płynniejszą rozgrywkę",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Spędzaj więcej czasu z&nbsp;dala od gniazdka ściennego",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultrawciągające",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wyjątkowo wydajny",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Twój codzienny towarzysz AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Doskonała rozmowa wideo",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Twój świat. Na Twoich urządzeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wyjątkowo bezpieczny",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Wbudowane zabezpieczenia zwiększające bezpieczeństwo i prywatność danych",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Wyjątkowo kreatywny",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Łatwa edycja zdjęć",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. Krótsza droga do ostatecznej wersji.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Szybko spisuj tekst swoich ulubionych piosenek",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Oszczędź sobie godzin edycji dzięki sztucznej inteligencji",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wyjątkowo skuteczny",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cheat code pozwalający na płynniejszą rozgrywkę",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Spędzaj więcej czasu z&nbsp;dala od gniazdka ściennego",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultrawciągające",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wyjątkowo wydajny",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Twój codzienny towarzysz AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Doskonała rozmowa wideo",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Twój świat. Na Twoich urządzeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wyjątkowo bezpieczny",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Wbudowane zabezpieczenia zwiększające bezpieczeństwo i prywatność danych",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Cała Twoja rozrywka. Jeden&nbsp;komputer.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "W&nbsp;przypadku komputera pełniącego funkcję centrum rozrywki potrzebna jest odpowiednia ilość pamięci masowej na ulubione utwory, filmy i&nbsp;gry oraz odpowiednie połączenie procesora i&nbsp;wyświetlacza, które zapewnią wciągającą grafikę. Ten komputer ma te wszystkie trzy funkcje.",
                "titleArray": [
                  {
                    "tr_title": "Przygotuj się na efekty wizualne nowej generacji",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Zbuduj cyfrową bibliotekę",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno na rozrywkę",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Płynne granie i&nbsp;streamowanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Zbuduj cyfrową bibliotekę",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno na rozrywkę",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zwiększ produktywność. Wszędzie.",
              "tr_tileTitle1": "Wydajniejsza praca. Szybko.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ten komputer charakteryzuje się idealną równowagą między mocą a&nbsp;mobilnością. Począwszy od procesora do smukłej i&nbsp;lekkiej konstrukcji, ten komputer wyposażony jest również w&nbsp;technologię Wi&#8209;Fi, która zapewnia łączność, a&nbsp;także odpowiednią baterię, która pozwala na pracę bez zewnętrznego źródła zasilania.",
                "tr_subtitle1": "Ten komputer zapewnia idealną równowagę między mocą i wydajnością, pomagając Ci osiągnąć więcej w krótszym czasie. Połączenie wydajnego procesora, przyspieszającej jego działanie obszernej pamięci oraz błyskawicznej łączności Wi-Fi umożliwia sprawniejsze wykonywanie codziennych zadań.",
                "tr_subtitle2": "Ten komputer charakteryzuje się idealną równowagą między mocą a&nbsp;mobilnością. Począwszy od procesora do smukłej i&nbsp;lekkiej konstrukcji, ten komputer wyposażony jest również w&nbsp;technologię Wi&#8209;Fi, która zapewnia łączność, a&nbsp;także odpowiednią pamięć do pracy wielozadaniowej.",
                "titleArray": [
                  {
                    "tr_title": "Stworzony, by dawać więcej możliwości",
                    "tr_title1": "Inteligentna wydajność, która ma znaczenie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Najlepsze w&nbsp;klasie połączenia",
                    "tr_title1": "Łączność, która ma znaczenie",
                    "tr_title2": "Duży postęp w&nbsp;technologii Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Pracuj dłużej na baterii",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Zmieniaj programy jak zawodowiec",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Swoboda tworzenia",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tworzenie treści to jedno z&nbsp;najbardziej wymagających zadań dla&nbsp;komputera. Na&nbsp;szczęście te komponenty umożliwiają optymalizację procesu twórczego.",
                "titleArray": [
                  {
                    "tr_title": "Inteligentniejszy sposób tworzenia",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ożyw swoje obrazy",
                    "tr_title1": "Zacznij tworzyć",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Pamięć do wielozadaniowości",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zajrzyj do wnętrza komputera",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "To trzy główne elementy, na które warto zwrócić uwagę podczas wybierania nowego komputera. Decydując się na sposób korzystania z&nbsp;nowego komputera, wchodzisz na dobrą drogę do wyboru, w&nbsp;jakim stopniu potrzebujesz tych trzech kluczowych składników. Są to:",
                "titleArray": [
                  {
                    "tr_title": "Wydajność na potrzeby grania i&nbsp;streamowania",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Pamięć masowa",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Pamięć operacyjna",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Cała Twoja rozrywka. Jeden&nbsp;komputer.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "W&nbsp;przypadku komputera pełniącego funkcję centrum rozrywki potrzebna jest odpowiednia ilość pamięci masowej na ulubione utwory, filmy i&nbsp;gry oraz odpowiednie połączenie procesora i&nbsp;wyświetlacza, które zapewnią wciągającą grafikę. Ten komputer ma te wszystkie trzy funkcje.",
                "titleArray": [
                  {
                    "tr_title": "Przygotuj się na efekty wizualne nowej generacji",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Zbuduj cyfrową bibliotekę",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno na rozrywkę",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Płynne granie i&nbsp;streamowanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Zbuduj cyfrową bibliotekę",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno na rozrywkę",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pamięć operacyjna",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Wyświetlacz",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Wydajna wielozadaniowość",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WYDAJNA WIELOZADANIOWOŚĆ",
                "tr_subtitle": "Więcej możliwości bez spowolnienia",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby wydajnej wielozadaniowości:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać wiele programów jednocześnie."
                  },
                  {
                    "tr_title": "Szybka, mocna i niezawodna sieć Wi&#8209;Fi."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która bezproblemowo przełącza się pomiędzy aplikacjami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wideorozmowy",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WIDEOROZMOWY",
                "tr_subtitle": "Krystaliczna jakość połączeń",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby wideorozmów:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który może równocześnie nagrywać i&nbsp;udostępniać obrazy."
                  },
                  {
                    "tr_title": "Szybka, mocna i niezawodna sieć Wi&#8209;Fi."
                  },
                  {
                    "tr_title": "Kamera internetowa umożliwiająca uzyskanie niesamowicie wyraźnego obrazu."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Obsługa rzeczywistości wirtualnej",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Technologia VR budzi się do życia",
                "tr_subtitle": "Co jest potrzebne do stworzenia całkowicie wirtualnego świata? Komputer wymaga koordynacji procesora, karty graficznej i&nbsp;pamięci.",
                "titleArray": [
                  {
                    "tr_title": "Moc wirtualnych światów",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Diabeł tkwi w&nbsp;szczegółach",
                    "tr_title1": "Daj się wciągnąć",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Edycja filmów",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDYCJA FILMÓW",
                "tr_subtitle": "Twórz magię kina",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby edycji filmów:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać duże pliki."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, dzięki której oprogramowanie do edycji jest szybkie i&nbsp;wydajne."
                  },
                  {
                    "tr_title": "Grafika, która skraca czas renderowania."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wydajna wielozadaniowość",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WYDAJNA WIELOZADANIOWOŚĆ",
                "tr_subtitle": "Więcej możliwości bez spowolnienia",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby wydajnej wielozadaniowości:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać wiele programów jednocześnie."
                  },
                  {
                    "tr_title": "Szybka, mocna i niezawodna sieć Wi&#8209;Fi."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która bezproblemowo przełącza się pomiędzy aplikacjami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Obsługa rzeczywistości wirtualnej",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Technologia VR budzi się do życia",
                "tr_subtitle": "Co jest potrzebne do stworzenia całkowicie wirtualnego świata? Komputer wymaga koordynacji procesora, karty graficznej i&nbsp;pamięci.",
                "titleArray": [
                  {
                    "tr_title": "Moc wirtualnych światów",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Diabeł tkwi w&nbsp;szczegółach",
                    "tr_title1": "Daj się wciągnąć",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Modelowanie 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELOWANIE 3D",
                "tr_subtitle": "Nowy poziom tworzenia",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby modelowania 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać zaawansowane oprogramowanie do pracy twórczej."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która jest w&nbsp;stanie szybko otwierać duże pliki."
                  },
                  {
                    "tr_title": "Grafika umożliwiająca wydajne tworzenie i&nbsp;edycję obrazów."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Współpraca w&nbsp;czasie rzeczywistym",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WSPÓŁPRACA W&nbsp;CZASIE RZECZYWISTYM",
                "tr_subtitle": "Praca z&nbsp;dowolnego miejsca",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby współpracy online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor obsługujący oprogramowanie biurowe."
                  },
                  {
                    "tr_title": "Sieć Wi&#8209;Fi, na której można polegać."
                  },
                  {
                    "tr_title": "Kamera internetowa, która nagrywa w&nbsp;niesamowitej jakości HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Obsługa rzeczywistości wirtualnej",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Technologia VR budzi się do życia",
                "tr_subtitle": "Co jest potrzebne do stworzenia całkowicie wirtualnego świata? Komputer wymaga koordynacji procesora, karty graficznej i&nbsp;pamięci.",
                "titleArray": [
                  {
                    "tr_title": "Moc wirtualnych światów",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Diabeł tkwi w&nbsp;szczegółach",
                    "tr_title1": "Daj się wciągnąć",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptopy Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Po tak smukłych i&nbsp;lekkich laptopach nikt nie spodziewa się takiej mocy",
                "tr_subtitle": "Laptopy Intel® Evo™, zaprojektowane z&nbsp;myślą o&nbsp;wydajności mobilnej, zapewniają odpowiednią szybkość, grafikę i&nbsp;czas pracy baterii w&nbsp;niesamowicie eleganckich, smukłych i&nbsp;lekkich konstrukcjach.",
                "titleArray": [
                  {
                    "tr_title": "Zadbaliśmy o&nbsp;to, aby możliwości laptopa dorównywały jego znaczeniu.",
                    "tr_subtitle": "Użyj ekranu dotykowego, aby odkrywać, obracać i&nbsp;powiększać."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Błyskawiczne wznawianie pracy",
                    "tr_subtitle": "Dzięki ekspresowemu wznawianiu pracy komputer może przejść ze stanu&nbsp;uśpienia do stanu gotowości w&nbsp;niecałą sekundę."
                  },
                  {
                    "tr_title": "Doskonały obraz",
                    "tr_subtitle": "Piękny ekran ożywia Twoje obrazy dzięki wysokiej rozdzielczości i&nbsp;jaskrawym barwom."
                  },
                  {
                    "tr_title": "Szybsze wykonywanie zadań",
                    "tr_subtitle": "Ciesz się ultrawysoką wydajnością dzięki procesorom Intel®&nbsp;Core™ jedenastej&nbsp;generacji."
                  },
                  {
                    "tr_title": "Dłuższa praca na baterii",
                    "tr_subtitle": "Zachowaj płynność pracy dzięki komputerowi, który optymalizuje czas&nbsp;pracy baterii i&nbsp;ładuje ją&nbsp;w&nbsp;mgnieniu oka."
                  },
                  {
                    "tr_title": "Uniwersalna łączność przewodowa",
                    "tr_subtitle": "Technologia Thunderbolt™&nbsp;4 umożliwia&nbsp;proste, szybkie i&nbsp;bezpieczne&nbsp;podłączanie&nbsp;innych urządzeń do&nbsp;komputera.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Szybkie, niezawodne połączenia",
                    "tr_subtitle": "Prowadź rozmowy wideo lub udostępniaj pliki za pomocą połączenia, na którym możesz polegać, z&nbsp;wykorzystaniem technologii&nbsp;Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptopy Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Wszystko czego potrzebujesz, a nawet więcej, w smukłym i lekkim laptopie.",
                "tr_subtitle": "Laptopy Intel® Evo™ zostały zaprojektowane z&nbsp;myślą o&nbsp;wydajności mobilnej i&nbsp;zapewniają najlepsze wrażenia<sup>2</sup>, niezależnie od tego, co robisz.",
                "titleArray": [
                  {
                    "tr_title": "Zadbaliśmy o&nbsp;to, aby możliwości laptopa dorównywały jego znaczeniu.",
                    "tr_subtitle": "Użyj ekranu dotykowego, aby odkrywać, obracać i&nbsp;powiększać."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Błyskawiczne wznawianie pracy",
                    "tr_subtitle": "Dzięki ekspresowemu wznawianiu pracy komputer może przejść ze stanu&nbsp;uśpienia do stanu gotowości w&nbsp;niecałą sekundę."
                  },
                  {
                    "tr_title": "Doskonały obraz",
                    "tr_subtitle": "Piękny ekran ożywia Twoje obrazy dzięki wysokiej rozdzielczości i&nbsp;jaskrawym barwom."
                  },
                  {
                    "tr_title": "Rewolucyjna wydajność",
                    "tr_subtitle": "Uzyskaj dodatkową moc tam, gdzie jej najbardziej potrzebujesz dzięki inteligentnej wydajności procesorów Intel® Core™ dwunastej generacji."
                  },
                  {
                    "tr_title": "Dłuższa praca na baterii",
                    "tr_subtitle": "Zachowaj płynność pracy dzięki komputerowi, który optymalizuje czas&nbsp;pracy baterii i&nbsp;ładuje ją&nbsp;w&nbsp;mgnieniu oka."
                  },
                  {
                    "tr_title": "Uniwersalna łączność przewodowa",
                    "tr_subtitle": "Technologia Thunderbolt™&nbsp;4 umożliwia&nbsp;proste, szybkie i&nbsp;bezpieczne&nbsp;podłączanie&nbsp;innych urządzeń do&nbsp;komputera.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Szybkie, niezawodne połączenia",
                    "tr_subtitle": "Prowadź wideorozmowy lub udostępniaj pliki za pomocą połączenia, na którym możesz polegać, z&nbsp;wykorzystaniem technologii Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptopy Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Po tak smukłych i&nbsp;lekkich laptopach nikt nie spodziewa się takiej mocy",
                "tr_subtitle": "Laptopy Intel® Evo™, zaprojektowane z&nbsp;myślą o&nbsp;wydajności mobilnej, zapewniają odpowiednią szybkość, grafikę i&nbsp;czas pracy baterii w&nbsp;niesamowicie eleganckich, smukłych i&nbsp;lekkich konstrukcjach.",
                "titleArray": [
                  {
                    "tr_title": "Zadbaliśmy o&nbsp;to, aby możliwości laptopa dorównywały jego znaczeniu.",
                    "tr_subtitle": "Użyj ekranu dotykowego, aby odkrywać, obracać i&nbsp;powiększać."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Błyskawiczne wznawianie pracy",
                    "tr_subtitle": "Dzięki ekspresowemu wznawianiu pracy komputer może przejść ze stanu&nbsp;uśpienia do stanu gotowości w&nbsp;niecałą sekundę."
                  },
                  {
                    "tr_title": "Doskonały obraz",
                    "tr_subtitle": "Piękny ekran ożywia Twoje obrazy dzięki wysokiej rozdzielczości i&nbsp;jaskrawym barwom."
                  },
                  {
                    "tr_title": "Szybsze wykonywanie zadań",
                    "tr_subtitle": "Ciesz się ultrawysoką wydajnością dzięki procesorom Intel®&nbsp;Core™ jedenastej&nbsp;generacji."
                  },
                  {
                    "tr_title": "Dłuższa praca na baterii",
                    "tr_subtitle": "Zachowaj płynność pracy dzięki komputerowi, który optymalizuje czas&nbsp;pracy baterii i&nbsp;ładuje ją&nbsp;w&nbsp;mgnieniu oka."
                  },
                  {
                    "tr_title": "Uniwersalna łączność przewodowa",
                    "tr_subtitle": "Technologia Thunderbolt™&nbsp;4 umożliwia&nbsp;proste, szybkie i&nbsp;bezpieczne&nbsp;podłączanie&nbsp;innych urządzeń do&nbsp;komputera.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Szybkie, niezawodne połączenia",
                    "tr_subtitle": "Prowadź rozmowy wideo lub udostępniaj pliki za pomocą połączenia, na którym możesz polegać, z&nbsp;wykorzystaniem technologii&nbsp;Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptopy Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Wszystko czego potrzebujesz, a nawet więcej, w smukłym i lekkim laptopie.",
                "tr_subtitle": "Laptopy Intel® Evo™ zostały zaprojektowane z&nbsp;myślą o&nbsp;wydajności mobilnej i&nbsp;zapewniają najlepsze wrażenia<sup>2</sup>, niezależnie od tego, co robisz.",
                "titleArray": [
                  {
                    "tr_title": "Zadbaliśmy o&nbsp;to, aby możliwości laptopa dorównywały jego znaczeniu.",
                    "tr_subtitle": "Użyj ekranu dotykowego, aby odkrywać, obracać i&nbsp;powiększać."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Błyskawiczne wznawianie pracy",
                    "tr_subtitle": "Dzięki ekspresowemu wznawianiu pracy komputer może przejść ze stanu&nbsp;uśpienia do stanu gotowości w&nbsp;niecałą sekundę."
                  },
                  {
                    "tr_title": "Doskonały obraz",
                    "tr_subtitle": "Piękny ekran ożywia Twoje obrazy dzięki wysokiej rozdzielczości i&nbsp;jaskrawym barwom."
                  },
                  {
                    "tr_title": "Rewolucyjna wydajność",
                    "tr_subtitle": "Uzyskaj dodatkową moc tam, gdzie jej najbardziej potrzebujesz dzięki inteligentnej wydajności procesorów Intel® Core™ dwunastej generacji."
                  },
                  {
                    "tr_title": "Dłuższa praca na baterii",
                    "tr_subtitle": "Zachowaj płynność pracy dzięki komputerowi, który optymalizuje czas&nbsp;pracy baterii i&nbsp;ładuje ją&nbsp;w&nbsp;mgnieniu oka."
                  },
                  {
                    "tr_title": "Uniwersalna łączność przewodowa",
                    "tr_subtitle": "Technologia Thunderbolt™&nbsp;4 umożliwia&nbsp;proste, szybkie i&nbsp;bezpieczne&nbsp;podłączanie&nbsp;innych urządzeń do&nbsp;komputera.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Szybkie, niezawodne połączenia",
                    "tr_subtitle": "Prowadź wideorozmowy lub udostępniaj pliki za pomocą połączenia, na którym możesz polegać, z&nbsp;wykorzystaniem technologii Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptopy Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Wszystko czego potrzebujesz, a nawet więcej, w smukłym i lekkim laptopie.",
                "tr_subtitle": "Laptopy Intel® Evo™ zostały zaprojektowane z&nbsp;myślą o&nbsp;wydajności mobilnej i&nbsp;zapewniają najlepsze wrażenia<sup>2</sup>, niezależnie od tego, co robisz.",
                "titleArray": [
                  {
                    "tr_title": "Zadbaliśmy o&nbsp;to, aby możliwości laptopa dorównywały jego znaczeniu.",
                    "tr_subtitle": "Użyj ekranu dotykowego, aby odkrywać, obracać i&nbsp;powiększać."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Błyskawiczne wznawianie pracy",
                    "tr_subtitle": "Dzięki ekspresowemu wznawianiu pracy komputer może przejść ze stanu&nbsp;uśpienia do stanu gotowości w&nbsp;niecałą sekundę."
                  },
                  {
                    "tr_title": "Doskonały obraz",
                    "tr_subtitle": "Piękny ekran ożywia Twoje obrazy dzięki wysokiej rozdzielczości i&nbsp;jaskrawym barwom."
                  },
                  {
                    "tr_title": "Rewolucyjna wydajność",
                    "tr_subtitle": "Uzyskaj dodatkową moc tam, gdzie jej najbardziej potrzebujesz dzięki inteligentnej wydajności procesorów Intel® Core™ trzynastej generacji."
                  },
                  {
                    "tr_title": "Dłuższa praca na baterii",
                    "tr_subtitle": "Zachowaj płynność pracy dzięki komputerowi, który optymalizuje czas&nbsp;pracy baterii i&nbsp;ładuje ją&nbsp;w&nbsp;mgnieniu oka."
                  },
                  {
                    "tr_title": "Uniwersalna łączność przewodowa",
                    "tr_subtitle": "Technologia Thunderbolt™&nbsp;4 umożliwia&nbsp;proste, szybkie i&nbsp;bezpieczne&nbsp;podłączanie&nbsp;innych urządzeń do&nbsp;komputera.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Szybkie, niezawodne połączenia",
                    "tr_subtitle": "Prowadź wideorozmowy lub udostępniaj pliki za pomocą połączenia, na którym możesz polegać, z&nbsp;wykorzystaniem technologii Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptopy Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Wszystko czego potrzebujesz, a nawet więcej, w smukłym i lekkim laptopie.",
                "tr_subtitle": "Laptopy Intel® Evo™ zostały zaprojektowane z&nbsp;myślą o&nbsp;wydajności mobilnej i&nbsp;zapewniają najlepsze wrażenia<sup>2</sup>, niezależnie od tego, co robisz.",
                "titleArray": [
                  {
                    "tr_title": "Zadbaliśmy o&nbsp;to, aby możliwości laptopa dorównywały jego znaczeniu.",
                    "tr_subtitle": "Użyj ekranu dotykowego, aby odkrywać, obracać i&nbsp;powiększać."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Błyskawiczne wznawianie pracy",
                    "tr_subtitle": "Dzięki ekspresowemu wznawianiu pracy komputer może przejść ze stanu&nbsp;uśpienia do stanu gotowości w&nbsp;niecałą sekundę."
                  },
                  {
                    "tr_title": "Doskonały obraz",
                    "tr_subtitle": "Piękny ekran ożywia Twoje obrazy dzięki wysokiej rozdzielczości i&nbsp;jaskrawym barwom."
                  },
                  {
                    "tr_title": "Rewolucyjna wydajność",
                    "tr_subtitle": "Uzyskaj dodatkową moc tam, gdzie jej najbardziej potrzebujesz dzięki inteligentnej wydajności procesorów Intel® Core™ trzynastej generacji."
                  },
                  {
                    "tr_title": "Dłuższa praca na baterii",
                    "tr_subtitle": "Zachowaj płynność pracy dzięki komputerowi, który optymalizuje czas&nbsp;pracy baterii i&nbsp;ładuje ją&nbsp;w&nbsp;mgnieniu oka."
                  },
                  {
                    "tr_title": "Uniwersalna łączność przewodowa",
                    "tr_subtitle": "Technologia Thunderbolt™&nbsp;4 umożliwia&nbsp;proste, szybkie i&nbsp;bezpieczne&nbsp;podłączanie&nbsp;innych urządzeń do&nbsp;komputera.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Szybkie, niezawodne połączenia",
                    "tr_subtitle": "Prowadź wideorozmowy lub udostępniaj pliki za pomocą połączenia, na którym możesz polegać, z&nbsp;wykorzystaniem technologii Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Co to jest edycja Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Dlaczego edycja Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatywność",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Pobudź swoją kreatywność w&nbsp;różnych miejscach",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Edycja zdjęć na pełnych obrotach",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Twórz oryginalne dzieła od ręki",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktywność",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zmaksymalizuj produktywność przy pomocy Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Przed udostępnieniem materiału identyfikuj treści deepfake za pomocą sztucznej inteligencji",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Twój świat. Na Twoich urządzeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilność",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Pomachaj na powitanie, aby sterować gestami",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Czas pracy baterii, który nie ogranicza",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automatyczne utrzymywanie łączności",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Co to jest edycja Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Dlaczego edycja Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreatywność",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Szybkie i&nbsp;płynne odtwarzanie filmów",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Twórz muzyczne hity, wykorzystując moc sztucznej inteligencji",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Najszybsza droga do ostatecznej wersji",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktywność",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zmaksymalizuj produktywność przy pomocy Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Przed udostępnieniem materiału identyfikuj treści deepfake za pomocą sztucznej inteligencji",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Twój świat. Na Twoich urządzeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilność",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Współpraca z&nbsp;dowolnego miejsca",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Czas pracy baterii, który nie ogranicza",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automatyczne utrzymywanie łączności",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Cała Twoja rozrywka. Jeden&nbsp;komputer.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "W&nbsp;przypadku komputera pełniącego funkcję centrum rozrywki potrzebna jest odpowiednia ilość pamięci masowej na ulubione utwory, filmy i&nbsp;gry oraz odpowiednie połączenie procesora i&nbsp;wyświetlacza, które zapewnią wciągającą grafikę. Ten komputer ma te wszystkie trzy funkcje.",
                "titleArray": [
                  {
                    "tr_title": "Efektowne granie i&nbsp;streamowanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Zbuduj cyfrową bibliotekę",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno na rozrywkę",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Płynne granie i&nbsp;streamowanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Zbuduj cyfrową bibliotekę",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno na rozrywkę",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zwiększ produktywność. Wszędzie.",
              "tr_tileTitle1": "Wydajniejsza praca. Szybko.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ten komputer charakteryzuje się idealną równowagą między mocą a&nbsp;mobilnością. Począwszy od procesora do smukłej i&nbsp;lekkiej konstrukcji, ten komputer wyposażony jest również w&nbsp;technologię Wi&#8209;Fi, która zapewnia łączność, a&nbsp;także odpowiednią baterię, która pozwala na pracę bez zewnętrznego źródła zasilania.",
                "tr_subtitle1": "Ten komputer zapewnia idealną równowagę między mocą i wydajnością, pomagając Ci osiągnąć więcej w krótszym czasie. Połączenie wydajnego procesora, przyspieszającej jego działanie obszernej pamięci oraz błyskawicznej łączności Wi-Fi umożliwia sprawniejsze wykonywanie codziennych zadań.",
                "tr_subtitle2": "Ten komputer charakteryzuje się idealną równowagą między mocą a&nbsp;mobilnością. Począwszy od procesora do smukłej i&nbsp;lekkiej konstrukcji, ten komputer wyposażony jest również w&nbsp;technologię Wi&#8209;Fi, która zapewnia łączność, a&nbsp;także odpowiednią pamięć do pracy wielozadaniowej.",
                "titleArray": [
                  {
                    "tr_title": "Inteligentna wydajność, która ma znaczenie",
                    "tr_title1": "Przyspiesz dzięki inteligentnej wydajności",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Najlepsze w&nbsp;klasie połączenia",
                    "tr_title1": "Łączność, która ma znaczenie",
                    "tr_title2": "Duży postęp w&nbsp;technologii Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Pracuj dłużej na baterii",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Zmieniaj programy jak zawodowiec",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Swoboda tworzenia",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tworzenie treści to jedno z&nbsp;najbardziej wymagających zadań dla&nbsp;komputera. Na&nbsp;szczęście te komponenty umożliwiają optymalizację procesu twórczego.",
                "titleArray": [
                  {
                    "tr_title": "Przyspieszona kreatywność",
                    "tr_title1": "Suń przez kreatywne projekty",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Wyższy poziom twórczości",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Pamięć do wielozadaniowości",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zajrzyj do wnętrza komputera",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "To trzy główne elementy, na które warto zwrócić uwagę podczas wybierania nowego komputera. Decydując się na sposób korzystania z&nbsp;nowego komputera, wchodzisz na dobrą drogę do wyboru, w&nbsp;jakim stopniu potrzebujesz tych trzech kluczowych składników. Są to:",
                "titleArray": [
                  {
                    "tr_title": "Procesor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Pamięć masowa",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Pamięć operacyjna",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Swoboda tworzenia",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tworzenie treści to jedno z&nbsp;najbardziej wymagających zadań dla&nbsp;komputera. Na&nbsp;szczęście te komponenty umożliwiają optymalizację procesu twórczego.",
                "titleArray": [
                  {
                    "tr_title": "Inteligentniejszy sposób tworzenia",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ożyw swoje obrazy",
                    "tr_title1": "Zacznij tworzyć",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Pamięć do wielozadaniowości",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zwiększ produktywność. Wszędzie.",
              "tr_tileTitle1": "Wydajniejsza praca. Szybko.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ten komputer charakteryzuje się idealną równowagą między mocą a&nbsp;mobilnością. Począwszy od procesora do smukłej i&nbsp;lekkiej konstrukcji, ten komputer wyposażony jest również w&nbsp;technologię Wi&#8209;Fi, która zapewnia łączność, a&nbsp;także odpowiednią baterię, która pozwala na pracę bez zewnętrznego źródła zasilania.",
                "tr_subtitle1": "Ten komputer zapewnia idealną równowagę między mocą i wydajnością, pomagając Ci osiągnąć więcej w krótszym czasie. Połączenie wydajnego procesora, przyspieszającej jego działanie obszernej pamięci oraz błyskawicznej łączności Wi-Fi umożliwia sprawniejsze wykonywanie codziennych zadań.",
                "tr_subtitle2": "Ten komputer charakteryzuje się idealną równowagą między mocą a&nbsp;mobilnością. Począwszy od procesora do smukłej i&nbsp;lekkiej konstrukcji, ten komputer wyposażony jest również w&nbsp;technologię Wi&#8209;Fi, która zapewnia łączność, a&nbsp;także odpowiednią pamięć do pracy wielozadaniowej.",
                "titleArray": [
                  {
                    "tr_title": "Stworzony, by dawać więcej możliwości",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Najlepsze w&nbsp;klasie połączenia",
                    "tr_title1": "Łączność, która ma znaczenie",
                    "tr_title2": "Duży postęp w&nbsp;technologii Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Pracuj dłużej na baterii",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Zmieniaj programy jak zawodowiec",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Obsługa rzeczywistości wirtualnej",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Technologia VR budzi się do życia",
                "tr_subtitle": "Co jest potrzebne do stworzenia całkowicie wirtualnego świata? Komputer wymaga koordynacji procesora, karty graficznej i&nbsp;pamięci.",
                "titleArray": [
                  {
                    "tr_title": "Moc wirtualnych światów",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Diabeł tkwi w&nbsp;szczegółach",
                    "tr_title1": "Daj się wciągnąć",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>ulga dla zmęczonych oczu",
      "tr_sub_header": "Niebieskie światło może powodować zaburzenia widzenia oraz zakłócać cykl snu. Wyświetlacz OLED firmy Samsung przetestowany pod kątem certyfikatu Eye Care Display szwajcarskiej organizacji SGS pomaga nie poprzez zmianę kolorów, lecz poprzez zmniejszenie długości fali potencjalnie szkodliwego niebieskiego światła, przez co zapewnia komfort widzenia i&nbsp;redukuje zmęczenie oczu.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Mniej szkodliwego niebieskiego światła"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra pod każdym względem",
      "tr_sub_header": "Ultralekka, ultrasmukła konstrukcja wyświetlaczy OLED zapewnia najwyższą jakość obrazu, jednocześnie minimalizując ich masę."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Prawdziwa czerń",
      "tr_sub_header": "0,0005&nbsp;nita"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "<span class='conventional_text'>Konwencjonalny wyświetlacz:</span><br/>74%",
      "tr_sub_header": "<span class='samsungoled_text'>Samsung OLED:</span><br/>120%"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streamowanie",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMOWANIE",
                "tr_subtitle": "Niekończąca się rozrywka",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby streamowania:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie streamować w&nbsp;jakości HD."
                  },
                  {
                    "tr_title": "Wyświetlacz zapewniający jasne, piękne obrazy."
                  },
                  {
                    "tr_title": "Sieć Wi&#8209;Fi zapewniającą krótsze buforowanie i&nbsp;ładowanie."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Przeglądanie Internetu",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PRZEGLĄDANIE INTERNETU",
                "tr_subtitle": "Łatwa obsługa Internetu",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby przeglądania Internetu:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który może szybko ładować strony zaawansowane multimedialnie."
                  },
                  {
                    "tr_title": "Szybka i&nbsp;niezawodna sieć Wi&#8209;Fi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wideorozmowy",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WIDEOROZMOWY",
                "tr_subtitle": "Krystaliczna jakość połączeń",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby wideorozmów:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który może równocześnie nagrywać i&nbsp;udostępniać obrazy."
                  },
                  {
                    "tr_title": "Szybka, mocna i niezawodna sieć Wi&#8209;Fi."
                  },
                  {
                    "tr_title": "Kamera internetowa umożliwiająca uzyskanie niesamowicie wyraźnego obrazu."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Wydajna wielozadaniowość",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WYDAJNA WIELOZADANIOWOŚĆ",
                "tr_subtitle": "Więcej możliwości bez spowolnienia",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby wydajnej wielozadaniowości:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać wiele programów jednocześnie."
                  },
                  {
                    "tr_title": "Szybka, mocna i niezawodna sieć Wi&#8209;Fi."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która bezproblemowo przełącza się pomiędzy aplikacjami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Wideorozmowy",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WIDEOROZMOWY",
                "tr_subtitle": "Krystaliczna jakość połączeń",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby wideorozmów:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który może równocześnie nagrywać i&nbsp;udostępniać obrazy."
                  },
                  {
                    "tr_title": "Szybka, mocna i niezawodna sieć Wi&#8209;Fi."
                  },
                  {
                    "tr_title": "Kamera internetowa umożliwiająca uzyskanie niesamowicie wyraźnego obrazu."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Proste gry",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PROSTE GRY",
                "tr_subtitle": "Czas na grę",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby płynnego grania:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Procesor zapewniający płynną rozgrywkę."
                  },
                  {
                    "tr_title": "Pamięć operacyjna umożliwiająca równoczesne rozmowy głosowe i&nbsp;streamowanie."
                  },
                  {
                    "tr_title": "Grafika zapewniająca niesamowite obrazy."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edycja zdjęć",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDYCJA ZDJĘĆ",
                "tr_subtitle": "Zdjęcia idealne co do piksela",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby edycji zdjęć:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać edycję dużych grup obrazów."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która przyspiesza oprogramowanie do edycji."
                  },
                  {
                    "tr_title": "Technologia Thunderbolt™&nbsp;4 umożliwia szybkie przesyłanie plików."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Wydajna wielozadaniowość",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WYDAJNA WIELOZADANIOWOŚĆ",
                "tr_subtitle": "Więcej możliwości bez spowolnienia",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby wydajnej wielozadaniowości:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać wiele programów jednocześnie."
                  },
                  {
                    "tr_title": "Szybka, mocna i niezawodna sieć Wi&#8209;Fi."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która bezproblemowo przełącza się pomiędzy aplikacjami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Współpraca w&nbsp;czasie rzeczywistym",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WSPÓŁPRACA W&nbsp;CZASIE RZECZYWISTYM",
                "tr_subtitle": "Praca z&nbsp;dowolnego miejsca",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby współpracy online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor obsługujący oprogramowanie biurowe."
                  },
                  {
                    "tr_title": "Sieć Wi&#8209;Fi, na której można polegać."
                  },
                  {
                    "tr_title": "Kamera internetowa, która nagrywa w&nbsp;niesamowitej jakości HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edycja filmów",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDYCJA FILMÓW",
                "tr_subtitle": "Twórz magię kina",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby edycji filmów:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać duże pliki."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, dzięki której oprogramowanie do edycji jest szybkie i&nbsp;wydajne."
                  },
                  {
                    "tr_title": "Grafika, która skraca czas renderowania."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edycja zdjęć",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDYCJA ZDJĘĆ",
                "tr_subtitle": "Zdjęcia idealne co do piksela",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby edycji zdjęć:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać edycję dużych grup obrazów."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która przyspiesza oprogramowanie do edycji."
                  },
                  {
                    "tr_title": "Technologia Thunderbolt™&nbsp;4 umożliwia szybkie przesyłanie plików."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Edycja filmów",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDYCJA FILMÓW",
                "tr_subtitle": "Twórz magię kina",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby edycji filmów:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać duże pliki."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, dzięki której oprogramowanie do edycji jest szybkie i&nbsp;wydajne."
                  },
                  {
                    "tr_title": "Grafika, która skraca czas renderowania."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Modelowanie 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELOWANIE 3D",
                "tr_subtitle": "Nowy poziom tworzenia",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby modelowania 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Procesor, który jest w&nbsp;stanie obsługiwać zaawansowane oprogramowanie do pracy twórczej."
                  },
                  {
                    "tr_title": "Pamięć operacyjna, która jest w&nbsp;stanie szybko otwierać duże pliki."
                  },
                  {
                    "tr_title": "Grafika umożliwiająca wydajne tworzenie i&nbsp;edycję obrazów."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Współpraca w&nbsp;czasie rzeczywistym",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WSPÓŁPRACA W&nbsp;CZASIE RZECZYWISTYM",
                "tr_subtitle": "Praca z&nbsp;dowolnego miejsca",
                "tr_subtitle1": "Oto zalety tego komputera na potrzeby współpracy online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor obsługujący oprogramowanie biurowe."
                  },
                  {
                    "tr_title": "Sieć Wi&#8209;Fi, na której można polegać."
                  },
                  {
                    "tr_title": "Kamera internetowa, która nagrywa w&nbsp;niesamowitej jakości HD."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Aplikacja Intel® Unison™",
    "tr_title": "Odblokuj połączony świat",
    "tr_subtitle": "Bezproblemowo przesyłaj pliki i&nbsp;zdjęcia, jednocześnie zarządzając powiadomieniami, połączeniami i&nbsp;wiadomościami tekstowymi ze smartfona przy pomocy komputera<sup>18</sup>.",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "PRZESYŁAJ PLIKI I&nbsp;ZDJĘCIA",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "WYKONUJ I&nbsp;ODBIERAJ POŁĄCZENIA",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "WYSYŁAJ I&nbsp;ODBIERAJ WIADOMOŚCI TEKSTOWE",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "ZARZĄDZAJ POWIADOMIENIAMI ZE SMARTFONA",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "ODBLOKUJ POŁĄCZONY ŚWIAT",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}